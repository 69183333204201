import { MemberList } from 'api/Admin/Member';
import {
    GetMemberListAction,
    MemberAction,
    GetMemberListSuccessAction,
    GetMemberListErrorAction,
} from './types';

export function getMemberList(refresh?: boolean): GetMemberListAction {
    return {
        type: MemberAction.GET_MEMBER_LIST,
        payload: { refresh: refresh || false },
    };
}

export function getMemberListSuccess(
    data: MemberList
): GetMemberListSuccessAction {
    return {
        type: MemberAction.GET_MEMBER_LIST_SUCCESS,
        payload: { data },
    };
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function getMemberListError(error: any): GetMemberListErrorAction {
    return {
        type: MemberAction.GET_MEMBER_LIST_ERROR,
        error,
    };
}
