import history from 'utils/history';
import { authInitialState } from './auth';
import { adminInitialState } from './admin';
import { appInitialState } from './app';
import { ApplicationRootState } from './types';

const initialState: ApplicationRootState = {
    app: appInitialState,
    auth: authInitialState,
    admin: adminInitialState,
    router: history,
};

export default initialState;
