import React, { FunctionComponent } from 'react';
import ConfirmationModal from '../ConfirmationModal';
import { DeleteConfirmationModalProps } from './DeleteConfirmationModal.types';

/**
 * A confirmation modal for confirming a delete action.
 */
const DeleteConfirmationModal: FunctionComponent<DeleteConfirmationModalProps> = ({
    submitButtonText = 'Delete',
    ...rest
}) => {
    return (
        <ConfirmationModal
            {...rest}
            submitButtonText={submitButtonText}
            submitButtonStyle="primary"
        />
    );
};

export default DeleteConfirmationModal;
