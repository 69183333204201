import { Entity } from 'api/Entities';
import { AsyncStateNonNull, ReduxAction } from 'types';

export enum AppEntitiesAction {
    GET_ENTITIES_LIST = 'app/entities/GET_ENTITIES_LIST',
    GET_ENTITIES_LIST_SUCCESS = 'app/entities/GET_ENTITIES_LIST_SUCCESS',
    GET_ENTITIES_LIST_ERROR = 'app/entities/GET_ENTITIES_LIST_ERROR',
}

export type GetEntitiesListAction = ReduxAction<
    AppEntitiesAction.GET_ENTITIES_LIST
>;

export type AppEntitiesActions = GetEntitiesListAction;

export type AppEntitiesState = AsyncStateNonNull<Entity[]>;
