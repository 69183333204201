/* eslint-disable import/export */
/* eslint-disable prettier/prettier */
export const ACCOUNT_SETUP_ROUTE = '/setup-account';
export const CLIENT_ACCOUNT_SETUP_ROUTE = '/:clientName/setup-account';
export const ADMIN_ROUTE = '/admin';
export const DASHBOARD_ROUTE = '/clients/:clientId/dashboard';
export const MEDIA_PLANS_ROUTE = '/plans';
export const CAMPAIGNS_ROUTE =
    '/clients/:clientId/advertisers/:advertiserId/campaigns';

export function pathToCampaignOverview(clientId, advertiserId) {
    return CAMPAIGNS_ROUTE.replace(':clientId', clientId).replace(
        ':advertiserId',
        advertiserId
    );
}

export const AD_GROUPS_ROUTE =
    '/clients/:clientId/advertisers/:advertiserId/campaigns/ad_groups';

export function pathToAdGroupOverview(clientId, advertiserId) {
    return AD_GROUPS_ROUTE.replace(':clientId', clientId).replace(
        ':advertiserId',
        advertiserId
    );
}

export const ENTITIES_ROUTE =
    '/clients/:clientId/advertisers/:advertiserId/campaigns/:entityLabel';

export function pathToEntityOverview(clientId, advertiserId, entityLabel) {
    return ENTITIES_ROUTE.replace(':clientId', clientId)
        .replace(':advertiserId', advertiserId)
        .replace(':entityLabel', entityLabel.plural.toLowerCase());
}
export const REPORTING_ROUTE =
    '/clients/:clientId/advertisers/:advertiserId/reporting';

export function pathToReportingOverview(clientId, advertiserId) {
        return REPORTING_ROUTE.replace(':clientId', clientId).replace(
            ':advertiserId',
            advertiserId
        );
}


export const SAVED_REPORTS_ROUTE = `${REPORTING_ROUTE}/saved_reports`;
export const INDIVIDUAL_SAVED_REPORT_ROUTE = `${REPORTING_ROUTE}/individual_saved_report`;

export function pathToSavedReports(clientId, advertiserId) {
    return SAVED_REPORTS_ROUTE.replace(':clientId', clientId).replace(
        ':advertiserId',
        advertiserId
    );
}

export function pathToIndividualSavedReport(clientId, advertiserId) {
    return INDIVIDUAL_SAVED_REPORT_ROUTE.replace(':clientId', clientId).replace(
        ':advertiserId',
        advertiserId
    );
}

export const CREATE_CAMPAIGNS_ROUTE = `/clients/:clientId/advertisers/:advertiserId/campaigns/create`;
export const EDIT_CAMPAIGN_ROUTE = `/clients/:clientId/advertisers/:advertiserId/campaigns/:campaignId`;
export const VIEW_AD_GROUPS_ROUTE = `${EDIT_CAMPAIGN_ROUTE}/ad_groups`;
export const CREATE_AD_GROUP_ROUTE = `${EDIT_CAMPAIGN_ROUTE}/ad_groups/create`;
export const EDIT_AD_GROUP_ROUTE = `${EDIT_CAMPAIGN_ROUTE}/ad_groups/:adGroupId`;
export const LOGIN_ROUTE = '/login';
export const CLIENT_LOGIN_ROUTE = '/:clientName/login';
export const CREATE_CAMPAIGNS_ROUTE_V2 = `/clients/:clientId/advertisers/:advertiserId/campaigns/create/v2`;
export const EDIT_CAMPAIGN_ROUTE_V2 = `/clients/:clientId/advertisers/:advertiserId/campaigns/:campaignId/v2`;

export const CREATE_CAMPAIGN_TEMPLATE = `/admin/clients/:clientId/template/create`;
export const EDIT_CAMPAIGN_TEMPLATE = `/admin/clients/:clientId/template/:templateId`;

export const CLIENT_ADVERTISER_MANAGEMENT_ROUTE = `${ADMIN_ROUTE}/clients`;
export const CLIENT_ADVERTISER_MANAGEMENT_CLIENTS_ROUTE = `${ADMIN_ROUTE}/clients`;
export const CLIENT_ADVERTISER_MANAGEMENT_ADVERTISER_GROUPS_ROUTE = `${CLIENT_ADVERTISER_MANAGEMENT_ROUTE}/advertiser-groups`;
export const CLIENT_ADVERTISER_MANAGEMENT_ADVERTISERS_ROUTE = `${CLIENT_ADVERTISER_MANAGEMENT_ROUTE}/advertisers`;
export const USER_MANAGEMENT_ROUTE = `${ADMIN_ROUTE}/users`;

export function pathToCreateCampaign(clientId, advertiserId) {
    return `/clients/${clientId}/advertisers/${advertiserId}/campaigns/create`;
}

export function pathToTemplateOverview(clientId) {
    return `/admin/clients/${clientId}/template-overview`;
}

export function pathToEditCampaign(clientId, advertiserId, campaignId) {
    return `/clients/${clientId}/advertisers/${advertiserId}/campaigns/${campaignId}`;
}

export function pathToCreateCampaignV2(clientId, advertiserId) {
    return `/clients/${clientId}/advertisers/${advertiserId}/campaigns/create/v2`;
}

export function pathToEditCampaignV2(clientId, advertiserId, campaignId) {
    return `/clients/${clientId}/advertisers/${advertiserId}/campaigns/${campaignId}/v2`;
}

export function pathToEditAdGroup(
    clientId,
    advertiserId,
    campaignId,
    adGroupId
) {
    return `/clients/${clientId}/advertisers/${advertiserId}/campaigns/${campaignId}/ad_groups/${adGroupId}`;
}

export function pathToCreateAdGroup(clientId, advertiserId, campaignId) {
    return `/clients/${clientId}/advertisers/${advertiserId}/campaigns/${campaignId}/ad_groups/create`;
}

export function pathToViewAdGroups(clientId, advertiserId, campaignId) {
    return `/clients/${clientId}/advertisers/${advertiserId}/campaigns/${campaignId}/ad_groups`;
}

// new
export const ADMIN_HOME_PATH = '/admin';

export const CENTRALIZED_CLIENTS = '/admin/clients';
export const CENTRALIZED_AD_GROUPS = '/admin/advertiser-groups';
export const CENTRALIZED_ADVERTISERS = '/admin/advertisers';
export const CENTRALIZED_USER_PATH = '/admin/users';

export const USER_PROFILE_PATH = '/admin/users/:userId';
export const USER_CREATE_PATH = '/admin/users/create';

export const ADVERTISER_GROUP_CREATE = '/admin/advertiser-groups/create';
export const CLIENT_CREATE_PATH = '/admin/clients/create';
export const ADVERTISER_CREATE_PATH = '/admin/advertisers/create';

export const CLIENT_ADVERTISER_GROUP =
    '/admin/clients/:clientId/advertiser-groups';
export const CLIENT_ADVERTISER = '/admin/clients/:clientId/advertisers';
export const CLIENT_USERS = '/admin/clients/:clientId/users';
export const CLIENT_INVOICES = '/admin/clients/:clientId/invoices';
export const CLIENT_BRANDING = '/admin/clients/:clientId/branding';
export const CLIENT_SPEND_LIMITS = '/admin/clients/:clientId/spend-limit';
export const CLIENT_FUNDING_AND_BILLING =
    '/admin/clients/:clientId/funding-and-billing';
export const CLIENT_SETTINGS = '/admin/clients/:clientId/settings';
export const CLIENT_TARGETING_CONFIGURATION =
    '/admin/clients/:clientId/targeting-configuration';
export const CLIENT_TARGETING_CONFIGURATION_EDIT =
    '/admin/clients/:clientId/targeting-configuration/:targetingId/edit';
export const CLIENT_TARGETING_CONFIGURATION_EDIT_V2 =
    '/admin/clients/:clientId/experiences/:experienceId/targeting-configuration/:targetingId/edit';
export const CLIENT_TARGETING_CONFIGURATION_CREATE =
    '/admin/clients/:clientId/create-targeting-configuration';
export const CLIENT_CURRENCY_LOCALIZATION =
    '/admin/clients/:clientId/currency-localization';
export const CLIENT_TEMPLATE_OVERVIEW =
    '/admin/clients/:clientId/template-overview';
export const CLIENT_TEMPLATE_CREATION =
    '/admin/clients/:clientId/template/create';
export const CLIENT_ADD_CURRENCY = '/admin/clients/:clientId/add-currency';

export const ADVERTISER_GROUP_PROFILE_PATH =
    '/admin/clients/:clientId/advertiser-groups/:advertiserGroupId/:resourceType';

export const ADVERTISER_USERS =
    '/admin/clients/:clientId/advertisers/:advertiserId/users';
export const ADVERTISER_ENTITIES =
    '/admin/clients/:clientId/advertisers/:advertiserId/entities';
export const ADVERTISER_FUNDING =
    '/admin/clients/:clientId/advertisers/:advertiserId/funding';
export const ADVERTISER_INVOICES =
    '/admin/clients/:clientId/advertisers/:advertiserId/invoices';
export const ADVERTISER_PAYMENT =
    '/admin/clients/:clientId/advertisers/:advertiserId/payment';
export const ADVERTISER_TRANSACTIONS =
    '/admin/clients/:clientId/advertisers/:advertiserId/transaction-history';
export const ADVERTISER_BILLING_EVENT_INVOICE =
    '/admin/clients/:clientId/advertisers/:advertiserId/transaction-history/invoice/:balanceEventId';

export function pathToAddFunds(clientId, advertiserId) {
    return `/admin/clients/${clientId}/advertisers/${advertiserId}/payment`;
}

// ----- current routes ------
export const CONTEXTUAL_ADVERTISER_GROUP_CREATE_PATH =
    '/admin/clients/:clientId/add-advertiser-groups';
export const CLIENT_CONTEXTUAL_USER_CREATE =
    '/admin/clients/:clientId/add-users';
export const ADVERTISER_CONTEXTUAL_USER_CREATE =
    '/admin/clients/:clientId/advertisers/:advertiserId/add-users';
export const CONTEXTUAL_ADVERTISER_CREATE =
    '/admin/clients/:clientId/add-advertisers';

export const CONTEXTUAL_CLIENT_USER_PROFILE =
    '/admin/clients/:clientId/users/:userId';

export const CONTEXTUAL_CLIENT_USER_EDIT =
    '/admin/clients/:clientId/users/:userId/edit';

export const CONTEXTUAL_ADVERTISER_USER_PROFILE =
    '/admin/clients/:clientId/advertisers/:advertiserId/users/:userId';

export const CONTEXTUAL_ADVERTISER_USER_EDIT =
    '/admin/clients/:clientId/advertisers/:advertiserId/users/:userId/edit';

// Registration path routes
export const REGISTRATION_ROUTE = '/:clientName/register';

export const ADVERTISER_REGISTER_BILLING_ROUTE =
    '/:clientName/register/advertisers/billing';
export const ADVERTISER_REGISTER_PAYMENT_ROUTE =
    '/:clientName/register/advertisers/:advertiserId/payment';
export const ADVERTISER_REGISTER_ENTITIES_ROUTE =
    '/:clientName/register/advertisers/:advertiserId/entities';
export const ADVERTISER_REGISTER_ROUTE = '/:clientName/register/advertisers/*';

export const pathToAdvertiserRegistration = (
    clientName,
    pathname,
    advertiserId
) => {
    if (advertiserId) {
        return `${ADVERTISER_REGISTER_ROUTE.replace(
            '*',
            `${advertiserId}/`
        ).replace(':clientName', clientName)}${pathname || ''}`;
    }
    return `${ADVERTISER_REGISTER_ROUTE.replace('*', '').replace(
        ':clientName',
        clientName
    )}${pathname || ''}`;
};
