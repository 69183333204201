import { combineReducers } from 'redux';
import createAsyncReducer from 'utils/createAsyncReducer';
import { TimezonesList } from 'api/Admin/Timezones';
import { TimezonesState, TimezonesAction } from './types';

export const initialState: TimezonesState = {
    list: {
        last_updated: null,
        loading: false,
        error: null,
        data: null,
    },
};

export const timezonesListReducer = createAsyncReducer<TimezonesList>(
    initialState.list,
    {
        start: TimezonesAction.GET_TIMEZONES_LIST,
        success: TimezonesAction.GET_TIMEZONES_LIST_SUCCESS,
        error: TimezonesAction.GET_TIMEZONES_LIST_ERROR,
    }
);

const timezonesReducer = combineReducers<TimezonesState>({
    list: timezonesListReducer,
});

export default timezonesReducer;
