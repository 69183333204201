/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import KoddiAPI from 'api';
import { createSimpleSagaWithCache } from 'utils/createSimpleSaga';
import { takeEvery, all } from 'redux-saga/effects';
import SagaRegistry from 'redux-core/sagaRegistry';
import { TimezonesList } from 'api/Admin/Timezones';
import { getTimezonesListError, getTimezonesListSuccess } from './actions';
import { TimezonesAction } from './types';
import { selectTimezonesList } from './selectors';

export const getTimezonesListApi = (): Promise<TimezonesList> =>
    KoddiAPI.Admin.Timezones.getList();

export const getTimezonesListSaga = createSimpleSagaWithCache(
    getTimezonesListApi,
    getTimezonesListSuccess,
    getTimezonesListError,
    selectTimezonesList
);

export function* watchGetTimezonesList() {
    yield takeEvery(TimezonesAction.GET_TIMEZONES_LIST, getTimezonesListSaga);
}

export function* timezonesSagas() {
    yield all([watchGetTimezonesList()]);
}

SagaRegistry.registerSaga(timezonesSagas);
