import { Experience } from 'api/Admin/MemberGroupExperiences/MemberGroupExperiences.types';
import { ReduxActionWithPayload } from 'types';

export type ExperiencesState = {
    memberGroupExperiences: Experience[] | null;
};

export enum ExperiencesAction {
    SET_MEMBER_GROUP_EXPERIENCES = 'admin/experiences/SET_MEMBER_GROUP_EXPERIENCES',
}

export type SetExperiencesAction = ReduxActionWithPayload<
    ExperiencesAction.SET_MEMBER_GROUP_EXPERIENCES,
    {
        experiences: Experience[];
    }
>;

export type ExperiencesActions = SetExperiencesAction;
