import { MemberGroupTypesList } from 'api/Admin/MemberGroupTypes';
import {
    MemberGroupTypesAction,
    GetMemberGroupTypesAction,
    GetMemberGroupTypesSuccessAction,
    GetMemberGroupTypesErrorAction,
} from './types';

export function getMemberGroupTypes(
    refresh = false
): GetMemberGroupTypesAction {
    return {
        type: MemberGroupTypesAction.GET_MEMBER_GROUP_TYPES,
        payload: { refresh },
    };
}

export function getMemberGroupTypesSuccess(
    data: MemberGroupTypesList
): GetMemberGroupTypesSuccessAction {
    return {
        type: MemberGroupTypesAction.GET_MEMBER_GROUP_TYPES_SUCCESS,
        payload: { data },
    };
}

export function getMemberGroupTypesError(
    error: Error
): GetMemberGroupTypesErrorAction {
    return {
        type: MemberGroupTypesAction.GET_MEMBER_GROUP_TYPES_ERROR,
        error,
    };
}
