import React, { FunctionComponent } from 'react';
import { BaseSVGProps } from '../icon.types';

const PencilIcon: FunctionComponent<BaseSVGProps> = ({ width, height }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        height={height}
        width={width}
        viewBox="0 0 24 24"
    >
        <path d="M0 20.39V24h3.59L18.44 9.13l-3.58-3.59zM20.44 0l-3 3L21 6.54l3-3z" />
    </svg>
);

export default PencilIcon;
