/* eslint-disable react/require-default-props */
import React from 'react';
import { Route } from 'react-router';
import { VerticalFlexBox } from 'koddi-components/FlexBox';

import {
    ADVERTISER_REGISTER_PAYMENT_ROUTE,
    ADVERTISER_REGISTER_ENTITIES_ROUTE,
    ADVERTISER_REGISTER_BILLING_ROUTE,
} from 'modules/constants/routes';
import RegisterAdvertiserForm from './Forms/RegisterAdvertiserForm';
import RegisterPaymentForm from './Forms/RegisterPaymentForm';
import RegisterEntitiesForm from './Forms/RegisterEntitiesForm';

export const AdvertiserRegistrationRoutes = ({
    isAuth0Styled,
}: {
    isAuth0Styled?: boolean;
}): JSX.Element => (
    <VerticalFlexBox
        alignItems="center"
        height={isAuth0Styled ? '' : '90vh'}
        overflow={isAuth0Styled ? 'none' : 'auto'}
    >
        <Route exact path={ADVERTISER_REGISTER_BILLING_ROUTE}>
            <RegisterAdvertiserForm />
        </Route>
        <Route exact path={ADVERTISER_REGISTER_PAYMENT_ROUTE}>
            <RegisterPaymentForm />
        </Route>
        <Route path={ADVERTISER_REGISTER_ENTITIES_ROUTE}>
            <RegisterEntitiesForm />
        </Route>
    </VerticalFlexBox>
);
