import React from 'react';
import {
    TableProviderContextValue,
    TableProviderProps,
} from './TableProvider.types';

export const TableProviderContext = React.createContext<
    TableProviderContextValue<any> | undefined
>(undefined);

/**
 * The `TableProvider` provides child components i.e. Cells with state
 * and actions for interacting with a table.
 */
function TableProvider<Data extends Record<string, unknown>>({
    children,
    ...rest
}: TableProviderProps<Data>): JSX.Element {
    return (
        <TableProviderContext.Provider value={rest}>
            {children}
        </TableProviderContext.Provider>
    );
}

export default TableProvider;
