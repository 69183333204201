import produce, { Draft } from 'immer';
import {
    AdvertiserGroupAction,
    AdvertiserGroupActions,
    AdvertiserGroupsState,
} from './types';

export const initialState = { status: 'idle', list: [] };

export const advertiserGroupsReducer = (
    state: AdvertiserGroupsState = initialState,
    action: AdvertiserGroupActions
): AdvertiserGroupsState =>
    produce(state, (draft: Draft<AdvertiserGroupsState>) => {
        switch (action.type) {
            case AdvertiserGroupAction.DELETE_ADVERTISER_GROUP:
                draft.status = 'pending';
                break;
            case AdvertiserGroupAction.DELETE_ADVERTISER_GROUP_SUCCESS:
                draft.status = 'success';
                break;
            case AdvertiserGroupAction.DELETE_ADVERTISER_GROUP_FAILURE:
                draft.status = 'failure';
                break;
            case AdvertiserGroupAction.GET_ADVERTISER_GROUP_LIST_SUCCESS:
                draft.status = 'success';
                draft.list = action.payload.adGroups;
                break;
            case AdvertiserGroupAction.GET_ADVERTISER_GROUP_LIST_ERROR:
                draft.status = 'failure';
                break;
            default:
                break;
        }
    });

export default advertiserGroupsReducer;
