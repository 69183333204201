import produce, { Draft } from 'immer';
import { combineReducers } from 'redux';
import {
    DashboardState,
    KPICardDataAction,
    KPICardDataActions,
    KPICardInitialState,
} from './types';

export const initialState: DashboardState = {
    KPICard: {
        status: 'idle',
        data: {
            average_basket_size: {
                total: 0,
                percentage: 0,
            },
            average_conversion_rate: {
                total: 0,
                percentage: 0,
            },
            cost_per_transaction: {
                total: 0,
                percentage: 0,
            },
            exposed_roas: {
                total: 0,
                percentage: 0,
            },
            exposed_revenue: {
                total: 0,
                percentage: 0,
            },
            exposed_transactions: {
                total: 0,
                percentage: 0,
            },
            exposed_units: {
                total: 0,
                percentage: 0,
            },
            impressions: {
                total: 0,
                percentage: 0,
            },
            clicks: {
                total: 0,
                percentage: 0,
            },
            click_through_rate: {
                total: 0,
                percentage: 0,
            },
            cost: {
                total: 0,
                percentage: 0,
            },
            cost_per_click: {
                total: 0,
                percentage: 0,
            },
            revenue: {
                total: 0,
                percentage: 0,
            },
            return_on_ad_spend: {
                total: 0,
                percentage: 0,
            },
            transactions: {
                total: 0,
                percentage: 0,
            },
            units: {
                total: 0,
                percentage: 0,
            },
            halo_units: {
                total: 0,
                percentage: 0,
            },
            halo_transactions: {
                total: 0,
                percentage: 0,
            },
            halo_revenue: {
                total: 0,
                percentage: 0,
            },
            halo_roas: {
                total: 0,
                percentage: 0,
            },
            halo_exposed_revenue: {
                total: 0,
                percentage: 0,
            },
            halo_exposed_roas: {
                total: 0,
                percentage: 0,
            },
            halo_exposed_transactions: {
                total: 0,
                percentage: 0,
            },
            halo_exposed_units: {
                total: 0,
                percentage: 0,
            },
        },
    },
};

/* eslint-disable default-case, no-param-reassign */
const KPICardDataReducer = (
    state: KPICardInitialState = initialState.KPICard,
    action: KPICardDataActions
): KPICardInitialState =>
    produce(state, (draft: Draft<KPICardInitialState>) => {
        switch (action.type) {
            case KPICardDataAction.FETCH_KPI_CARD_DATA: {
                draft.status = 'pending';
                break;
            }
            case KPICardDataAction.FETCH_KPI_CARD_DATA_SUCCESS: {
                draft.data = action.payload.data;
                draft.status = 'success';
                break;
            }
            case KPICardDataAction.FETCH_KPI_CARD_DATA_ERROR:
                draft.status = 'failure';
                draft.data = initialState.KPICard.data;
                break;
            default:
                break;
        }
    });

const dashboardReducer = combineReducers<DashboardState>({
    KPICard: KPICardDataReducer,
});

export default dashboardReducer;
