import {
    ApplicationRootState,
    ApplicationOutputSelector,
} from 'redux-core/types';
import get from 'lodash/get';
import { createSelector } from 'reselect';
import { Industry, IndustryList } from 'api/Admin/Industry';
import { AsyncState } from 'types';
import { SelectOption } from 'koddi-components/Select';
import { initialState } from './reducer';
import { IndustryState } from './types';

export const selectAdminIndustry = (
    state: ApplicationRootState
): IndustryState => state.admin.industry || initialState;

export const selectIndustryList: ApplicationOutputSelector<
    AsyncState<IndustryList>,
    (res: IndustryState) => AsyncState<IndustryList>
> = createSelector(selectAdminIndustry, (state) => state.list);

export const selectIndustryListOptions: ApplicationOutputSelector<
    SelectOption<Industry>[],
    (res: IndustryState) => SelectOption<Industry>[]
> = createSelector(selectAdminIndustry, (state) => {
    if (!state || !state.list || !state.list.data) return [];
    const industries = get(state, 'list.data.industries', []);
    return industries.map((industry: Industry) => ({
        label: industry.name,
        value: industry,
    }));
});
