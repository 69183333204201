import React, { FunctionComponent } from 'react';
import { BaseSVGProps } from '../icon.types';

const BellIcon: FunctionComponent<BaseSVGProps> = ({ width, height }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        height={height}
        width={width}
        viewBox="0 0 24 24"
    >
        <path d="M10.23 23.27A2.43 2.43 0 0012 24a2.71 2.71 0 001.77-.73l2.4-2.4H7.83zm10.12-7.62v-5.21a8.39 8.39 0 00-6.26-8V0H9.91v2.4a8.39 8.39 0 00-6.26 8v5.21h-1v3.13h18.74v-3.09z" />
    </svg>
);

export default BellIcon;
