/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable react/display-name */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import debounce from 'lodash/debounce';
import numeral from 'numeral';
import styled, { css } from 'styled-components';
import { usePopper } from 'react-popper';
import { Portal } from 'react-portal';
import CheckboxCell from 'koddi-components/TableCells/CheckboxCell';
import Button, { ButtonGroup } from 'koddi-components/Button';
import Input from 'koddi-components/Input';
import Table from 'koddi-components/Table';
import { HorizontalFlexBox } from 'koddi-components/FlexBox';
import {
    useAppAdvertisers,
    useSelectedAdvertisers,
} from 'redux-core/app/advertisers/hooks';
import OnOutsideClick from 'koddi-components/OnOutsideClick';
import { useDispatch } from 'react-redux';
import { TableFilter } from 'koddi-components/Table/Table.types';
import { setSelectedAdvertisers } from 'redux-core/app/advertisers/actions';
import { useAppContextStatus } from 'redux-core/app/context/hooks';
import { useKPICardStatus } from 'redux-core/app/dashboard/hooks';

import { MultiAdvertiserWrapper } from './AdvertiserSelector.styled';

const SelectedOnlyCell = styled.span`
    font-size: 12px;
`;

const MultiAdvertiserModal = styled.div`
    ${({ theme: { spaceUnit, white } }) => css`
        display: flex;
        flex-direction: column;
        background-color: ${white};
        padding: ${spaceUnit * 4}px;
        min-width: 560px;
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
        border-radius: 4px;
    `}
`;

const TableWrapper = styled.div`
    ${({ theme: { spaceUnit } }) => css`
        margin: ${spaceUnit * 4}px ${spaceUnit * 2}px;
        flex: 1;
        &:first-of-type {
            margin-left: 0px;
        }
        &:last-of-type {
            margin-right: 0px;
        }
    `}
`;

const TableTitle = styled.div`
    ${({ theme: { spaceUnit } }) => css`
        font-size: 14px;
        text-align: center;
        margin-bottom: ${spaceUnit * 2}px;
    `}
`;

const selectedOnlyColumns = [
    {
        Header: ' ',
        id: 'name',
        accessor: ({ name, advertiser_id }: any) =>
            `${name} (${advertiser_id})`,
        Cell: function Row({ value }: any) {
            return (
                <SelectedOnlyCell>
                    <div translate="no">{value}</div>
                </SelectedOnlyCell>
            );
        },
        disableSortBy: true,
    },
];

const MultiAdvertiserSelector = (): JSX.Element => {
    const dispatch = useDispatch();
    const [initialized, setInitialized] = useState(false);
    const [allSelected, setAllSelected] = useState(true);
    const [isOpen, setIsOpen] = useState(false);
    const [allSearchValue, setAllSearchValue] = useState('');
    const [allFilters, setAllFilters] = useState<TableFilter[]>([]);
    const [selectedOnlySearchValue, setSelectedOnlySearchValue] = useState('');
    const [selectedOnlyFilters, setSelectedOnlyFilters] = useState<
        TableFilter[]
    >([]);
    const selectedAppAdvertisers = useSelectedAdvertisers();

    const [initialDraft, setInitialDraft] = useState<Record<
        number,
        true
    > | null>(null);
    const [selectedDraft, setSelectedDraft] = useState<any | null>({});
    const selectedDraftCount = Object.keys(selectedDraft).length;

    const { data: appAdvertisers } = useAppAdvertisers();
    const contextChanging = useAppContextStatus() === 'pending';
    const kpiCardLoading = useKPICardStatus() === 'pending';
    const [rootRef, setRootRef] = useState<HTMLDivElement | null>(null);
    const [popperRef, setPopperRef] = useState<HTMLDivElement | null>(null);

    const toggleIsOpen = useCallback(() => {
        setIsOpen(!isOpen);
    }, [isOpen]);

    const handleRowSelected = useCallback(
        (selectedRowIds) => {
            const newSelectedDraft: Record<number, true> = {};
            Object.keys(selectedRowIds).forEach((rowId: string) => {
                const rowIndex = Number(rowId);
                if (Number.isNaN(rowIndex)) return;
                const selectedAdvertiser = appAdvertisers[rowIndex];
                if (selectedAdvertiser)
                    newSelectedDraft[selectedAdvertiser.advertiser_id] = true;
            });
            setSelectedDraft(newSelectedDraft);
        },
        [appAdvertisers]
    );

    const { styles, attributes } = usePopper(rootRef, popperRef, {
        modifiers: [
            {
                name: 'offset',
                options: {
                    offset: [-300, 18],
                },
            },
        ],
        placement: 'bottom-start',
    });

    const updateAllFilters = debounce((value: string) => {
        // id here must match the id of the column to be searched
        setAllFilters([{ id: 'name', value }]);
    }, 300);

    const updateSelectedOnlyFilters = debounce((value: string) => {
        // id here must match the id of the column to be searched
        setSelectedOnlyFilters([{ id: 'name', value }]);
    }, 300);

    const handleAllSearchChange = useCallback(
        (e) => {
            setAllSearchValue(e.target.value);
            updateAllFilters(e.target.value);
            if (e.target.value !== null) {
                setAllSelected(false);
            }
        },
        [updateAllFilters]
    );

    const handleSelectedOnlySearchChange = useCallback(
        (e) => {
            setSelectedOnlySearchValue(e.target.value);
            updateSelectedOnlyFilters(e.target.value);
        },
        [updateSelectedOnlyFilters]
    );

    const handleCancel = useCallback(() => {
        setIsOpen(false);
        if (initialDraft) setSelectedDraft(initialDraft);
    }, [initialDraft]);

    const handleApply = useCallback(() => {
        setInitialDraft(selectedDraft);
        dispatch(setSelectedAdvertisers(selectedDraft));
        setIsOpen(false);
    }, [dispatch, selectedDraft]);

    const initialState = useMemo(() => {
        const selectedRowIds: Record<number, true> = {};

        for (let i = 0; i < appAdvertisers.length; i += 1) {
            const advertiser = appAdvertisers[i];
            if (selectedDraft[advertiser.advertiser_id])
                selectedRowIds[i] = true;
        }

        return {
            selectedRowIds,
        };
    }, [appAdvertisers, selectedDraft]);

    const selectedOnlyAdvertisers = useMemo(() => {
        return appAdvertisers.filter((a) => {
            if (selectedDraft[a.advertiser_id]) return true;
            return false;
        });
    }, [appAdvertisers, selectedDraft]);

    const allColumns = useMemo(() => {
        return [
            {
                id: 'name',
                Header: 'selectAllCheckBox',
                accessor: ({ name, advertiser_id }: any) =>
                    `${name} (${advertiser_id})`,
                Filter: () => null,
                alignItems: 'center',
                justifyContent: 'flex-start',
                headerLabel: 'Select All',
                width: 200,
                wrapText: true,
                noTranslate: true,
                Cell: CheckboxCell,
                onCheck: (data: any) => {
                    if (allSelected) {
                        const newDraft = { ...selectedDraft };
                        delete newDraft[data.row.original.advertiser_id];
                        setSelectedDraft(newDraft);
                        setAllSelected(false);
                        return;
                    }
                    data.row.toggleRowSelected();
                },
                getIsDisabled: (_: any, data: any) => {
                    const allRowsSelected =
                        data.selectedFlatRows.length === appAdvertisers.length;
                    return !data.row.isSelected && allRowsSelected;
                },
                isSelectAllChecked: (data: any) => {
                    const selectedFilteredAdvertisersIds = data.selectedFlatRows.map(
                        (selectedFilteredAdvertiser: any) => {
                            return selectedFilteredAdvertiser.original
                                .advertiser_id;
                        }
                    );
                    const filteredAdvertisersIds = data.globalFilteredFlatRows.map(
                        (filteredAdvertiser: any) => {
                            return filteredAdvertiser.original.advertiser_id;
                        }
                    );

                    return (
                        allSelected ||
                        data.selectedFlatRows.length ===
                            appAdvertisers.length ||
                        JSON.stringify(filteredAdvertisersIds) ===
                            JSON.stringify(selectedFilteredAdvertisersIds)
                    );
                },
                onSelectAll: (data: any) => {
                    const selectedFilteredAdvertisersIds = data.selectedFlatRows.map(
                        (selectedFilteredAdvertiser: any) => {
                            return selectedFilteredAdvertiser.original
                                .advertiser_id;
                        }
                    );
                    const filteredAdvertisersIds = data.globalFilteredFlatRows.map(
                        (filteredAdvertiser: any) => {
                            return filteredAdvertiser.original.advertiser_id;
                        }
                    );
                    const hasFilter = data.state.filters.length > 0;

                    const allRowsSelected =
                        data.selectedFlatRows.length ===
                            appAdvertisers.length ||
                        (JSON.stringify(selectedFilteredAdvertisersIds) ===
                            JSON.stringify(filteredAdvertisersIds) &&
                            hasFilter);

                    if (allRowsSelected) {
                        data.toggleAllRowsSelected(false);
                        setAllSelected(false);
                        setSelectedDraft({});
                    } else {
                        let i = 0;
                        let newSelectedDraft = {};

                        do {
                            if (data.flatRows[i]?.toggleRowSelected) {
                                data.flatRows[i].toggleRowSelected(true);
                            }
                            newSelectedDraft = {
                                ...newSelectedDraft,
                                [data.flatRows[i]?.original
                                    .advertiser_id]: true,
                            };
                            setSelectedDraft(newSelectedDraft);
                            setAllSelected(true);
                            i += 1;
                        } while (i < appAdvertisers.length);
                    }
                },
                isChecked: (row: any) => {
                    return row.isSelected;
                },
                selectedRows: selectedDraft,
                checkboxLabel: (row: any) => row.original.name,
                disableSortBy: true,
            },
        ];
    }, [selectedDraft, appAdvertisers, allSelected]);

    useEffect(() => {
        if (!initialized) {
            setInitialized(true);
        }
    }, [initialized]);

    useEffect(() => {
        const newInitialDraft: Record<number, true> = {};
        selectedAppAdvertisers.forEach((advertiser) => {
            newInitialDraft[advertiser.advertiser_id] = true;
        });
        setInitialDraft(newInitialDraft);
        setSelectedDraft(newInitialDraft);
    }, [selectedAppAdvertisers]);

    const allAdvertiserCountDisplayValue = useMemo(() => {
        if (!numeral.localeData()) return appAdvertisers.length;
        return numeral(appAdvertisers.length).format('0,0');
    }, [appAdvertisers.length]);

    const selectedAdvertiserCountDisplayValue = useMemo(() => {
        if (!numeral.localeData()) return selectedDraftCount;
        return numeral(selectedDraftCount).format('0,0');
    }, [selectedDraftCount]);

    const selectedOnlyTableKey = useMemo(() => {
        return `${JSON.stringify(selectedOnlyAdvertisers)}-${JSON.stringify(
            selectedDraft
        )}`;
    }, [selectedDraft, selectedOnlyAdvertisers]);

    const allTableKey = useMemo(() => {
        return `${allSelected}`;
    }, [allSelected]);

    return (
        <MultiAdvertiserWrapper
            ref={setRootRef}
            contextChanging={contextChanging}
        >
            <Button
                btnStyle="selector"
                onClick={toggleIsOpen}
                iconRight={isOpen ? 'chevronUp' : 'chevronDown'}
                disabled={contextChanging || kpiCardLoading}
            >
                {contextChanging || kpiCardLoading
                    ? 'loading...'
                    : `Advertisers (${selectedAdvertiserCountDisplayValue})`}
            </Button>
            <Portal>
                <OnOutsideClick
                    listenForOutsideClick={isOpen}
                    onOutsideClick={handleCancel}
                    getRef={setPopperRef}
                    style={{
                        ...styles.popper,
                        zIndex: 2,
                    }}
                    additionalElement={rootRef}
                    {...attributes.popper}
                >
                    {isOpen && (
                        <MultiAdvertiserModal>
                            <HorizontalFlexBox>
                                <TableWrapper>
                                    <TableTitle>
                                        All Advertisers (
                                        {allAdvertiserCountDisplayValue})
                                    </TableTitle>
                                    <Input
                                        iconLeft="magnifier"
                                        value={allSearchValue}
                                        onChange={handleAllSearchChange}
                                        placeholder="Search all advertisers"
                                        showErrors={false}
                                    />
                                    <Table
                                        id="multi-advertiser-select-all"
                                        key={allTableKey}
                                        data={appAdvertisers}
                                        columns={allColumns}
                                        filters={allFilters}
                                        initialState={initialState}
                                        onRowsSelected={handleRowSelected}
                                        useSpacer={false}
                                        tableHeight={400}
                                        headerRowHeight={35}
                                        disableManualSort
                                        skipPageReset
                                    />
                                </TableWrapper>
                                <TableWrapper>
                                    <TableTitle>
                                        Selected Advertisers (
                                        {selectedAdvertiserCountDisplayValue})
                                    </TableTitle>
                                    <Input
                                        iconLeft="magnifier"
                                        value={selectedOnlySearchValue}
                                        onChange={
                                            handleSelectedOnlySearchChange
                                        }
                                        placeholder="Search selected advertisers"
                                        showErrors={false}
                                    />
                                    <Table
                                        key={selectedOnlyTableKey}
                                        id="multi-advertiser-select-selectedOnly"
                                        data={selectedOnlyAdvertisers}
                                        columns={selectedOnlyColumns}
                                        filters={selectedOnlyFilters}
                                        useSpacer={false}
                                        tableHeight={435}
                                        headerRowHeight={0}
                                        headless
                                    />
                                </TableWrapper>
                            </HorizontalFlexBox>
                            <HorizontalFlexBox justifyContent="flex-end">
                                <ButtonGroup alignEnd>
                                    <Button
                                        btnStyle="secondary"
                                        onClick={handleCancel}
                                        v2
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        onClick={handleApply}
                                        disabled={
                                            !Object.keys(selectedDraft).length
                                        }
                                        btnStyle="primary"
                                        v2
                                    >
                                        Apply
                                    </Button>
                                </ButtonGroup>
                            </HorizontalFlexBox>
                        </MultiAdvertiserModal>
                    )}
                </OnOutsideClick>
            </Portal>
        </MultiAdvertiserWrapper>
    );
};

export default MultiAdvertiserSelector;
