/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import KoddiAPI from 'api';
import {
    all,
    takeEvery,
    put,
    call,
    takeLatest,
    CallEffect,
    PutEffect,
} from 'redux-saga/effects';
import SagaRegistry from 'redux-core/sagaRegistry';
import { PaymentMethod } from 'api/Advertiser/Advertiser.types';
import {
    AdvertiserAction,
    Payload,
    RemoveAdvertiserFromGroup,
    RemoveAdvertiserFromGroupParams,
    AdvertiserInAdvertiserGroupAPISuccess,
    AdvertiserInAdvertiserGroupAPIFailure,
} from './types';
import {
    AdvertiserPaymentMethodAction,
    LoadPaymentMethodParams,
    LoadAdvertiserPaymentMethodsAction,
    DeletePaymentMethodParams,
    DeletePaymentMethodActionType,
} from '../advertiserPayment/types';
import {
    advertiserInAdvertiserGroupAPISuccess,
    advertiserInAdvertiserGroupAPIFailure,
} from './actions';
import {
    LoadAdvertiserPaymentMethods,
    PaymentsLoadedSuccessAction,
} from '../advertiserPayment/actions';

export function* removeAdvertiserFromGroup({
    payload,
}: Payload<RemoveAdvertiserFromGroupParams>): Generator<
    | CallEffect<any>
    | PutEffect<AdvertiserInAdvertiserGroupAPISuccess>
    | PutEffect<AdvertiserInAdvertiserGroupAPIFailure>,
    void,
    any
> {
    const { advertiserId, memberGroupId } = payload;
    const response = yield call(
        KoddiAPI.Advertiser.removeAdvertiserFromGroup,
        memberGroupId,
        advertiserId
    );
    if (response === 'success') {
        yield put(advertiserInAdvertiserGroupAPISuccess());
    } else {
        yield put(advertiserInAdvertiserGroupAPIFailure());
    }
}

export function* watchRemoveAdvertiserFromGroups() {
    yield takeEvery<RemoveAdvertiserFromGroup>(
        AdvertiserAction.REMOVE_ADVERTISER_FROM_GROUP,
        removeAdvertiserFromGroup
    );
}

function* fetchPaymentMethods({
    payload,
}: Payload<LoadPaymentMethodParams>): Generator<
    CallEffect<PaymentMethod[]> | PutEffect<any>,
    void,
    PaymentMethod[]
> {
    const { memberGroupId, advertiserId } = payload;
    const paymentMethods = yield call(
        KoddiAPI.Advertiser.getAdvertiserPaymentMethods,
        memberGroupId,
        advertiserId
    );
    yield put(PaymentsLoadedSuccessAction(paymentMethods));
}

function* deletePaymentMethod({
    payload,
}: Payload<DeletePaymentMethodParams>): Generator<any, void, void> {
    const { confirm, memberGroupId, advertiserId, paymentMethodId } = payload;
    if (confirm) {
        yield call(
            KoddiAPI.Advertiser.deleteAdvertiserPaymentMethod,
            memberGroupId,
            advertiserId,
            paymentMethodId
        );
        yield put(
            LoadAdvertiserPaymentMethods({ memberGroupId, advertiserId })
        );
    }
}

function* onDeletePaymentMethod() {
    yield takeLatest<DeletePaymentMethodActionType>(
        AdvertiserPaymentMethodAction.DELETE_PAYMENT_METHOD,
        deletePaymentMethod
    );
}

function* onFetchPaymentMethods() {
    yield takeLatest<LoadAdvertiserPaymentMethodsAction>(
        AdvertiserPaymentMethodAction.FETCH,
        fetchPaymentMethods
    );
}

function* advertiserSagas() {
    yield all([
        watchRemoveAdvertiserFromGroups(),
        onFetchPaymentMethods(),
        onDeletePaymentMethod(),
    ]);
}

SagaRegistry.registerSaga(advertiserSagas);
