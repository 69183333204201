/* eslint-disable no-console */
import { Saga } from 'redux-saga';
import { IS_DEV_ENV } from 'utils/constants';

const LOG_SAGAS = false; // change this to log sagas as they register
const registry: Saga[] = [];

class SagaRegistryClass {
    /**
     * Adds a saga to the registry that will be added to the redux-saga middleware at run time.
     */
    public registerSaga = (saga: Saga) => {
        registry.push(saga);
        if (IS_DEV_ENV && LOG_SAGAS)
            console.log(`Registering the following saga: ${saga.name}`);
    };

    /** Get all of the registered sagas. */
    public getAllSagas = () => {
        return registry;
    };
}

/**
 * The Saga Registry is a registry for adding sagas to the redux store saga middleware.
 *
 * The Saga Registry makes it easy to add sagas to the middleware so that they can
 * respond to events during the aaplications lifetime.
 */
const SagaRegistry = new SagaRegistryClass();

export default SagaRegistry;
