import styled, { css } from 'styled-components';
import { expandMaxHeight } from 'koddi-components/animations';
import { MenuULProps } from './Menu.types';

export const MenuContainer = styled.div``;

export const MenuUL = styled.ul<MenuULProps>`
    ${({ isVertical }) => css`
        list-style-type: none;
        margin: 0px;
        padding: 0px;
        ${isVertical && css``}
    `}
`;

export const SubMenuUL = styled.ul<{ active: boolean; nested: boolean }>`
    ${({ theme: { spaceUnit, grayDark }, active, nested }) => css`
        list-style-type: none;
        overflow: hidden;
        max-height: 0px;
        padding: 0px;
        margin: 0px 0px 0px ${spaceUnit * 6}px;
        border-left: 2px solid ${grayDark};
        will-change: max-height, padding, margin;
        ${active &&
        nested &&
        css`
            overflow: visible;
        `}
        ${active &&
        css`
            margin: ${spaceUnit * 4}px 0px 0px ${spaceUnit * 6}px;
            animation: ${expandMaxHeight} 0.3s ease-in-out forwards;
        `}
    `}
`;
