import {
    ReduxActionWithError,
    ReduxActionWithPayload,
    ReduxActionWithRefresh,
    AsyncState,
} from 'types';
import { TimezonesList } from 'api/Admin/Timezones';

export enum TimezonesAction {
    GET_TIMEZONES_LIST = 'admin/timezones/GET_TIMEZONES_LIST',
    GET_TIMEZONES_LIST_SUCCESS = 'admin/timezones/GET_TIMEZONES_LIST_SUCCESS',
    GET_TIMEZONES_LIST_ERROR = 'admin/timezones/GET_TIMEZONES_LIST_ERROR',
}

export type GetTimezonesListAction = ReduxActionWithRefresh<
    TimezonesAction.GET_TIMEZONES_LIST
>;

export type GetTimezonesListSuccessAction = ReduxActionWithPayload<
    TimezonesAction.GET_TIMEZONES_LIST_SUCCESS,
    { data: TimezonesList }
>;

export type GetTimezonesListErrorAction = ReduxActionWithError<
    TimezonesAction.GET_TIMEZONES_LIST_ERROR
>;

export type TimezonesActions =
    | GetTimezonesListAction
    | GetTimezonesListSuccessAction
    | GetTimezonesListErrorAction;

export type TimezonesState = {
    list: AsyncState<TimezonesList>;
};
