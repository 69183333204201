/* eslint-disable import/no-extraneous-dependencies */
import { StateMachine } from '@xstate/fsm';
import {
    ReduxActionWithPayload,
    ReduxActionWithError,
    ReduxAction,
} from 'types';
import { Locale } from 'api/Admin/Locales';
import { KoddiUser } from 'api/Admin/User';
import { ForgotPasswordData } from 'api/Auth/Auth.types';
import { Auth0State } from 'modules/Auth0';

export enum AuthAction {
    ATTEMPTING_LOGIN_ON_LOAD = 'auth/ATTEMPTING_LOGIN_ON_LOAD',

    LOGIN_WITH_SSO_SUCCESS = 'auth/LOGIN_WITH_SSO_SUCCESS',

    LOGIN = 'auth/LOGIN',
    LOGIN_SUBMIT = 'auth/LOGIN_SUBMIT',
    LOGIN_SUCCESS = 'auth/LOGIN_SUCCESS',
    LOGIN_ERROR = 'auth/LOGIN_ERROR',

    LOGOUT_WITH_SSO = 'auth/LOGOUT_WITH_SSO',
    LOGOUT_WITH_SSO_SUBMIT = 'auth/LOGOUT_WITH_SSO_SUBMIT',

    LOGOUT = 'auth/LOGOUT',
    LOGOUT_SUBMIT = 'auth/LOGOUT_SUBMIT',
    LOGOUT_SUCCESS = 'auth/LOGOUT_SUCCESS',
    LOGOUT_ERROR = 'auth/LOGOUT_ERROR',

    ACCOUNT_SETUP = 'auth/ACCOUNT_SETUP',
    ACCOUNT_SETUP_SUBMIT = 'auth/ACCOUNT_SETUP_SUBMIT',
    ACCOUNT_SETUP_SUCCESS = 'auth/ACCOUNT_SETUP_SUCCESS',
    ACCOUNT_SETUP_ERROR = 'auth/ACCOUNT_SETUP_ERROR',

    CONFIRM_PASSWORD = 'auth/CONFIRM_PASSWORD',

    RESET_PASSWORD = 'auth/RESET_PASSWORD',
    RESET_PASSWORD_SUBMIT = 'auth/RESET_PASSWORD_SUBMIT',
    RESET_PASSWORD_SUCCESS = 'auth/RESET_PASSWORD_SUCCESS',
    RESET_PASSWORD_ERROR = 'auth/RESET_PASSWORD_ERROR',

    FORGOT_PASSWORD = 'auth/FORGOT_PASSWORD',
    FORGOT_PASSWORD_GET_CODE = 'auth/FORGOT_PASSWORD_GET_CODE',
    FORGOT_PASSWORD_GET_CODE_SUCCESS = 'auth/FORGOT_PASSWORD_GET_CODE_SUCCESS',
    FORGOT_PASSWORD_GET_CODE_ERROR = 'auth/FORGOT_PASSWORD_GET_CODE_ERROR',

    UPDATE_USER = 'auth/UPDATE_USER',
    UPDATE_USER_SUCCESS = 'auth/UPDATE_USER_SUCCESS',
    UPDATE_USER_ERROR = 'auth/UPDATE_USER_ERROR',

    UNAUTHORIZED_REQUEST = 'auth/UNAUTHORIZED_REQUEST',

    CLEAR_SESSION = 'auth/CLEAR_SESSION',
    SAVE_SESSION = 'auth/SAVE_SESSION',

    REFRESH_SESSION = 'auth/REFRESH_SESSION',
    REFRESH_SESSION_SUCCESS = 'auth/REFRESH_SESSION_SUCCESS',
    REFRESH_SESSION_ERROR = 'auth/REFRESH_SESSION_ERROR',
}

export type AuthLocalStorageData = {
    token: string;
    email: string;
    refreshToken: string;
    expiresIn: number;
};

export type AttemptingLoginOnLoadAction = ReduxAction<
    AuthAction.ATTEMPTING_LOGIN_ON_LOAD
>;

// Login Action Types
export type LoginWithSSOSuccessAction = ReduxActionWithPayload<
    AuthAction.LOGIN_WITH_SSO_SUCCESS,
    {
        member_group_id: number;
        auth0State: Auth0State;
    }
>;

export type LoginAction = ReduxAction<AuthAction.LOGIN>;

export type LoginSubmitAction = ReduxActionWithPayload<
    AuthAction.LOGIN_SUBMIT,
    { email: string; password: string }
>;
export type LoginSuccessAction = ReduxActionWithPayload<
    AuthAction.LOGIN_SUCCESS,
    {
        user: KoddiUser;
        id_token: string;
        refresh_token: string;
        expires_in: number;
        persist: boolean;
    }
>;
export type LoginErrorAction = ReduxActionWithError<AuthAction.LOGIN_ERROR>;

// Logout Action Types
export type LogoutAction = ReduxActionWithPayload<
    AuthAction.LOGOUT | AuthAction.LOGOUT_WITH_SSO,
    { loginKey: string | undefined; returnRoute?: string }
>;

export type LogoutSubmitAction = ReduxActionWithPayload<
    AuthAction.LOGOUT_SUBMIT | AuthAction.LOGOUT_WITH_SSO_SUBMIT,
    {
        message: string | undefined;
        loginKey: string | undefined;
        returnRoute?: string;
    }
>;
export type LogoutSuccessAction = ReduxActionWithPayload<
    AuthAction.LOGOUT_SUCCESS,
    { message: string | null | undefined }
>;
export type LogoutErrorAction = ReduxActionWithError<AuthAction.LOGOUT_ERROR>;

// Account Setup Action Types

export type AccountSetupAction = ReduxActionWithPayload<
    AuthAction.ACCOUNT_SETUP,
    { session: string; email: string }
>;
export type AccountSetupSubmitAction = ReduxActionWithPayload<
    AuthAction.ACCOUNT_SETUP_SUBMIT,
    { first_name: string; last_name: string; password: string; locale: Locale }
>;
export type AccountSetupSuccessAction = ReduxActionWithPayload<
    AuthAction.ACCOUNT_SETUP_SUCCESS,
    { user: KoddiUser }
>;
export type AccountSetupErrorAction = ReduxActionWithError<
    AuthAction.ACCOUNT_SETUP_ERROR
>;

// Confirm Password Action Types

export type ConfirmPasswordAction = ReduxActionWithPayload<
    AuthAction.CONFIRM_PASSWORD,
    { username: string }
>;

// Reset Password Action Types

export type ResetPasswordAction = ReduxActionWithPayload<
    AuthAction.RESET_PASSWORD,
    { email: string }
>;
export type ResetPasswordSubmitAction = ReduxActionWithPayload<
    AuthAction.RESET_PASSWORD_SUBMIT,
    { email: string; code: string; password: string }
>;
export type ResetPasswordSuccessAction = ReduxAction<
    AuthAction.RESET_PASSWORD_SUCCESS
>;
export type ResetPasswordErrorAction = ReduxActionWithError<
    AuthAction.RESET_PASSWORD_ERROR
>;

// Forgot Password Action Types

export type ForgotPasswordAction = ReduxAction<AuthAction.FORGOT_PASSWORD>;

export type ForgotPasswordGetCodeAction = ReduxActionWithPayload<
    AuthAction.FORGOT_PASSWORD_GET_CODE,
    { email: string }
>;
export type ForgotPasswordGetCodeSuccessAction = ReduxActionWithPayload<
    AuthAction.FORGOT_PASSWORD_GET_CODE_SUCCESS,
    { codeDeliveryDetails: ForgotPasswordData }
>;
export type ForgotPasswordGetCodeErrorAction = ReduxActionWithError<
    AuthAction.FORGOT_PASSWORD_GET_CODE_ERROR
>;

export type UpdateUserAction = ReduxAction<AuthAction.UPDATE_USER>;

export type UpdateUserSuccessAction = ReduxActionWithPayload<
    AuthAction.UPDATE_USER_SUCCESS,
    { user: KoddiUser }
>;

export type UpdateUserErrorAction = ReduxActionWithError<
    AuthAction.UPDATE_USER_ERROR
>;

// Refresh Session Action types
export type RefreshSessionAction = ReduxAction<AuthAction.REFRESH_SESSION>;

export type RefreshSessionSuccessAction = ReduxActionWithPayload<
    AuthAction.REFRESH_SESSION_SUCCESS,
    { token: string }
>;

export type RefreshSessionError = ReduxActionWithError<
    AuthAction.REFRESH_SESSION_ERROR
>;

// Other Action Types

export type UnauthorizedRequestAction = ReduxActionWithError<
    AuthAction.UNAUTHORIZED_REQUEST
> & {
    message: string | undefined;
    loginKey: string | undefined;
    returnRoute?: string;
};

/** All valid auth states. */
export type AuthStateStatus =
    | 'signIn'
    | 'signedIn'
    | 'accountSetup'
    | 'forgotPassword'
    | 'confirmPassword'
    | 'resetPassword'
    | 'attemptingLoginOnLoad';

/** All of the available actions that the auth reducer should respond to. */
export type AuthActions =
    | LoginAction
    | LoginSubmitAction
    | LoginSuccessAction
    | LoginErrorAction
    | LogoutAction
    | LogoutSubmitAction
    | LogoutSuccessAction
    | LogoutErrorAction
    | AccountSetupAction
    | AccountSetupSubmitAction
    | AccountSetupSuccessAction
    | AccountSetupErrorAction
    | ConfirmPasswordAction
    | RefreshSessionAction
    | RefreshSessionSuccessAction
    | ResetPasswordAction
    | ResetPasswordSubmitAction
    | ResetPasswordSuccessAction
    | ResetPasswordErrorAction
    | ForgotPasswordAction
    | ForgotPasswordGetCodeAction
    | ForgotPasswordGetCodeSuccessAction
    | ForgotPasswordGetCodeErrorAction
    | UnauthorizedRequestAction
    | UpdateUserAction
    | UpdateUserSuccessAction
    | UpdateUserErrorAction;

export type AuthMachineContext = {
    user: KoddiUser | null;
    error: Error | null;
    message: string | null;
    loading: boolean;
    email: string | null;
    username: string | null;
    session: string | null;
};

export type AuthMachineState = {
    value: AuthStateStatus;
    context: AuthMachineContext;
};

export type AuthState = StateMachine.State<
    AuthMachineContext,
    AuthActions,
    AuthMachineState
>;
