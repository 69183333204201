/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { takeEvery, all, put } from 'redux-saga/effects';
import SagaRegistry from 'redux-core/sagaRegistry';

import { AppMessagesAction } from './types';
import { clearAppMessage } from './actions';

export function* clearAppMessageSaga() {
    yield put(clearAppMessage());
}

export function* watchAppMessages() {
    yield takeEvery(AppMessagesAction.SET_MESSAGE, clearAppMessageSaga);
}

function* appMessagesSagas() {
    yield all([watchAppMessages()]);
}

SagaRegistry.registerSaga(appMessagesSagas);
