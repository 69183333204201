import produce, { Draft } from 'immer';
import {
    TermsAndConditionsState,
    TermsAndConditionsActions,
    TermsAndConditionsAction,
} from './types';

export const initialState: TermsAndConditionsState = {
    hasAccepted: false,
    lastModified: null,
    loading: false,
    url: '',
    memberGroupId: null,
    localeId: null,
    error: false,
    errorType: null,
};

/* eslint-disable default-case, no-param-reassign */
const termsAndConditionsReducer = (
    state: TermsAndConditionsState = initialState,
    action: TermsAndConditionsActions
): TermsAndConditionsState =>
    produce(state, (draft: Draft<TermsAndConditionsState>) => {
        switch (action.type) {
            case TermsAndConditionsAction.FETCH_TERMS_AND_CONDITIONS: {
                draft.loading = true;
                break;
            }
            case TermsAndConditionsAction.FETCH_TERMS_AND_CONDITIONS_SUCCESS: {
                draft.hasAccepted = action.payload.hasAccepted;
                draft.lastModified = action.payload.lastModified;
                draft.url = action.payload.url;
                draft.memberGroupId = action.payload.memberGroupId;
                draft.loading = false;
                draft.localeId = action.payload.localeId;
                draft.error = false;
                draft.errorType = null;
                break;
            }
            case TermsAndConditionsAction.FETCH_TERMS_AND_CONDITIONS_ERROR:
                draft.loading = false;
                draft.error = true;
                draft.memberGroupId = action.payload.memberGroupId;
                draft.localeId = action.payload.localeId;
                draft.errorType = action.payload.type;
                draft.hasAccepted = initialState.hasAccepted;
                draft.lastModified = initialState.lastModified;
                draft.url = initialState.url;
                break;
            case TermsAndConditionsAction.ACCEPT_TERMS_AND_CONDITIONS:
                draft.loading = true;
                break;
            case TermsAndConditionsAction.ACCEPT_TERMS_AND_CONDITIONS_SUCCESS:
                draft.loading = false;
                draft.hasAccepted = true;
                break;
            default:
                break;
        }
    });

export default termsAndConditionsReducer;
