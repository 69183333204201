/* eslint-disable react/require-default-props */
import React from 'react';
import styled, { useTheme } from 'styled-components';
import StatusActiveIcon from 'koddi-components/Icon/icons/status_active';
import StatusPendingIcon from 'koddi-components/Icon/icons/status_pending';
import StatusPausedIcon from 'koddi-components/Icon/icons/status_paused';
import StatusEndedIcon from 'koddi-components/Icon/icons/status_ended';
import StatusLockedIcon from 'koddi-components/Icon/icons/status_locked';
import StatusInactiveIcon from 'koddi-components/Icon/icons/status_inactive';
import StatusDeactivatedIcon from 'koddi-components/Icon/icons/status_deactivated';
import StatusInvalidIcon from 'koddi-components/Icon/icons/status_invalid';
import { getStatusColor } from 'koddi-components/utils';

const StatusIconWrapper = styled.span<{
    color: string;
    marginLeft?: number;
    marginRight?: number;
}>`
    margin-left: ${({ marginLeft = 0 }) => marginLeft}px;
    margin-right: ${({ marginRight = 0 }) => marginRight}px;
    display: flex;
    align-items: center;
    path,
    circle {
        fill: ${({ color }) => color};
    }
`;

const statusMap: Record<string, any> = {
    active: StatusActiveIcon,
    inactive: StatusInactiveIcon,
    pending: StatusPendingIcon,
    paused: StatusPausedIcon,
    ended: StatusEndedIcon,
    locked: StatusLockedIcon,
    deactivated: StatusDeactivatedIcon,
    invalid: StatusInvalidIcon,
};

type StatusDotProps = {
    statusValue: string;
    marginLeft?: number;
    marginRight?: number;
};

const StatusDot = ({
    statusValue,
    marginLeft,
    marginRight,
}: StatusDotProps): JSX.Element => {
    const theme = useTheme();
    const formattedStatusValue = statusValue?.toLowerCase();
    let StatusIcon;
    if (statusMap[formattedStatusValue]) {
        StatusIcon = statusMap[formattedStatusValue];
    } else {
        StatusIcon = StatusActiveIcon;
    }
    const color = getStatusColor(formattedStatusValue, theme);
    return (
        <StatusIconWrapper
            data-test="status-dot"
            color={color}
            marginLeft={marginLeft}
            marginRight={marginRight}
        >
            <StatusIcon height={10} width={10} />
        </StatusIconWrapper>
    );
};

export default StatusDot;
