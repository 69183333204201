import { KoddiAPIResponse } from 'types';
import { KoddiListFilterParams } from 'koddi/types';
import KoddiObjectAPI from '../../api.object';
import { Role, UpdateRoleInput, RoleListResponse } from './Role.types';

const KODDI_ROLE_URL = '/role';
const KODDI_ROLE_LIST_URL = '/roles';

class RoleAPI extends KoddiObjectAPI {
    public create = async (role: Role): Promise<Role> => {
        const response = await this.axios.post<KoddiAPIResponse<Role>>(
            `v1${KODDI_ROLE_URL}`,
            role
        );
        return response.data.result;
    };

    public get = async (role_id: Role['role_id']): Promise<Role> => {
        const response = await this.axios.get<KoddiAPIResponse<Role>>(
            `v1${KODDI_ROLE_URL}/${role_id}`
        );
        return response.data.result;
    };

    public update = async ({
        role_id,
        role_title,
    }: UpdateRoleInput): Promise<Role> => {
        const response = await this.axios.put<KoddiAPIResponse<Role>>(
            `v1${KODDI_ROLE_URL}/${role_id}`,
            { title: role_title }
        );
        return response.data.result;
    };

    public delete = async (): Promise<Role> => {
        const response = await this.axios.delete<KoddiAPIResponse<Role>>(
            `v1${KODDI_ROLE_URL}`
        );
        return response.data.result;
    };

    public getList = async (
        params?: KoddiListFilterParams
    ): Promise<Role[]> => {
        const response = await this.axios.get<
            KoddiAPIResponse<RoleListResponse>
        >(`v1${KODDI_ROLE_LIST_URL}`, {
            params,
        });
        return response.data.result.roles;
    };
}

export default RoleAPI;
