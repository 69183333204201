import React from 'react';
import { ListChildComponentProps } from 'react-window';
import CanView from 'koddi-components/CanView';
import { TableRowBase } from '../Table.styled';
import { TableCellProps } from './TableRow.types';
import { TableRowLoading, TableCell } from './TableRow.styled';
import {
    useKoddiTable,
    RowUpdater,
    TableItemUpdaterErrorFn,
    TableItemUpdaterSuccessFn,
} from '../TableProvider';

/**
 * The component rendered for a table row in the table body.
 */
function TableRowComponent<D extends Record<string, unknown>>({
    style,
    index,
}: ListChildComponentProps): JSX.Element {
    const {
        rows,
        totalColumnsWidth,
        prepareRow,
        sticky,
        oddStyles,
        borderless,
        headerRowHeight,
        updateItem,
        disableCellSelection,
    } = useKoddiTable<D>();
    const row = rows[index];
    const isOdd = oddStyles ? index % 2 !== 0 : false;

    if (!row) {
        return (
            <TableRowLoading
                style={{
                    ...style,
                    top: (style?.top as number) + headerRowHeight,
                }}
                isOdd={isOdd}
            />
        );
    }

    prepareRow(row);

    const { style: rowStyle, key, ...restRowProps } = row.getRowProps({
        style,
    });

    return (
        <TableRowBase
            {...restRowProps}
            key={`${key}-${row.original.id}`}
            odd={isOdd}
            style={{
                ...rowStyle,
                top: (rowStyle?.top as number) + headerRowHeight,
                width: sticky ? totalColumnsWidth : rowStyle?.width,
            }}
        >
            {row.cells.map((cell: TableCellProps<D>) => {
                const {
                    style: cellStyle,
                    ...restCellProps
                } = cell.getCellProps();
                return (
                    <CanView
                        permission={cell.column.permission}
                        any={cell.column.anyPermission}
                        role={cell.column.role}
                        anyRole={cell.column.anyRole}
                        key={`column-${cell.column.id}-row-${row.id}`}
                    >
                        <TableCell
                            {...restCellProps}
                            style={{
                                ...cellStyle,
                                display: 'inline-flex',
                            }}
                            padding={cell.column.padding}
                            align={cell.column.align}
                            textAlign={cell.column.textAlign}
                            odd={isOdd}
                            sticky={sticky}
                            borderless={borderless}
                            textEllipsis={cell.column.textEllipsis}
                            cellWidth={
                                cell.column.width || cell.column.totalWidth
                            }
                            disableSelection={
                                disableCellSelection ||
                                cell.column.disableSelection
                            }
                            maxWidth={cell.column.maxWidth}
                            tabIndex={0}
                        >
                            {cell.render('Cell', {
                                updateItem: (
                                    rowUpdater: RowUpdater<D>,
                                    itemId?: number,
                                    onSuccess?: TableItemUpdaterSuccessFn<D>,
                                    onError?: TableItemUpdaterErrorFn<D>
                                ) =>
                                    updateItem(
                                        itemId === undefined
                                            ? (row.index as number)
                                            : itemId,
                                        row,
                                        rowUpdater,
                                        onSuccess,
                                        onError
                                    ),
                            })}
                        </TableCell>
                    </CanView>
                );
            })}
        </TableRowBase>
    );
}

export default React.memo(TableRowComponent);
