import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { BaseSVGProps } from '../icon.types';

const SVGWrapper = styled.span`
    color: inherit;
    svg path {
        fill: currentColor;
    }
`;

const SettingsIcon: FunctionComponent<BaseSVGProps> = ({ width, height }) => (
    <SVGWrapper>
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="-1 -1.7 15.841 18.841"
        >
            <path
                d="M54.544 226.314l-1.538 1.543-1.533-1.613a6.342 6.342 0 01-2.081.875.278.278 0 00-.14.217 39.297 39.297 0 000 1.784c.005.218-.063.286-.281.28a28.47 28.47 0 00-1.627 0c-.243.008-.29-.079-.285-.3.014-.645.005-1.291.005-1.874l-2.25-.893-1.475 1.536-1.554-1.569 1.579-1.527a5.729 5.729 0 01-.864-2.073c-.033-.173-.145-.143-.254-.143h-2.014v-2.178h1.47c.244 0 .545.071.715-.044.15-.1.138-.425.225-.639.2-.5.419-.995.656-1.55l-1.562-1.49 1.568-1.576c.436.444.885.883 1.312 1.343a.382.382 0 00.548.086 12.874 12.874 0 011.651-.65c.206-.067.254-.139.25-.334-.01-.646 0-1.293 0-1.962h2.184v2.1c0 .139.024.221.185.253a5.329 5.329 0 011.863.746c.135.087.2.026.287-.06q.708-.72 1.42-1.437l1.536 1.591c-.193.178-.439.4-.677.625-.259.248-.513.5-.76.761-.049.052-.1.173-.075.213a6.173 6.173 0 01.818 2.1h2.234v2.1h-2.228a5.681 5.681 0 01-.181.67c-.179.444-.365.889-.587 1.312a.271.271 0 00.057.4c.471.441.931.908 1.403 1.377zm-6.469-1.87a2.907 2.907 0 10-2.906-2.93 2.926 2.926 0 002.905 2.93z"
                transform="translate(-40.232 -213.56)"
            />
        </svg>
    </SVGWrapper>
);

export default SettingsIcon;
