import produce, { Draft } from 'immer';

import { AppSetupState, SetupAppActions, AppSetupAction } from './types';

export const initialState: AppSetupState = {
    status: 'idle',
};

const appSetupReducer = (
    state: AppSetupState = initialState,
    action: SetupAppActions
): AppSetupState =>
    produce(state, (draft: Draft<AppSetupState>) => {
        switch (action.type) {
            case AppSetupAction.SETUP_APP:
                draft.status = 'pending';
                break;
            case AppSetupAction.SETUP_SUCCESS:
                draft.status = 'success';
                break;
            default:
                break;
        }
    });

export default appSetupReducer;
