import produce, { Draft } from 'immer';
import { AdminAlertState, AdminAlertActions, AdminAlertAction } from './types';

export const initialState: AdminAlertState = {
    message: null,
    type: null,
};

/* eslint-disable default-case, no-param-reassign */
const adminAlertReducer = (
    state: AdminAlertState = initialState,
    action: AdminAlertActions
): AdminAlertState =>
    produce(state, (draft: Draft<AdminAlertState>) => {
        switch (action.type) {
            case AdminAlertAction.SHOW_ALERT: {
                draft.message = action.payload.message;
                draft.type = action.payload.type;
                break;
            }
            case AdminAlertAction.CLEAR_ALERT: {
                draft.message = null;
                draft.type = null;
                break;
            }
        }
    });

export default adminAlertReducer;
