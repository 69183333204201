/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import KoddiAPI from 'api';
import { takeEvery, call, all, put } from 'redux-saga/effects';
import SagaRegistry from 'redux-core/sagaRegistry';

import {
    EntityRegistrationAction,
    EntityRegistrationsFetchAction,
    InitiateEntityRegistrationAction,
    InitiateEntityRegistrationCSVAction,
} from './types';

import {
    clearEntityRegistrationSave,
    fetchEntityRegistrationsSucess,
    entityRegistrationErrorMessage,
    entityRegistrationReportSuccess,
    entityRegistrationReportFailure,
    clearEntityRegistrationReport,
    entityRegistrationReportAction,
    entityRegistrationHideOrRetryErrorAction,
} from './actions';

export function* fetchEntityRegistrationsSaga(
    action: EntityRegistrationsFetchAction
) {
    const { advertiserId, memberGroupId } = action.payload;
    try {
        const response = yield call(
            KoddiAPI.Admin.EntityRegistration.getEntityRegistrations,
            memberGroupId,
            advertiserId
        );
        yield put(fetchEntityRegistrationsSucess(response.events));
        yield put(entityRegistrationReportAction(memberGroupId, advertiserId));
    } catch (e) {
        yield put(entityRegistrationErrorMessage(e.error?.message));
    }
}

export function* initiateEntityRegistrationSaga(
    action: InitiateEntityRegistrationAction
) {
    const { advertiserId, memberGroupId, uuids } = action.payload;
    try {
        const response = yield call(
            KoddiAPI.Admin.EntityRegistration.initiateEntityRegistration,
            memberGroupId,
            advertiserId,
            uuids
        );
        yield put(clearEntityRegistrationReport());
        yield put(fetchEntityRegistrationsSucess([response.event_id]));
    } catch (e) {
        yield put(entityRegistrationErrorMessage(e.error?.message));
    }
}

export function* initiateEntityRegistrationCSVSaga(
    action: InitiateEntityRegistrationCSVAction
) {
    const { advertiserId, memberGroupId, csvFile } = action.payload;
    try {
        const response = yield call(
            KoddiAPI.Admin.EntityRegistration.initiateEntityRegistrationCSV,
            memberGroupId,
            advertiserId,
            csvFile
        );
        yield put(clearEntityRegistrationReport());
        yield put(fetchEntityRegistrationsSucess([response.event_id]));
    } catch (e) {
        yield put(entityRegistrationErrorMessage(e.error?.message));
    }
}

export function* entityRegistrationReport({ payload }: any) {
    const { memberGroupId, advertiserId } = payload;
    try {
        const response = yield call(
            KoddiAPI.Admin.EntityRegistration.entityRegistrationReport,
            memberGroupId,
            advertiserId
        );
        yield put(entityRegistrationReportSuccess(response));
        yield put(clearEntityRegistrationSave());
    } catch {
        yield put(entityRegistrationReportFailure());
        yield put(clearEntityRegistrationSave());
    }
}

export function* entityRegistrationRetry({ payload }: any) {
    const { memberGroupId, advertiserId, eventDetailId } = payload;
    yield put(entityRegistrationHideOrRetryErrorAction(''));
    try {
        yield call(
            KoddiAPI.Admin.EntityRegistration.entityRegistrationRetry,
            memberGroupId,
            advertiserId,
            eventDetailId
        );
        yield put(entityRegistrationReportAction(memberGroupId, advertiserId));
    } catch (e) {
        yield put(entityRegistrationHideOrRetryErrorAction(e.error?.message));
    }
}

export function* entityRegistrationRetryAll({ payload }: any) {
    const { memberGroupId, advertiserId } = payload;
    yield put(entityRegistrationHideOrRetryErrorAction(''));
    try {
        yield call(
            KoddiAPI.Admin.EntityRegistration.entityRegistrationRetryAll,
            memberGroupId,
            advertiserId
        );
        yield put(entityRegistrationReportAction(memberGroupId, advertiserId));
    } catch (e) {
        yield put(entityRegistrationHideOrRetryErrorAction(e.error?.message));
    }
}

export function* entityRegistrationHide({ payload }: any) {
    const { memberGroupId, advertiserId, eventDetailId } = payload;
    yield put(entityRegistrationHideOrRetryErrorAction(''));
    try {
        yield call(
            KoddiAPI.Admin.EntityRegistration.entityRegistrationHide,
            memberGroupId,
            advertiserId,
            eventDetailId
        );
        yield put(entityRegistrationReportAction(memberGroupId, advertiserId));
    } catch (e) {
        yield put(entityRegistrationHideOrRetryErrorAction(e.error?.message));
    }
}

export function* entityRegistrationHideAll({ payload }: any) {
    const { memberGroupId, advertiserId } = payload;
    yield put(entityRegistrationHideOrRetryErrorAction(''));
    try {
        yield call(
            KoddiAPI.Admin.EntityRegistration.entityRegistrationHideAll,
            memberGroupId,
            advertiserId
        );
        yield put(entityRegistrationReportAction(memberGroupId, advertiserId));
    } catch (e) {
        yield put(entityRegistrationHideOrRetryErrorAction(e.error?.message));
    }
}

export function* watchEntityRegistration() {
    yield takeEvery(
        EntityRegistrationAction.FETCH_ENTITY_REGISTRATIONS,
        fetchEntityRegistrationsSaga
    );
    yield takeEvery(
        EntityRegistrationAction.INITIATE_ENTITY_REGISTRATION,
        initiateEntityRegistrationSaga
    );
    yield takeEvery(
        EntityRegistrationAction.INITIATE_ENTITY_REGISTRATION_CSV,
        initiateEntityRegistrationCSVSaga
    );
    yield takeEvery(
        EntityRegistrationAction.ENTITY_REGISTRATION_REPORT,
        entityRegistrationReport
    );
    yield takeEvery(
        EntityRegistrationAction.ENTITY_REGISTRATION_RETRY,
        entityRegistrationRetry
    );
    yield takeEvery(
        EntityRegistrationAction.ENTITY_REGISTRATION_RETRY_ALL,
        entityRegistrationRetryAll
    );
    yield takeEvery(
        EntityRegistrationAction.ENTITY_REGISTRATION_HIDE,
        entityRegistrationHide
    );
    yield takeEvery(
        EntityRegistrationAction.ENTITY_REGISTRATION_HIDE_ALL,
        entityRegistrationHideAll
    );
}

function* entityRegistrationSagas() {
    yield all([watchEntityRegistration()]);
}

SagaRegistry.registerSaga(entityRegistrationSagas);
