import { useMemo, useCallback, useState, useEffect } from 'react';

type OptionValue = {
    label: string;
    value: string;
};

export const useGetCountries = (): {
    getCountries: () => void;
    countries: OptionValue[];
} => {
    const [countries, setCountries] = useState([]);
    const getCountries = useCallback(async () => {
        await import('country-state-city/dist/lib/country').then(
            (CityState: any) => {
                setCountries(CityState.default.getAllCountries?.());
            }
        );
    }, []);

    return {
        getCountries,
        countries,
    };
};

export const useGetStates = (): {
    getStates: (countryCode: string) => void;
    states: OptionValue[];
} => {
    const [states, setStates] = useState([]);
    const getStates = useCallback(async (countryCode: string) => {
        await import('country-state-city/dist/lib/state').then(
            (CityState: any) => {
                setStates(CityState.default.getStatesOfCountry(countryCode));
            }
        );
    }, []);

    return {
        getStates,
        states,
    };
};

export const useBillingStateOptions = (countryCode: string): OptionValue[] => {
    const { getStates, states } = useGetStates();

    useEffect(() => {
        getStates(countryCode);
    }, [countryCode, getStates]);

    return useMemo(() => {
        return states?.map((state: any) => {
            return {
                label: state.name,
                value: state.isoCode,
            };
        });
    }, [states]);
};

export const useBillingCountryOptions = (): OptionValue[] => {
    const { getCountries, countries } = useGetCountries();
    if (!countries.length) getCountries();
    return useMemo(() => {
        return countries?.map((country: any) => {
            return {
                label: country.name,
                value: country.isoCode,
            };
        });
    }, [countries]);
};
