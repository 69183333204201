import { MemberGroupList } from 'api/Admin/MemberGroup';
import { TemplateOverviewType } from 'api/Admin/TemplateOverview/TemplateOverview.types';

import {
    GetMemberGroupListAction,
    MemberGroupAction,
    GetMemberGroupListSuccessAction,
    GetMemberGroupListErrorAction,
    GetMemberGroupActiveTemplates,
} from './types';

export function getMemberGroupList(
    refresh?: boolean
): GetMemberGroupListAction {
    return {
        type: MemberGroupAction.GET_MEMBER_GROUP_LIST,
        payload: { refresh: refresh || false },
    };
}

export function getMemberGroupListSuccess(
    data: MemberGroupList
): GetMemberGroupListSuccessAction {
    return {
        type: MemberGroupAction.GET_MEMBER_GROUP_LIST_SUCCESS,
        payload: { data },
    };
}

export function getMemberGroupListError(
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    error: any
): GetMemberGroupListErrorAction {
    return {
        type: MemberGroupAction.GET_MEMBER_GROUP_LIST_ERROR,
        error,
    };
}

export function setMemberGroupActiveTemplates(
    data: TemplateOverviewType[]
): GetMemberGroupActiveTemplates {
    return {
        type: MemberGroupAction.GET_MEMBER_GROUP_ACTIVE_TEMPLATES,
        payload: { data },
    };
}
