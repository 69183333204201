import { combineReducers } from 'redux';
import createAsyncReducer from 'utils/createAsyncReducer';
import { LocalesList } from 'api/Admin/Locales';
import { LocalesState, LocalesAction } from './types';

export const initialState: LocalesState = {
    list: {
        last_updated: null,
        loading: false,
        error: null,
        data: null,
    },
};

export const localesListReducer = createAsyncReducer<LocalesList>(
    initialState.list,
    {
        start: LocalesAction.GET_LOCALES_LIST,
        success: LocalesAction.GET_LOCALES_LIST_SUCCESS,
        error: LocalesAction.GET_LOCALES_LIST_ERROR,
    }
);

const localesReducer = combineReducers<LocalesState>({
    list: localesListReducer,
});

export default localesReducer;
