import {
    ApplicationRootState,
    ApplicationOutputSelector,
} from 'redux-core/types';
import { createSelector } from 'reselect';
import { AsyncState } from 'types';
import { LocalesList } from 'api/Admin/Locales';
import { initialState } from './reducer';
import { LocalesState } from './types';

const selectAdminLocales = (state: ApplicationRootState) =>
    state.admin.locales || initialState;

export const selectLocalesList: ApplicationOutputSelector<
    AsyncState<LocalesList>,
    (res: LocalesState) => AsyncState<LocalesList>
> = createSelector(selectAdminLocales, (state) => state.list);

export const selectLocalesOptions = createSelector(
    selectLocalesList,
    (locales) => {
        return locales.data?.map((locale) => ({
            label: locale.name,
            value: locale.id,
        }));
    }
);
