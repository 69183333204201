import { createSelector } from 'reselect';
import { ApplicationRootState } from 'redux-core/types';
import { TermsAndConditionsErrorState, TermsAndConditionsState } from './types';

const selectTermsAndConditions = (
    state: ApplicationRootState
): TermsAndConditionsState => state.app.termsAndConditions;

export const selectHasAccceptedTerms = (state: ApplicationRootState): boolean =>
    state.app.termsAndConditions.hasAccepted;

export const selectTermsUrl = (state: ApplicationRootState): string =>
    state.app.termsAndConditions.url;

export const selectTermsLoading = (state: ApplicationRootState): boolean =>
    state.app.termsAndConditions.loading;

export const selectTermsMemberGroupId = (
    state: ApplicationRootState
): number | null => state.app.termsAndConditions.memberGroupId;

export const selectTermsLocaleId = (
    state: ApplicationRootState
): number | null => state.app.termsAndConditions.localeId;

export const selectTermsError = createSelector(
    selectTermsAndConditions,
    (state: TermsAndConditionsState): TermsAndConditionsErrorState => {
        const { memberGroupId, localeId, errorType } = state;
        return {
            memberGroupId,
            localeId,
            errorType,
        };
    }
);
