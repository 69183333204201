import styled, { css } from 'styled-components';
import { HorizontalFlexBox } from 'koddi-components/FlexBox';
import { FormFooterContainerProps } from './FormFooter.types';

// eslint-disable-next-line prettier/prettier
export const FormFooterContainer = styled(HorizontalFlexBox)<FormFooterContainerProps>`
    ${({ theme: { spaceUnit, grayLight }, noBorderTop }) => css`
        padding: ${spaceUnit * 6}px 0px;
        border-top: ${noBorderTop ? `none` : `1px solid ${grayLight}`};
    `}
`;

export const FormFooterError = styled.div``;
