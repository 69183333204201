import KoddiAPI from 'api';
import notEmpty from 'utils/notEmpty';
import { RolePermission } from 'api/Admin/RolePermission';
import { EditRoleActivitiesInput } from './types';

type RolePermissionEdit<Input extends any = any> = [
    (input: Input) => Promise<RolePermission>,
    Input
];

type RolePermissionEditsTuple = RolePermissionEdit[];

/**
 * Returns an array of api requests to either create
 * or delete role permissions based on whether or not
 * they were selected and/or already existed.
 *
 * Example Return: `[() => Delete Role Permission 45, () => Create Role Permission 46]`
 */
export function createRolePermissionEdits(
    role_id: number,
    activities: EditRoleActivitiesInput
): RolePermissionEditsTuple {
    return Object.values(activities).reduce((result, activity) => {
        return result.concat(
            Object.values(activity)
                .map(
                    ({
                        role_permission_id,
                        permission_id,
                        activity_id,
                        selected,
                    }) => {
                        if (!selected && role_permission_id) {
                            return [
                                KoddiAPI.Admin.RolePermission.delete,
                                role_permission_id,
                            ] as RolePermissionEdit;
                        }
                        if (selected && !role_permission_id) {
                            return [
                                KoddiAPI.Admin.RolePermission.create,
                                { permission_id, role_id, activity_id },
                            ] as RolePermissionEdit;
                        }
                        return null;
                    }
                )
                .filter(notEmpty)
        );
    }, [] as RolePermissionEditsTuple);
}
