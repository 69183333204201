/* eslint-disable react/display-name */
import React, { useState } from 'react';
import Card from 'koddi-components/Card';
import ChartWrapper, {
    ChartWrapperDataMarker,
} from 'koddi-components/ChartWrapper';
import { TTabItem } from 'koddi-components/Tabs';
import { ChartCardProps } from './ChartCard.types';
import ChartCardHeader from './ChartCardHeader';
import createTabs from './ChartCard.tabs';
import { ChartCardTabsProvider, ChartCardTabContent } from './ChartCard.styled';

/**
 * The `ChartCard` component is a spsecific instance of our Card
 * UI that renders data as either a `VerticalBarChart` or `LineChart`.
 * The chart types can be toggled.
 *
 * Accessibility built following the guidlines from: https://www.w3.org/TR/wai-aria-practices-1.1/examples/accordion/accordion.html
 */
function ChartCard<
    Data extends Record<string, unknown>,
    DataMarker extends ChartWrapperDataMarker = ChartWrapperDataMarker
>({
    id,
    selectOptions,
    selectValue,
    onSelectChange,
    header: CustomCardHeader,
    reloadApi,
    selectLabel,
    className,
    chartOpen,
    allowHide,
    ...rest
}: ChartCardProps<Data, DataMarker>): JSX.Element {
    const [tabs] = useState<TTabItem[]>(createTabs<Data>(id));
    const cardContentProps = {
        'aria-labelledby': `${id}-card-content`,
        role: 'region',
        id: `${id}-card-content`,
        'data-test': `${id}-card-content`,
    };
    const CardHeader = CustomCardHeader || ChartCardHeader;
    const cardHeader = (
        <CardHeader
            id={id}
            onSelectChange={onSelectChange}
            selectLabel={selectLabel}
            selectOptions={selectOptions}
            selectValue={selectValue}
        />
    );

    return (
        <>
            {chartOpen || !allowHide ? (
                <ChartCardTabsProvider
                    id={`${id}-tabs`}
                    tabs={tabs}
                    defaultActiveTabKey={1}
                    className={className}
                >
                    <Card header={cardHeader} contentProps={cardContentProps}>
                        <ChartWrapper
                            {...rest}
                            id={id}
                            reloadApi={reloadApi}
                            renderChart={(chartProps) => {
                                return <ChartCardTabContent {...chartProps} />;
                            }}
                        />
                    </Card>
                </ChartCardTabsProvider>
            ) : null}
        </>
    );
}

export default ChartCard;
