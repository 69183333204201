import createAsyncReducer from 'utils/createAsyncReducer';
import { combineReducers } from 'redux';
import { ActivityState, ActivityAction } from './types';

export const initialState: ActivityState = {
    list: {
        last_updated: null,
        loading: false,
        error: null,
        data: null,
    },
};

export const activityListReducer = createAsyncReducer(initialState.list, {
    start: ActivityAction.GET_ACTIVITY_LIST,
    success: ActivityAction.GET_ACTIVITY_LIST_SUCCESS,
    error: ActivityAction.GET_ACTIVITY_LIST_ERROR,
});

/* eslint-disable default-case, no-param-reassign */
const activityReducer = combineReducers<ActivityState>({
    list: activityListReducer,
});

export default activityReducer;
