import formatDateForKoddiAPI from 'utils/formatDateForKoddiAPI';
import { SelectOption } from 'koddi-components/Select';
import { ReportType, CreateReportInput } from 'api/Reports';
import subDays from 'date-fns/subDays';
import { Currency } from 'api/Admin/Currency';

export function getOverviewReportPayload(
    startDate: Date,
    endDate: Date,
    report_type: ReportType,
    metrics: string | SelectOption[],
    memberGroupId: number | null,
    reportCurrency: Currency | null,
    advertiserIds?: number[]
): CreateReportInput {
    return {
        context: 'campaign',
        report_type,
        dimensions: ['campaign_id'],
        metrics:
            typeof metrics === 'string'
                ? [metrics]
                : metrics.map((data: SelectOption) => data.value),
        member_group_id: memberGroupId,
        advertiser_ids: advertiserIds,
        start_date: formatDateForKoddiAPI(startDate || subDays(new Date(), 7)),
        end_date: formatDateForKoddiAPI(endDate || subDays(new Date(), 1)),
        currency_code: reportCurrency?.code,
    };
}
