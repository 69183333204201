/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import produce, { Draft } from 'immer';
import { RowUpdater } from './TableProvider';

/**
 * Immutably updates the data for a row in a tables data.
 * @param data The current table data.
 * @param rowIndex The row index of the row being updated.
 * @param rowUpdater The function that updates the rows data.
 */
export function updateData<Data extends Record<string, unknown>>(
    data: Data[],
    rowData: any,
    rowUpdater: RowUpdater<Data>
): { updatedData: Data[]; updatedRow: Data | null } {
    try {
        if (rowData.id.includes('.')) {
            // this if handles that case of the nested Entities table
            // It is intentionally dependent on the three indexs from the subArrays

            const parseIndexes: number[] = rowData.id
                .split('.')
                .map((index: string) => Number(index));
            const [indexOne, indexTwo, indexThree] = parseIndexes;

            /** @ts-ignore */
            const row = data[indexOne].subRows[indexTwo].subRows[indexThree];
            /** @ts-ignore */
            const updatedRow = produce(row, (draftRow) => {
                rowUpdater(draftRow);
            });
            const updatedData = produce(data, (draftData) => {
                /** @ts-ignore */
                draftData[indexOne].subRows[indexTwo].subRows[
                    indexThree
                ] = updatedRow;
            });

            /** @ts-ignore */
            return { updatedData, updatedRow };
        }
        const row = data[rowData.index];
        const updatedRow = produce(row, (draftRow) => {
            rowUpdater(draftRow);
        });
        const updatedData = produce(data, (draftData) => {
            draftData.splice(rowData.index, 1, updatedRow as Draft<Data>);
        });
        return { updatedData, updatedRow };
    } catch (error) {
        console.error(error);
        // eslint-disable-next-line no-console
        console.warn(
            `An error occured when attempting to update your data. Either an item with the index ${rowData.index} was not found or you attempted to update a property that did not exist. As a result this update has been ignored.`
        );
    }
    return { updatedData: data, updatedRow: null };
}
