/* eslint-disable @typescript-eslint/ban-types */
import React, { useCallback } from 'react';
import { CustomModalProps } from './CustomModal.types';
import { CustomModalBase } from './CustomModal.styled';

/**
 * A generic modal that allows you to render any interface
 * for the modal by passing in a `Component` prop that will
 * be rendered as the modal ui.
 */
export function CustomModal<CustomProps extends {} = {}>({
    closeModal,
    shouldCloseOnEsc = true,
    shouldCloseOnOverlayClick = true,
    Component,
    width,
    labelledby,
    describedby,
    isOpen,
    onSubmit,
    onCancel,
    minWidth,
    minHeight,
    v2,
    ...rest
}: CustomModalProps<CustomProps>): JSX.Element {
    const handleCancel = useCallback(() => {
        if (onCancel) {
            onCancel();
        }
        closeModal();
    }, [closeModal, onCancel]);

    return (
        <CustomModalBase
            {...rest}
            role="alertdialog"
            shouldCloseOnEsc={shouldCloseOnEsc}
            shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
            aria={{
                labelledby,
                describedby,
            }}
            onRequestClose={closeModal}
            width={width}
            minWidth={minWidth}
            minHeight={minHeight}
            isOpen={isOpen}
            onSubmit={onSubmit}
            v2={v2}
        >
            <Component
                {...rest}
                closeModal={closeModal}
                width={width}
                labelledby={labelledby}
                describedby={describedby}
                isOpen={isOpen}
                onSubmit={onSubmit}
                onCancel={handleCancel}
            />
        </CustomModalBase>
    );
}

export default CustomModal;
