import React, { useEffect } from 'react';
import { Switch, Route, useLocation } from 'react-router-dom';
import { GuardProvider } from 'react-router-guards';

import AdvertiserRegistration from 'features/Registration/AdvertiserRegistration';
import { CrumbTracker } from 'features/Routes/KoddiRoute';
import { datadogRum } from '@datadog/browser-rum';
import {
    LOGIN_ROUTE,
    ACCOUNT_SETUP_ROUTE,
    CLIENT_LOGIN_ROUTE,
    CLIENT_ACCOUNT_SETUP_ROUTE,
    REGISTRATION_ROUTE,
    ADVERTISER_REGISTER_ROUTE,
} from 'modules/constants/routes';

import { KoddiSuspense } from 'koddi-components/KoddiSuspense';
import { useGuards } from './guards';

const Home = React.lazy(() => import('features/Home'));
const Login = React.lazy(() => import('features/Login'));
const NewUser = React.lazy(() => import('features/Registration/NewUser'));
const PageNotFound = React.lazy(() => import('features/PageNotFound'));

const ErrorComponent = () => {
    const { pathname } = useLocation();
    if (pathname.includes('admin')) {
        return (
            <CrumbTracker>
                <PageNotFound />
            </CrumbTracker>
        );
    }
    return <PageNotFound />;
};

const Routes = (): JSX.Element | null => {
    const guards = useGuards();
    const { pathname } = useLocation();

    useEffect(() => {
        const url = pathname?.replace?.(/[0-9]/g, '').replaceAll?.('//', '/');
        window.ga('send', 'pageview', url);

        if (datadogRum) {
            datadogRum.startView(url);
        }
    }, [pathname]);

    return (
        <GuardProvider guards={guards} error={ErrorComponent}>
            <Switch>
                <Route
                    exact
                    path={[
                        LOGIN_ROUTE,
                        ACCOUNT_SETUP_ROUTE,
                        CLIENT_LOGIN_ROUTE,
                        CLIENT_ACCOUNT_SETUP_ROUTE,
                    ]}
                >
                    <KoddiSuspense>
                        <Login />
                    </KoddiSuspense>
                </Route>
                <Route exact path={REGISTRATION_ROUTE}>
                    <KoddiSuspense>
                        <NewUser />
                    </KoddiSuspense>
                </Route>
                <Route exact path={ADVERTISER_REGISTER_ROUTE}>
                    <KoddiSuspense>
                        <AdvertiserRegistration />
                    </KoddiSuspense>
                </Route>
                <Route path="/">
                    <KoddiSuspense>
                        <Home />
                    </KoddiSuspense>
                </Route>
            </Switch>
        </GuardProvider>
    );
};

export default Routes;
