import React, { FunctionComponent } from 'react';
import { BaseSVGProps } from '../icon.types';

const StatusEndedIcon: FunctionComponent<BaseSVGProps> = ({
    width,
    height,
}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        height={height}
        width={width}
        viewBox="0 0 24 24"
    >
        <title>Status Ended</title>
        <path d="M12 0a12 12 0 1012 12A12 12 0 0012 0zm5.65 16L16 17.65l-4-3.95-3.95 3.95L6.35 16l4-3.95-4-3.95 1.7-1.7 3.95 4 3.95-4 1.7 1.7L13.7 12z" />
    </svg>
);

export default StatusEndedIcon;
