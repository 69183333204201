import {
    ApplicationRootState,
    ApplicationOutputSelector,
} from 'redux-core/types';
import { createSelector } from 'reselect';
import { AsyncState } from 'types';
import { RolePermissionList } from 'api/Admin/RolePermission';
import { initialState } from './reducer';
import { RolePermissionState } from './types';

export const selectAdminRolePermission = (
    state: ApplicationRootState
): RolePermissionState => state.admin.rolePermission || initialState;

export const selectRolePermissionList: ApplicationOutputSelector<
    AsyncState<RolePermissionList>,
    (res: RolePermissionState) => AsyncState<RolePermissionList>
> = createSelector(selectAdminRolePermission, (state) => state.list);
