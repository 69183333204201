import React, { FunctionComponent } from 'react';
import styled, { css } from 'styled-components';
import { darken, lighten } from 'polished';
import { scaleIn } from 'koddi-components/animations';
import { BaseSVGProps } from '../icon.types';

const RadioIconContainer = styled.div<{
    width?: string | number;
    disabled?: boolean;
}>`
    ${({ theme, width, disabled }) => css`
        display: flex;
        flex-direction: column;
        justify-content: center;
        border-radius: 50%;
        background-color: transparent;
        svg {
            circle {
                animation: ${scaleIn} 0.2s cubic-bezier(0, 1.65, 0.595, 1.3);
                animation-fill-mode: forwards;
                transform-origin: 50% 50%;
                fill: ${disabled ? theme.grayLight : theme.black};
            }
            path {
                fill: ${disabled ? theme.grayLight : theme.black};
            }
        }
        &:hover {
            cursor: ${disabled ? 'not-allowed' : 'pointer'};
            ${!disabled &&
            css`
                ${width
                    ? `box-shadow: 0 0 0px ${
                          parseInt(width.toString(), 10) / 2
                      }px
                    ${darken(0.1, theme.grayLighter)}`
                    : ''};
                background-color: ${darken(0.1, theme.grayLighter)};
                polyline {
                    fill: ${lighten(0.1, theme.black)};
                }
            `}
        }
    `}
`;

export type RadioIconProps = BaseSVGProps & {
    width?: string | number;
    disabled?: boolean;
};

const RadioIcon: FunctionComponent<RadioIconProps> = ({
    width,
    height,
    checked,
    disabled,
}) => (
    <RadioIconContainer width={width} disabled={disabled}>
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width={width}
            height={height}
        >
            <path d="M12 24a12 12 0 1112-12 12 12 0 01-12 12zm0-20.73A8.73 8.73 0 1020.73 12 8.74 8.74 0 0012 3.27z" />
            {checked && <circle cx="12" cy="12" r="5.45" />}
        </svg>
    </RadioIconContainer>
);

export default RadioIcon;
