import { combineReducers } from 'redux';
import createAsyncReducer from 'utils/createAsyncReducer';
import { PermissionList } from 'api/Admin/Permission';
import { PermissionState, PermissionAction } from './types';

export const initialState: PermissionState = {
    list: {
        last_updated: null,
        loading: false,
        error: null,
        data: null,
    },
};

export const permissionListReducer = createAsyncReducer<PermissionList>(
    initialState.list,
    {
        start: PermissionAction.GET_PERMISSION_LIST,
        success: PermissionAction.GET_PERMISSION_LIST_SUCCESS,
        error: PermissionAction.GET_PERMISSION_LIST_ERROR,
    }
);

const permissionReducer = combineReducers<PermissionState>({
    list: permissionListReducer,
});

export default permissionReducer;
