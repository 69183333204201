import {
    ShowAdminAlertAction,
    AdminAlertAction,
    ShowAdminAlertPayload,
    ClearAdminAlertAction,
} from './types';

export function showAdminAlert(
    payload: ShowAdminAlertPayload
): ShowAdminAlertAction {
    return {
        type: AdminAlertAction.SHOW_ALERT,
        payload,
    };
}

export function showAdminAlertSuccess(message?: string): ShowAdminAlertAction {
    return {
        type: AdminAlertAction.SHOW_ALERT,
        payload: {
            type: 'success',
            message: message || 'Saved Successfully',
        },
    };
}

export function clearAdminAlert(): ClearAdminAlertAction {
    return {
        type: AdminAlertAction.CLEAR_ALERT,
    };
}
