/* eslint-disable no-console */
import { useDispatch } from 'react-redux';
import { useCallback, useEffect, useState } from 'react';
import { loginWithSSOSuccess } from 'redux-core/auth/actions';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { SESSION_STORAGE_KEY } from 'utils/constants';
import { useAuthError, useAuthStatus } from 'redux-core/auth/hooks';
import { useKoddiThemeResource, useSSOIds } from 'redux-core/app/theme/hooks';
import {
    RETURN_ROUTE_SESSION_KEY,
    USER_ATTEMPTED_LOGIN_KEY,
} from './Auth0.const';
import { Auth0 } from './Auth0';

export const useAuth0 = (): any => {
    const dispatch = useDispatch();
    const { member_group_id } = useKoddiThemeResource();
    const { sso } = useFlags();
    const [isLoadingAuth0, setIsLoadingAuth0] = useState(true);
    const [userAttemptedToLogin, setUserAttemptedToLogin] = useState<boolean>();

    const authStatus = useAuthStatus();
    const authError = useAuthError();
    const { sso_organization_id, sso_connection_id } = useSSOIds();

    const handleSignUp = useCallback(async () => {
        try {
            await Auth0.signUp();
        } catch (err) {
            console.error(err);
            // do something with this later
        }
    }, []);

    const handleLogin = useCallback(async () => {
        try {
            sessionStorage.setItem(USER_ATTEMPTED_LOGIN_KEY, 'true');
            await Auth0.login({ sso_organization_id, sso_connection_id });
        } catch (err) {
            console.error(err);
            // do something with this later
        }
    }, [sso_organization_id, sso_connection_id]);

    const handleLogout = useCallback(async () => {
        try {
            await Auth0.logout();
        } catch (err) {
            console.error(err);
            // do something with this later
        }
    }, []);

    const checkAuth0State = async () => {
        const localStorageAuth = localStorage.getItem(SESSION_STORAGE_KEY);
        const auth0state = await Auth0.initializeAuth0();
        if (!auth0state) {
            setIsLoadingAuth0(false);
            return;
        }
        if (!localStorageAuth) {
            dispatch(loginWithSSOSuccess(member_group_id, auth0state));
        }
        if (localStorageAuth) setIsLoadingAuth0(false);
    };

    const handleReturnRouteStorage = async (
        route: string,
        forceOverwrite = false
    ) => {
        const storedReturnRoute = sessionStorage.getItem(
            RETURN_ROUTE_SESSION_KEY
        );
        if (!storedReturnRoute || forceOverwrite) {
            sessionStorage.setItem(RETURN_ROUTE_SESSION_KEY, `${route}`);
        }
    };

    useEffect(() => {
        const hasUserAttemptedToLogin = !!sessionStorage.getItem(
            USER_ATTEMPTED_LOGIN_KEY
        );
        setUserAttemptedToLogin(hasUserAttemptedToLogin);
        if (authStatus === 'signedIn' || authError) {
            setIsLoadingAuth0(false);
        }
    }, [authStatus, authError]);

    return {
        ssoFeatureFlag: sso,
        isLoadingAuth0,
        authError,
        userAttemptedToLogin,
        handleLogin,
        handleLogout,
        checkAuth0State,
        handleReturnRouteStorage,
        handleSignUp,
    };
};
