import { KoddiUser } from 'api/Admin/User';
import {
    SetupAppAction,
    SetupEmbeddedAppAction,
    AppSetupAction,
    SetupAppSuccessAction,
} from './types';

export function setupApp(
    user: KoddiUser,
    memberGroupId: number,
    advertiserId: number | null,
    advertiserGroupId: number | null,
    pathname: string,
    featureFlags: Record<string, boolean>
): SetupAppAction {
    return {
        type: AppSetupAction.SETUP_APP,
        payload: {
            user,
            memberGroupId,
            advertiserId,
            advertiserGroupId,
            pathname,
            featureFlags,
        },
    };
}

export function setupEmbedded(memberGroupId: number): SetupEmbeddedAppAction {
    return {
        type: AppSetupAction.SETUP_EMBEDDED,
        payload: {
            memberGroupId,
        },
    };
}

export function setupAppSucess(): SetupAppSuccessAction {
    return {
        type: AppSetupAction.SETUP_SUCCESS,
    };
}
