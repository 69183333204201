import React, { VoidFunctionComponent } from 'react';
import Button, { ButtonGroup } from 'koddi-components/Button';
import { ErrorText } from 'koddi-components/Typography';
import { FormFooterContainer } from './FormFooter.styled';
import { FormFooterProps } from './FormFooter.types';

const FormFooter: VoidFunctionComponent<FormFooterProps> = ({
    submitButtonText = 'Submit',
    cancelText = 'Cancel',
    onCancel,
    noBorderTop = false,
    submitButtonDisabled = false,
    onSubmit,
    error,
    'data-test': dataTest = 'form-footer',
    hideCancel = false,
    v2,
}) => {
    return (
        <FormFooterContainer
            fullWidth
            alignItems="center"
            justifyContent="space-between"
            noBorderTop={noBorderTop}
            data-test={dataTest}
        >
            <ErrorText
                data-test={`${dataTest}-error-text`}
                tKey={error?.code || ''}
            >
                {error?.message}
            </ErrorText>
            <ButtonGroup alignEnd>
                {hideCancel ? null : (
                    <Button
                        data-test={`${dataTest}-cancel-btn`}
                        onClick={onCancel}
                        type="button"
                        btnStyle="primary"
                        v2={v2}
                    >
                        {cancelText}
                    </Button>
                )}
                <Button
                    data-test={`${dataTest}-submit-btn`}
                    type="submit"
                    disabled={submitButtonDisabled}
                    onClick={onSubmit}
                    v2={v2}
                >
                    {submitButtonText}
                </Button>
            </ButtonGroup>
        </FormFooterContainer>
    );
};

export default FormFooter;
