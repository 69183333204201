import React, { FunctionComponent } from 'react';
import { BaseSVGProps } from '../icon.types';

const WarningIcon: FunctionComponent<BaseSVGProps> = ({ width, height }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 72 72"
        width={width}
        height={height}
    >
        <g>
            <path
                d="M35.49,6.22c16.22,0,29.42,13.2,29.42,29.42s-13.2,29.42-29.42,29.42S6.08,51.86,6.08,35.64
		S19.27,6.22,35.49,6.22 M35.49,2.22c-18.46,0-33.42,14.96-33.42,33.42s14.96,33.42,33.42,33.42s33.42-14.96,33.42-33.42
		S53.95,2.22,35.49,2.22L35.49,2.22z"
            />
        </g>
        <g>
            <path
                d="M32.41,54.31c-0.14-2.07,1.27-3.58,3.19-3.52c1.66,0.05,2.95,1.5,2.98,3.17c0.03,1.84-1.13,3.27-2.98,3.32
		C33.94,57.33,32.52,55.97,32.41,54.31z M33.14,44.37l-0.78-26.78c-0.05-1.77,1.37-3.23,3.13-3.23h0c1.77,0,3.19,1.46,3.13,3.23
		l-0.78,26.78c-0.04,1.27-1.08,2.29-2.35,2.29h0C34.22,46.65,33.18,45.64,33.14,44.37z"
            />
        </g>
    </svg>
);

export default WarningIcon;
