import React, { useState, useCallback, useEffect } from 'react';
import LoadingSpinner from 'koddi-components/LoadingSpinner';
import ErrorMessage from 'koddi-components/ErrorMessage';
import { useCancelablePromise } from 'koddi-components/hooks';
import {
    AsyncStateManagerState,
    AsyncStateManagerProps,
} from './AsyncStateManager.types';
import { AsyncStateManagerContainer } from './AsyncStateManager.styled';

const DEFAULT_ERROR_MESSAGE =
    'There was an error loading this information. Click retry to try again.';

/**
 * A UI abstraction for displaying the Koddi `LoadingSpinner` component while
 * an api loads and rendering content on success or an error component
 * on failure.
 * 
 * Example Usage:
 * 
 * ```
 * <AsyncStateManager
    api={() => KoddiAPI.Charts.get()}
    id="overview-chart"
    renderData={(data) => <ChartCard data={data} />}
/>
 * ```
 */
function AsyncStateManager<
    Data,
    ComponentProps extends Record<string, unknown> = Record<string, unknown>
>({
    api,
    renderData,
    errorComponent: ErrorComponent = ErrorMessage,
    maxRetries = 3,
    id,
    errorTitle,
    errorMessage,
    component: Component,
    loadingComponent: LoadingComponent,
    reloadApi,
    width,
    maxHeight,
    maxWidth,
    minHeight,
    minWidth,
    height,
    componentProps,
    useComponentAsLoader = false,
}: AsyncStateManagerProps<Data, ComponentProps>): JSX.Element {
    const [state, setState] = useState<AsyncStateManagerState>('idle');
    const [data, setData] = useState<Data | null>(null);
    const [error, setError] = useState<Error | null>();
    const [retries, setRetries] = useState<number>(0);
    const createCancelablePromise = useCancelablePromise<Data>(false, true);

    const callApi = useCallback(() => {
        setState('loading');
        const promise = api();
        const resolve = (result: Data) => {
            setData(result);
            setState('resolved');
        };
        const reject = (e: any) => {
            setError(e);
            setState('error');
        };
        createCancelablePromise(promise, resolve, reject);
    }, [api, createCancelablePromise]);

    const retry = useCallback(() => {
        if (retries < maxRetries) {
            setRetries(retries + 1);
            setState('loading');
            callApi();
        }
    }, [callApi, retries, setRetries, maxRetries]);

    useEffect(() => {
        setState('idle');
    }, [setState, reloadApi]);

    useEffect(() => {
        if (state === 'idle') {
            callApi();
        }
    }, [callApi, state]);

    if (
        state === 'resolved' ||
        (useComponentAsLoader && state === 'loading') ||
        (useComponentAsLoader && state === 'idle')
    ) {
        if (Component) {
            return (
                <Component
                    {...(componentProps as ComponentProps)}
                    data={data as Data}
                    refetch={callApi}
                    state={state}
                />
            );
        }
        if (renderData) return renderData(data as Data);
        return <div>You forgot to pass in either renderData or Component</div>;
    }

    if (state === 'error') {
        return (
            <ErrorComponent
                {...(componentProps as ComponentProps)}
                error={error as Error}
                onRetry={retry}
                title={errorTitle || 'Error'}
                message={
                    errorMessage || error?.message || DEFAULT_ERROR_MESSAGE
                }
                width={width || maxWidth || minWidth}
                height={height || maxHeight || minHeight}
                maxRetriesReached={retries >= maxRetries}
                refetch={callApi}
                state={state}
            />
        );
    }

    return (
        <AsyncStateManagerContainer
            height={height}
            width={width}
            maxHeight={maxHeight}
            maxWidth={maxWidth}
            minHeight={minHeight}
            minWidth={minWidth}
            data-test={`${id}-async-state-manager`}
        >
            {LoadingComponent ? (
                <LoadingComponent
                    {...(componentProps as ComponentProps)}
                    state={state}
                    refetch={callApi}
                />
            ) : (
                <LoadingSpinner id={id} absolutePosition />
            )}
        </AsyncStateManagerContainer>
    );
}

export default AsyncStateManager;
