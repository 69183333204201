import CachedKoddiAPI from 'api/api.cachedObject';
import { KoddiAPIResponse } from 'types';
import {
    Entity,
    BulkEntity,
    GetEntityAttributeResponse,
    EntityBasic,
} from './Entities.types';

class KoddiEntitiesAPI extends CachedKoddiAPI {
    getByMemberGroup = async (memberGroupId: number): Promise<Entity[]> => {
        const response = await this.axios.get<
            KoddiAPIResponse<{ entities: Entity[] }>
        >(`v1/member_groups/${memberGroupId}/entities`);

        return response.data.result.entities;
    };

    getEntityBasic = async (
        memberGroupId: number,
        entity_ids: string[]
    ): Promise<EntityBasic[]> => {
        const response = await this.axios.post<
            KoddiAPIResponse<{ entities: EntityBasic[] }>
        >(`v1/member_groups/${memberGroupId}/entities/basic`, {
            entity_ids,
        });

        return response.data.result.entities;
    };

    getEntityBasicByCSV = async (
        memberGroupId: number,
        csv: File
    ): Promise<{ entities: EntityBasic[] }> => {
        const formData = new FormData();
        formData.append('file', csv);
        const response = await this.axios.post(
            `v1/member_groups/${memberGroupId}/entities/basic/csv`,
            formData,
            {
                headers: { 'Content-Type': 'multipart/form-data' },
            }
        );

        return response.data.result;
    };

    getEntityAttributes = async (
        memberGroupId: number
    ): Promise<GetEntityAttributeResponse> => {
        const response = await this.cache.makeCachedRequest(
            'getEntityAttributes',
            memberGroupId,
            () => {
                return this.axios.get<any>(
                    `v1/member_groups/${memberGroupId}/entity_attributes`
                );
            }
        );

        return response.data.result;
    };

    getByAdvertiser = async (
        memberGroupId: number,
        advertiserId: number
    ): Promise<Entity[]> => {
        const response = await this.axios.get<
            KoddiAPIResponse<{ entities: Entity[] }>
        >(
            `v1/member_groups/${memberGroupId}/advertisers/${advertiserId}/entities`
        );

        return response.data.result.entities;
    };

    updateAdGroupEntitiesBulk = async (
        payload: BulkEntity
    ): Promise<BulkEntity> => {
        const response = await this.axios.put<
            KoddiAPIResponse<{
                entities: BulkEntity['entities'];
                bid_adjustment: BulkEntity['bid_adjustment'];
            }>
        >(`v1/ad_groups/entities/bids/bulk`, payload);

        return response.data.result;
    };

    deleteEntity = async (
        memberGroupId: number,
        advertiserId: number,
        entity_ids: string[]
    ): Promise<any> => {
        const response = await this.axios.delete<KoddiAPIResponse<any>>(
            `v1/member_groups/${memberGroupId}/advertisers/${advertiserId}/entities`,
            { data: { entities: [entity_ids] } }
        );

        return response.data.result;
    };
}

export default KoddiEntitiesAPI;
