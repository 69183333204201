import {
    ApplicationRootState,
    ApplicationOutputSelector,
} from 'redux-core/types';
import get from 'lodash/get';
import { createSelector } from 'reselect';
import {
    MemberGroupTypes,
    MemberGroupTypesList,
} from 'api/Admin/MemberGroupTypes';
import { AsyncState } from 'types';
import { SelectOption } from 'koddi-components/Select';
import { initialState } from './reducer';
import { MemberGroupTypesState } from './types';

export const selectAdminMemberGroupTypes = (
    state: ApplicationRootState
): MemberGroupTypesState => state.admin.memberGroupTypes || initialState;

export const selectMemberGroupTypes: ApplicationOutputSelector<
    AsyncState<MemberGroupTypesList>,
    (res: MemberGroupTypesState) => AsyncState<MemberGroupTypesList>
> = createSelector(selectAdminMemberGroupTypes, (state) => state.list);

export const selectMemberGroupTypesOptions: ApplicationOutputSelector<
    SelectOption<number>[],
    (res: MemberGroupTypesState) => SelectOption<number>[]
> = createSelector(selectAdminMemberGroupTypes, (state) => {
    if (!state || !state.list || !state.list.data) return [];
    const industries = get(state, 'list.data.member_group_types', []);
    return industries.map((memberGroupType: MemberGroupTypes) => ({
        label: memberGroupType.name,
        value: memberGroupType.id,
    }));
});
