import React from 'react';
import {
    ChartCardHeaderWrapper,
    ChartCardSelect,
    ChartCardControls,
    ChartCardTabList,
} from './ChartCardHeader.styled';
import { ChartCardHeaderProps } from './ChartCardHeader.types';

const ChartCardHeader = ({
    id,
    selectLabel,
    onSelectChange,
    selectOptions,
    selectValue,
}: ChartCardHeaderProps): JSX.Element => {
    return (
        <ChartCardHeaderWrapper>
            <ChartCardSelect
                name={`${id}-select`}
                label={selectLabel}
                options={selectOptions}
                placeholder="Select Reporting Metric"
                defaultValue="ROAS"
                data-test={`${id}-select`}
                btnStyle="rect"
                onChange={onSelectChange}
                value={selectValue}
                useAriaLabel
                selectWidth={290}
            />
            <ChartCardControls>
                <ChartCardTabList />
            </ChartCardControls>
        </ChartCardHeaderWrapper>
    );
};

export default ChartCardHeader;
