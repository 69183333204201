import { useCallback } from 'react';

import { UserProperties, UseHeapValue } from './heap.types';

const useHeap = (): UseHeapValue => {
    const identify = useCallback((userId: number) => {
        window.heap.identify(userId);
    }, []);

    const addUserProperties = useCallback((userProperties: UserProperties) => {
        window.heap.addUserProperties(userProperties);
    }, []);

    return {
        identify,
        addUserProperties,
    };
};

export default useHeap;
