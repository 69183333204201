import React, { FunctionComponent, useEffect } from 'react';
import styled from 'styled-components';
import { useKoddiTheme, KoddiTheme } from 'koddi-components/ThemeProvider';
import { scaleIn, fadeIn } from 'koddi-components/animations';
import { VerticalFlexBox } from 'koddi-components/FlexBox';
import { BaseSVGProps } from '../icon.types';

const LoadingSpinnerSVG = styled.svg`
    circle.reportSuccess {
        animation: ${scaleIn} 0.3s cubic-bezier(0, 1.34, 0.61, 1.02);
        transform-origin: 50% 50%;
    }
    polygon.reportSuccess {
        transform-origin: 50% 50%;
        transform: scale(1.4);
        opacity: 0;
        animation: ${fadeIn} 0.3s ease both;
        animation-delay: 0.2s;
    }
`;

const LoadingSpinnerContainer = styled.div`
    position: relative;
`;

const SuccessLabel = styled.label`
    position: absolute;
    opacity: 0;
    bottom: -20px;
    animation: ${fadeIn} 0.4s ease both;
    white-space: nowrap;
`;

export type LoadingSpinnerIconProps = BaseSVGProps & {
    reportSuccess?: boolean;
    onSuccessReported?: () => void;
    successLabel?: string;
};

const LoadingSpinnerIcon: FunctionComponent<LoadingSpinnerIconProps> = ({
    width,
    height,
    color,
    strokeWidth = 10,
    reportSuccess,
    onSuccessReported,
    successLabel = 'Success',
}) => {
    const theme: KoddiTheme = useKoddiTheme();

    const animationDuration = 400;

    useEffect(() => {
        let timeoutId: number;
        if (reportSuccess && onSuccessReported) {
            timeoutId = setTimeout(onSuccessReported, animationDuration);
        }
        return function cleanup() {
            if (timeoutId) clearTimeout(timeoutId);
        };
    }, [reportSuccess, onSuccessReported]);

    function renderSuccess() {
        return (
            <>
                <circle
                    cx="60"
                    cy="60"
                    r="50"
                    fill={theme.success}
                    className="reportSuccess"
                />
                <polygon
                    fill={theme.white}
                    className="reportSuccess"
                    points="68.4 50.84 57.44 61.8 51.6 55.97 48 59.56 57.59 69.16 72 54.44 68.4 50.84"
                />
            </>
        );
    }
    return (
        <LoadingSpinnerContainer>
            <VerticalFlexBox justifyContent="center" alignItems="center">
                <LoadingSpinnerSVG
                    width={width}
                    height={height}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 120 120"
                    preserveAspectRatio="xMidYMid"
                >
                    <rect x="0" y="0" width="120" height="120" fill="none" />
                    <circle
                        cx="60"
                        cy="60"
                        r="40"
                        stroke={theme.grayLight}
                        fill="none"
                        strokeWidth={strokeWidth}
                    />
                    <circle
                        cx="60"
                        cy="60"
                        r="40"
                        stroke={color}
                        fill="none"
                        strokeWidth={strokeWidth}
                    >
                        <animate
                            attributeName="stroke-dashoffset"
                            dur="2s"
                            repeatCount="indefinite"
                            from="0"
                            to="502"
                        />
                        <animate
                            attributeName="stroke-dasharray"
                            dur="2s"
                            repeatCount="indefinite"
                            values="150.6 100.4;1 250;150.6 100.4"
                        />
                    </circle>
                    {reportSuccess && renderSuccess()}
                </LoadingSpinnerSVG>
                {reportSuccess && <SuccessLabel>{successLabel}</SuccessLabel>}
            </VerticalFlexBox>
        </LoadingSpinnerContainer>
    );
};

export default LoadingSpinnerIcon;
