import {
    EntityRegistrationFetchSucess,
    EntityRegistrationsFetchAction,
    EntityRegistrationAction,
    InitiateEntityRegistrationAction,
    EntityRegistrationErrorMessage,
    ClearEntityRegistrationErrorMessage,
    InitiateEntityRegistrationCSVAction,
    EntityRegistrationReport,
    EntityRegistrationReportPending,
    EntityRegistrationReportSuccess,
    EntityRegistrationReportFailure,
    ClearEntityRegistrationSave,
    ClearEntityRegistrationReport,
    EntityRegistrationRetry,
    EntityRegistrationRetryAll,
    EntityRegistrationHide,
    EntityRegistrationHideAll,
    EntityRegistrationHideOrRetryError,
} from './types';

export function initiateEntityRegistration(
    memberGroupId: number,
    advertiserId: number,
    uuids: string[]
): InitiateEntityRegistrationAction {
    return {
        type: EntityRegistrationAction.INITIATE_ENTITY_REGISTRATION,
        payload: {
            memberGroupId,
            advertiserId,
            uuids,
        },
    };
}

export function initiateEntityRegistrationCSV(
    memberGroupId: number,
    advertiserId: number,
    csvFile: File
): InitiateEntityRegistrationCSVAction {
    return {
        type: EntityRegistrationAction.INITIATE_ENTITY_REGISTRATION_CSV,
        payload: {
            memberGroupId,
            advertiserId,
            csvFile,
        },
    };
}

export function fetchEntityRegistrations(
    memberGroupId: number,
    advertiserId: number
): EntityRegistrationsFetchAction {
    return {
        type: EntityRegistrationAction.FETCH_ENTITY_REGISTRATIONS,
        payload: {
            memberGroupId,
            advertiserId,
        },
    };
}

export function fetchEntityRegistrationsSucess(
    events: number[]
): EntityRegistrationFetchSucess {
    return {
        type: EntityRegistrationAction.FETCH_ENTITY_REGISTRATIONS_SUCCESS,
        payload: {
            events,
        },
    };
}

export function entityRegistrationErrorMessage(
    message: string
): EntityRegistrationErrorMessage {
    return {
        type: EntityRegistrationAction.ERROR_MESSAGE,
        payload: {
            message,
        },
    };
}

export function clearEntityRegistrationSave(): ClearEntityRegistrationSave {
    return {
        type: EntityRegistrationAction.CLEAR_SAVING_STATE,
    };
}

export function clearEntityRegistrationErrorMessage(): ClearEntityRegistrationErrorMessage {
    return {
        type: EntityRegistrationAction.CLEAR_ERROR_MESSSAGE,
    };
}

export function entityRegistrationReportAction(
    memberGroupId: number,
    advertiserId: number
): EntityRegistrationReport {
    return {
        type: EntityRegistrationAction.ENTITY_REGISTRATION_REPORT,
        payload: {
            memberGroupId,
            advertiserId,
        },
    };
}

export function entityRegistrationReportPending(): EntityRegistrationReportPending {
    return {
        type: EntityRegistrationAction.ENTITY_REGISTRATION_REPORT_PENDING,
    };
}

export function entityRegistrationReportSuccess(
    data: any[]
): EntityRegistrationReportSuccess {
    return {
        type: EntityRegistrationAction.ENTITY_REGISTRATION_REPORT_SUCCESS,
        payload: { data },
    };
}

export function entityRegistrationReportFailure(): EntityRegistrationReportFailure {
    return {
        type: EntityRegistrationAction.ENTITY_REGISTRATION_REPORT_FAILURE,
    };
}

export function clearEntityRegistrationReport(): ClearEntityRegistrationReport {
    return {
        type: EntityRegistrationAction.CLEAR_REGISTRATION_REPORT,
    };
}

export function entityRegistrationRetryAction(
    memberGroupId: number,
    advertiserId: number,
    eventDetailId: number
): EntityRegistrationRetry {
    return {
        type: EntityRegistrationAction.ENTITY_REGISTRATION_RETRY,
        payload: {
            memberGroupId,
            advertiserId,
            eventDetailId,
        },
    };
}

export function entityRegistrationRetryAllAction(
    memberGroupId: number,
    advertiserId: number
): EntityRegistrationRetryAll {
    return {
        type: EntityRegistrationAction.ENTITY_REGISTRATION_RETRY_ALL,
        payload: {
            memberGroupId,
            advertiserId,
        },
    };
}

export function entityRegistrationHideAction(
    memberGroupId: number,
    advertiserId: number,
    eventDetailId: number
): EntityRegistrationHide {
    return {
        type: EntityRegistrationAction.ENTITY_REGISTRATION_HIDE,
        payload: {
            memberGroupId,
            advertiserId,
            eventDetailId,
        },
    };
}

export function entityRegistrationHideAllAction(
    memberGroupId: number,
    advertiserId: number
): EntityRegistrationHideAll {
    return {
        type: EntityRegistrationAction.ENTITY_REGISTRATION_HIDE_ALL,
        payload: {
            memberGroupId,
            advertiserId,
        },
    };
}

export function entityRegistrationHideOrRetryErrorAction(
    error: string
): EntityRegistrationHideOrRetryError {
    return {
        type: EntityRegistrationAction.ENTITY_REGISTRATION_HIDE_OR_RETRY_ERROR,
        payload: { error },
    };
}
