import React, { useState, useEffect, Dispatch, SetStateAction } from 'react';
import { useForm } from 'react-final-form';
import { useDropzone } from 'react-dropzone';

import Button from 'koddi-components/Button';
import { useKoddiToast } from 'koddi-components/ToastProvider';

import { useMemberGroupEntityLabel } from 'redux-core/app/context/hooks';
import { useKoddiThemeResource } from 'redux-core/app/theme/hooks';
import { ErrorText } from 'koddi-components/Typography';
import { LoadingWrapper } from 'koddi-components/FilterToolbar/FilterToolbar.styled';
import LoadingSpinner from 'koddi-components/LoadingSpinner';

import KoddiAPI from 'api';
import { EntityBasic } from 'api/Entities';

import { useAuth0 } from 'modules/Auth0';
import {
    AddEntitiesFooter,
    AddEntitiesHeader,
    AddEntitiesTextArea,
    AddEntitiesWrapper,
    ClearFileButton,
    FileListContainer,
    FileListHeader,
    FileListText,
    ItemListContainer,
    UploadCSVPlaceholder,
    UploadCSVWrapper,
} from './AddEntityIds.styled';

type AddEntityIdsProps = {
    files: File[];
    setFiles: Dispatch<SetStateAction<File[]>>;
    entityLabel?: string;
    entityArr: EntityBasic[];
    setEntityArr: Dispatch<SetStateAction<EntityBasic[]>>;
    onChange?: (values: any) => void;
};

const CSV_ERROR_TYPES: Record<string, unknown> = {
    'file-invalid-type': 'the file was not a .csv file',
    'too-many-files': 'only 1 file can be uploaded at a time',
};

const AddEntityIds: React.FC<AddEntityIdsProps> = ({
    files,
    setFiles,
    onChange,
    setEntityArr,
    entityArr,
}) => {
    const [idInput, setIdInput] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [csvLoading, setCsvLoading] = useState(false);
    const { singular, plural } = useMemberGroupEntityLabel();
    const { showErrorToast } = useKoddiToast();
    const theme = useKoddiThemeResource();
    const formState = useForm();
    const { change } = formState;
    const { ssoFeatureFlag } = useAuth0();
    const handleIdInput = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setIdInput(e.target.value);
        onChange?.(e.target.value);
    };

    const handleCSVUpload = async (acceptedFiles: File[], errors: any) => {
        setCsvLoading(true);
        if (acceptedFiles.length) {
            const entitiesFromCSV = await KoddiAPI.Entities.getEntityBasicByCSV(
                theme.member_group_id,
                acceptedFiles[0]
            );
            if (entitiesFromCSV.entities) {
                setEntityArr(entitiesFromCSV.entities);
                setFiles([...files, ...acceptedFiles]);
                setErrorMessage('');
                setCsvLoading(false);
            } else {
                setErrorMessage('No matching IDs were found in that CSV.');
                setFiles([]);
                setCsvLoading(false);
            }
        }
        if (errors.length) {
            errors.forEach((error: any) => {
                const errorCode = error.errors[0].code;
                showErrorToast(
                    'Error',
                    `${error.file.name} could not be uploaded because ${CSV_ERROR_TYPES[errorCode]}`
                );
                setCsvLoading(false);
            });
        }
    };

    const { getInputProps, open: openDropzone } = useDropzone({
        noClick: true,
        noKeyboard: true,
        onDrop: handleCSVUpload,
        accept: 'text/csv',
        maxFiles: 1,
    });

    const addIds = async () => {
        const ids = idInput.split(',').map((id) => id.trim(), 10);
        const entities = await KoddiAPI.Entities.getEntityBasic(
            theme.member_group_id,
            ids
        );
        if (entities?.length) {
            setEntityArr(entities);
            setErrorMessage('');
        } else {
            setErrorMessage(
                'The IDs you entered do not match any records for this client.'
            );
            setIdInput('');
        }
    };

    const handleRemoveFile = () => {
        setFiles([]);
        setEntityArr([]);
    };

    const handleRemoveId = (index: number) => {
        const tempEntityArr = [...entityArr];
        tempEntityArr.splice(index, 1);
        setEntityArr(tempEntityArr);
        if (!files.length) {
            setIdInput(tempEntityArr.join(', '));
        }
    };

    const handleClearIds = () => setEntityArr([]);

    useEffect(() => {
        change('entities', entityArr);
    }, [entityArr, change]);

    const fileItem = (file: File) => (
        <li key={file.name}>
            <div translate="no">{file.name.split('.')[0]}</div>
            <ClearFileButton
                type="button"
                onClick={() => handleRemoveFile()}
                onKeyDown={() => handleRemoveFile()}
            >
                X
            </ClearFileButton>
        </li>
    );

    const idItem = (entity: EntityBasic, index: number) => (
        <li key={entity.id}>
            <FileListText>
                <div translate="no">{entity.name}</div>
            </FileListText>
            <ClearFileButton
                type="button"
                onClick={() => handleRemoveId(index)}
                onKeyDown={() => handleRemoveId(index)}
            >
                X
            </ClearFileButton>
        </li>
    );

    return (
        <>
            <AddEntitiesWrapper>
                <AddEntitiesHeader
                    textAlign={ssoFeatureFlag ? 'center' : 'left'}
                    tKey=""
                    text={`Select Your ${plural}`}
                />
                <ErrorText tKey="error">{errorMessage}</ErrorText>
                <p>
                    {`Add one or more ${plural.toLowerCase()} to your advertiser by inputting your ${singular} IDs below.
                    For multiple ${plural.toLowerCase()}, use a comma separated list of ${singular} IDs.`}
                </p>
                <p>
                    {`Booking Network operates on your Booking.com ${singular} ID.`}
                </p>
                {files.length === 0 && entityArr.length === 0 && !csvLoading && (
                    <>
                        <AddEntitiesTextArea
                            name="idInput"
                            type="textarea"
                            value={idInput}
                            placeholder={`Enter your ${singular} IDs`}
                            onChange={handleIdInput}
                        />
                        <AddEntitiesFooter justifyContent="flex-end">
                            <Button
                                v2
                                disabled={!idInput || /^s*$/.test(idInput)}
                                onClick={addIds}
                            >
                                Save
                            </Button>
                        </AddEntitiesFooter>
                        <UploadCSVWrapper>
                            <UploadCSVPlaceholder>
                                {`You can also upload a CSV with all your 
                            ${singular} ID's.`}
                            </UploadCSVPlaceholder>
                            <Button
                                onClick={openDropzone}
                                onKeyUp={openDropzone}
                                type="button"
                                v2
                            >
                                Upload
                            </Button>
                            <input {...getInputProps()} />
                        </UploadCSVWrapper>
                    </>
                )}
                {files.length > 0 && !csvLoading && (
                    <>
                        <FileListHeader tKey="" text="File" />
                        <FileListContainer>
                            {files.map((file) => {
                                return fileItem(file);
                            })}
                        </FileListContainer>
                        <FileListHeader tKey="" text={plural} />
                        <ItemListContainer>
                            {entityArr.map((entity, index) => {
                                return idItem(entity, index);
                            })}
                        </ItemListContainer>
                    </>
                )}
                {files.length === 0 && entityArr.length > 0 && !csvLoading && (
                    <>
                        <FileListContainer>
                            {entityArr.map((entity, index) => {
                                return idItem(entity, index);
                            })}
                        </FileListContainer>
                        <Button
                            type="button"
                            onClick={handleClearIds}
                            onKeyDown={handleClearIds}
                            v2
                        >
                            Clear All
                        </Button>
                    </>
                )}
                {csvLoading && (
                    <LoadingWrapper>
                        <LoadingSpinner
                            size="small"
                            id="filter-toolbar-loading-spinner"
                        />
                    </LoadingWrapper>
                )}
            </AddEntitiesWrapper>
        </>
    );
};

export default AddEntityIds;
