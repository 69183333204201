import { RoleList, Role } from 'api/Admin/Role';
import {
    GetRoleListAction,
    RoleAction,
    GetRoleListSuccessAction,
    GetRoleListErrorAction,
    EditRoleAction,
    EditRoleErrorAction,
    EditRoleSubmitAction,
    EditRoleSuccessAction,
    EditRolePayload,
} from './types';

// Role List Actions

export function getRoleList(refresh?: boolean): GetRoleListAction {
    return {
        type: RoleAction.GET_ROLE_LIST,
        payload: { refresh: refresh || false },
    };
}

export function getRoleListSuccess(data: RoleList): GetRoleListSuccessAction {
    return {
        type: RoleAction.GET_ROLE_LIST_SUCCESS,
        payload: { data },
    };
}

export function getRoleListError(
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    error: any
): GetRoleListErrorAction {
    return {
        type: RoleAction.GET_ROLE_LIST_ERROR,
        error,
    };
}

// Edit Role Actions

export function editRole(role: Role): EditRoleAction {
    return {
        type: RoleAction.EDIT_ROLE,
        payload: { role },
    };
}

export function editRoleSubmit(data: EditRolePayload): EditRoleSubmitAction {
    return {
        type: RoleAction.EDIT_ROLE_SUBMIT,
        payload: { data },
    };
}

export function editRoleSubmitSuccess(role: Role): EditRoleSuccessAction {
    return {
        type: RoleAction.EDIT_ROLE_SUBMIT_SUCCESS,
        payload: { role },
    };
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function editRoleSubmitError(error: any): EditRoleErrorAction {
    return {
        type: RoleAction.EDIT_ROLE_SUBMIT_ERROR,
        error,
    };
}
