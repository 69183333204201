/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import KoddiAPI from 'api';
import { createSimpleSagaWithCache } from 'utils/createSimpleSaga';
import { takeEvery, all } from 'redux-saga/effects';
import SagaRegistry from 'redux-core/sagaRegistry';
import { LocalesList } from 'api/Admin/Locales';
import { getLocalesListError, getLocalesListSuccess } from './actions';
import { LocalesAction } from './types';
import { selectLocalesList } from './selectors';

export const getLocalesListApi = (): Promise<LocalesList> =>
    KoddiAPI.Admin.Locales.getList();

export const getLocalesListSaga = createSimpleSagaWithCache(
    getLocalesListApi,
    getLocalesListSuccess,
    getLocalesListError,
    selectLocalesList
);

export function* watchGetLocalesList() {
    yield takeEvery(LocalesAction.GET_LOCALES_LIST, getLocalesListSaga);
}

export function* localesSagas() {
    yield all([watchGetLocalesList()]);
}

SagaRegistry.registerSaga(localesSagas);
