import {
    ReduxActionWithError,
    ReduxActionWithPayload,
    ReduxActionWithRefresh,
    AsyncState,
} from 'types';
import { PermissionList } from 'api/Admin/Permission';

export enum PermissionAction {
    GET_PERMISSION_LIST = 'admin/permission/GET_PERMISSION_LIST',
    GET_PERMISSION_LIST_SUCCESS = 'admin/permission/GET_PERMISSION_LIST_SUCCESS',
    GET_PERMISSION_LIST_ERROR = 'admin/permission/GET_PERMISSION_LIST_ERROR',
}

export type GetPermissionListAction = ReduxActionWithRefresh<
    PermissionAction.GET_PERMISSION_LIST
>;

export type GetPermissionListSuccessAction = ReduxActionWithPayload<
    PermissionAction.GET_PERMISSION_LIST_SUCCESS,
    { data: PermissionList }
>;

export type GetPermissionListErrorAction = ReduxActionWithError<
    PermissionAction.GET_PERMISSION_LIST_ERROR
>;

export type PermissionActions =
    | GetPermissionListAction
    | GetPermissionListSuccessAction
    | GetPermissionListErrorAction;

export type PermissionState = {
    list: AsyncState<PermissionList>;
};
