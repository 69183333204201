import { HorizontalFlexBox } from 'koddi-components/FlexBox';
import styled, { css } from 'styled-components';

export const ClickableCell = styled(HorizontalFlexBox)<{ underline?: boolean }>`
    ${({ underline }) => css`
        text-decoration: ${underline ? 'underline' : 'inherit'};
        width: 100%;
        height: 100%;
        align-items: center;
        &:hover {
            cursor: pointer;
        `}
`;
