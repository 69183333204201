import styled, { css } from 'styled-components';
import { HorizontalFlexBox } from 'koddi-components/FlexBox';
import { Heading2, Heading3 } from 'koddi-components/Typography';
import { Input } from 'koddi-components/Input';
import { FieldContainer } from 'koddi-components/Form';

export const RegisterAdvertiserContainer = styled.div`
    ${({ theme: { spaceUnit } }) => css`
        padding: ${spaceUnit * 5}px;
        height: 100%;
        width: 100%;
    `}
`;

export const AddEntitiesWrapper = styled(FieldContainer)`
    padding-top: 0;
    max-height: calc(100vh - 100px);
    & p {
        font-size: 12px;
    }
`;

export const AddEntitiesHeader = styled(Heading2)<{
    textAlign: 'center' | 'left';
}>`
    ${({ theme: { spaceUnit }, textAlign = 'left' }) => css`
        margin: 0px 0px ${spaceUnit * 4}px 0px;
        text-align: ${textAlign};
    `}
`;

export const AddEntitiesTextArea = styled(Input)`
    height: 154px;
    resize: none;
`;

export const AddEntitiesFooter = styled(HorizontalFlexBox)`
    ${({ theme: { spaceUnit } }) => css`
        margin-top: ${spaceUnit * 4}px;
        justify-content: flex-start;
    `}
`;

export const UploadCSVWrapper = styled(FieldContainer)`
    ${({ theme: { spaceUnit } }) => css`
        padding: ${spaceUnit * 6}px;
    `}
`;

export const UploadCSVPlaceholder = styled.div`
    ${({ theme: { spaceUnit } }) => css`
        margin: 0px 0px ${spaceUnit * 4}px 0px;
        padding-bottom: ${spaceUnit * 2}px;
        border-bottom: 1px solid black;
    `}
`;

export const UploadCSVFooter = styled(HorizontalFlexBox)`
    ${({ theme: { spaceUnit } }) => css`
        margin-top: ${spaceUnit * 4}px;
    `}
`;

export const FileListContainer = styled.ul`
    ${({ theme: { spaceUnit, gray, darken } }) => css`
        margin: ${spaceUnit * 4}px 0;
        list-style: none;
        padding-left: ${spaceUnit * 2}px;
        & li {
            margin-top: ${spaceUnit * 2}px;
            width: calc(100% - ${spaceUnit * 2}px);
            color: ${gray};
            display: flex;
            justify-content: space-between;
            align-items: start;
            &:hover,
            &:focus {
                color: ${darken(gray)};
            }
        }
    `}
`;

export const ItemListContainer = styled(FileListContainer)`
    max-height: calc(100% - 200px);
    overflow-y: auto;
`;

export const FileListHeader = styled(Heading3)`
    ${({ theme: { spaceUnit } }) => css`
        margin: 0px 0px ${spaceUnit * 4}px 0px;
    `}
`;

export const FileListText = styled.span`
    pointer-events: none;
`;

export const ClearFileButton = styled.button`
    ${({ theme: { spaceUnit, gray } }) => css`
        padding-left: ${spaceUnit * 3}px;
        border: none;
        background: none;
        cursor: pointer;
        color: ${gray};
        pointer-events: auto;
    `}
`;
