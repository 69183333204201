/* eslint-disable no-nested-ternary */
import styled, { css } from 'styled-components';
import { ButtonBase } from 'koddi-components/Button';
import { cssValue } from 'koddi-components/utils';
import { HorizontalFlexBox } from 'koddi-components/FlexBox';
import { DropdownMenuListProps } from './dropdown.types';

export const DropdownWrapper = styled.div`
    display: 'block';
    position: relative;
    cursor: pointer;
    ${ButtonBase}:focus {
        outline: none;
    }
`;

export const DropdownArrow = styled.div`
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: '';
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
`;

export const DropdownMenuList = styled.ul<DropdownMenuListProps>`
    ${({ theme, maxHeight, usePortal, minWidth, width }) => css`
        position: absolute;
        max-height: ${cssValue(maxHeight)};
        min-width: ${cssValue(minWidth, '10rem')};
        overflow: auto;
        background-color: #fff;
        cursor: pointer;
        box-shadow: 0 0px 3px rgba(0, 0, 0, 0.16),
            0 2px 10px rgba(0, 0, 0, 0.12);
        margin: 0px;
        padding: 0px;
        z-index: ${theme.zIndex.dropdown};
        ${!usePortal &&
        css`
            top: 100%;
            left: 0%;
        `}
        ${width &&
        css`
            width: ${width};
        `}
    `}
`;

export const DropdownMenuListItem = styled.li<{
    isDestructive?: boolean;
    isDisabled?: boolean;
    padding?: string;
}>`
    ${({
        isDestructive,
        isDisabled,
        padding,
        theme: { error, grayDark },
    }) => css`
        display: block;
        padding: ${padding || '10px 10px 10px 10px'};
        clear: both;
        font-weight: 400;
        color: ${isDisabled ? grayDark : isDestructive ? error : '#000'};
        text-align: inherit;
        white-space: nowrap;
        background-color: transparent;
        border: 0;
        &:hover {
            color: ${isDisabled ? grayDark : isDestructive ? error : '#16181b'};
            text-decoration: none;
            background-color: ${isDisabled ? 'transparent' : '#f8f9fa'};
            cursor: ${isDisabled ? 'not-allowed' : 'pointer'};
        }
    `}
`;

export const DropdownMenuTitle = styled.h4`
    font-weight: 600;
    margin: 0px 0px 0px 5px;
    padding: 5px;
    height: 40px;
    align-items: center;
    display: flex;
`;

export const DropDownMenuHeaderWrapper = styled(HorizontalFlexBox)`
    align-items: center;
    justify-content: space-between;
    cursor: default;
`;
