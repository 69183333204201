import React, { FunctionComponent, useMemo, useCallback } from 'react';
import { useKoddiTabs } from '../../Tabs.hooks';
import { TTabItemWithIndex } from '../../Tabs.types';
import { TabContentProps, TabContentItem } from './TabContent.types';
import { createTabPaneId, createTabId } from '../../Tabs.utils';
import { TabContentBase, TabContentPane } from './TabContent.styled';

function showComponent(
    show: boolean,
    component: React.ReactNode,
    passThroughProps: Record<string, unknown>
): React.ReactNode {
    if (show) {
        return typeof component === 'function'
            ? component(passThroughProps)
            : component;
    }
    return null;
}

/**
 * The `TabContent` renders each of the `TabPane`'s.
 * This component uses a hook to hook into the `TabsContext`
 * to render the tab panes.
 *
 * This component can be rendered anywhere in the component
 * tree so long as it is wrapped in the `TabsProvider`.
 * This allows for building custom tab ui's.
 */
const TabContent: FunctionComponent<TabContentProps> = ({
    className,
    style,
    noPadding = false,
    minHeight,
    noHorizontalPadding = false,
    ...passThroughProps
}) => {
    const { id, tabItems, activeTabKey, renderPreContent } = useKoddiTabs();

    const createTabPaneComponents = useCallback(
        () =>
            tabItems.map((tabItem: TTabItemWithIndex, index: number) => {
                const active =
                    tabItem.index === activeTabKey ||
                    tabItem.key === activeTabKey;
                return {
                    key: createTabPaneId(id, index),
                    id: createTabPaneId(id, index),
                    component: tabItem.children,
                    show: active,
                    'aria-hidden': !active,
                    'aria-labelledby': createTabId(id, index),
                    tabIndex: active ? 0 : -1,
                };
            }),
        [activeTabKey, id, tabItems]
    );

    const tabContentPanes = useMemo(
        () =>
            createTabPaneComponents().map(
                ({ component, show, key, ...rest }: TabContentItem) => {
                    return (
                        <TabContentPane
                            {...rest}
                            key={key}
                            noPadding={noPadding}
                            noHorizontalPadding={noHorizontalPadding}
                        >
                            {renderPreContent()}
                            {showComponent(show, component, passThroughProps)}
                        </TabContentPane>
                    );
                }
            ),
        [
            createTabPaneComponents,
            noHorizontalPadding,
            noPadding,
            passThroughProps,
            renderPreContent,
        ]
    );

    return (
        <TabContentBase
            id={`${id}-content`}
            className={className}
            style={style}
            minHeight={minHeight}
        >
            {tabContentPanes}
        </TabContentBase>
    );
};

export default TabContent;
