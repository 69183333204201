/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { all, put, takeLeading } from 'redux-saga/effects';
import SagaRegistry from 'redux-core/sagaRegistry';
import { AdminAction, GetClientFormDataAction } from './types';
import { getMemberGroupList } from './memberGroup/actions';
import { getMemberGroupTypes } from './memberGroupTypes/actions';
import { getIndustryList } from './industry/actions';
import { getCurrencyList } from './currency/actions';
import { getLocalesList } from './locales/actions';
import { getTimezonesList } from './timezones/actions';

// Get Admin Data Sagas
export function* getClientFormData({
    payload: { refresh },
}: GetClientFormDataAction) {
    yield put(getMemberGroupTypes(refresh));
    yield put(getIndustryList(refresh));
    yield put(getCurrencyList());
    yield put(getLocalesList());
    yield put(getTimezonesList());
}

export function* getAdvertiserFormData({
    payload: { refresh },
}: GetClientFormDataAction) {
    yield put(getMemberGroupList(refresh));
    yield put(getCurrencyList());
}

export function* getAdvertiserGroupFormData({
    payload: { refresh },
}: GetClientFormDataAction) {
    yield put(getMemberGroupList(refresh));
}

export function* watchGetAdminData() {
    yield takeLeading(AdminAction.GET_CLIENT_FORM_DATA, getClientFormData);
    yield takeLeading(
        AdminAction.GET_ADVERTISER_FORM_DATA,
        getAdvertiserFormData
    );
    yield takeLeading(
        AdminAction.GET_ADVERTISER_GROUP_FORM_DATA,
        getAdvertiserGroupFormData
    );
}

function* adminSagas() {
    yield all([watchGetAdminData()]);
}

SagaRegistry.registerSaga(adminSagas);
