import Button from 'koddi-components/Button/button';
import Dropdown from 'koddi-components/Dropdown';
import Icon from 'koddi-components/Icon';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

export const FilterToolbarContainer = styled.div`
    ${({ theme: { spaceUnit } }) => css`
        button[data-test='date-range-dropdown-button'] {
            margin-right: ${spaceUnit * 2}px;
        }
        margin-bottom: 20px;
    `}
`;

export const ErrorWrapper = styled.div`
    display: flex;
    flex-direction: row;
    cursor: pointer;
`;

export const FilterToolbarError = styled.div`
    margin-top: 8px;
    text-decoration: underline;
`;

export const ErrorIcon = styled(Icon)`
    ${({ theme }) => css`
        color: ${theme.error};
        padding: 0px ${theme.spaceUnit * 2}px;
        margin-left: ${theme.spaceUnit * 5}px;
    `}
`;

export const FilterToolbarActionButton = styled(Button)<{
    filterToolbarV2?: boolean;
    width?: string;
}>`
    ${({ filterToolbarV2, width }) => css`
        width: ${width ? `${width}px` : 'auto'};
        ${filterToolbarV2
            ? `
            margin-left: 0px;
            margin-bottom: 20px;
        `
            : 'margin-left: 10px;'};
    `}
`;

export const FilterDropdown = styled(Dropdown)`
    ${({ theme: { white, gray, grayLight, btnPrimary } }) => css`
        button {
            background-color: ${btnPrimary};
            color: ${white};
            text-align: center;
            margin-left: 10px;

            &:disabled {
                background-color: ${grayLight};
                color: ${gray};
            }
        }
    `}
`;

export const LoadingWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-left: 20px;

    span {
        white-space: nowrap;
        margin-left: 4px;
    }
`;

export const FilterToggleText = styled.span<{ v2?: boolean }>`
    ${({ theme: { grayDark, typography }, v2 }) => css`
        text-decoration: ${v2 && 'underline'};
        color: ${v2 && grayDark};
        font-size: ${v2 && 'smaller'};
        align-self: center;
        margin-left: 20px;
        &:hover {
            cursor: pointer;
            text-decoration: underline;
        }
        ${v2 ? `font-size: ${typography.baseFontSize};` : ''}
    `}
`;

export const FilterToolbarText = styled.span`
    align-self: center;
    & + span {
        margin-left: 5px;
    }
`;

export const FilterTextLinkContainer = styled.span<{ v2?: boolean }>`
    ${({ v2 }) => css`
        display: flex;
        margin: ${v2 ? 0 : '0 20px'};
        flex-direction: ${v2 && 'column'};
    `}
`;

export const FilterToolbarLink = styled(Link)`
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const FilterToolbarLinkText = styled.span<{ v2?: boolean }>`
    ${({ v2 }) => css`
        align-self: ${!v2 && 'center'};
        margin-left: 10px;
        text-decoration: ${v2 && 'underline'};
        position: ${v2 && 'relative'};
        left: ${v2 && '40px'};
    `}
`;

export const FilterToolbarButtonsV2 = styled.span``;

export const FilterToolbarFilterAndDatePickerV2 = styled.div`
    display: flex;
    height: fit-content;
`;

export const LinkButtonWrapper = styled.div`
    display: inline-flex;
`;
