import { KoddiThemeResource } from 'redux-core/app/theme/types';
import { KoddiTheme } from 'koddi-components/ThemeProvider';
import { KoddiAPIResponse } from 'types';
import CachedKoddiAPI from 'api/api.cachedObject';

class KoddiThemeAPI extends CachedKoddiAPI {
    public create = async (theme: KoddiThemeResource): Promise<any> => {
        const response = await this.axios.post<KoddiAPIResponse<any>>(
            `v1/member_groups/${theme.member_group_id}/theme`,
            theme
        );
        return response.data;
    };

    public delete = async (member_group_id: number): Promise<any> => {
        const response = await this.axios.delete<KoddiAPIResponse<any>>(
            `v1/member_groups/${member_group_id}/theme`
        );
        this.cache.break('get', member_group_id);
        return response.data;
    };

    public getList = async (): Promise<KoddiTheme[]> => {
        const response = await this.axios.get<
            KoddiAPIResponse<{ themes: KoddiTheme[] }>
        >('v1/member_groups/themes');

        return response.data.result.themes;
    };

    public get = async (memberGroupId: number): Promise<KoddiTheme> => {
        const response = await this.cache.makeCachedRequest(
            'get',
            memberGroupId,
            () => {
                return this.axios.get<KoddiAPIResponse<KoddiTheme>>(
                    `v1/member_groups/${memberGroupId}/theme`
                );
            }
        );
        return response.data.result;
    };

    public update = async (theme: KoddiThemeResource): Promise<any> => {
        const response = await this.axios.patch<KoddiAPIResponse<any>>(
            `v1/member_groups/${theme.member_group_id}/theme`,
            theme
        );
        this.cache.break('get', theme.member_group_id);
        return response.data;
    };
}

export default KoddiThemeAPI;
