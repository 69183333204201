import { ReduxAction, ReduxActionWithPayload } from 'types';

export type MemberGroupIdType = number;
export type LocaleIdType = number;

export type TermsAndConditionsState = {
    hasAccepted: boolean;
    lastModified: string | null;
    loading: boolean;
    url: string;
    memberGroupId: MemberGroupIdType | null;
    localeId: LocaleIdType | null;
    error: boolean;
    errorType: TermsAndConditionsErrorType | null;
};

export enum TermsAndConditionsErrors {
    POST = 'POST',
    GET = 'GET',
}

export type TermsAndConditionsErrorType =
    | TermsAndConditionsErrors.POST
    | TermsAndConditionsErrors.GET;

export type TermsAndConditionsError = {
    error: Error;
    memberGroupId: MemberGroupIdType;
    localeId: LocaleIdType;
    type: TermsAndConditionsErrorType;
};

export type TermsAndConditionsErrorState = {
    memberGroupId: MemberGroupIdType | null;
    localeId: LocaleIdType | null;
    errorType: TermsAndConditionsErrorType | null;
};

export enum TermsAndConditionsAction {
    FETCH_TERMS_AND_CONDITIONS_SUCCESS = 'app/termsAndConditions/FETCH_TERMS_AND_CONDITIONS_SUCCESS',
    FETCH_TERMS_AND_CONDITIONS = 'app/termsAndConditions/FETCH_TERMS_AND_CONDITIONS',
    FETCH_TERMS_AND_CONDITIONS_ERROR = 'app/termsAndConditions/TERMS_AND_CONDITIONS_ERROR',
    ACCEPT_TERMS_AND_CONDITIONS = 'app/termsAndConditions/ACCEPT_TERMS_AND_CONDITIONS',
    ACCEPT_TERMS_AND_CONDITIONS_SUCCESS = 'app/termsAndConditions/ACCEPT_TERMS_AND_CONDITIONS_SUCCESS',
}

export type FetchTermsAndConditionsSuccessAction = ReduxActionWithPayload<
    TermsAndConditionsAction.FETCH_TERMS_AND_CONDITIONS_SUCCESS,
    {
        hasAccepted: boolean;
        lastModified: string | null;
        memberGroupId: MemberGroupIdType;
        url: string;
        localeId: LocaleIdType;
    }
>;

export type FetchTermsAndConditionsAction = ReduxActionWithPayload<
    TermsAndConditionsAction.FETCH_TERMS_AND_CONDITIONS,
    {
        userId: number;
        memberGroupId: MemberGroupIdType;
        localeId: LocaleIdType;
    }
>;

export type FetchTermsAndConditionsErrorAction = ReduxActionWithPayload<
    TermsAndConditionsAction.FETCH_TERMS_AND_CONDITIONS_ERROR,
    {
        error: Error;
        localeId: LocaleIdType;
        memberGroupId: MemberGroupIdType;
        type: TermsAndConditionsErrorType;
    }
>;

export type AcceptTermsAndConditionsAction = ReduxActionWithPayload<
    TermsAndConditionsAction.ACCEPT_TERMS_AND_CONDITIONS,
    {
        memberGroupId: MemberGroupIdType;
        url: string;
        localeId: LocaleIdType;
    }
>;

export type AcceptTermsAndConditionsSuccessAction = ReduxAction<
    TermsAndConditionsAction.ACCEPT_TERMS_AND_CONDITIONS_SUCCESS
>;

export type TermsAndConditionsActions =
    | FetchTermsAndConditionsSuccessAction
    | FetchTermsAndConditionsErrorAction
    | FetchTermsAndConditionsAction
    | AcceptTermsAndConditionsSuccessAction
    | AcceptTermsAndConditionsAction;
