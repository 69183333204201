import styled, { css } from 'styled-components';
import { cssValue } from 'koddi-components/utils';
import { ErrorMessageContainerProps } from './ErrorMessage.types';

export const ErrorMessageContainer = styled.div<ErrorMessageContainerProps>`
    ${({ theme, width, height }) => css`
        background: ${theme.grayLightest};
        width: ${cssValue(width, '100%')};
        height: ${cssValue(height, '100%')};
        padding: ${theme.spaceUnit * 8}px;
    `}
`;

export const ErrorMessageTitle = styled.h4`
    ${({ theme }) => css`
        color: #000000;
        font-size: 24px;
        font-weight: 500;
        margin: 0px 0px ${theme.spaceUnit * 4}px 0px;
    `}
`;

export const ErrorMessageText = styled.p`
    ${({ theme }) => css`
        color: ${theme.grayDark};
        margin: 0px 0px ${theme.spaceUnit * 4}px 0px;
    `}
`;
