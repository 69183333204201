import { useCallback, useState } from 'react';
import { useStripe, useElements } from '@stripe/react-stripe-js';
import { SetupIntentResult } from '@stripe/stripe-js';
import { PaymentMethodCreateParams } from '@stripe/stripe-js/types/api/payment-methods';
import { ConfirmPaymentData } from '@stripe/stripe-js/types/stripe-js/payment-intents';

type PaymentMethodTypes =
    | 'confirmCardPayment'
    | 'confirmAlipayPayment'
    | 'confirmBancontactPayment'
    | 'confirmEpsPayment'
    | 'confirmGiropayPayment'
    | 'confirmIdealPayment'
    | 'confirmP24Payment'
    | 'confirmSofortPayment'
    | 'confirmSepaDebitPayment'
    | 'confirmAcssDebitPayment'
    | 'confirmUsBankAccountPayment';

type PaymentType = {
    acceptsOptionsParameter: boolean;
    methodName: PaymentMethodTypes;
};

const CardPayment: PaymentType = {
    acceptsOptionsParameter: true,
    methodName: 'confirmCardPayment',
};

const AlipayPayment: PaymentType = {
    acceptsOptionsParameter: true,
    methodName: 'confirmAlipayPayment',
};

const BancontactPayment: PaymentType = {
    acceptsOptionsParameter: true,
    methodName: 'confirmBancontactPayment',
};

const EpsPayment: PaymentType = {
    acceptsOptionsParameter: true,
    methodName: 'confirmEpsPayment',
};

const GiropayPayment: PaymentType = {
    acceptsOptionsParameter: true,
    methodName: 'confirmGiropayPayment',
};

const IdealPayment: PaymentType = {
    acceptsOptionsParameter: true,
    methodName: 'confirmIdealPayment',
};

const P24Payment: PaymentType = {
    acceptsOptionsParameter: true,
    methodName: 'confirmP24Payment',
};

const SofortPayment: PaymentType = {
    acceptsOptionsParameter: true,
    methodName: 'confirmSofortPayment',
};

const SepaPayment: PaymentType = {
    acceptsOptionsParameter: false,
    methodName: 'confirmSepaDebitPayment',
};

const AcssDebit: PaymentType = {
    acceptsOptionsParameter: false,
    methodName: 'confirmAcssDebitPayment',
};

const AchPayment: PaymentType = {
    acceptsOptionsParameter: false,
    methodName: 'confirmUsBankAccountPayment',
};

export const paymentMapping = {
    card: CardPayment,
    alipay: AlipayPayment,
    bancontact: BancontactPayment,
    eps: EpsPayment,
    giropay: GiropayPayment,
    ideal: IdealPayment,
    p24: P24Payment,
    sofort: SofortPayment,
    sepa_debit: SepaPayment,
    acss_debit: AcssDebit,
    us_bank_account: AchPayment,
};

type PaymentMethodData = {
    return_url: string;
    billing_details?: PaymentMethodCreateParams.BillingDetails;
};
type PaymentMethodDataFn = (
    confirmParams: ConfirmPaymentData,
    onSubmitSuccess?: () => void
) => void;

type StripeService = {
    confirmSetup: (returnRoute: string) => void;
    confirmSetupWithParams: PaymentMethodDataFn;
    error?: any;
    getSetupIntent: (
        clientSecret: string
    ) => Promise<SetupIntentResult> | undefined;
    confirmPayment: (clientSecret: string) => Promise<any> | undefined;
    confirmStripePayment: (
        type: keyof typeof paymentMapping,
        clientSecret: string,
        data?: any,
        options?: any
    ) => Promise<any> | undefined;
};

export const useStripeService = (): StripeService => {
    const [paymentError, setError] = useState<any>(null);
    const stripe = useStripe();
    const elements = useElements();

    const getSetupIntent = useCallback(
        (clientSecret) => {
            return stripe?.retrieveSetupIntent(clientSecret);
        },
        [stripe]
    );

    const confirmSetup = useCallback(
        async (returnRoute: string) => {
            if (stripe && elements) {
                setError(null);
                const { error } = await stripe.confirmSetup({
                    elements,
                    confirmParams: {
                        return_url: returnRoute,
                    },
                });
                if (error) {
                    setError(error);
                }
            }
        },
        [stripe, elements]
    );

    const confirmSetupWithParams = useCallback(
        async (
            confirmParams: ConfirmPaymentData,
            onSubmitSuccess?: () => void
        ) => {
            if (stripe && elements) {
                setError(null);
                const { error } = await stripe.confirmSetup({
                    elements,
                    redirect: 'if_required',
                    confirmParams,
                });
                if (error) {
                    setError(error);
                    return;
                }
                onSubmitSuccess?.();
            }
        },
        [stripe, elements]
    );

    const confirmPayment = useCallback(
        async (returnRoute: string) => {
            if (stripe && elements) {
                setError(null);
                const { error } = await stripe.confirmPayment({
                    elements,
                    confirmParams: {
                        return_url: returnRoute,
                    },
                });
                if (error) {
                    setError(error);
                }
            }
        },
        [stripe, elements]
    );

    const confirmStripePayment = useCallback(
        async (
            type: keyof typeof paymentMapping,
            clientSecret: string,
            data?: any,
            options?
        ) => {
            if (stripe && elements) {
                setError(null);
                // eslint-disable-next-line prefer-destructuring
                const methodName: PaymentMethodTypes =
                    paymentMapping[type].methodName;

                const { acceptsOptionsParameter } = paymentMapping[type];
                let response;

                if (acceptsOptionsParameter) {
                    response = await stripe[methodName](
                        clientSecret,
                        data,
                        options
                    );
                } else {
                    response = await stripe[methodName](clientSecret, data);
                }

                if (response.error) {
                    setError(response.error);
                    throw new Error(response.error.message);
                }
            }
        },
        [elements, stripe]
    );

    return {
        confirmSetup,
        confirmSetupWithParams,
        error: paymentError,
        getSetupIntent,
        confirmPayment,
        confirmStripePayment,
    };
};

export default useStripeService;
