import React from 'react';
import { TTabItem, TTabItemWithIndex } from './Tabs.types';

export function createTabId(tabsId: string, tabIndex: number): string {
    return `${tabsId}-tab-${tabIndex}`;
}

export function createTabPaneId(tabsId: string, tabIndex: number): string {
    return `${tabsId}-tab-pane-${tabIndex}`;
}

/**
 * Creates an array of tab defintiions to be used
 * by the `TabList` and the `TabContent`
 * to render the `Tabs`'s and the `TabPane`'s.
 */
export function createTabItems(
    tabItems?: TTabItem[],
    tabPlaceholders?: React.ReactNode
): TTabItemWithIndex[] {
    if (tabItems) {
        return tabItems.map((tabItem, index) => ({ ...tabItem, index }));
    }
    if (tabPlaceholders) {
        return (
            React.Children.map(tabPlaceholders, (child, index) => {
                if (child && React.isValidElement(child)) {
                    return {
                        children: child.props.children,
                        index,
                        title: child.props.title,
                    };
                }
                return null;
            })?.filter((item) => item !== null) || []
        );
    }
    return [];
}

export function findTabByKey(
    key: string,
    tabs: TTabItem[]
): TTabItem | undefined {
    return tabs.find((tab) => tab.key === key);
}

export function findTabIndexByKey(key: string, tabs: TTabItem[]): number {
    return tabs.findIndex((tab) => tab.key === key);
}
