import { ReduxAction, ReduxActionWithPayload } from 'types';

export type AppMessage = {
    message: string;
    type: string;
    title: string;
    key?: string;
};

export type AppMessagesState = {
    current: AppMessage | null;
    list: AppMessage[];
};

export enum AppMessagesAction {
    SET_MESSAGE = 'app/messages/SET_MESSAGE',
    CLEAR_MESSAGE = 'app/messages/CLEAR_MESSAGE',
}

export type SetMessageAction = ReduxActionWithPayload<
    AppMessagesAction.SET_MESSAGE,
    {
        type: string;
        message: string;
        title: string;
        key?: string;
    }
>;

export type ClearMessageAction = ReduxAction<AppMessagesAction.CLEAR_MESSAGE>;

export type AppMessagesActions = SetMessageAction | ClearMessageAction;
