import styled from 'styled-components';

export const Auth0Container = styled.div<{ maxWidth: number }>`
    ${({ theme, maxWidth }) => `
        border: 1px solid ${theme.grayLight};
        padding:40px;
        display:block;
        margin: auto;
        max-width: ${maxWidth}px;
        width: 90%;
        max-height:100%;
    `}
`;

export const Logo = styled.img`
    max-width: 240px;
    width: 90%;
    height: auto;
    display: block;
    margin: 0 auto 40px;
`;

export const Auth0Content = styled.div`
    overflow-y: auto;
    height: 100%;
    max-height: 70vh;
    padding-right: 8px;
    width: 100%;
`;
