import {
    ApplicationRootState,
    ApplicationOutputSelector,
} from 'redux-core/types';
import { createSelector } from 'reselect';
import { AsyncState } from 'types';
import { TimezonesList } from 'api/Admin/Timezones';
import { initialState } from './reducer';
import { TimezonesState } from './types';

const selectAdminTimezones = (state: ApplicationRootState) =>
    state.admin.timezones || initialState;

export const selectTimezonesList: ApplicationOutputSelector<
    AsyncState<TimezonesList>,
    (res: TimezonesState) => AsyncState<TimezonesList>
> = createSelector(selectAdminTimezones, (state) => state.list);

export const selectTimezonesOptions = createSelector(
    selectTimezonesList,
    (timezones) => {
        return (
            timezones.data?.map((timezone) => ({
                label: timezone.name,
                value: timezone.id,
            })) ?? []
        );
    }
);
