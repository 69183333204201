/* eslint-disable @typescript-eslint/no-empty-function */
import React from 'react';
import isEmpty from 'lodash/isEmpty';
import Icon from 'koddi-components/Icon';
import Checkbox from 'koddi-components/Checkbox';
import FlexBox from 'koddi-components/FlexBox';
import { HeaderGroup } from 'react-table';
import CanView from 'koddi-components/CanView';
import { useKoddiTable } from '../TableProvider';
import { TableHeadProps } from './TableHead.types';

import {
    TableHeadBase,
    TableHeadCellBase,
    SortArrow,
} from './TableHead.styled';
import { TableRowBase } from '../Table.styled';

function renderSortIcon(column: HeaderGroup<any>, disableManualSort: boolean) {
    const shouldShowIcon =
        !disableManualSort &&
        !column.defaultCanSort &&
        !column.placeholderOf &&
        !column.disableSortBy;
    if (shouldShowIcon) {
        return (
            <SortArrow isSorted={column.isSorted}>
                <Icon
                    width={8}
                    height={8}
                    icon={
                        column.isSortedDesc || !column.isSorted
                            ? 'chevronDown'
                            : 'chevronUp'
                    }
                />
            </SortArrow>
        );
    }
    return null;
}

function renderCheckBoxHeader(props: any) {
    const { column } = props;
    const noRowsChecked = isEmpty(column.selectedRows);
    const allRowsSelected = column.selectAll;

    return (
        <FlexBox
            alignItems={column.alignItems || 'center'}
            justifyContent={column.justifyContent || 'center'}
            fillContainer
        >
            <FlexBox
                height={column.height || 12}
                alignItems={column.alignItems || 'center'}
            >
                <span
                    aria-checked={allRowsSelected || !noRowsChecked}
                    role="checkbox"
                    tabIndex={0}
                    onKeyUp={() => {}}
                    onClick={() => column.onSelectAll(props)}
                >
                    <Checkbox
                        name="checkbox"
                        animated
                        disabled={false}
                        fullWidth
                        checked={
                            column.isSelectAllChecked
                                ? column.isSelectAllChecked(props)
                                : allRowsSelected || !noRowsChecked
                        }
                        isMinus={!noRowsChecked && !allRowsSelected}
                        onClick={(e: any) => {
                            e.preventDefault();
                            e.stopPropagation();
                        }}
                        label={column.headerLabel || null}
                        onChange={() => {}}
                    />
                </span>
            </FlexBox>
        </FlexBox>
    );
}

/**
 * The component rendered for the table head.
 */
function TableHead<D extends Record<string, unknown>>({
    headerGroups,
    disableManualSort,
    headerRowHeight,
    sticky = false,
    id,
}: TableHeadProps<D>): JSX.Element {
    const { borderless, boldHeaders, disableHeaderSelection } = useKoddiTable();
    return (
        <TableHeadBase
            sticky={sticky}
            borderless={borderless}
            data-test={`${id}-head`}
        >
            {headerGroups.map((headerGroup, index) => (
                <TableRowBase
                    {...headerGroup.getHeaderGroupProps()}
                    key={`group-${headerGroup.id || index}`}
                >
                    {headerGroup.headers.map((column: any) => (
                        <CanView
                            permission={column.permission}
                            any={column.anyPermission}
                            role={column.role}
                            anyRole={column.anyRole}
                            key={`column-${column.id}`}
                        >
                            <TableHeadCellBase
                                {...column.getHeaderProps(
                                    column.getSortByToggleProps()
                                )}
                                height={headerRowHeight}
                                sticky={sticky}
                                align={column.align}
                                borderless={borderless}
                                isPlaceholder={
                                    column.placeholderOf !== undefined
                                }
                                cellWidth={column.width || column.totalWidth}
                                maxWidth={column.maxWidth}
                                bold={boldHeaders}
                                sortDisabled={
                                    disableManualSort || column.disableSortBy
                                }
                                disableSelection={
                                    disableHeaderSelection ||
                                    column.disableSelection
                                }
                            >
                                {column.Header === 'selectAllCheckBox' ? (
                                    column.render(renderCheckBoxHeader)
                                ) : (
                                    <span>{column.render('Header')}</span>
                                )}
                                {renderSortIcon(column, disableManualSort)}
                            </TableHeadCellBase>
                        </CanView>
                    ))}
                </TableRowBase>
            ))}
        </TableHeadBase>
    );
}

export default TableHead;
