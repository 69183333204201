import { CurrencyList } from 'api/Admin/Currency';
import {
    GetCurrencyListAction,
    CurrencyAction,
    GetCurrencyListSuccessAction,
    GetCurrencyListErrorAction,
} from './types';

export function getCurrencyList(refresh?: boolean): GetCurrencyListAction {
    return {
        type: CurrencyAction.GET_CURRENCY_LIST,
        payload: { refresh: refresh || false },
    };
}

export function getCurrencyListSuccess(
    data: CurrencyList
): GetCurrencyListSuccessAction {
    return {
        type: CurrencyAction.GET_CURRENCY_LIST_SUCCESS,
        payload: { data },
    };
}

export function getCurrencyListError(
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    error: any
): GetCurrencyListErrorAction {
    return {
        type: CurrencyAction.GET_CURRENCY_LIST_ERROR,
        error,
    };
}
