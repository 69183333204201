import { useContext } from 'react';
import { TabsContext } from './Tabs.provider';
import { TTabsProviderContext } from './Tabs.types';

export function useKoddiTabs(): TTabsProviderContext {
    const context = useContext(TabsContext);

    if (context === undefined) {
        throw new Error('`useKoddiTabs` must be used within a `TabsProvider`');
    }

    return context;
}
