import styled, { css } from 'styled-components';

export const AppContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
`;

export const AppHeader = styled.div`
    ${({ theme }) => css`
        height: ${theme.spaceUnit * 16}px;
        background-color: ${theme.headerBackground || '#000000'};
        color: ${theme.white};
    `}
`;

export const AppBody = styled.div`
    display: flex;
    flex-direction: row;
    flex: 1;
`;

export const SideNavContainer = styled.div`
    ${({ theme }) => css`
        border-right: 1px solid ${theme.grayLight};
    `}
`;

export const ContentContainer = styled.div<{ spaceForTopNav?: boolean }>`
    ${({ theme, spaceForTopNav = true }) => css`
        height: ${spaceForTopNav
            ? `calc(100vh - ${theme.spaceUnit * 16}px)`
            : `100vh`};
        flex: 1;
        overflow-y: auto;
        overflow-x: auto;
        position: relative;
    `}
`;
