import React, { FunctionComponent } from 'react';
import { BaseSVGProps } from '../icon.types';

const ChevronDownIcon: FunctionComponent<BaseSVGProps> = ({
    width,
    height,
}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 24 24"
    >
        <path d="M0 3.6v7.6l12 9.2 12-9.2V3.6l-12 9.2z" />
    </svg>
);

export default ChevronDownIcon;
