import React, { FunctionComponent } from 'react';
import { BaseSVGProps } from '../icon.types';

const MagnifierIcon: FunctionComponent<BaseSVGProps> = ({ width, height }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 24 24"
    >
        <path d="M24 21.67l-4.89-4.89a10.84 10.84 0 002-6.22 10.56 10.56 0 10-10.56 10.55 10.87 10.87 0 006.23-2L21.67 24zm-20.67-11a7.23 7.23 0 117.22 7.22 7.21 7.21 0 01-7.22-7.2z" />
    </svg>
);

export default MagnifierIcon;
