import {
    ApplicationRootState,
    ApplicationOutputSelector,
} from 'redux-core/types';
import { createSelector } from 'reselect';
import { AsyncState } from 'types';
import { ActivityList } from 'api/Admin/Activity';
import { initialState } from './reducer';
import { ActivityState } from './types';

const selectAdminActivity = (state: ApplicationRootState) =>
    state.admin.activity || initialState;

export const selectActivityList: ApplicationOutputSelector<
    AsyncState<ActivityList>,
    (res: ActivityState) => AsyncState<ActivityList>
> = createSelector(selectAdminActivity, (state) => state.list);
