/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import KoddiAPI from 'api';
import { takeEvery, call, all, put } from 'redux-saga/effects';
import SagaRegistry from 'redux-core/sagaRegistry';
import { fetchKPICardDataSuccess, fetchKPICardDataError } from './actions';

import { KPICardDataAction, FetchKPICardDataAction } from './types';

export function* fetchKPICardDataSaga(action: FetchKPICardDataAction) {
    try {
        /* @ts-ignore */
        const response = yield call(
            KoddiAPI.Report.fetchTotalReport,
            action.payload.reportPayload
        );
        yield put(fetchKPICardDataSuccess(response));
    } catch (e) {
        yield put(fetchKPICardDataError(e.error?.message));
    }
}

export function* watchDashboard() {
    yield takeEvery(
        KPICardDataAction.FETCH_KPI_CARD_DATA,
        fetchKPICardDataSaga
    );
}

function* dashboardSagas() {
    yield all([watchDashboard()]);
}

SagaRegistry.registerSaga(dashboardSagas);
