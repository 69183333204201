/* eslint-disable no-plusplus */
/* eslint-disable no-useless-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from 'react';
import useResizeObserver from 'use-resize-observer';
import {
    useTable,
    useResizeColumns,
    useFlexLayout,
    useSortBy,
    useBlockLayout,
    useRowSelect,
    useRowState,
    useExpanded,
    useGlobalFilter,
    useFilters,
} from 'react-table';
import { useSticky } from 'react-table-sticky';
import TableContainer from './TableContainer';
import TableProvider, {
    TableItemUpdater,
    TableItemUpdaterSuccessFn,
    TableItemUpdaterErrorFn,
} from './TableProvider';

import { TableComponentProps } from './Table.types';
import { TableBase, TableFrame } from './Table.styled';
import { updateData } from './Table.utils';
import ActionBar from './ActionBar';

/**
 * The Koddi `Table` Component
 *
 * The `Table` component allows developer to create
 * a basic data table.
 *
 * Example Columns:

        const columns = [
            {
                Header: 'First Name',
                accessor: 'first_name',
            },
            {
                Header: 'Last Name',
                accessor: 'last_name',
            },
            {
                Header: 'Email',
                accessor: 'email',
            },
        ];

 * Example Data:

        const data = [
            {
                first_name: 'First',
                last_name: 'Last',
                email: 'email@website.com',
            },
        ];

 * Example Usage:

        <Table<DataType> id="my-table" columns={columns} data={data} tableHeight={400} />

 */
export function Table<D extends Record<string, unknown>>({
    columns,
    data,
    onSort,
    loadMoreItems = () => null,
    disableManualSort = false,
    overFlowXDisabled = false,
    actionBarItems = [],
    rowHeight = 35,
    headless = false,
    oddStyles = true,
    itemCount = data.length,
    headerRowHeight = 50,
    tableHeight = 600,
    loadingMoreItems = false,
    threshold = 15,
    fixedSizeListOuterRef,
    id,
    sticky = false,
    onUpdateItem,
    onUpdateItemSuccess,
    onUpdateItemError,
    skipPageReset,
    borderless = false,
    boldHeaders = false,
    useSpacer = true,
    filters = [],
    disabled,
    initialState = {},
    onRowsSelected,
    filterState,
    customSelectedRows,
    getActionBarTitle,
    selectAll,
    disableCellSelection,
    disableHeaderSelection,
}: TableComponentProps<D>): JSX.Element {
    const [formattedColumns, setFormattedColumns] = useState(columns);
    const [tableFrameWidth, setTableFrameWidth] = useState(0);
    const [tableLaidOut, setTableLaidOut] = useState(false);

    const tableLayoutPlugins = sticky
        ? [useBlockLayout, useSticky]
        : [useFlexLayout, useResizeColumns];

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        state,
        totalColumnsWidth,
        selectedFlatRows,
        setFilter,
    } = useTable<D>(
        {
            columns: formattedColumns,
            data,
            manualSortBy: !!onSort,
            autoResetPage: !skipPageReset,
            autoResetExpanded: !skipPageReset,
            autoResetGroupBy: !skipPageReset,
            autoResetSelectedRows: !skipPageReset,
            autoResetSortBy: !skipPageReset,
            autoResetFilters: !skipPageReset,
            autoResetRowState: !skipPageReset,
            initialState,
        },
        ...tableLayoutPlugins,
        useRowState,
        useFilters,
        useGlobalFilter,
        useSortBy,
        useExpanded,
        useRowSelect
    );

    useEffect(() => {
        filters.forEach((filter) => {
            setFilter(filter.id, filter.value);
        });
    }, [filters]);

    useEffect(() => {
        if (onRowsSelected) onRowsSelected(state.selectedRowIds);
    }, [state.selectedRowIds]);

    const { sortBy } = state;

    const { ref, width = 0 } = useResizeObserver<HTMLDivElement>();

    useEffect(() => {
        if (width) {
            setTableFrameWidth(width);
            setTimeout(() => setTableLaidOut(true), 1); // allow paint before showing table
        }
    }, [width]);

    // listen for column changes and manually inject a width onto the 'spacer' column
    // to allow for dynamic stretching to fill the table frame
    useEffect(() => {
        if (!tableFrameWidth || !useSpacer) return;
        setTimeout(() => {
            if (tableFrameWidth > totalColumnsWidth) {
                const newColumns = [...columns];
                const stickyRightColumnsIndex = columns.findIndex(
                    (c) => c.sticky === 'right'
                );
                newColumns.splice(stickyRightColumnsIndex, 0, {
                    Header: ' ',
                    id: 'spacer',
                    disableSortBy: true,
                    width: tableFrameWidth - totalColumnsWidth,
                });
                setFormattedColumns(newColumns);
                return;
            }
        }, 200);
        // update with non formatted columns to reset totalColumnsWidth
        setFormattedColumns(columns);
    }, [columns, tableFrameWidth]);

    const filtersPresent = filters.length > 0;
    const nestedItemsCount = useMemo(() => {
        const visibleExpandedRows: Record<string, any> = {};

        function countExpandedRows(allRows: any) {
            for (let i = 0; i < allRows.length; i++) {
                const row = allRows[i];
                const hasSubRows = row.subRows && row.subRows.length;
                /**
                 * count all rows that have all parent rows expanded as well. Collapsing a
                 * mid-tier row will continue to count child rows as 'expanded' even though they
                 * are no longer visible to the user
                 */
                if (row.isExpanded) {
                    if (!visibleExpandedRows[row.id]) {
                        visibleExpandedRows[row.id] = row.subRows.length;
                        if (hasSubRows) {
                            countExpandedRows(row.subRows);
                        }
                    }
                }
            }
        }
        countExpandedRows(rows);
        const visibleExpandedRowsCount = Object.keys(
            visibleExpandedRows
        ).reduce((prev, curr) => {
            return prev + visibleExpandedRows[curr];
        }, 0);
        return (
            visibleExpandedRowsCount +
            (filtersPresent ? rows.length : itemCount)
        );
    }, [data, state.expanded, selectedFlatRows, itemCount]);

    const showActionBar =
        (actionBarItems.length > 0 &&
            customSelectedRows &&
            Object.keys(customSelectedRows).length > 0) ||
        selectAll;

    const handleUpdateItem: TableItemUpdater<D> = (
        itemId,
        rowData,
        rowUpdater,
        onSuccess,
        onError
    ) => {
        const { updatedData, updatedRow } = updateData<D>(
            data,
            rowData,
            rowUpdater
        );
        if (updatedRow) {
            const handleSuccess: TableItemUpdaterSuccessFn<D> = (
                row,
                newData
            ) => {
                if (onSuccess) onSuccess(row, newData);
                if (onUpdateItemSuccess) onUpdateItemSuccess(row, newData);
            };

            const handleError: TableItemUpdaterErrorFn<D> = (
                error,
                row,
                newData
            ) => {
                if (onError) onError(error, row, newData);
                if (onUpdateItemError)
                    onUpdateItemError(error, row, newData, rowData.original);
            };

            if (onUpdateItem) {
                const rowIndex = rowData?.index;
                onUpdateItem({
                    itemId,
                    updatedData,
                    updatedRow,
                    rowIndex,
                    onSuccess: handleSuccess,
                    onError: handleError,
                });
            } else {
                // eslint-disable-next-line no-console
                console.warn(
                    `The table data was updated but 'onUpdateItem' was not passed as a prop. Pass 'onUpdateItem' to persist the data changes. `
                );
            }
        }
    };

    useEffect(() => {
        if (onSort && !disableManualSort) {
            onSort(sortBy);
        }
    }, [onSort, sortBy, disableManualSort]);

    const calculatedTableHeight = Math.min(
        nestedItemsCount * rowHeight,
        tableHeight
    );

    return (
        <TableProvider
            id={id}
            headerGroups={headerGroups}
            headless={headless}
            oddStyles={oddStyles}
            updateItem={handleUpdateItem}
            getTableBodyProps={getTableBodyProps}
            sticky={sticky}
            borderless={borderless}
            tableHeight={calculatedTableHeight}
            headerRowHeight={headerRowHeight}
            disableManualSort={disableManualSort}
            actionBarItems={actionBarItems}
            selectedFlatRows={selectedFlatRows}
            state={state}
            prepareRow={prepareRow}
            rows={rows}
            totalColumnsWidth={totalColumnsWidth}
            boldHeaders={boldHeaders}
            filterState={filterState}
            customSelectedRows={customSelectedRows}
            getActionBarTitle={getActionBarTitle}
            selectAll={selectAll}
            disableCellSelection={disableCellSelection}
            disableHeaderSelection={disableHeaderSelection}
        >
            <TableFrame
                key={calculatedTableHeight}
                data-test={`${id}-frame`}
                sticky={sticky}
                borderless={borderless}
                disabled={disabled}
                ref={ref}
                tableLaidOut={tableLaidOut}
                overFlowXDisabled={overFlowXDisabled}
            >
                {showActionBar ? <ActionBar /> : null}
                <TableBase
                    tableHeight={calculatedTableHeight + headerRowHeight}
                    data-test={`${id}-base`}
                    borderless={borderless}
                    {...getTableProps()}
                    sticky={sticky}
                >
                    <TableContainer<D>
                        loadMoreItems={loadMoreItems}
                        loading={loadingMoreItems}
                        itemCount={nestedItemsCount}
                        rowHeight={rowHeight}
                        threshold={threshold}
                        data={data}
                        innerRef={fixedSizeListOuterRef}
                    />
                </TableBase>
            </TableFrame>
        </TableProvider>
    );
}

export default Table;
