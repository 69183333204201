import produce, { Draft } from 'immer';
import defaultTheme from 'koddi-components/ThemeProvider/themes/default';
import { ThemeState, ThemeActions, ThemeAction } from './types';

export const initialState: ThemeState = {
    theme: {
        theme: defaultTheme,
        member_group_id: 1,
        id: 1,
    },
    themes: [],
    status: 'idle',
    error: null,
    initialized: false,
    registration_enabled: false,
};

/* eslint-disable default-case, no-param-reassign */
const themeReducer = (
    state: ThemeState = initialState,
    action: ThemeActions
): ThemeState =>
    produce(state, (draft: Draft<ThemeState>) => {
        switch (action.type) {
            case ThemeAction.GET_THEME_LIST: {
                draft.status = 'pending';
                break;
            }
            case ThemeAction.GET_THEME_BY_MEMBER_GROUP_ID:
                draft.status = 'pending';
                break;
            case ThemeAction.DELETE_THEME: {
                draft.theme = action.payload.theme;
                break;
            }
            case ThemeAction.CHANGE_THEME: {
                draft.theme = action.payload.theme;
                draft.status = 'success';
                draft.initialized = true;
                if (action.payload.theme.registration_enabled !== undefined) {
                    draft.registration_enabled =
                        action.payload.theme.registration_enabled;
                }
                draft.sso_organization_id =
                    action.payload.theme.sso_organization_id;
                draft.sso_connection_id =
                    action.payload.theme.sso_connection_id;
                break;
            }
            case ThemeAction.CREATE_THEME: {
                draft.theme = action.payload.theme;
                break;
            }
            case ThemeAction.UPDATE_THEME: {
                draft.theme = action.payload.theme;
                break;
            }
            case ThemeAction.SET_THEME_LIST: {
                draft.themes = action.payload.themes;
                draft.status = 'success';
                break;
            }
            case ThemeAction.SET_THEME_STATUS: {
                draft.status = action.payload.status;
                break;
            }
            case ThemeAction.SET_REGISTRATION_ENABLED: {
                draft.registration_enabled = action.payload.registrationEnabled;
                break;
            }
            case ThemeAction.THEME_ERROR: {
                draft.error = action.payload.error;
                break;
            }
        }
    });

export default themeReducer;
