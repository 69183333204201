import { Dispatch, Middleware } from 'redux';
import {
    ReduxAction,
    ReduxActionWithPayload,
    ReduxActionWithError,
} from 'types';

/**
 * Immer does not handle classes gracefully. Since errors are returned as a javascript
 * class they are mishandled by immer. So, this middleware transforms all errors into
 * plain javascript objects that Immer can understand.
 */
function errorTransformMiddleware(): Middleware {
    return () => (next: Dispatch) => (
        action:
            | ReduxAction<string>
            | ReduxActionWithError<string>
            | ReduxActionWithPayload<string>
    ) => {
        if ('error' in action && typeof action.error === 'object') {
            const error = action.error as any;
            const isError = error instanceof Error || typeof error === 'object';
            const parsedError = JSON.parse(
                JSON.stringify(error, Object.getOwnPropertyNames(error))
            );
            return next({
                ...action,
                error: isError ? parsedError : action.error,
            });
        }
        return next(action);
    };
}

export default errorTransformMiddleware;
