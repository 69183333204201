function containsLetter<Value extends any = string>(value: Value): string {
    const includesLetter = /[a-zA-Z]/g;
    if (!value) {
        return 'Required';
    }
    if (typeof value === 'string' && !includesLetter.test(value)) {
        return 'Must contain at least one letter';
    }
    return '';
}

export default containsLetter;
