import { AxiosInstance } from 'axios';
import { KoddiAPIResponse } from 'types';
import { PermissionList } from './Permission.types';

const KODDI_PERMISSION_URL = '/permission';

class PermissionAPI {
    protected axios: AxiosInstance;

    constructor(axios: AxiosInstance) {
        this.axios = axios;
    }

    public getList = async (): Promise<PermissionList> => {
        const response = await this.axios.get<KoddiAPIResponse<PermissionList>>(
            `v1${KODDI_PERMISSION_URL}`
        );
        return response.data.result;
    };
}

export default PermissionAPI;
