import styled, { css } from 'styled-components';

export const Label = styled.label`
    ${({ theme }) => css`
        display: block;
        font-size: ${theme.typography.baseFontSize};
        margin-bottom: ${theme.spaceUnit * 3}px;
        white-space: nowrap;
        &:hover {
            cursor: pointer;
        }
    `}
`;
