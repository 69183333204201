import styled, { css } from 'styled-components';

export const SlideTransitionContent = styled.div<{ duration: number }>`
    ${({ duration }) => css`
        &.appear {
            transform: translateX(100%);
        }
        &.appear-active {
            transform: translateX(0%);
            transition: transform ${duration}ms;
        }
        &.appear-done {
            transform: translateX(0%);
        }

        &.enter {
            transform: translateX(100%);
        }
        &.enter-active {
            transform: translateX(0%);
            transition: transform ${duration}ms;
        }
        &.enter-done {
            transform: translateX(0%);
        }

        &.exit {
            transform: translateX(0%);
        }
        &.exit-active {
            transform: translateX(100%);
            transition: transform ${duration}ms;
        }
        &.exit-done {
            transform: translateX(100%);
        }
    `}
`;
