import {
    EntityAttributesAction,
    FetchEntityAttributesAction,
    FetchEntityAttributesSuccessAction,
    FetchEntityAttributesErrorAction,
    entityTableAttributeData,
} from './types';

export function fetchEntityAttributes(
    memberGroupId: number
): FetchEntityAttributesAction {
    return {
        type: EntityAttributesAction.FETCH_ENTITY_ATTRIBUTES,
        payload: {
            memberGroupId,
        },
    };
}

export function fetchEntityAttributesSuccess(
    data: entityTableAttributeData
): FetchEntityAttributesSuccessAction {
    return {
        type: EntityAttributesAction.FETCH_ENTITY_ATTRIBUTES_SUCCESS,
        payload: {
            data,
        },
    };
}

export function fetchEntityAttributesError(
    error: string
): FetchEntityAttributesErrorAction {
    return {
        type: EntityAttributesAction.FETCH_ENTITY_ATTRIBUTES_ERROR,
        payload: {
            error,
        },
    };
}
