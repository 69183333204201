import React, { FunctionComponent } from 'react';
import { BaseSVGProps } from '../icon.types';

const StatusPendingIcon: FunctionComponent<BaseSVGProps> = ({
    width,
    height,
}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        height={height}
        width={width}
        viewBox="0 0 24 24"
    >
        <title>Status Pending</title>
        <path d="M12,0A12,12,0,1,0,24,12,12,12,0,0,0,12,0Zm7.2,15H9.6V4.2H12v8.4h7.2Z" />
    </svg>
);

export default StatusPendingIcon;
