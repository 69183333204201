import React, {
    FunctionComponent,
    useEffect,
    useState,
    useMemo,
    Suspense,
} from 'react';
import { useLocation } from 'react-router-dom';
import { useAuthStatus, useAuthUser } from 'redux-core/auth/hooks';
import { useDispatch } from 'react-redux';
import { useLDClient, useFlags } from 'launchdarkly-react-client-sdk';

import KoddiAPI, { LAST_ACTIVITY_TIME_KEY } from 'api';
import useLocalize from 'koddi/hooks/useLocalize';
import useHeap from 'koddi/hooks/useHeap';
import ThemeProvider from 'koddi-components/ThemeProvider';
import ToastProvider, { useKoddiToast } from 'koddi-components/ToastProvider';
import ModalProvider from 'koddi-components/ModalProvider';
import {
    getThemeByMemberGroupId,
    changeTheme,
    setThemeStatus,
} from 'redux-core/app/theme';
import defaultKoddiTheme from 'koddi-components/ThemeProvider/themes/default';
import { SESSION_STORAGE_KEY } from 'utils/constants';
import { logout, logoutWithSSO } from 'redux-core/auth/actions';
import PermissionsProvider from 'koddi-components/PermissionsProvider';
import { useLocale } from 'koddi-components/LocaleProvider';
import { LoadingSpinnerWithTransition } from 'koddi-components/LoadingSpinner';
import Favicon from 'koddi-components/Favicon';
import Routes from 'features/Routes/GRoutes';
import { useFaviconURL, useAppTitle } from 'koddi/hooks/app';
import { DEFAULT_MEMBER_GROUP_ID } from 'modules/constants/app';
import DocumentTitle from 'koddi-components/DocumentTitle';
import {
    useAppTheme,
    useThemeContextChanged,
    useThemeStatus,
    useThemeHasError,
} from 'redux-core/app/theme/hooks';
import { fetchTermsAndConditions } from 'redux-core/app/termsAndConditions/actions';
import {
    fetchAppContext,
    fetchAppContextSuccess,
    setPreviousMemberGroupId,
    setErrorCode,
} from 'redux-core/app/context';
import {
    useMemberGroupId,
    useAdvertiserId,
    useContextHasError,
    useContextChanged,
    useAdvertiserGroupId,
    useAppContextMemberGroup,
    useAppContextAdvertiser,
    useMemberGroupCurrencies,
    useCalculatedPageCurrency,
    useAppContextErrorCode,
    useAppContextStatus,
} from 'redux-core/app/context/hooks';
import {
    useTermsContextChanged,
    useShowTermsAndConditionsModal,
    useTermsAndConditionsLoading,
} from 'redux-core/app/termsAndConditions/hooks';
import { useAppMessage } from 'redux-core/app/messages/hooks';
import TermsAndConditionsModal from 'react-ui/components/TermsAndConditionsModal';
import { useCurrentRouteMatch } from 'redux-core/app/hooks';

import { setupApp } from 'redux-core/app/setup/actions';
import { useIsAppReady, useAppStatus } from 'redux-core/app/setup/hooks';
import { datadogRum } from '@datadog/browser-rum';
import { useAuth0 } from 'modules/Auth0/Auth0.hooks';

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import 'react-circular-progressbar/dist/styles.css';

const Elevio = React.lazy(() => import('elevio/lib/react'));

const AppMessage: FunctionComponent = () => {
    const [messageKeys, setMessageKeys] = useState<string[]>([]);
    const dispatch = useDispatch();
    const message = useAppMessage();
    const { showErrorToast, showSuccessToast } = useKoddiToast();

    useEffect(() => {
        if (message && message.type === 'error') {
            const key = `error: ${message.key}`;
            if (messageKeys.includes(key)) return;
            showErrorToast(message.title, message.message);
            if (message.key) {
                setMessageKeys([...messageKeys, key]);
            }
        }
        if (message && message?.type === 'success') {
            const key = `success: ${message.key}`;
            if (messageKeys.includes(key)) return;
            showSuccessToast(message.title, message.message);
            setMessageKeys([...messageKeys, key]);
        }
    }, [message, showSuccessToast, showErrorToast, dispatch, messageKeys]);

    return null;
};

const AppFavicon = () => {
    const faviconURL = useFaviconURL();
    return <Favicon faviconURL={faviconURL} />;
};

const App: FunctionComponent = () => {
    const appContextStatus = useAppContextStatus();
    const launchDarkly = useLDClient();
    const { localizeWidget, elevio, experiencesConfiguration } = useFlags();
    const dispatch = useDispatch();
    const authStatus = useAuthStatus();
    const user = useAuthUser();
    const { pathname } = useLocation();
    const routeMatch = useCurrentRouteMatch();
    const { changeLocale, changeCurrency } = useLocale();
    const currencies = useMemberGroupCurrencies();
    const calulatedPageCurrency = useCalculatedPageCurrency();
    const heap = useHeap();
    const theme = useAppTheme(); // pull theme from redux, useKoddiTheme pulls from redux-agnostic styled-components context
    const themeContextChanged = useThemeContextChanged();
    const themeStatus = useThemeStatus();
    const themeHasError = useThemeHasError();

    const termsContextChanged = useTermsContextChanged();
    const showTermsAndConditions = useShowTermsAndConditionsModal();
    const termsAndConditionsLoading = useTermsAndConditionsLoading();

    const contextMemberGroupId = useMemberGroupId();
    const contextAdvertiserId = useAdvertiserId();
    const contextAdvertiserGroupId = useAdvertiserGroupId();
    const appContextHasErrors = useContextHasError();
    const appContextChanged = useContextChanged();
    const appContextMemberGroup = useAppContextMemberGroup();
    const appContextAdvertiser = useAppContextAdvertiser();
    const appContextErrorCode = useAppContextErrorCode();
    const { checkAuth0State, ssoFeatureFlag, isLoadingAuth0 } = useAuth0();

    const appSetupStatus = useAppStatus();
    const isAppReady = useIsAppReady();

    const [wasOnAdmin, setWasOnAdmin] = useState(false);
    const isAdmin = pathname.includes('admin');

    const loggedIn = authStatus === 'signedIn';

    const isOnRegister = pathname.includes('register');
    const isOnLogin = pathname.includes('login');
    const showLoader =
        ((authStatus === 'attemptingLoginOnLoad' || !isAppReady) &&
            !isOnRegister) ||
        (isLoadingAuth0 && ssoFeatureFlag);

    const { setLocale, setBrowserLocale, showWidget, hideWidget } = useLocalize(
        changeLocale
    );

    useEffect(() => {
        if (ssoFeatureFlag && !loggedIn) {
            checkAuth0State();
        }
        // eslint-disable-next-line
    }, [ssoFeatureFlag]);

    /* Initiates the app setup process */
    useEffect(() => {
        if (
            loggedIn &&
            user &&
            appSetupStatus === 'idle' &&
            contextMemberGroupId
        ) {
            datadogRum.setUser({
                id: user?.user_id?.toString(),
                name: `${user.first_name} ${user.last_name}`,
                email: user.email,
                status: user.status,
                roles: user.roles,
                locale: user.locale,
            });

            window.ga('set', 'clientId', contextMemberGroupId);
            window.ga('set', 'userId', user?.user_id);

            if (!isOnRegister && launchDarkly !== undefined) {
                dispatch(
                    setupApp(
                        user,
                        contextMemberGroupId,
                        contextAdvertiserId,
                        contextAdvertiserGroupId,
                        pathname,
                        launchDarkly.allFlags()
                    )
                );
            }
        }
    }, [
        appSetupStatus,
        user,
        loggedIn,
        dispatch,
        contextMemberGroupId,
        contextAdvertiserId,
        contextAdvertiserGroupId,
        pathname,
        launchDarkly,
        isOnRegister,
    ]);

    useEffect(() => {
        if (user && contextMemberGroupId) {
            heap.identify(user?.user_id);
            heap.addUserProperties({
                Name: `${user.first_name} ${user.last_name}`,
            });
            launchDarkly?.identify({
                anonymous: false,
                key: String(user?.user_id),
                name: `${user.first_name} ${user.last_name}`,
                email: user.email,
                custom: {
                    clientId: contextMemberGroupId,
                    userId: user?.user_id,
                    advertiserId: Number(contextAdvertiserId),
                    role: user?.roles?.[0]?.id,
                    deployEnv: window.DEPLOY_ENV,
                },
            });
        }
    }, [user, contextMemberGroupId, contextAdvertiserId, launchDarkly, heap]);

    useEffect(() => {
        if (user?.locale?.code) {
            setLocale(user.locale.code);
        }
    }, [user?.locale?.code, setLocale]);

    useEffect(() => {
        if (!loggedIn) {
            setBrowserLocale();
        }
    }, [loggedIn, setBrowserLocale]);

    useEffect(() => {
        if (localizeWidget) {
            showWidget?.();
        } else {
            hideWidget?.();
        }
    }, [hideWidget, showWidget, localizeWidget]);

    /**
     * We listen for changes to local storage and dispatch
     * signout events accross all tabs if a user logs out of
     * a tab.
     */
    useEffect(() => {
        function handleStorageChange(e: StorageEvent) {
            if (e.key === SESSION_STORAGE_KEY && e.oldValue && !e.newValue) {
                if (ssoFeatureFlag) dispatch(logoutWithSSO(theme.login?.key));
                else dispatch(logout(theme.login?.key));
            }
            if (e.key === SESSION_STORAGE_KEY && e.newValue) {
                const value = JSON.parse(e.newValue);
                KoddiAPI.signIn(
                    value.token,
                    value.expiresIn,
                    value.expirationTime
                );
            }
            if (e.key === LAST_ACTIVITY_TIME_KEY && loggedIn) {
                KoddiAPI.startSignOutTimer();
            }
        }
        window.addEventListener('storage', handleStorageChange);
        return function cleanup() {
            window.removeEventListener('storage', handleStorageChange);
        };
    }, [theme, dispatch, loggedIn, ssoFeatureFlag]);

    useEffect(() => {
        if (isAdmin && !wasOnAdmin) {
            setWasOnAdmin(true);
        }
        if (!isAdmin && wasOnAdmin) {
            setWasOnAdmin(false);
        }
    }, [isAdmin, wasOnAdmin, routeMatch, dispatch]);

    /**
     * Loads the T&C, sets theme and sets app context when app/user context changes
     */
    useEffect(() => {
        if (!isAppReady || !loggedIn) return;
        const userLeftAdmin = wasOnAdmin && !isAdmin;
        const isDefaultMemberGroup =
            contextMemberGroupId === DEFAULT_MEMBER_GROUP_ID;
        const themeLoading = themeStatus === 'pending';
        if (
            (appContextChanged || userLeftAdmin) &&
            !appContextHasErrors &&
            !isDefaultMemberGroup &&
            contextMemberGroupId !== null &&
            !isOnRegister
        ) {
            window.ga('set', 'clientId', contextMemberGroupId);
            dispatch(
                setPreviousMemberGroupId(
                    appContextMemberGroup?.member_group_id || null
                )
            );
            if (typeof experiencesConfiguration !== 'undefined') {
                dispatch(
                    fetchAppContext(
                        experiencesConfiguration,
                        contextMemberGroupId,
                        contextAdvertiserId,
                        contextAdvertiserGroupId
                    )
                );
            }
        }

        if (
            user &&
            termsContextChanged &&
            !termsAndConditionsLoading &&
            contextMemberGroupId !== null
        ) {
            dispatch(
                fetchTermsAndConditions(
                    user.user_id,
                    contextMemberGroupId,
                    user.locale.id
                )
            );
        }

        if (
            themeContextChanged &&
            !isDefaultMemberGroup &&
            !themeLoading &&
            !themeHasError &&
            contextMemberGroupId !== null
        ) {
            dispatch(getThemeByMemberGroupId(contextMemberGroupId));
        }
        if (
            themeContextChanged &&
            themeHasError &&
            contextMemberGroupId &&
            appContextErrorCode !== 403 &&
            appContextErrorCode !== 404 &&
            appContextErrorCode !== 400 &&
            appContextErrorCode !== null
        ) {
            dispatch(
                changeTheme({
                    member_group_id: contextMemberGroupId,
                    theme: defaultKoddiTheme,
                })
            );
            dispatch(setThemeStatus('success'));
        }
    }, [
        dispatch,
        user,
        contextMemberGroupId,
        isAppReady,
        termsContextChanged,
        themeContextChanged,
        appContextChanged,
        appContextHasErrors,
        contextAdvertiserGroupId,
        contextAdvertiserId,
        isAdmin,
        wasOnAdmin,
        loggedIn,
        themeStatus,
        termsAndConditionsLoading,
        themeHasError,
        appContextMemberGroup,
        appContextErrorCode,
        isOnRegister,
        experiencesConfiguration,
    ]);

    /* Sets context/theme when default member group is set */
    useEffect(() => {
        if (
            contextMemberGroupId === DEFAULT_MEMBER_GROUP_ID &&
            appContextMemberGroup?.member_group_id !== DEFAULT_MEMBER_GROUP_ID
        ) {
            dispatch(
                setPreviousMemberGroupId(
                    appContextMemberGroup?.member_group_id || null
                )
            );
            dispatch(
                fetchAppContextSuccess(
                    {
                        member_group_id: DEFAULT_MEMBER_GROUP_ID,
                    },
                    null,
                    null
                )
            );
            dispatch(setErrorCode(null));
            dispatch(
                changeTheme({
                    member_group_id: DEFAULT_MEMBER_GROUP_ID,
                    theme: defaultKoddiTheme,
                })
            );
        }
    }, [contextMemberGroupId, appContextMemberGroup, dispatch]);

    const userPermissions = useMemo(() => {
        if (contextMemberGroupId === null) return [];
        return (
            user?.base_activities ||
            user?.member_group_activities?.[contextMemberGroupId]?.activities ||
            (contextAdvertiserGroupId &&
                user?.advertiser_group_activities?.[contextAdvertiserGroupId]
                    ?.activities) ||
            (contextAdvertiserId &&
                user?.advertiser_activities?.[contextAdvertiserId]
                    ?.activities) ||
            []
        );
    }, [
        user,
        contextMemberGroupId,
        contextAdvertiserGroupId,
        contextAdvertiserId,
    ]);

    /* Sets currency in the locale provider */
    useEffect(() => {
        const onDashboard = pathname.includes('dashboard');
        if (onDashboard && calulatedPageCurrency) {
            const calcCurrency = currencies?.find((currency) => {
                return currency.id === calulatedPageCurrency;
            });
            if (calcCurrency) {
                changeCurrency?.(calcCurrency);
            }
        }
        if (appContextAdvertiser?.currency && !onDashboard) {
            changeCurrency?.(appContextAdvertiser.currency);
        }
        if (
            appContextMemberGroup?.default_currency &&
            !appContextAdvertiser?.currency &&
            !onDashboard
        ) {
            changeCurrency?.(appContextMemberGroup?.default_currency);
        }
    }, [
        appContextAdvertiser,
        appContextMemberGroup,
        changeCurrency,
        currencies,
        pathname,
        calulatedPageCurrency,
    ]);

    return (
        <PermissionsProvider
            roles={user?.roles || []}
            permissions={userPermissions}
        >
            <Suspense fallback={<div />}>
                <Elevio
                    settings={{ enabled: elevio && isAppReady }}
                    accountId="629e76ca521ef"
                />
            </Suspense>
            <ThemeProvider theme={theme}>
                <AppFavicon />
                <DocumentTitle title={useAppTitle()} />
                <ToastProvider>
                    <ModalProvider closeModals={!loggedIn}>
                        <AppMessage />
                        <TermsAndConditionsModal
                            show={
                                appContextStatus !== 'error' &&
                                showTermsAndConditions &&
                                !isOnRegister &&
                                !isOnLogin
                            }
                        />
                        {!showLoader ? <Routes /> : null}
                        <LoadingSpinnerWithTransition
                            id="app"
                            in={showLoader}
                            hideContentBelow
                            absolutePosition
                            unmountOnExit
                            duration={500}
                        />
                    </ModalProvider>
                </ToastProvider>
            </ThemeProvider>
        </PermissionsProvider>
    );
};

export default App;
