import {
    ReduxActionWithError,
    ReduxActionWithPayload,
    ReduxActionWithRefresh,
    AsyncState,
} from 'types';
import { MemberList } from 'api/Admin/Member';

export enum MemberAction {
    GET_MEMBER_LIST = 'admin/member/GET_MEMBER_LIST',
    GET_MEMBER_LIST_SUCCESS = 'admin/member/GET_MEMBER_LIST_SUCCESS',
    GET_MEMBER_LIST_ERROR = 'admin/member/GET_MEMBER_LIST_ERROR',
}

export type GetMemberListAction = ReduxActionWithRefresh<
    MemberAction.GET_MEMBER_LIST
>;

export type GetMemberListSuccessAction = ReduxActionWithPayload<
    MemberAction.GET_MEMBER_LIST_SUCCESS,
    { data: MemberList }
>;

export type GetMemberListErrorAction = ReduxActionWithError<
    MemberAction.GET_MEMBER_LIST_ERROR
>;

export type MemberActions =
    | GetMemberListAction
    | GetMemberListSuccessAction
    | GetMemberListErrorAction;

export type MemberState = {
    list: AsyncState<MemberList>;
};
