import React, { useCallback, FunctionComponent } from 'react';
import classnames from 'classnames';
import { HotKeys } from 'react-hotkeys';

import { findTabByKey, findTabIndexByKey } from './Tabs.utils';
import { TTabsProviderContext } from './Tabs.types';
import styles from './Tabs.hotkeys.css';

/**
 * Handles tab key events for accesibility.
 * The hotkeys are defined in accordance with the
 * W3 recommendation at: https://www.w3.org/TR/wai-aria-practices/examples/tabs/tabs-1/tabs.html
 */
const TabsHotKeys: FunctionComponent<
    { className?: string } & Omit<TTabsProviderContext, 'renderPreContent'>
> = ({
    children,
    id,
    className,
    activeTabKey,
    onSelectTab,
    numberOfTabs,
    tabItems,
}) => {
    const classes = classnames(className, styles.tabs);

    const handleSelectCurrentTab = useCallback(() => {
        if (typeof activeTabKey === 'number') {
            onSelectTab(activeTabKey, tabItems[activeTabKey].title);
        } else {
            const tab = findTabByKey(activeTabKey, tabItems);
            if (tab) onSelectTab(activeTabKey, tab.title);
        }
    }, [activeTabKey, onSelectTab, tabItems]);

    const handleSelectNextTab = useCallback(() => {
        if (typeof activeTabKey === 'number') {
            if (activeTabKey < numberOfTabs - 1) {
                onSelectTab(activeTabKey + 1, tabItems[activeTabKey + 1].title);
            }
        } else {
            const tabIndex = findTabIndexByKey(activeTabKey, tabItems);
            if (tabIndex > -1 && tabIndex < numberOfTabs - 1)
                onSelectTab(activeTabKey + 1, tabItems[tabIndex + 1].title);
        }
    }, [activeTabKey, numberOfTabs, onSelectTab, tabItems]);

    const handleSelectPrevTab = useCallback(() => {
        if (typeof activeTabKey === 'number') {
            if (activeTabKey > 0)
                onSelectTab(activeTabKey - 1, tabItems[activeTabKey - 1].title);
        } else {
            const tabIndex = findTabIndexByKey(activeTabKey, tabItems);
            if (tabIndex > 0)
                onSelectTab(tabIndex, tabItems[tabIndex - 1].title);
        }
    }, [activeTabKey, onSelectTab, tabItems]);

    const keyMap = {
        SELECT_NEXT_TAB: 'right',
        SELECT_PREV_TAB: 'left',
        SELECT_CURRENT_TAB: 'shift+tab',
    };
    const keyMapHandlers = {
        SELECT_NEXT_TAB: handleSelectNextTab,
        SELECT_PREV_TAB: handleSelectPrevTab,
        SELECT_CURRENT_TAB: handleSelectCurrentTab,
    };

    return (
        <HotKeys
            id={id}
            tabIndex={0}
            className={classes}
            allowChanges
            keyMap={keyMap}
            handlers={keyMapHandlers}
            style={{ height: '100%' }}
        >
            {children}
        </HotKeys>
    );
};

export default TabsHotKeys;
