import React from 'react';
import {
    LineChart,
    XAxis,
    YAxis,
    Tooltip,
    CartesianGrid,
    Line,
} from 'recharts';
import {
    useChartColorScheme,
    useReChartsXAxisAlignment,
    valueFormatter,
} from 'koddi-components/hooks';
import { useLocale } from 'koddi-components/LocaleProvider';
import { LineChartProps } from './LineChart.types';
import { LineChartWrapper, TooltipWrapper } from './LineChart.styled';

/**
 * A Line Chart for displaying line graphs in Koddi One built using `recharts`.
 *
 * The colors are the lines do not need to be specified as a color palette is generated
 * from the client's theme colors. 24 additional colors will be generated from the clients
 * color scheme that are in the same palette.
 */
function KoddiLineChart<Data extends Record<string, unknown>>({
    xAxisDataKey,
    dataMarkers,
    data,
    id,
    formatYAxisTick,
    ...rest
}: LineChartProps<Data>): JSX.Element {
    const { yAxisWidth, handleChartRefCallback } = useReChartsXAxisAlignment();
    const colorScheme = useChartColorScheme();
    const { currencySymbol, currencyDigits, locale } = useLocale();
    // NOTE: this custom Tooltip limits what we can use this chart for. it assumes the payload, for its specific use case. this should be moved to the react ui or abstracted to work for other use cases
    const CustomTooltip = ({ active, payload }: any) => {
        if (active && payload && payload.length) {
            return (
                <TooltipWrapper>
                    {payload.map((toolTipData: any, index: any) => {
                        const date =
                            toolTipData.dataKey === 'selected'
                                ? toolTipData.payload.date
                                : toolTipData.payload.prior_date;
                        return (
                            <p
                                key={toolTipData.name}
                                style={{ color: toolTipData.color }}
                            >
                                {`
                                ${date}
                                :
                                ${valueFormatter(
                                    toolTipData.payload[toolTipData.dataKey],
                                    dataMarkers[index]?.presentation,
                                    {
                                        currencyOptionalDecimal: true,
                                        currencySymbol,
                                        currencyDigits,
                                    },
                                    locale
                                )}
                            `}
                            </p>
                        );
                    })}
                </TooltipWrapper>
            );
        }
        return null;
    };
    const CustomXTick = ({ x, y, payload }: any) => {
        return (
            <g transform={`translate(${x},${y})`}>
                <text
                    x={2}
                    y={-5}
                    dy={16}
                    textAnchor="end"
                    fill="#666"
                    transform="rotate(-45)"
                    fontSize={14}
                >
                    {payload.value}
                </text>
            </g>
        );
    };
    return (
        <LineChartWrapper
            id={`${id}-line-chart`}
            data-test={`${id}-line-chart`}
        >
            <LineChart
                {...rest}
                data={data}
                ref={handleChartRefCallback}
                margin={{ bottom: 40 }}
            >
                <CartesianGrid
                    stroke="#eee"
                    strokeDasharray="5 0"
                    vertical={false}
                />
                <XAxis
                    dataKey={xAxisDataKey}
                    padding={{ left: 0, right: 30 }}
                    tick={<CustomXTick />}
                />
                <YAxis
                    width={yAxisWidth || undefined}
                    tickFormatter={formatYAxisTick}
                    tickSize={14}
                    padding={{ bottom: 0, top: 10 }}
                />
                <Tooltip content={<CustomTooltip />} />
                {dataMarkers.map((dataMarker, index) => (
                    <Line
                        {...dataMarker}
                        key={dataMarker.key}
                        stroke={colorScheme[index]}
                        type="monotone"
                        strokeWidth={2}
                    />
                ))}
            </LineChart>
        </LineChartWrapper>
    );
}

export default KoddiLineChart;
