import React, { FunctionComponent } from 'react';
import { BaseSVGProps } from '../icon.types';

const MinusIcon: FunctionComponent<BaseSVGProps> = ({ width, height }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        height={height}
        width={width}
        viewBox="0 0 24 24"
    >
        <path d="M24 13.65v-3.3l-10.42.07h-3.14L0 10.35v3.3l10.42-.08h3.14z" />
    </svg>
);

export default MinusIcon;
