import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import getIdFromRoute from 'utils/getIdFromRoute';
import { useAuthUser } from 'redux-core/auth/hooks';
import { useSelectedAdvertisers } from 'redux-core/app/advertisers/hooks';
import { AdvertiserGroup } from 'api/Admin/AdvertiserGroups';
import { CurrencyList } from 'api/Admin/Currency';
import { PRE_PAY_ID } from 'modules/constants/fundingTypes';
import { BiddingConfigReport } from 'api/Admin/Currency/Currency.types';
import { useAuth0 } from 'modules/Auth0';
import {
    selectAppContextStatus,
    selectAppContextMemberGroup,
    selectMemberGroupEntityLabel,
    selectAppContextAdvertiser,
    selectAppContextAdvertiserGroup,
    selectPreviousMemberGroupId,
    selectContextErrorStatus,
    selectMemberGroupCurrencies,
    selectMemberGroupBiddingConfigs,
    selectCalculatedPageCurrency,
    selectAdvertiserHasFunds,
    selectTaxTypes,
    selectSavedReportTitle,
} from './selectors';
import { selectAppThemeResource } from '../theme/selectors';
import {
    MemberGroupIndustrySelectorReturn,
    ContextMemberGroup,
    ContextAdvertiser,
    AdvertiserGroupError,
} from './types';

export function useAppContextStatus(): string {
    return useSelector(selectAppContextStatus);
}

export function useAppContextMemberGroup(): ContextMemberGroup | null {
    return useSelector(selectAppContextMemberGroup);
}

export function useMemberGroupEntityLabel(): MemberGroupIndustrySelectorReturn {
    return useSelector(selectMemberGroupEntityLabel);
}

export function useAppContextAdvertiser(): ContextAdvertiser | null {
    return useSelector(selectAppContextAdvertiser);
}

export function useAppContextErrorCode(): number | null {
    return useSelector(selectContextErrorStatus);
}

export function useNoContextError(): boolean {
    const errorCode = useAppContextErrorCode();
    return errorCode === 404 || errorCode === 403 || errorCode === 400;
}

export function useAppContextAdvertiserGroup():
    | AdvertiserGroup
    | AdvertiserGroupError
    | null {
    return useSelector(selectAppContextAdvertiserGroup);
}

export function usePreviousMemberGroupId(): number | null {
    return useSelector(selectPreviousMemberGroupId);
}

export function useSavedReportTitle(): string | null {
    return useSelector(selectSavedReportTitle);
}

export function useMemberGroupId(): number | null {
    const location = useLocation();
    const user = useAuthUser();
    const isAdmin = location.pathname.includes('admin');
    const isOnRegister = location.pathname.includes('register');
    const routeMemberGroupId = getIdFromRoute(location.pathname, 'clients');
    const returnRoute = new URLSearchParams(location.search).get('returnRoute');
    const returnRouteMemberGroupId = returnRoute
        ? getIdFromRoute(returnRoute, 'clients')
        : null;
    const userMemberGroupId = user?.member_group_id;
    const memberGroupIdQuery = location.search.split('client_id=')?.[1];
    const contextStatus = useAppContextStatus();
    const memberGroupFromTheme = useSelector(selectAppThemeResource)
        .member_group_id;
    const { ssoFeatureFlag } = useAuth0();

    if (
        isAdmin &&
        !routeMemberGroupId &&
        !memberGroupIdQuery &&
        contextStatus !== 'idle'
    ) {
        return 0;
    }

    if (memberGroupFromTheme && ssoFeatureFlag && isOnRegister) {
        return memberGroupFromTheme;
    }

    if (returnRouteMemberGroupId) {
        return returnRouteMemberGroupId;
    }

    if (routeMemberGroupId) {
        return routeMemberGroupId;
    }

    if (memberGroupIdQuery) {
        return parseInt(memberGroupIdQuery, 10);
    }

    if (user?.advertiser_member_group) {
        const advertiserMemberGroup: number | undefined = Object.values(
            user.advertiser_member_group
        ).find((memberGroupId) => memberGroupId);
        if (advertiserMemberGroup) {
            return advertiserMemberGroup;
        }
    }
    if (user?.member_group_activities) {
        const memberGroupIdArray = Object.keys(user?.member_group_activities);
        const memberGroupId = Number(memberGroupIdArray[0]);
        if (memberGroupId) {
            return memberGroupId;
        }
    }

    return userMemberGroupId || null;
}

export function useAdvertiserId(): number | null {
    const location = useLocation();
    const routeAdvertiserId =
        getIdFromRoute(location.pathname, 'advertisers') ||
        getIdFromRoute(location.pathname, 'advertiser');
    const advertiserIdQuery = location.search.split('advertiser_id=')?.[1];
    const selectedAdvertisers = useSelectedAdvertisers();
    const isOverviewRoute = location.pathname.includes('/dashboard');
    const isAdminRoute = location.pathname.includes('/admin');
    const isEmbedded = location.pathname.includes('/embedded');

    if (routeAdvertiserId) {
        return routeAdvertiserId;
    }
    if (advertiserIdQuery) {
        return parseInt(advertiserIdQuery, 10);
    }
    if (!isOverviewRoute && !isAdminRoute && !isEmbedded) {
        return selectedAdvertisers?.[0]?.advertiser_id ?? null;
    }
    return null;
}

export function useAdvertiserGroupId(): number | null {
    const location = useLocation();
    const routeAdvertiserGroupId = getIdFromRoute(
        location.pathname,
        'advertiser-groups'
    );
    if (routeAdvertiserGroupId) {
        return routeAdvertiserGroupId;
    }
    return null;
}

export function useContextHasError(): boolean {
    const status = useAppContextStatus();
    const memberGroupId = useMemberGroupId();
    const advertiserId = useAdvertiserId();
    const advertiser = useAppContextAdvertiser();
    const memberGroup = useAppContextMemberGroup();
    const hasError = status === 'error';

    const memberGroupHasError =
        hasError && memberGroup?.member_group_id === memberGroupId;
    const advertiserHasError =
        hasError && advertiser?.advertiser_id === advertiserId;

    return !!(memberGroupHasError && advertiserId && advertiserHasError);
}

export function useContextChanged(): boolean {
    const status = useAppContextStatus();
    const advertiser = useAppContextAdvertiser();
    const advertiserId = useAdvertiserId();
    const memberGroup = useAppContextMemberGroup();
    const memberGroupId = useMemberGroupId();
    const advertiserGroupId = useAdvertiserGroupId();
    const advertiserGroup = useAppContextAdvertiserGroup();
    const contextLoaded = status === 'success';
    const hasErrors = status === 'error';
    const advertiserWasSelected = !advertiser && advertiserId;
    const advertiserGroupWasSelected = !advertiserGroup && advertiserGroupId;

    const memberGroupChanged =
        (contextLoaded || hasErrors) &&
        memberGroup &&
        memberGroupId !== memberGroup.member_group_id;
    const advertiserChanged =
        (contextLoaded || hasErrors) &&
        advertiser &&
        advertiserId !== advertiser?.advertiser_id;
    const advertiserGroupChanged =
        (contextLoaded || hasErrors) &&
        advertiserGroup &&
        advertiserGroupId !== advertiserGroup?.id;

    return !!(
        memberGroupChanged ||
        advertiserChanged ||
        advertiserWasSelected ||
        advertiserGroupChanged ||
        advertiserGroupWasSelected
    );
}
export function useMemberGroupCurrencies(): CurrencyList | null {
    return useSelector(selectMemberGroupCurrencies);
}

export function useMemberGroupBiddingConfigs(): BiddingConfigReport | null {
    return useSelector(selectMemberGroupBiddingConfigs);
}

export function useCalculatedPageCurrency(): number | null {
    return useSelector(selectCalculatedPageCurrency);
}

export function useClientIsPrepay(): boolean {
    const memberGroup = useAppContextMemberGroup();
    return !!memberGroup?.funding_types?.find(
        (type) => type.id === PRE_PAY_ID && type.status === 'Active'
    );
}

export function useAdvertiserHasFunds(): boolean {
    return useSelector(selectAdvertiserHasFunds);
}

export function useTaxTypes(): any {
    return useSelector(selectTaxTypes);
}
