import styled, { css } from 'styled-components';

export const VerticalBarChartWrapper = styled.div`
    position: relative;
`;

export const TooltipWrapper = styled.div`
    ${({ theme }) => css`
        background: ${theme.white};
        padding: 10px;
        border: 1px solid ${theme.grayLight};
        -webkit-box-shadow: 2px 1px 8px -2px rgba(0, 0, 0, 0.5);
        box-shadow: 2px 1px 8px -2px rgba(0, 0, 0, 0.5);
    `}
`;
