import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { formatDate, KoddiLocaleKey } from 'koddi-components/LocaleProvider';
import { IS_TEST_ENV } from 'utils/constants';

if (IS_TEST_ENV) {
    i18n.use(initReactI18next).init({
        backend: undefined,
        lng: 'en-US',
        fallbackLng: [],
        interpolation: {
            escapeValue: false,
            format: (value, format, lng) => {
                if (value instanceof Date)
                    return formatDate(value, lng as KoddiLocaleKey, format);
                return value;
            },
        },
        resources: { 'en-US': { translations: {} } },
        react: {
            useSuspense: false,
        },
    });
}

const i18nForTesting = i18n;

export default i18nForTesting;
