import { ReduxActionWithPayload } from 'types';

export enum ResourceHeaderAction {
    SET_HIDDEN_STATUS = 'admin/resourceHeader/SET_HIDDEN_STATUS',
}

export type ResourceHeaderState = {
    hidden: boolean;
};

export type SetHiddenStatusAction = ReduxActionWithPayload<
    ResourceHeaderAction.SET_HIDDEN_STATUS,
    {
        status: boolean;
    }
>;

export type ResourceHeaderActions = SetHiddenStatusAction;
