import styled, { css } from 'styled-components';
import { HorizontalFlexBox } from 'koddi-components/FlexBox';
import Button from 'koddi-components/Button';

export const ActionBarContainer = styled(HorizontalFlexBox)`
    ${({ theme: { spaceUnit, grayLight, grayLightest } }) => css`
        height: 40px;
        position: sticky;
        background-color: ${grayLightest};
        padding-left: ${spaceUnit * 4}px;
        font-size: 12px;
        border-bottom: 1px solid ${grayLight};
    `}
`;

export const ActionBarText = styled.span``;

export const ActionBarButton = styled(Button)`
    ${({ theme: { spaceUnit, darken }, backgroundColor, noPadding }) => css`
        line-height: 100%;
        font-size: 12px;
        margin-left: ${spaceUnit * 6}px;
        ${backgroundColor &&
        css`
            background-color: ${backgroundColor};
            &:hover,
            &:focus:not(:active) {
                background-color: ${darken(backgroundColor, 0.1)};
            }
        `}
        ${noPadding &&
        css`
            &:first-of-type {
                margin-left: 0;
            }
        `}
    `}
`;
