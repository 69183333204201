import { LocalesList } from 'api/Admin/Locales';
import {
    GetLocalesListAction,
    LocalesAction,
    GetLocalesListSuccessAction,
    GetLocalesListErrorAction,
} from './types';

export function getLocalesList(refresh?: boolean): GetLocalesListAction {
    return {
        type: LocalesAction.GET_LOCALES_LIST,
        payload: { refresh: refresh || false },
    };
}

export function getLocalesListSuccess(
    data: LocalesList
): GetLocalesListSuccessAction {
    return {
        type: LocalesAction.GET_LOCALES_LIST_SUCCESS,
        payload: { data },
    };
}

export function getLocalesListError(
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    error: any
): GetLocalesListErrorAction {
    return {
        type: LocalesAction.GET_LOCALES_LIST_ERROR,
        error,
    };
}
