import { useSelector } from 'react-redux';
import { selectKPICardData, selectKPICardStatus } from './selectors';

export function useKPICardData(): any {
    return useSelector(selectKPICardData);
}

export function useKPICardStatus(): any {
    return useSelector(selectKPICardStatus);
}
