/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { KoddiUser } from 'api/Admin/User';
import { ForgotPasswordData } from 'api/Auth';
import { Locale } from 'api/Admin/Locales';
import { Auth0State } from 'modules/Auth0';
import {
    LoginAction,
    AuthAction,
    LoginSuccessAction,
    LoginErrorAction,
    LogoutErrorAction,
    LogoutSuccessAction,
    UnauthorizedRequestAction,
    ForgotPasswordAction,
    ForgotPasswordGetCodeAction,
    ForgotPasswordGetCodeSuccessAction,
    ForgotPasswordGetCodeErrorAction,
    ResetPasswordAction,
    ResetPasswordSubmitAction,
    ResetPasswordSuccessAction,
    ResetPasswordErrorAction,
    RefreshSessionAction,
    RefreshSessionSuccessAction,
    RefreshSessionError,
    LoginSubmitAction,
    LogoutAction,
    LogoutSubmitAction,
    AccountSetupAction,
    AccountSetupSubmitAction,
    AccountSetupSuccessAction,
    AccountSetupErrorAction,
    ConfirmPasswordAction,
    AttemptingLoginOnLoadAction,
    UpdateUserAction,
    UpdateUserSuccessAction,
    UpdateUserErrorAction,
    LoginWithSSOSuccessAction,
} from './types';

export function attemptingLoginOnLoad(): AttemptingLoginOnLoadAction {
    return {
        type: AuthAction.ATTEMPTING_LOGIN_ON_LOAD,
    };
}

// Login Actions

export function login(): LoginAction {
    return {
        type: AuthAction.LOGIN,
    };
}

export function loginSubmit(
    email: string,
    password: string
): LoginSubmitAction {
    return {
        type: AuthAction.LOGIN_SUBMIT,
        payload: { email, password },
    };
}

export function loginWithSSOSuccess(
    member_group_id: number,
    auth0State: Auth0State
): LoginWithSSOSuccessAction {
    return {
        type: AuthAction.LOGIN_WITH_SSO_SUCCESS,
        payload: {
            member_group_id,
            auth0State,
        },
    };
}

export function loginSuccess(
    user: KoddiUser,
    id_token: string,
    refresh_token: string,
    expires_in: number,
    persist: boolean
): LoginSuccessAction {
    return {
        type: AuthAction.LOGIN_SUCCESS,
        payload: { user, id_token, refresh_token, expires_in, persist },
    };
}

export function loginError(error: any): LoginErrorAction {
    return {
        type: AuthAction.LOGIN_ERROR,
        error,
    };
}

// Logout Actions

export function logout(loginKey?: string, returnRoute?: string): LogoutAction {
    return {
        type: AuthAction.LOGOUT,
        payload: {
            loginKey,
            returnRoute,
        },
    };
}

export function logoutSubmit(
    message?: string,
    loginKey?: string,
    returnRoute?: string
): LogoutSubmitAction {
    return {
        type: AuthAction.LOGOUT_SUBMIT,
        payload: { message, loginKey, returnRoute },
    };
}

export function logoutSuccess(message?: string | null): LogoutSuccessAction {
    return {
        type: AuthAction.LOGOUT_SUCCESS,
        payload: { message: message || null },
    };
}

export function logoutError(error: any): LogoutErrorAction {
    return {
        type: AuthAction.LOGOUT_ERROR,
        error,
    };
}

// Logout Actions

export function logoutWithSSO(
    loginKey?: string,
    returnRoute?: string
): LogoutAction {
    return {
        type: AuthAction.LOGOUT_WITH_SSO,
        payload: {
            loginKey,
            returnRoute,
        },
    };
}

export function logoutWithSSOSubmit(
    message?: string,
    loginKey?: string,
    returnRoute?: string
): LogoutSubmitAction {
    return {
        type: AuthAction.LOGOUT_WITH_SSO_SUBMIT,
        payload: { message, loginKey, returnRoute },
    };
}

// Account Setup Actions

export function accountSetup(
    session: string,
    email: string
): AccountSetupAction {
    return {
        type: AuthAction.ACCOUNT_SETUP,
        payload: { session, email },
    };
}

export function accountSetupSubmit(
    first_name: string,
    last_name: string,
    password: string,
    locale: Locale
): AccountSetupSubmitAction {
    return {
        type: AuthAction.ACCOUNT_SETUP_SUBMIT,
        payload: { first_name, last_name, password, locale },
    };
}

export function accountSetupSuccess(
    user: KoddiUser
): AccountSetupSuccessAction {
    return {
        type: AuthAction.ACCOUNT_SETUP_SUCCESS,
        payload: { user },
    };
}

export function accountSetupError(error: any): AccountSetupErrorAction {
    return {
        type: AuthAction.ACCOUNT_SETUP_ERROR,
        error,
    };
}

// Confirm Password Actions

export function confirmPassword(username: string): ConfirmPasswordAction {
    return {
        type: AuthAction.CONFIRM_PASSWORD,
        payload: { username },
    };
}

// Reset Password Actions

export function resetPassword(email: string): ResetPasswordAction {
    return {
        type: AuthAction.RESET_PASSWORD,
        payload: { email },
    };
}

export function resetPasswordSubmit(
    email: string,
    code: string,
    password: string
): ResetPasswordSubmitAction {
    return {
        type: AuthAction.RESET_PASSWORD_SUBMIT,
        payload: { email, code, password },
    };
}

export function resetPasswordSuccess(): ResetPasswordSuccessAction {
    return {
        type: AuthAction.RESET_PASSWORD_SUCCESS,
    };
}

export function resetPasswordError(error: any): ResetPasswordErrorAction {
    return {
        type: AuthAction.RESET_PASSWORD_ERROR,
        error,
    };
}

// Forgot Password Actions

export function forgotPassword(): ForgotPasswordAction {
    return {
        type: AuthAction.FORGOT_PASSWORD,
    };
}

export function forgotPasswordGetCode(
    email: string
): ForgotPasswordGetCodeAction {
    return {
        type: AuthAction.FORGOT_PASSWORD_GET_CODE,
        payload: { email },
    };
}

export function forgotPasswordGetCodeSuccess(
    codeDeliveryDetails: ForgotPasswordData
): ForgotPasswordGetCodeSuccessAction {
    return {
        type: AuthAction.FORGOT_PASSWORD_GET_CODE_SUCCESS,
        payload: { codeDeliveryDetails },
    };
}

export function forgotPasswordGetCodeError(
    error: any
): ForgotPasswordGetCodeErrorAction {
    return {
        type: AuthAction.FORGOT_PASSWORD_GET_CODE_ERROR,
        error,
    };
}

// Update User Actions

export function updateUser(): UpdateUserAction {
    return {
        type: AuthAction.UPDATE_USER,
    };
}

export function updateUserSuccess(user: KoddiUser): UpdateUserSuccessAction {
    return {
        type: AuthAction.UPDATE_USER_SUCCESS,
        payload: { user },
    };
}

export function updateUserError(error: any): UpdateUserErrorAction {
    return {
        type: AuthAction.UPDATE_USER_ERROR,
        error,
    };
}

// Refresh Session Actions
export function refreshSession(): RefreshSessionAction {
    return {
        type: AuthAction.REFRESH_SESSION,
    };
}

export function refreshSessionSuccess(
    token: string
): RefreshSessionSuccessAction {
    return {
        type: AuthAction.REFRESH_SESSION_SUCCESS,
        payload: { token },
    };
}

export function refreshSessionError(error: any): RefreshSessionError {
    return {
        type: AuthAction.REFRESH_SESSION_ERROR,
        error,
    };
}

// Miscellaneous Actions

export function unauthorizedRequest({
    message,
    loginKey,
    returnRoute,
}: {
    message?: string;
    loginKey?: string;
    returnRoute?: string;
}): UnauthorizedRequestAction {
    return {
        type: AuthAction.UNAUTHORIZED_REQUEST,
        error: new Error(message || 'You do not have access to this resource.'),
        message,
        loginKey,
        returnRoute,
    };
}
