import React, { FunctionComponent } from 'react';
import { BaseSVGProps } from '../icon.types';

const AGroupIcon: FunctionComponent<BaseSVGProps> = ({ width, height }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        height={height}
        width={width}
        viewBox="0 0 24 24"
    >
        <g>
            <path d="M15.5,12v-2h-7c-0.55,0-1,0.45-1,1v2h7C15.05,13,15.5,12.55,15.5,12z" />
            <path d="M6.5,11c0-1.1,0.9-2,2-2h7V6c0-0.55-0.45-1-1-1h-11c-0.55,0-1,0.45-1,1v6c0,0.55,0.45,1,1,1h3V11z" />
        </g>
        <g>
            <path d="M8.5,10h11c0.55,0,1,0.45,1,1v6c0,0.55-0.45,1-1,1h-11c-0.55,0-1-0.45-1-1v-6C7.5,10.45,7.95,10,8.5,10z" />
        </g>
    </svg>
);

export default AGroupIcon;
