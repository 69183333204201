import React, { FunctionComponent, useRef, useEffect } from 'react';
import { getDataTestName } from 'koddi-components/utils';
import { TTabProps, TPlaceholderTabProps } from './Tab.types';
import { useKoddiTabs } from '../../Tabs.hooks';
import { TabButtonBase, CompactTabButton } from './Tab.styled';

/**
 * The `Tab` renders a nav item for the
 * `TabList` in the `Tabs`.
 */
const Tab: FunctionComponent<TTabProps> = ({
    id,
    title,
    tabPaneId,
    tabComponent: CustomTabComponent,
    tabKey,
    className = '',
    compact,
    ...rest
}) => {
    const ref = useRef<any>(null);
    const { activeTabKey, onSelectTab } = useKoddiTabs();
    const selected = tabKey === activeTabKey;

    useEffect(() => {
        if (selected && ref.current) {
            ref.current.focus();
        }
    }, [selected, ref]);

    const dataTest = getDataTestName(title, 'admin-tab-');
    const TabButton = compact ? CompactTabButton : TabButtonBase;
    return (
        <TabButton
            {...rest}
            id={id}
            className={className}
            data-test={dataTest}
            onClick={() => !selected && onSelectTab(tabKey, title)}
            aria-controls={tabPaneId}
            aria-selected={selected}
            tabIndex={selected ? 0 : -1}
            role="tab"
            ref={ref}
            title={title}
            as={CustomTabComponent || 'button'}
        >
            {title}
        </TabButton>
    );
};

/**
 * This Tab is not rendered in the ui and is simplying used for
 * defining the tabs in a react way. This component simply
 * captures the `Tab` defintions so that they can
 * be rendered by the `TabList` and the `TabContent`.
 */
const PlaceholderTab: FunctionComponent<TPlaceholderTabProps> = () => {
    return null;
};

export { PlaceholderTab };
export default Tab;
