import { createGlobalStyle } from 'styled-components';

export const ModalAnimation = createGlobalStyle`
    .ReactModal__Overlay {
        opacity: 0;
        transition: all 300ms ease-in-out;
    }

    .ReactModal__Overlay--after-open {
        opacity: 1;
    }

    .ReactModal__Overlay--before-close {
        opacity: 0;
        pointer-events: none
    }
`;
