import styled, { css } from 'styled-components';

export const CardWrapper = styled.div`
    ${({ theme }) => css`
        margin: ${theme.spaceUnit * 2}px;
        flex: 1;
    `}
`;

/** Unstyled spacer for use in matching Card padding without border/padding */
export const CardBlock = styled.div`
    margin: ${(props) => props.theme.spaceUnit * 4}px 0px;
`;

export const CardHeader = styled.div<{ compact: boolean }>`
    ${({ theme, compact }) => css`
        padding: 0px ${compact ? theme.spaceUnit * 2.5 : theme.spaceUnit * 4}px;
        border: 1px solid ${theme.grayLight};
    `}
`;

export const CardTitle = styled.div<{ compact: boolean }>`
    ${({ theme, compact }) => css`
        padding: 0px ${compact ? theme.spaceUnit * 3 : theme.spaceUnit * 4}px;
        line-height: 40px;
        border: 1px solid ${theme.grayLight};
        font-size: 16px;
    `}
`;

export const CardContent = styled.div<{ compact: boolean; borderTop: boolean }>`
    ${({ theme, compact, borderTop }) => css`
        border: 1px solid ${theme.grayLight};
        ${!borderTop && `border-top: 0px;`}
        padding: ${compact ? theme.spaceUnit * 3 : theme.spaceUnit * 4}px;
        font-size: 14px;
    `}
`;
