import React, { FunctionComponent } from 'react';

import { ImageWrapper } from './image.styled';
import { ImageProps } from './image.types';

export const Image: FunctionComponent<ImageProps> = ({
    src,
    size,
    type = 'normal',
    alt,
    height,
    width,
    ...restProps
}) => {
    return (
        <ImageWrapper size={size} type={type}>
            <img
                loading="lazy"
                {...restProps}
                src={src}
                alt={alt || ''}
                style={{
                    height,
                    width,
                }}
            />
        </ImageWrapper>
    );
};

export default Image;
