import { keyframes } from 'styled-components';

export const expandMaxHeight = keyframes`
  0% {
    max-height: 0%;
  }
  100% {
    max-height: 100%;
  }
`;

export const collapseMaxHeight = keyframes`
  0% {
    max-height: 100%;
  }
  100% {
    max-height: 0%;
  }
`;

export const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const fadeDownIn = keyframes`
    0% {
      opacity: 0;
      transform: translateY(-6px);
    }
    100% {
      opacity: 1;
      transform: translateY(0px);
    }
`;

export function getFadeDownIn(translateY = '-6px'): any {
    return keyframes`
      0% {
        opacity: 0;
        transform: translateY(${translateY});
      }
      100% {
        opacity: 1;
        transform: translateY(0px);
      }
    `;
}

export const scaleIn = keyframes`
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
`;

export const slideLeftIn = keyframes`
    0% {
      opacity: 0;
      transform: translateX(48px);
    }
    100% {
      opacity: 1;
      transform: translateX(0px);
    }
`;

export const slideRightOut = keyframes`
    0% {
      opacity: 1;
      transform: translateX(0px);
    }
    100% {
      opacity: 0;
      transform: translateX(48px);
    }
`;

export const fadeOverlayIn = keyframes`
    0% {
      background-color: rgba(0, 0, 0, 0);
    }
    100% {
      background-color: rgba(0, 0, 0, 0.3);
    }
`;

export const fadeOverlayOut = keyframes`
    0% {
      background-color: rgba(0, 0, 0, 0.3);
    }
    100% {
      background-color: rgba(0, 0, 0, 0);
    }
`;
