/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable react/require-default-props */
import React, { FunctionComponent, useMemo, useState, useEffect } from 'react';
import { TableCellComponentProps } from 'koddi-components/Table';
import FlexBox from 'koddi-components/FlexBox';
import Checkbox from 'koddi-components/Checkbox';
import {
    Row,
    GridApi,
    GridColumn,
} from 'koddi-components/Table/v2/Table.types';

type CheckboxCellProps<
    Data extends Record<string, unknown> = Record<string, unknown>
> = TableCellComponentProps<Data, boolean> & {
    selectedRows: Record<string, any>;
    getIsMinus: (row: Row<any>, api: GridApi) => boolean;
    getIsChecked: (row: Row<any>, api: GridApi) => boolean;
    getIsDisabled: (row: Row<any>, api: GridApi) => boolean;
    column: any;
    onCheck?: (row: Row<any>, column: GridColumn, api: GridApi) => boolean;
    customCheck?: (row: Row<any>, column: GridColumn, api: GridApi) => boolean;
    checked?: boolean;
    noTranslate?: boolean;
};

const CheckboxCell: FunctionComponent<any> = (
    props: CheckboxCellProps
): JSX.Element => {
    const {
        row,
        column,
        context,
        node,
        getIsMinus,
        getIsChecked,
        getIsDisabled,
        api: gridAPI,
        onCheck,
        customCheck,
        checked,
        noTranslate,
    } = props;
    const [initialized, setInitialized] = useState(false);
    const [selectedValue, setSelectedValue] = useState(
        node?.isSelected() || false
    );
    const isV2Table = !!context;
    const selectAllEntities = column?.selectAll && row?.depth === 0;
    const isChecked = column?.isChecked
        ? column?.isChecked(row)
        : selectAllEntities || column?.selectedRows?.[row?.id];

    const hasSubRowsChecked = useMemo(() => {
        let hasCheckedChildren = false;
        // recurse through row objects and following subRows
        // down until
        function checkRows(rows: any[]) {
            rows.forEach((r) => {
                if (column.selectedRows[r.id]) {
                    hasCheckedChildren = true;
                } else if (r?.subRows) {
                    hasCheckedChildren = checkRows(r?.subRows);
                }
            });
            return hasCheckedChildren;
        }
        if (row?.subRows?.length) {
            return checkRows(row.subRows);
        }
        return hasCheckedChildren;
    }, [column.selectedRows, row?.subRows]);

    const overrideDisabled = column.getIsDisabled
        ? column.getIsDisabled(row.original || node?.data, props)
        : false;

    const disabled =
        overrideDisabled ||
        (row?.depth === 0 &&
            column.selectionMode === 'ad_group' &&
            column.context === 'entity');

    useEffect(() => {
        if (!initialized) {
            if (column?.onInitialization) column?.onInitialization(row);
            setInitialized(true);
        }
    }, [initialized, row, column]);

    const isCheckedValue = useMemo(() => {
        if (isV2Table) return node.isSelected();
        return disabled ? false : isChecked || hasSubRowsChecked;
    }, [isV2Table, node, disabled, hasSubRowsChecked, isChecked]);

    return (
        <FlexBox
            alignItems={column.alignItems || 'center'}
            justifyContent={column.justifyContent || 'center'}
            fillContainer
        >
            <FlexBox
                width={column.width || 12}
                height={column.height || 12}
                alignItems={column.alignItems || 'center'}
                justifyContent={column.justifyContent || 'center'}
            >
                <span
                    aria-checked={isChecked}
                    tabIndex={row?.index}
                    role="checkbox"
                    onKeyUp={() => {}}
                    onClick={() => {
                        if (disabled) return;
                        if (column.onCheck && !isV2Table) column.onCheck(props);
                        if (customCheck) {
                            customCheck(node, column, gridAPI);
                            onCheck?.(node, column, gridAPI);
                            return;
                        }
                        if (isV2Table) {
                            node.setSelected(!selectedValue);
                            setSelectedValue(!selectedValue);
                            onCheck?.(node, column, gridAPI);
                        }
                    }}
                >
                    <Checkbox
                        name="checkbox"
                        animated
                        disabled={
                            getIsDisabled
                                ? getIsDisabled(node, gridAPI)
                                : disabled
                        }
                        fullWidth
                        checked={
                            checked ||
                            node?.isSelected() ||
                            getIsChecked?.(node, gridAPI) ||
                            isCheckedValue
                        }
                        noTranslateLabel={noTranslate || column.noTranslate}
                        label={
                            column.checkboxLabel
                                ? column.checkboxLabel(row)
                                : undefined
                        }
                        isMinus={
                            getIsMinus
                                ? getIsMinus(node, gridAPI)
                                : hasSubRowsChecked
                        }
                        onClick={(e: any) => {
                            e.preventDefault();
                            e.stopPropagation();
                        }}
                        onChange={() => {}}
                    />
                </span>
            </FlexBox>
        </FlexBox>
    );
};

export default CheckboxCell;
