import styled, { css } from 'styled-components';
import { cssProp } from 'koddi-components/utils';
import { AsyncStateManagerDimensions } from './AsyncStateManager.types';

// eslint-disable-next-line prettier/prettier
export const AsyncStateManagerContainer = styled.div<AsyncStateManagerDimensions>`
    ${({ width, height, minHeight, minWidth, maxHeight, maxWidth }) => css`
        position: relative;
        ${cssProp('width', width, '100%')};
        ${cssProp('height', height, '100%')};
        ${cssProp('min-width', minWidth)};
        ${cssProp('min-height', minHeight)};
        ${cssProp('max-width', maxWidth)};
        ${cssProp('max-height', maxHeight)};
    `}
`;
