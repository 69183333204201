import { useContext } from 'react';
import { PermissionsProviderContext } from './PermissionsProvider.component';
import { PermissionsProviderValue } from './PermissionsProvider.types';

/*
 * A custom hook to use the permissions found in the permissions provider.
 */
export function usePermissions(): PermissionsProviderValue {
    const context = useContext<PermissionsProviderValue | undefined>(
        PermissionsProviderContext
    );

    if (context === undefined) {
        throw new Error(
            '`usePermissions` must be used inside of a PermissionsProvider.'
        );
    }

    return context;
}
