import React from 'react';
import Button from 'koddi-components/Button';
import { ErrorMessageProps } from './ErrorMessage.types';
import {
    ErrorMessageContainer,
    ErrorMessageText,
    ErrorMessageTitle,
} from './ErrorMessage.styled';

const ErrorMessage = ({
    title,
    message,
    onRetry,
    width,
    height,
    maxRetriesReached,
}: ErrorMessageProps): JSX.Element => {
    return (
        <ErrorMessageContainer width={width} height={height}>
            <ErrorMessageTitle>{title}</ErrorMessageTitle>
            <ErrorMessageText>{message}</ErrorMessageText>
            <Button onClick={onRetry} disabled={maxRetriesReached}>
                Retry
            </Button>
        </ErrorMessageContainer>
    );
};

export default ErrorMessage;
