import { KoddiAPIResponse } from 'types';
import CachedKoddiAPI from 'api/api.cachedObject';
import { FundingType } from './Funds.types';

class KoddiFundsAPI extends CachedKoddiAPI {
    public getFundingTypes = async (): Promise<FundingType[]> => {
        const response = await this.axios.get<KoddiAPIResponse<any>>(
            `v1/funds/funding_types`
        );
        return response.data.result.funding_types;
    };
}

export default KoddiFundsAPI;
