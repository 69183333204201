/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import KoddiAPI from 'api';
import { createSimpleSagaWithCache } from 'utils/createSimpleSaga';
import { takeEvery, all } from 'redux-saga/effects';
import SagaRegistry from 'redux-core/sagaRegistry';
import { PermissionList } from 'api/Admin/Permission';
import { getPermissionListError, getPermissionListSuccess } from './actions';
import { PermissionAction } from './types';
import { selectPermissionList } from './selectors';

export const getPermissionListApi = (): Promise<PermissionList> =>
    KoddiAPI.Admin.Permission.getList();

export const getPermissionListSaga = createSimpleSagaWithCache(
    getPermissionListApi,
    getPermissionListSuccess,
    getPermissionListError,
    selectPermissionList
);

export function* watchGetPermissionList() {
    yield takeEvery(
        PermissionAction.GET_PERMISSION_LIST,
        getPermissionListSaga
    );
}

export function* permissionSagas() {
    yield all([watchGetPermissionList()]);
}

SagaRegistry.registerSaga(permissionSagas);
