import React, { useState, useCallback } from 'react';
import Select, {
    Props as ReactSelectProps,
    OptionTypeBase,
} from 'react-select';
import { usePopper } from 'react-popper';
import { SelectComponents } from 'react-select/src/components';
import isNumber from 'lodash/isNumber';
import { Portal } from 'react-portal';
import Label from 'koddi-components/Label';
import {
    DropdownContainer,
    SelectButton,
    SelectContainer,
    SelectOutsideClick,
    TargetContainer,
    ReadOnlyTarget,
    LabelWrapper,
    LabelToolTip,
} from './select.styled';
import {
    ControlComponent,
    GroupComponent,
    GroupHeaderComponent,
    MenuComponent,
    MultiControlComponent,
    MultiOptionComponent,
    OptionComponent,
    ValueContainerComponent,
} from './select.components';

import { SelectComponentProps, SelectOption } from './select.types';

const multiComponents = {
    Control: MultiControlComponent,
    DropdownIndicator: null,
    IndicatorSeparator: null,
    Menu: MenuComponent,
    Option: MultiOptionComponent,
    ValueContainer: ValueContainerComponent,
    Group: GroupComponent,
    GroupHeading: GroupHeaderComponent,
};

const defaultComponents = {
    Control: ControlComponent,
    Menu: MenuComponent,
    Option: OptionComponent,
    ValueContainer: ValueContainerComponent,
    DropdownIndicator: null,
    IndicatorSeparator: null,
};

/**
 * The Koddi `Select` Component
 *
 * Flexible select component that accepts `value`, `onChange`, and `value`
 * props and renders a <Button /> 'target' via `btnStyle` that expands into a dropdown.
 *
 * Use the `MultiSelect` export instead of passing `isMulti`.
 *
 * Optional `selectWidth` and `dropdownWidth` allow for manual control of component widths
 * Optional
 *
 * Example Usage:

        <Select
            name="my_select"
            selectWidth={'300px'}
            onChange={onChange}
            options={options}
            getOptionLabel={option => option.name}
            getOptionValue={option => option.id}
            value={value} />
 */
function SelectComponent<OptionType extends OptionTypeBase = SelectOption>({
    isMulti = false,
    isDisabled,
    options,
    name,
    value,
    onChange,
    btnStyle = 'primary',
    btnMinWidth,
    btnText,
    placeholder = '',
    selectWidth,
    dropdownWidth = 300,
    offset = [0, 0],
    customOption,
    hasError,
    backspaceRemovesValue = false,
    controlShouldRenderValue = false,
    escapeClearsValue = true,
    hideSelectedOptions = false,
    isClearable = false,
    autoClose = true,
    target,
    useAriaLabel = false,
    label,
    required,
    className,
    btnEllipsisOnOverflow = false,
    getOptionLabel = (o) => o?.label,
    getOptionValue = (o) => o?.value,
    'data-test': dataTest,
    usePortal = true,
    dropdownMinWidth,
    searchPlaceholder,
    isSearchable = false,
    readOnly = false,
    labelToolTip = false,
    toolTipText,
    v2 = false,
    btnV2 = false,
    ...rest
}: SelectComponentProps<OptionType>): JSX.Element {
    const [isOpen, setIsOpen] = useState(false);
    const [
        referenceElement,
        setReferenceElement,
    ] = useState<HTMLDivElement | null>(null);
    const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
        null
    );
    const visibility = !isDisabled && required ? 'visible' : 'hidden';

    const { styles, attributes } = usePopper(referenceElement, popperElement, {
        modifiers: [{ name: 'offset', options: { offset } }],
        placement: 'bottom-start',
    });

    function createButtonText(): React.ReactNode {
        if (btnText) return btnText;
        if (Array.isArray(value)) {
            return value.length
                ? value
                      .map((v) => (getOptionLabel ? getOptionLabel(v) : v))
                      .join(', ')
                : btnText || placeholder;
        }
        return value
            ? String(
                  getOptionLabel ? getOptionLabel(value as OptionType) : value
              )
            : btnText || placeholder;
    }

    function toggleOpen() {
        setIsOpen(!isOpen);
    }

    const handleChange: ReactSelectProps<OptionType>['onChange'] = useCallback(
        (option, action) => {
            if (onChange) {
                onChange(option, action);
            }
            if (!isMulti) setIsOpen(false);
        },
        [onChange, isMulti]
    );

    function renderSelect() {
        const components: Partial<SelectComponents<OptionType>> = isMulti
            ? { ...multiComponents }
            : {
                  ...defaultComponents,
                  Option: customOption || defaultComponents.Option,
              };
        return (
            <div>
                <Select<OptionType>
                    options={options}
                    value={value}
                    autoFocus
                    isMulti={isMulti}
                    isDisabled={isDisabled}
                    onChange={handleChange}
                    components={components}
                    backspaceRemovesValue={backspaceRemovesValue}
                    controlShouldRenderValue={controlShouldRenderValue}
                    hideSelectedOptions={hideSelectedOptions}
                    escapeClearsValue={escapeClearsValue}
                    isClearable={isClearable}
                    menuIsOpen={isOpen}
                    name={name}
                    placeholder={searchPlaceholder || placeholder}
                    getOptionLabel={getOptionLabel}
                    getOptionValue={getOptionValue}
                    isSearchable={isSearchable}
                    theme={(theme) => ({
                        ...theme,
                        borderRadius: 0,
                    })}
                    aria-label={useAriaLabel ? label : undefined}
                    v2={v2}
                    {...rest}
                />
            </div>
        );
    }

    function renderTarget() {
        const icon = isOpen ? 'chevronUp' : 'chevronDown';
        const optionsLength = options?.length || 0;

        return (
            <SelectButton
                onClick={toggleOpen}
                btnStyle={btnStyle}
                iconRight={optionsLength <= 1 || isDisabled ? null : icon}
                ellipsisOnOverflow={btnEllipsisOnOverflow}
                data-test={`${dataTest || name}-button`}
                disabled={isDisabled}
                minWidth={btnMinWidth || undefined}
                v2={btnV2}
            >
                {createButtonText()}
            </SelectButton>
        );
    }

    function handleOutsideClick() {
        if (autoClose) setIsOpen(false);
    }

    const DropdownPortal = usePortal ? Portal : React.Fragment;

    return (
        <SelectOutsideClick
            listenForOutsideClick={isOpen}
            onOutsideClick={handleOutsideClick}
            additionalElement={popperElement}
            width={selectWidth}
        >
            <SelectContainer
                selectWidth={`${
                    isNumber(selectWidth) ? `${selectWidth}px` : selectWidth
                }`}
                data-test={name}
                className={className}
                ref={setReferenceElement}
            >
                {label && (
                    <LabelWrapper
                        visibility={visibility}
                        labelToolTip={labelToolTip}
                    >
                        <Label htmlFor={name || ''}>{label}</Label>
                        {labelToolTip && toolTipText && (
                            <LabelToolTip
                                translationKey=""
                                defaultText={toolTipText}
                                icon="info"
                                iconHeight={13}
                                iconWidth={13}
                            />
                        )}
                    </LabelWrapper>
                )}
                {readOnly ? (
                    <ReadOnlyTarget data-test={`${dataTest || name}-read-only`}>
                        {createButtonText()}
                    </ReadOnlyTarget>
                ) : null}
                <TargetContainer hidden={readOnly} hasError={hasError} v2={v2}>
                    {target
                        ? target(value, toggleOpen, isOpen)
                        : renderTarget()}
                </TargetContainer>
                <DropdownPortal>
                    {isOpen && (
                        <DropdownContainer
                            dropdownWidth={
                                v2
                                    ? dropdownWidth
                                    : referenceElement?.clientWidth ||
                                      dropdownWidth
                            }
                            dropdownMinWidth={dropdownMinWidth}
                            style={usePortal ? styles.popper : {}}
                            ref={setPopperElement}
                            data-test={`${name}-dropdown`}
                            {...(usePortal ? attributes.popper : {})}
                        >
                            {renderSelect()}
                        </DropdownContainer>
                    )}
                </DropdownPortal>
            </SelectContainer>
        </SelectOutsideClick>
    );
}

export function MultiSelect<OptionType extends OptionTypeBase = SelectOption>(
    props: SelectComponentProps<OptionType>
): JSX.Element {
    return <SelectComponent<OptionType> {...props} isMulti />;
}

export default SelectComponent;
