const cuisineOptions = [
    {
        label: 'American',
        options: [
            {
                label: 'Breakfast and brunch',
                value: 'Breakfast and brunch',
            },
            {
                label: 'Sandwich',
                value: 'Sandwich',
            },
            {
                label: 'Other American Food',
                value: 'Other American Food',
            },
        ],
    },
    {
        label: 'Asian',
        options: [
            {
                label: 'Chinese',
                value: 'Chinese',
            },
            {
                label: 'Indian',
                value: 'Indian',
            },
            {
                label: 'Japanese',
                value: 'Japanese',
            },
            {
                label: 'Korean',
                value: 'Korean',
            },
            {
                label: 'Thai',
                value: 'Thai',
            },
            {
                label: 'Other Asian Food',
                value: 'Other Asian Food',
            },
        ],
    },
    {
        label: 'Caribbean',
        value: 'Caribbean',
    },
    {
        label: 'Comfort Food',
        value: 'Comfort Food',
    },
    {
        label: 'Dessert, Coffee & Tea, Baked Goods',
        value: 'Dessert, Coffee & Tea, Baked Goods',
    },
    {
        label: 'European',
        options: [
            {
                label: 'Italian',
                value: 'Italian',
            },
            {
                label: 'Other European Food',
                value: 'Other European Food',
            },
        ],
    },
    {
        label: 'Healthy Salad, Vegan, Vegetarian',
        value: 'Healthy Salad, Vegan, Vegetarian',
    },
    {
        label: 'Juice & Smoothie',
        value: 'Juice & Smoothie',
    },
    {
        label: 'Latin American',
        options: [
            {
                label: 'Mexican',
                value: 'Mexican',
            },
            {
                label: 'Other Latin American Food',
                value: 'Other Latin American Food',
            },
        ],
    },
    {
        label: 'Mediterranean & Middle Eastern',
        value: 'Mediterranean & Middle Eastern',
    },
    {
        label: 'Pizza, Burger, Fast Food',
        value: 'Pizza, Burger, Fast Food',
    },
];

export default cuisineOptions;
