import {
    ChangeThemeAction,
    CreateThemeAction,
    SetThemeListAction,
    DeleteThemeAction,
    ThemeAction,
    UpdateThemeAction,
    KoddiThemeResource,
    SetThemeStatus,
    ThemeErrorAction,
    ThemeError,
    GetThemeByMemberGroupHostname,
} from './types';

export function changeTheme(theme: KoddiThemeResource): ChangeThemeAction {
    return {
        type: ThemeAction.CHANGE_THEME,
        payload: { theme },
    };
}

export function createTheme(
    theme: KoddiThemeResource,
    onSuccess?: () => void,
    onFailure?: () => void
): CreateThemeAction {
    return {
        type: ThemeAction.CREATE_THEME,
        payload: { theme, onSuccess, onFailure },
    };
}

export function deleteTheme(
    theme: KoddiThemeResource,
    onSuccess?: () => void,
    onFailure?: () => void
): DeleteThemeAction {
    return {
        type: ThemeAction.DELETE_THEME,
        payload: { theme, onSuccess, onFailure },
    };
}

export function getThemeList(): any {
    return {
        type: ThemeAction.GET_THEME_LIST,
    };
}

export function getThemeByMemberGroupId(memberGroupId: number): any {
    return {
        type: ThemeAction.GET_THEME_BY_MEMBER_GROUP_ID,
        payload: { memberGroupId },
    };
}

export function getThemeByMemberGroupHostname(
    hostname: string
): GetThemeByMemberGroupHostname {
    return {
        type: ThemeAction.GET_THEME_BY_MEMBER_GROUP_HOSTNAME,
        payload: { hostname },
    };
}

export function setThemeList(themes: KoddiThemeResource[]): SetThemeListAction {
    return {
        type: ThemeAction.SET_THEME_LIST,
        payload: { themes },
    };
}

export function updateTheme(
    theme: KoddiThemeResource,
    onSuccess?: () => void,
    onFailure?: () => void
): UpdateThemeAction {
    return {
        type: ThemeAction.UPDATE_THEME,
        payload: { theme, onSuccess, onFailure },
    };
}

export function setThemeStatus(status: string): SetThemeStatus {
    return {
        type: ThemeAction.SET_THEME_STATUS,
        payload: {
            status,
        },
    };
}

export function themeError(error: ThemeError): ThemeErrorAction {
    return {
        type: ThemeAction.THEME_ERROR,
        payload: {
            error,
        },
    };
}

export function setRegistrationEnabled(registrationEnabled: boolean): any {
    return {
        type: ThemeAction.SET_REGISTRATION_ENABLED,
        payload: {
            registrationEnabled,
        },
    };
}
