import {
    SetMessageAction,
    AppMessagesAction,
    ClearMessageAction,
} from './types';

export function setAppMessage(
    type: string,
    title: string,
    message: string,
    key?: string
): SetMessageAction {
    return {
        type: AppMessagesAction.SET_MESSAGE,
        payload: {
            message,
            type,
            title,
            key,
        },
    };
}

export function clearAppMessage(): ClearMessageAction {
    return {
        type: AppMessagesAction.CLEAR_MESSAGE,
    };
}
