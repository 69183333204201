import {
    ApplicationRootState,
    ApplicationOutputSelector,
} from 'redux-core/types';
import { createSelector } from 'reselect';
import { AsyncState } from 'types';
import { KoddiUserList } from 'api/Admin/User';
import { initialState } from './reducer';
import { UserState } from './types';

export const selectAdminUser = (state: ApplicationRootState): UserState =>
    state.admin.user || initialState;

export const selectAdminMemberGroupId = (
    state: ApplicationRootState
): number | undefined => state.auth.context.user?.member_group_id;

export const selectUserList: ApplicationOutputSelector<
    AsyncState<KoddiUserList>,
    (res: UserState) => AsyncState<KoddiUserList>
> = createSelector(selectAdminUser, (state) => state.list);
