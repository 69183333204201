import format from 'date-fns/format';
import { KoddiLocaleKey } from './LocaleProvider.types';
import { KODDI_LOCALE_TO_DATEFNS } from './LocaleProvider.constants';

export function isValidKoddiLocale(
    possibleLocale: string,
    errorMessage?: string
): boolean {
    if (!possibleLocale) return false;
    const localeIsValid =
        Object.keys(KODDI_LOCALE_TO_DATEFNS).findIndex(
            (validLocale) => validLocale === possibleLocale
        ) > -1;
    if (localeIsValid) return true;

    // eslint-disable-next-line no-console
    console.error(
        errorMessage ||
            `LocaleProvider: The locale "${possibleLocale}" is not supported by Koddi. As a result the locale will be set to "en-US" to prevent the app from crashing.`
    );
    return false;
}

/**
 * A helper function for formatting dates for a specific locale.
 * By default dates of formatted using the `P` (Long localized date) format string such as MM/DD/YYYY.
 */
export function formatDate(
    date: string | Date,
    possibleLocale: KoddiLocaleKey,
    dateFormat?: string
): string {
    const localeIsValid = isValidKoddiLocale(
        possibleLocale,
        `formatDate: The locale ${possibleLocale} is not supported by Koddi. The date you are trying to format will be formatted using "en-US".`
    );

    const dateFormatRegex = new RegExp('\\d{4}-\\d{2}-\\d{2}');

    let dateToFormat = date;

    if (typeof date === 'string' && dateFormatRegex.test(date)) {
        dateToFormat = date.replaceAll('-', '/');
    }

    const dt = new Date(dateToFormat);

    const locale = localeIsValid ? possibleLocale : 'en-US';
    return format(dt, dateFormat || 'P', {
        locale: KODDI_LOCALE_TO_DATEFNS[locale],
    });
}
