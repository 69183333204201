import React from 'react';
import { TransitionGroup } from 'react-transition-group';
import SlideTransition from 'koddi-components/Transitions/SlideTransition';
import { ToastsContainerBase } from './ToastsContainer.styled';
import Toast from '../Toast';
import { KoddiToast } from '../ToastProvider.types';

export type ToastsContainerProps = {
    toasts: KoddiToast[];
    removeToastById: (id: string) => void;
};

const ToastsContainer = ({
    toasts,
    removeToastById,
}: ToastsContainerProps): JSX.Element => {
    return (
        <ToastsContainerBase>
            <TransitionGroup className="" component={null}>
                {toasts.map(({ id, ...restToast }) => (
                    <SlideTransition key={id} duration={500} unmountOnExit>
                        <Toast
                            {...restToast}
                            id={id}
                            hideToast={() => removeToastById(id)}
                        />
                    </SlideTransition>
                ))}
            </TransitionGroup>
        </ToastsContainerBase>
    );
};

export default ToastsContainer;
