/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, FunctionComponent, useRef } from 'react';
import { OnOutsideClickProps } from './OnOutsideClick.types';

/**
 * The `OnOutsideClick` captures all clicks outside
 * the element and will fire a callback whenever an
 * element outside is clicked.
 */
const OnOutsideClick: FunctionComponent<OnOutsideClickProps> = ({
    children,
    onClick,
    listenForOutsideClick,
    onOutsideClick,
    additionalElement,
    getRef,
    ...restProps
}) => {
    const ref = useRef<HTMLDivElement>(null);
    const handleClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        const target = e.target as Node;
        const targetIsNotChild = !ref?.current?.contains(target);
        if (targetIsNotChild) e.nativeEvent.stopPropagation();
        if (onClick) onClick(e);
    };
    useEffect(() => {
        const handleWindowClick = (e: MouseEvent) => {
            const target = e.target as Node;
            if (
                ref.current &&
                !ref.current?.contains(target) &&
                !additionalElement?.contains(target)
            )
                onOutsideClick();
        };

        if (listenForOutsideClick)
            window.addEventListener('mousedown', handleWindowClick);
        else window.removeEventListener('mousedown', handleWindowClick);

        return function cleanup() {
            window.removeEventListener('mousedown', handleWindowClick);
        };
    }, [ref, onOutsideClick, listenForOutsideClick, additionalElement]);

    useEffect(() => {
        if (ref.current && getRef) {
            getRef(ref.current);
        }
    }, [ref, getRef]);

    return (
        <div {...restProps} ref={ref} onClick={handleClick}>
            {children}
        </div>
    );
};

export default OnOutsideClick;
