import { useAppTheme } from 'redux-core/app/theme/hooks';
import { useAppContextMemberGroup } from 'redux-core/app/context/hooks';
import { useAuthStatus } from 'redux-core/auth/hooks';
import { THEME_STORAGE_KEY } from 'utils/constants';

import appConfig from 'app/app_config.json';

export const useFaviconURL = (): string => {
    const theme = useAppTheme();
    return theme?.faviconSrc || appConfig.default_favicon;
};

export const useAppTitle = (): string => {
    const appContextMemberGroup = useAppContextMemberGroup();
    const appTheme = useAppTheme();
    const loggedIn = useAuthStatus() === 'signedIn';
    const localStorageThemeValue = localStorage.getItem(THEME_STORAGE_KEY);
    const localStorageTheme = localStorageThemeValue
        ? JSON.parse(localStorageThemeValue)
        : null;

    if (loggedIn) {
        return (
            appContextMemberGroup?.name ||
            appTheme?.name ||
            localStorageTheme?.theme?.name ||
            'Koddi'
        );
    }

    return appTheme?.name || localStorageTheme?.name || 'Koddi';
};
