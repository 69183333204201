import { AxiosInstance } from 'axios';
import CachedAPI, {
    CacheKey,
    CacheOptions,
    CacheMethodValue,
} from 'api/cachedAPI';

abstract class CachedKoddiAPI {
    protected axios: AxiosInstance;

    protected cache: CachedAPI;

    constructor(axios: AxiosInstance, cacheOptions?: CacheOptions) {
        this.axios = axios;
        this.cache = new CachedAPI({
            ...(cacheOptions || {}),
            cacheName: cacheOptions?.cacheName || this.constructor.name,
        });
    }

    public breakCache(method: CacheMethodValue, key: CacheKey): void {
        this.cache.break(method, key);
    }

    public breakMethodCache(method: CacheMethodValue): void {
        this.cache.breakMethod(method);
    }
}

export default CachedKoddiAPI;
