import isNumber from 'lodash/isNumber';

/**
 * Converts a css value depending on whether
 * or not it is a string or a number. If it is
 * a number it will append `px`.
 * @param value The css value
 */
export function parseCSSValue(value: string | number | undefined): string {
    if (value === undefined) return '';
    return isNumber(value) ? `${value}px` : `${value}`;
}
