import { FieldValidationFunctionSync } from '@lemoncode/fonk';

export const postalCodeValidator: FieldValidationFunctionSync = (input) => {
    const pattern = /^[a-zA-Z0-9-_ .-]*$/;
    const succeeded = pattern.test(input.value);
    return {
        type: '',
        succeeded,
        message: succeeded ? '' : 'Must enter valid postal code',
    };
};

export default postalCodeValidator;
