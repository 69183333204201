import {
    ReduxActionWithError,
    ReduxActionWithPayload,
    ReduxActionWithRefresh,
    AsyncState,
} from 'types';
import { MemberGroupList } from 'api/Admin/MemberGroup';
import { TemplateOverviewType } from 'api/Admin/TemplateOverview/TemplateOverview.types';

export enum MemberGroupAction {
    GET_MEMBER_GROUP_LIST = 'admin/memberGroup/GET_MEMBER_GROUP_LIST',
    GET_MEMBER_GROUP_LIST_SUCCESS = 'admin/memberGroup/GET_MEMBER_GROUP_LIST_SUCCESS',
    GET_MEMBER_GROUP_LIST_ERROR = 'admin/memberGroup/GET_MEMBER_GROUP_LIST_ERROR',
    GET_MEMBER_GROUP_ACTIVE_TEMPLATES = 'admin/memberGroup/GET_MEMBER_GROUP_ACTIVE_TEMPLATES',
}

export type GetMemberGroupListAction = ReduxActionWithRefresh<
    MemberGroupAction.GET_MEMBER_GROUP_LIST
>;

export type GetMemberGroupListSuccessAction = ReduxActionWithPayload<
    MemberGroupAction.GET_MEMBER_GROUP_LIST_SUCCESS,
    { data: MemberGroupList }
>;

export type GetMemberGroupListErrorAction = ReduxActionWithError<
    MemberGroupAction.GET_MEMBER_GROUP_LIST_ERROR
>;

export type GetMemberGroupActiveTemplates = ReduxActionWithPayload<
    MemberGroupAction.GET_MEMBER_GROUP_ACTIVE_TEMPLATES,
    { data: TemplateOverviewType[] }
>;

export type MemberGroupActions =
    | GetMemberGroupListAction
    | GetMemberGroupListSuccessAction
    | GetMemberGroupListErrorAction
    | GetMemberGroupActiveTemplates;

type MemberGroupListType = {
    list: AsyncState<MemberGroupList>;
};

export type MemberGroupState = MemberGroupListType & {
    activeTemplates: TemplateOverviewType[] | null;
};
