import styled, { css } from 'styled-components';
import { IconWrapper } from 'koddi-components/Icon';
import { ButtonBase } from 'koddi-components/Button';
import Dropdown from 'koddi-components/Dropdown';

export const TopNavContainer = styled.div`
    ${({ theme }) => css`
        height: 100%;
        display: flex;
        padding: 10px;
        flex-direction: row;
        justify-content: space-between;
        border-bottom: 1px solid ${theme.headerBorderColor || 'transparent'};
    `}
`;

export const LeftMenu = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

export const LogoContainer = styled.div`
    ${({ theme }) => css`
        height: ${theme.logoHeight || theme.spaceUnit * 8}px;
        ${theme.logoWidth ? `width: ${theme.logoWidth}px;` : ''}
        margin-left: ${theme.spaceUnit * 8}px;
        img {
            max-height: 100%;
            max-width: 100%;
        }
    `}
`;

export const RightMenu = styled.div`
    ${({ theme }) => css`
        display: flex;
        flex-direction: row;
        color: ${theme.headerTextColor || theme.white};
        justify-content: center;
        align-items: center;
        button {
            color: ${theme.headerTextColor || theme.white};
        }
    `}
`;

export const TopNavDropdown = styled(Dropdown)`
    ${({ theme: { spaceUnit } }) => css`
        ${ButtonBase} {
            background: transparent;
            &:hover,
            &:focus,
            &:active {
                background: transparent;
                color: #ffffff;
            }
            ${IconWrapper} {
                margin-right: ${spaceUnit * 6}px;
                svg {
                    height: ${spaceUnit * 4}px;
                    width: ${spaceUnit * 4}px;
                }
            }
        }
    `}
`;
