/**
 * A utility predicate for filtering out null values
 * from an array or object with typescript to avoid
 * type checking errors.
 */
function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
    return value !== null && value !== undefined;
}

export default notEmpty;
