import React from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useKoddiTheme } from 'koddi-components/ThemeProvider';
import Image from 'koddi-components/Image';
import { useAuth0 } from 'modules/Auth0';
import { useAuthUser } from 'redux-core/auth/hooks';
import { logoutSubmit, logoutWithSSOSubmit } from 'redux-core/auth/actions';
import AdvertiserSelector from '../AdvertiserSelector';
import {
    TopNavContainer,
    LeftMenu,
    LogoContainer,
    RightMenu,
    TopNavDropdown,
} from './TopNav.styled';
import { TopNavProps } from './TopNav.types';

const TopNavigation = ({ showRightMenu = true }: TopNavProps): JSX.Element => {
    const dispatch = useDispatch();
    const user = useAuthUser();
    const { pathname } = useLocation();
    const isAdmin = pathname.includes('admin');
    const theme = useKoddiTheme();
    const { ssoFeatureFlag } = useAuth0();

    const dropdownItems = [
        {
            label: 'Sign Out',
            onClick: () => {
                if (ssoFeatureFlag)
                    dispatch(logoutWithSSOSubmit(undefined, theme.login?.key));
                else dispatch(logoutSubmit(undefined, theme.login?.key));
            },
            value: 'logout',
            'data-test': 'sign-out-dropdown-button',
        },
    ];

    return (
        <TopNavContainer>
            <LeftMenu>
                <LogoContainer>
                    <Image
                        height={theme?.logoHeight || theme.spaceUnit * 8}
                        width={theme.logoWidth}
                        src={theme.logoSrc}
                        alt="Client Logo"
                    />
                </LogoContainer>
            </LeftMenu>
            {showRightMenu ? (
                <RightMenu>
                    {!isAdmin && <AdvertiserSelector />}
                    <TopNavDropdown
                        btnStyle="secondary"
                        btnText={`${user?.first_name ?? ''} ${
                            user?.last_name ?? ''
                        }`}
                        btnTextIsVar
                        items={dropdownItems}
                        iconRight="profile"
                        data-test="account-dropdown"
                        dropdownOffset={[0, 17]}
                    />
                </RightMenu>
            ) : null}
        </TopNavContainer>
    );
};

export default TopNavigation;
