import { KoddiAPIResponse } from 'types';
import { Campaign, PlayerCompetitorLeaderData } from 'api/Campaigns';
import KoddiObjectAPI from 'api/api.object';
import {
    CreateAdvertiserCampaignInput,
    UpdateAdvertiserCampaignInput,
    SimpleCampaignDraftPayload,
    SimpleCampaignDetails,
    CampaignTemplateDetails,
    EntitiesList,
    FilterItem,
    SimpleCampaignCreationResponse,
    CampaignTemplateDetailsV2,
    CampaignTemplatePayload,
} from './Campaigns.types';
import { Advertiser } from '../Advertiser.types';

class KoddiAdvertiserCampaignsAPI extends KoddiObjectAPI {
    /** Creates a new campaign for an advertiser. */
    public create = async (
        advertiser_id: Advertiser['advertiser_id'],
        member_group_id: Advertiser['member_group_id'],
        data: CreateAdvertiserCampaignInput
    ): Promise<Campaign> => {
        const response = await this.axios.post<KoddiAPIResponse<Campaign>>(
            `v0.9/member_groups/${member_group_id}/advertisers/${advertiser_id}/campaigns`,
            data
        );
        return response.data.result;
    };

    /** Gets a campaign by id. */
    public get = async (campaign_id: Campaign['id']): Promise<Campaign> => {
        const response = await this.axios.get<KoddiAPIResponse<Campaign>>(
            `v1/campaign/${campaign_id}`
        );
        return response.data.result;
    };

    /** Updates a campaign for an advertiser. */
    public update = async (
        advertiser_id: Advertiser['advertiser_id'],
        member_group_id: Advertiser['member_group_id'],
        campaign_id: Campaign['id'],
        data: UpdateAdvertiserCampaignInput
    ): Promise<Campaign> => {
        const response = await this.axios.put<KoddiAPIResponse<Campaign>>(
            `v0.9/member_groups/${member_group_id}/advertisers/${advertiser_id}/campaigns/${campaign_id}`,
            data
        );
        return response.data.result;
    };

    public updateAll = async (
        advertiser_id: Advertiser['advertiser_id'],
        member_group_id: Advertiser['member_group_id'],
        campaign_id: Campaign['id'],
        data: { budget: number }
    ): Promise<Campaign> => {
        const response = await this.axios.patch<KoddiAPIResponse<Campaign>>(
            `v0.9/member_groups/${member_group_id}/advertisers/${advertiser_id}/campaigns/${campaign_id}/budget`,
            data
        );
        return response.data.result;
    };

    /** Deletes a campaign for a specific advertiser */
    public delete = async (
        member_group_id: Advertiser['member_group_id'],
        advertiser_id: Advertiser['advertiser_id'],
        campaign_id: Campaign['id']
    ): Promise<null> => {
        await this.axios.delete<KoddiAPIResponse<null>>(
            `v1/member_groups/${member_group_id}/advertisers/${advertiser_id}/campaigns/${campaign_id}`
        );
        return null;
    };

    public getDetailsForSimpleCampaignAndAdGroup = async (
        memberGroupId: number,
        advertiserId: string,
        campaignId: string
    ): Promise<SimpleCampaignDetails> => {
        const response = await this.axios.get<
            KoddiAPIResponse<SimpleCampaignDetails>
        >(
            `${window.API_ROUTE}v1/member_groups/${memberGroupId}/advertisers/${advertiserId}/simple_campaigns/${campaignId}`
        );

        return response.data.result;
    };

    public getUniqueEntityAttributes = async (
        memberGroupId: number,
        advertiserId: string,
        entityAttributeID: string
    ): Promise<any> => {
        const response = await this.axios.get(
            `${window.API_ROUTE}v1/member_groups/${memberGroupId}/advertisers/${advertiserId}/entity_attributes/${entityAttributeID}/get_unique_values`
        );

        return response.data.result;
    };

    public getAvailableEntities = async (
        memberGroupId: number,
        advertiserId: string,
        uuid: number,
        payload: {
            filters?: FilterItem[];
            pagination?: { start: number; count: number };
        }
    ): Promise<{ entities: EntitiesList }> => {
        const response = await this.axios.post(
            `${window.API_ROUTE}v1/member_groups/${memberGroupId}/advertisers/${advertiserId}/drafts/${uuid}/available_entities_report`,
            payload
        );

        return response.data.result;
    };

    public getActiveEntities = async (
        memberGroupId: number,
        advertiserId: string,
        uuid: number,
        payload: {
            filters?: FilterItem[];
            pagination?: { start: number; count: number };
        }
    ): Promise<{ entities: EntitiesList; total_count: number }> => {
        const response = await this.axios.post(
            `${window.API_ROUTE}v1/member_groups/${memberGroupId}/advertisers/${advertiserId}/drafts/${uuid}/entities_report`,
            payload
        );

        return response.data.result;
    };

    public addEntitiesToDraft = async (
        memberGroupId: number,
        advertiserId: string,
        uuid: number,
        payload: SimpleCampaignDraftPayload
    ): Promise<any> => {
        const response = await this.axios.patch(
            `${window.API_ROUTE}v1/member_groups/${memberGroupId}/advertisers/${advertiserId}/drafts/${uuid}/add_entities`,
            payload
        );

        return response.data.result;
    };

    public addAllEntitiesToDraft = async (
        memberGroupId: number,
        advertiserId: string,
        uuid: number
    ): Promise<any> => {
        const response = await this.axios.patch(
            `${window.API_ROUTE}v1/member_groups/${memberGroupId}/advertisers/${advertiserId}/drafts/${uuid}/add_all_entities`
        );

        return response.data.result;
    };

    public removeEntitiesFromDraft = async (
        memberGroupId: number,
        advertiserId: string,
        uuid: number,
        payload: SimpleCampaignDraftPayload
    ): Promise<any> => {
        const response = await this.axios.delete(
            `${window.API_ROUTE}v1/member_groups/${memberGroupId}/advertisers/${advertiserId}/drafts/${uuid}/remove_entities`,
            {
                data: {
                    koddi_entity_ids: payload.koddi_entity_ids,
                    filters: payload.filters,
                },
            }
        );

        return response.data.result;
    };

    public createSimpleCampaignDraft = async (
        memberGroupId: number,
        advertiserId: string,
        payload: SimpleCampaignDraftPayload
    ): Promise<any> => {
        const response = await this.axios.post(
            `${window.API_ROUTE}v1/member_groups/${memberGroupId}/advertisers/${advertiserId}/drafts`,
            payload
        );

        return response.data.result;
    };

    public createDraftInTheContextOfAdGroup = async (
        memberGroupId: number,
        advertiserId: string,
        adGroupId: string,
        payload: SimpleCampaignDraftPayload
    ): Promise<any> => {
        const response = await this.axios.post(
            `${window.API_ROUTE}v1/member_groups/${memberGroupId}/advertisers/${advertiserId}/ad_groups/${adGroupId}/drafts`,
            payload
        );

        return response.data.result;
    };

    public updateSimpleCampaign = async (
        memberGroupId: number,
        advertiserId: string,
        uuid: number,
        campaignId: string,
        params: Partial<SimpleCampaignDetails>
    ): Promise<Partial<SimpleCampaignDetails>> => {
        const response = await this.axios.patch(
            `${window.API_ROUTE}v1/member_groups/${memberGroupId}/advertisers/${advertiserId}/drafts/${uuid}/simple_campaigns/${campaignId}`,
            params
        );

        return response.data.result;
    };

    public createSimpleCampaign = async (
        memberGroupId: number,
        advertiserId: string,
        templateId: number,
        uuid: number,
        params: Partial<SimpleCampaignDetails>
    ): Promise<SimpleCampaignCreationResponse> => {
        const response = await this.axios.post(
            `${window.API_ROUTE}v1/member_groups/${memberGroupId}/advertisers/${advertiserId}/templates/${templateId}/drafts/${uuid}/simple_campaigns`,
            params
        );

        return response.data.result;
    };

    public removeAllEntitiesFromDraft = async (
        memberGroupId: number,
        advertiserId: string,
        uuid: number
    ): Promise<any> => {
        const response = await this.axios.delete(
            `${window.API_ROUTE}v1/member_groups/${memberGroupId}/advertisers/${advertiserId}/drafts/${uuid}/remove_all_entities`
        );

        return response.data.result;
    };

    /** Gets player, competitor, leader budget data. */
    public getPlayerCompetitorLeaderData = async (
        memberGroupId: number | undefined,
        advertiserId: Advertiser['advertiser_id'] | undefined,
        uuid: number | undefined,
        budget_type: string
    ): Promise<any> => {
        const response = await this.axios.get<
            KoddiAPIResponse<PlayerCompetitorLeaderData>
        >(
            `${window.API_ROUTE}v1/member_groups/${memberGroupId}/advertisers/${advertiserId}/drafts/${uuid}/pcl`,
            {
                params: {
                    budget_type,
                },
            }
        );

        return response.data.result;
    };

    public getCampaignTemplateDetails = async (
        memberGroupId: number,
        campaignTemplateId: number
    ): Promise<any> => {
        const response = await this.axios.get<any>(
            `${window.API_ROUTE}v1/member_groups/${memberGroupId}/templates/${campaignTemplateId}`
        );

        return response.data.result;
    };

    public createCampaignTemplate = async (
        memberGroupId: number,
        payload: CampaignTemplateDetails
    ): Promise<CampaignTemplateDetails> => {
        const response = await this.axios.post(
            `${window.API_ROUTE}v1/member_groups/${memberGroupId}/templates`,
            payload
        );

        return response.data.result;
    };

    public createCampaignTemplateV2 = async (
        memberGroupId: number,
        payload: CampaignTemplateDetailsV2
    ): Promise<CampaignTemplateDetailsV2> => {
        const response = await this.axios.post(
            `${window.API_ROUTE}v2/member_groups/${memberGroupId}/templates`,
            payload
        );

        return response.data.result;
    };

    public updateCampaignTemplate = async (
        memberGroupId: number,
        templateId: number,
        payload: Partial<CampaignTemplateDetails>
    ): Promise<Partial<CampaignTemplateDetails>> => {
        const response = await this.axios.patch(
            `${window.API_ROUTE}v1/member_groups/${memberGroupId}/templates/${templateId}`,
            payload
        );

        return response.data.result;
    };

    public updateCampaignTemplateV2 = async ({
        memberGroupId,
        templateId,
        payload,
    }: CampaignTemplatePayload): Promise<
        Partial<CampaignTemplateDetailsV2>
    > => {
        const response = await this.axios.patch(
            `${window.API_ROUTE}v2/member_groups/${memberGroupId}/templates/${templateId}`,
            payload
        );

        return response.data.result;
    };

    public deleteCampaignTemplate = async (
        memberGroupId: number,
        templateId: number
    ): Promise<any> => {
        const response = await this.axios.delete(
            `${window.API_ROUTE}v1/member_groups/${memberGroupId}/templates/${templateId}`
        );

        return response.data.result;
    };

    public getCampaignFundsDifference = async (
        memberGroupId: number,
        advertiserId: number
    ): Promise<any> => {
        const response = await this.axios.get<any>(
            `${window.API_ROUTE}v1/member_groups/${memberGroupId}/advertisers/${advertiserId}/auto_rebills/calculate_campaign_budget_difference`
        );

        return response.data.result;
    };
}

export default KoddiAdvertiserCampaignsAPI;
