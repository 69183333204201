import styled, { css } from 'styled-components';

export const ImageWrapper = styled.div<{
    type?: string;
    size?: string;
    withBackground?: boolean;
    height?: string | number | undefined;
    width?: string | number | undefined;
}>`
    ${({ type, withBackground, size, height, width }) => css`
        img {
            width: 100%;
            height: 100%;
        }
        overflow: hidden;

        ${type === 'round' ? `border-radius: 27px;` : ''}
        ${size === 'small' ? `height 35px; width: 35px;` : ''}
        ${withBackground ? `background-color: grey;` : ''}
        ${height ? `height: ${height}px;` : ''}
        ${width ? `width: ${width}px;` : ''}
    `}
`;
