import { combineReducers } from 'redux';
import createAsyncReducer from 'utils/createAsyncReducer';
import { RolePermissionList } from 'api/Admin/RolePermission';
import { RolePermissionState, RolePermissionAction } from './types';

export const initialState: RolePermissionState = {
    list: {
        last_updated: null,
        loading: false,
        error: null,
        data: null,
    },
};

export const rolePermissionListReducer = createAsyncReducer<RolePermissionList>(
    initialState.list,
    {
        start: RolePermissionAction.GET_ROLE_PERMISSION_LIST,
        success: RolePermissionAction.GET_ROLE_PERMISSION_LIST_SUCCESS,
        error: RolePermissionAction.GET_ROLE_PERMISSION_LIST_ERROR,
    }
);

const rolePermissionReducer = combineReducers<RolePermissionState>({
    list: rolePermissionListReducer,
});

export default rolePermissionReducer;
