import { combineReducers } from 'redux';
import createAsyncReducer from 'utils/createAsyncReducer';
import { CurrencyList } from 'api/Admin/Currency';
import { CurrencyState, CurrencyAction } from './types';

export const initialState: CurrencyState = {
    list: {
        last_updated: null,
        loading: false,
        error: null,
        data: null,
    },
};

export const currencyListReducer = createAsyncReducer<CurrencyList>(
    initialState.list,
    {
        start: CurrencyAction.GET_CURRENCY_LIST,
        success: CurrencyAction.GET_CURRENCY_LIST_SUCCESS,
        error: CurrencyAction.GET_CURRENCY_LIST_ERROR,
    }
);

const currencyReducer = combineReducers<CurrencyState>({
    list: currencyListReducer,
});

export default currencyReducer;
