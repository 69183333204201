import { TimezonesList } from 'api/Admin/Timezones';
import {
    GetTimezonesListAction,
    TimezonesAction,
    GetTimezonesListSuccessAction,
    GetTimezonesListErrorAction,
} from './types';

export function getTimezonesList(refresh?: boolean): GetTimezonesListAction {
    return {
        type: TimezonesAction.GET_TIMEZONES_LIST,
        payload: { refresh: refresh || false },
    };
}

export function getTimezonesListSuccess(
    data: TimezonesList
): GetTimezonesListSuccessAction {
    return {
        type: TimezonesAction.GET_TIMEZONES_LIST_SUCCESS,
        payload: { data },
    };
}

export function getTimezonesListError(
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    error: any
): GetTimezonesListErrorAction {
    return {
        type: TimezonesAction.GET_TIMEZONES_LIST_ERROR,
        error,
    };
}
