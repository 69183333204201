import React, {
    useCallback,
    useMemo,
    useContext,
    useEffect,
    useState,
} from 'react';
import { useHistory } from 'react-router';
import { Form } from 'react-final-form';
import { useDispatch } from 'react-redux';

import {
    useCreateStripeCustomer,
    useMemberGroupCurrencies,
} from 'api/Admin/MemberGroup/MemberGroup.hooks';

import required from 'validators/required';
import containsLetter from 'validators/containsLetter';

import { useBillingCountryOptions } from 'koddi/hooks/useBillingCountryState';

import { FieldContainer } from 'koddi-components/Form';
import { InputField } from 'koddi-components/Input';
import { SelectField } from 'koddi-components/Select';
import FormFooter from 'koddi-components/FormFooter';
import { ErrorText } from 'koddi-components/Typography';
import LoadingSpinner from 'koddi-components/LoadingSpinner';
import { CheckboxField } from 'koddi-components/Checkbox';

import { acceptTermsAndConditions } from 'redux-core/app/termsAndConditions';
import { useTermsAndConditionsUrl } from 'redux-core/app/termsAndConditions/hooks';
import { useAuthUser } from 'redux-core/auth/hooks';
import { pathToAdvertiserRegistration } from 'modules/constants/routes';
import { useKoddiThemeResource } from 'redux-core/app/theme/hooks';

import { AdvertiserFormValues } from 'features/Registration/AdvertiserRegistration/AdvertiserRegistration.types';
import AdvertiserRegistrationContext from 'features/Registration/AdvertiserRegistration/AdvertiserRegistration.context';

import { DynamicStateField } from 'components/DynamicStateField/DynamicStateField';
import KoddiAPI from 'api';
import { TaxTypeOptions } from 'api/Advertiser';
import { BillingDisclaimer } from 'features/Admin/ClientAdvertiser/ManageAdvertiser/Payment/components/BillingAddressModel.styled';
import { useAuth0 } from 'modules/Auth0';
import { Row } from 'koddi-components/Layout';
import {
    StyledRegisterForm,
    RegisterFormSectionHeader,
    RegisterFormFieldContainer,
    TermsLink,
    TermsAndConditionsFormRow,
} from './RegisterAdvertiserForm.styled';

import { validateField } from './validations/registerAdvertiserValidations';

const RegisterAdvertiserForm = (): JSX.Element => {
    const termsAndConditionsUrl = useTermsAndConditionsUrl();
    const { ssoFeatureFlag } = useAuth0();
    const history = useHistory();
    const {
        clientName,
        setAdvertiserValues,
        memberGroupId,
        setClientSecret,
        setAdvertiserId,
    } = useContext(AdvertiserRegistrationContext);
    const theme = useKoddiThemeResource();
    const { first_name, last_name, email, locale } = useAuthUser() || {};
    const {
        createStripeCustomer,
        status: apiStatus,
        response,
        error: apiError,
    } = useCreateStripeCustomer(memberGroupId || 0);
    const dispatch = useDispatch();
    const countryOptions = useBillingCountryOptions();
    const { getCurrencies, currencies } = useMemberGroupCurrencies(
        theme?.member_group_id || 1
    );

    const currencyOptions = useMemo(() => {
        return currencies?.map((currency) => {
            return {
                value: currency.id,
                label: `${currency.symbol} - ${currency.name} (${currency.code})`,
            };
        });
    }, [currencies]);

    const [taxTypes, setTaxTypes] = useState<[TaxTypeOptions] | null>(null);
    useEffect(() => {
        const fetchTaxTypes = async () => {
            const taxTypeOptions = await KoddiAPI.Advertiser.getStripeTaxTypes();
            setTaxTypes(taxTypeOptions);
        };
        fetchTaxTypes();
    }, []);

    const formattedTaxTypes = taxTypes
        ? taxTypes.map((type: TaxTypeOptions) => {
              return {
                  value: type.name,
                  label: type.label,
              };
          })
        : null;

    const initialFormState = useMemo(
        () => ({
            currency_id: currencyOptions?.[0]?.value,
            first_name: first_name ?? first_name,
            last_name: last_name ?? last_name,
            billing_email: email ?? email,
            billing_country: 'US',
        }),
        [first_name, last_name, email, currencyOptions]
    );

    const renderTermsAndConditions = useCallback(() => {
        if (termsAndConditionsUrl) {
            return (
                <>
                    {`I accept the ${theme?.theme?.name}  `}
                    <TermsLink
                        href={termsAndConditionsUrl}
                        target="_blank"
                        rel="noreferrer"
                        data-test="register-terms-external-link"
                    >
                        Terms & Conditions
                    </TermsLink>
                </>
            );
        }
        return null;
    }, [termsAndConditionsUrl, theme?.theme?.name]);

    const onSubmit = useCallback(
        async (values: AdvertiserFormValues) => {
            const formattedAdvertiserValues = {
                ...values,
                advertiser_name: values.advertiser_name.trim(),
            };
            setAdvertiserValues(formattedAdvertiserValues);
            createStripeCustomer({
                ...formattedAdvertiserValues,
                currency_id: values?.currency_id,
                billing_name: `${values.first_name} ${values.last_name}`,
            });
        },
        [setAdvertiserValues, createStripeCustomer]
    );

    useEffect(() => {
        if (theme?.member_group_id) {
            getCurrencies();
        }
    }, [theme?.member_group_id, getCurrencies]);

    useEffect(() => {
        if (
            apiStatus === 'success' &&
            response?.client_secret &&
            response.advertiser_id
        ) {
            if (memberGroupId && locale?.id) {
                dispatch(
                    acceptTermsAndConditions(
                        memberGroupId,
                        termsAndConditionsUrl,
                        locale?.id
                    )
                );
            }
            setClientSecret(response?.client_secret);
            setAdvertiserId(response.advertiser_id);
            history.push(
                pathToAdvertiserRegistration(
                    clientName,
                    'payment',
                    response.advertiser_id
                )
            );
        }
    }, [
        apiStatus,
        history,
        clientName,
        response,
        setClientSecret,
        setAdvertiserId,
        memberGroupId,
        locale?.id,
        dispatch,
        termsAndConditionsUrl,
    ]);

    return (
        <Form
            initialValues={initialFormState}
            onSubmit={onSubmit}
            render={(renderProps) => {
                const { submitting, handleSubmit } = renderProps;
                return (
                    <>
                        {apiStatus === 'pending' ? (
                            <LoadingSpinner
                                absolutePosition
                                withOverlay
                                id="advertiser-registration-spinner"
                            />
                        ) : null}
                        <StyledRegisterForm
                            onSubmit={handleSubmit}
                            isAuth0Styled={ssoFeatureFlag}
                        >
                            <RegisterFormFieldContainer>
                                {apiError &&
                                apiError !== 'invalid tax id format' ? (
                                    <ErrorText tKey="">{`Error: ${apiError}`}</ErrorText>
                                ) : null}

                                <RegisterFormSectionHeader
                                    isAuth0Styled={ssoFeatureFlag}
                                    tKey=""
                                    text="Advertiser Settings"
                                />
                                <Row>
                                    <FieldContainer>
                                        <InputField
                                            name="advertiser_name"
                                            label="Advertiser Name"
                                            required
                                            placeholder="Enter advertiser name"
                                            validate={containsLetter}
                                        />
                                    </FieldContainer>

                                    <FieldContainer>
                                        <SelectField
                                            name="currency_id"
                                            label="Currency"
                                            placeholder="Select currency"
                                            btnStyle="rect"
                                            btnEllipsisOnOverflow
                                            dropdownMinWidth={300}
                                            options={currencyOptions}
                                            validate={required}
                                        />
                                    </FieldContainer>
                                </Row>
                            </RegisterFormFieldContainer>
                            <RegisterFormFieldContainer>
                                <RegisterFormSectionHeader
                                    isAuth0Styled={ssoFeatureFlag}
                                    tKey=""
                                    text="Billing"
                                    marginTop="30px"
                                />
                                <Row>
                                    <FieldContainer>
                                        <InputField
                                            name="first_name"
                                            label="First Name"
                                            placeholder="Enter first name"
                                            autoComplete="given-name"
                                            required
                                            validate={validateField}
                                        />
                                    </FieldContainer>
                                    <FieldContainer>
                                        <InputField
                                            name="last_name"
                                            label="Last Name"
                                            required
                                            placeholder="Enter last name"
                                            autoComplete="family-name"
                                            validate={validateField}
                                        />
                                    </FieldContainer>
                                </Row>
                                <Row>
                                    <FieldContainer>
                                        <InputField
                                            name="billing_email"
                                            label="Email Address"
                                            required
                                            placeholder="Enter email"
                                            autoComplete="email"
                                            validate={validateField}
                                            readOnly
                                        />
                                    </FieldContainer>
                                </Row>
                                <Row>
                                    <FieldContainer>
                                        <InputField
                                            name="billing_address"
                                            label="Billing Address"
                                            required
                                            placeholder="Enter street address"
                                            autoComplete="street-address"
                                            validate={validateField}
                                        />
                                    </FieldContainer>
                                </Row>
                                <Row>
                                    <FieldContainer>
                                        <InputField
                                            label="Apt/Suite"
                                            name="billing_address_line_2"
                                            placeholder="Enter apt/suite"
                                        />
                                    </FieldContainer>
                                    <FieldContainer>
                                        <InputField
                                            name="billing_city"
                                            placeholder="Enter city"
                                            required
                                            label="City"
                                            autoComplete="address-level2"
                                            validate={validateField}
                                        />
                                    </FieldContainer>
                                </Row>
                                <Row>
                                    <FieldContainer>
                                        <SelectField
                                            name="billing_country"
                                            options={countryOptions}
                                            label="Country"
                                            validate={required}
                                            isSearchable
                                        />
                                    </FieldContainer>
                                    <DynamicStateField
                                        name="billing_state"
                                        connectedCountryFieldName="billing_country"
                                    />
                                </Row>
                                <Row>
                                    <FieldContainer>
                                        <InputField
                                            name="billing_zipcode"
                                            placeholder="Enter postal code"
                                            label="Postal Code"
                                            autoComplete="postal-code"
                                            required
                                            validate={validateField}
                                            maxLength="12"
                                            hideIcons
                                        />
                                    </FieldContainer>
                                </Row>
                                {formattedTaxTypes && (
                                    <>
                                        <RegisterFormSectionHeader
                                            isAuth0Styled={ssoFeatureFlag}
                                            marginTop={30}
                                            marginBottom={0}
                                            tKey=""
                                        >
                                            Optional Tax ID
                                        </RegisterFormSectionHeader>
                                        <BillingDisclaimer>
                                            You will be purchasing ads for a
                                            business purpose. Taxes are not
                                            being collected by Koddi for this
                                            transaction. If you owe taxes, you
                                            may need to self report with your
                                            local tax authorities. We are unable
                                            to give tax advice. If you have a
                                            question about taxes, we suggest
                                            that you contact your tax advisors
                                            or local tax authorities..
                                        </BillingDisclaimer>
                                        <Row>
                                            <FieldContainer>
                                                <SelectField
                                                    name="tax_type"
                                                    options={formattedTaxTypes}
                                                    label="Tax ID Type"
                                                    isSearchable
                                                />
                                            </FieldContainer>

                                            <FieldContainer>
                                                <InputField
                                                    name="tax_id"
                                                    placeholder="Optional"
                                                    label="Tax ID Value"
                                                    hideIcons
                                                    showErrors={false}
                                                />
                                                {apiError &&
                                                apiError ===
                                                    'invalid tax id format' ? (
                                                    /* eslint-disable-next-line react/jsx-indent */
                                                    <ErrorText tKey="">{`Error: ${apiError}`}</ErrorText>
                                                ) : null}
                                            </FieldContainer>
                                        </Row>
                                    </>
                                )}
                            </RegisterFormFieldContainer>
                            <FieldContainer>
                                <TermsAndConditionsFormRow verticalSpacing="xsmall">
                                    <CheckboxField
                                        name="terms_and_conditions"
                                        id="register-terms"
                                        isRequired
                                        label={renderTermsAndConditions()}
                                        validate={required}
                                    />
                                </TermsAndConditionsFormRow>
                                <FormFooter
                                    noBorderTop={ssoFeatureFlag}
                                    submitButtonDisabled={submitting}
                                    hideCancel
                                    v2
                                />
                            </FieldContainer>
                        </StyledRegisterForm>
                    </>
                );
            }}
        />
    );
};

export default RegisterAdvertiserForm;
