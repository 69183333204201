import React from 'react';
import { createGlobalStyle, css } from 'styled-components';

const KoddiGlobalStyles = createGlobalStyle`
    ${({ theme }) => {
        const defaultFontFamily = `'Noto Sans', Arial, sans-serif`;
        const fontFamily =
            theme.typography.fontFamily === 'sans-serif'
                ? defaultFontFamily
                : theme.typography.fontFamily;
        return css`
            /** Reference: https://css-tricks.com/snippets/css/hide-scrollbar-in-edge-ie-1011/ */
            html {
                -ms-overflow-style: -ms-autohiding-scrollbar;
            }
            html,
            body,
            #root {
                width: 100%;
                height: 100%;
            }

            body {
                font-family: ${fontFamily};
                margin: 0px;
                font-size: ${theme.typography.baseFontSize};
            }

            #root,
            #root-wrapper {
                overflow: hidden;
                width: 100%;
                height: 100%;
            }

            * {
                box-sizing: border-box;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
            }

            a {
                text-decoration: none;
                color: ${theme.linkColor};
            }
            p {
                line-height: 1.2rem;
            }
            @font-face {
                font-family: 'UberMoveText';
                src: url('https://d1a3f4spazzrp4.cloudfront.net/dotcom-assets/fonts/UberMoveText-Regular.woff2')
                        format('woff2'),
                    url('https://d1a3f4spazzrp4.cloudfront.net/dotcom-assets/fonts/UberMoveText-Regular.woff')
                        format('woff');
                font-style: normal;
                font-weight: 400;
                font-display: swap;
            }
            @font-face {
                font-family: 'UberMoveText';
                src: url('https://d1a3f4spazzrp4.cloudfront.net/dotcom-assets/fonts/UberMoveText-Medium.woff2')
                        format('woff2'),
                    url('https://d1a3f4spazzrp4.cloudfront.net/dotcom-assets/fonts/UberMoveText-Medium.woff')
                        format('woff');
                font-style: normal;
                font-weight: 500;
                font-display: swap;
            }
        `;
    }}
`;

export default React.memo(KoddiGlobalStyles);
