import React, { FunctionComponent, useState, useCallback, useRef } from 'react';
import uniqueId from 'lodash/uniqueId';
import { useCancelablePromise } from 'koddi-components/hooks';
import { resolveAfter } from 'koddi-components/utils';
import {
    ToastProviderContextValue,
    ToastProviderProps,
    KoddiToast,
    KoddiToastType,
    ShowToastFunction,
} from './ToastProvider.types';
import ToastsContainer from './ToastsContainer';

export const ToastProviderContext = React.createContext<
    ToastProviderContextValue | undefined
>(undefined);

/**
 * The `ToastProvider` provides child components with ability
 * to show toast messages in the ui. Multiple toasts can be
 * show in the ui and a max number of toasts to be shown
 * can be configured in the `ToastProvider`. By default
 * a max of 3 toasts are show and each toast is visible for
 * 5 seconds.
 *
 * Example Usage:
 *
 * ```
 * const { showSuccessToast, showErrorToast } = useKoddiToast();
 *
 * const handleClick = () => {
 *     showSuccessToast('Campaign Created', 'Your campaign has been created.')
 * }
 * return (
 *     <div onClick={handleClick}>
 *          Show Toast
 *     </div>
 * );
 * ```
 */
const ToastProvider: FunctionComponent<ToastProviderProps> = ({
    children,
    maxVisibleToasts = 3,
    toastTTL = 5000,
}) => {
    const [toasts, setToasts] = useState<KoddiToast[]>([]);
    const currentToasts = useRef<KoddiToast[]>([...toasts]);
    const createRemoveToastPromise = useCancelablePromise(false, false);

    const removeToastById = useCallback((id: string) => {
        const nextToasts = [...currentToasts.current];
        const toastIndex = nextToasts.findIndex((toast) => toast.id === id);
        if (toastIndex > -1) {
            nextToasts.splice(toastIndex, 1);
            currentToasts.current = [...nextToasts];
            setToasts([...nextToasts]);
        }
    }, []);

    const handleShowToast = useCallback(
        (
            title: string,
            details: string,
            type: KoddiToastType,
            onClick?: VoidFunction
        ) => {
            const id = uniqueId('toast-');
            const newToast = { id, title, details, type, onClick };

            const maxToastsShowing = toasts.length === maxVisibleToasts;
            const nextToasts = [...currentToasts.current];
            const optimisticToasts = maxToastsShowing
                ? [...nextToasts.slice(-maxVisibleToasts + 1), newToast]
                : [...nextToasts, newToast];

            currentToasts.current = [...optimisticToasts];

            setToasts([...optimisticToasts]);

            const removeToast = () => {
                removeToastById(id);
            };

            createRemoveToastPromise(
                resolveAfter(toastTTL),
                removeToast,
                removeToast
            );
        },
        [
            toasts,
            maxVisibleToasts,
            createRemoveToastPromise,
            toastTTL,
            removeToastById,
        ]
    );

    const handleShowSuccessToast: ShowToastFunction = useCallback(
        (title, details, onClick) => {
            handleShowToast(title, details, 'success', onClick);
        },
        [handleShowToast]
    );

    const handleShowErrorToast: ShowToastFunction = useCallback(
        (title, details, onClick) => {
            handleShowToast(title, details, 'error', onClick);
        },
        [handleShowToast]
    );

    const handleShowWarningToast: ShowToastFunction = useCallback(
        (title, details, onClick) => {
            handleShowToast(title, details, 'warning', onClick);
        },
        [handleShowToast]
    );

    const value = {
        showSuccessToast: handleShowSuccessToast,
        showErrorToast: handleShowErrorToast,
        showWarningToast: handleShowWarningToast,
    };

    return (
        <ToastProviderContext.Provider value={value}>
            <ToastsContainer
                toasts={toasts}
                removeToastById={removeToastById}
            />
            {children}
        </ToastProviderContext.Provider>
    );
};

export default ToastProvider;
