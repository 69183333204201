import { ReduxActionWithPayload, ReduxAction } from 'types';
import { AlertType } from 'koddi-components/Alert';

export enum AdminAlertAction {
    SHOW_ALERT = 'admin/alert/SHOW_ALERT',
    CLEAR_ALERT = 'admin/alert/CLEAR_ALERT',
}

export type ShowAdminAlertPayload = {
    message: string;
    type: AlertType;
};

export type ShowAdminAlertAction = ReduxActionWithPayload<
    AdminAlertAction.SHOW_ALERT,
    ShowAdminAlertPayload
>;

export type ClearAdminAlertAction = ReduxAction<AdminAlertAction.CLEAR_ALERT>;

export type AdminAlertActions = ShowAdminAlertAction | ClearAdminAlertAction;

export type AdminAlertState = {
    message: string | null;
    type: AlertType | null;
};
