import React, { FunctionComponent } from 'react';
import { BaseSVGProps } from '../icon.types';

const ExclamationIcon: FunctionComponent<BaseSVGProps> = ({
    width,
    height,
}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 24 24"
    >
        <path d="M12 24c6.6 0 12-5.4 12-12S18.6 0 12 0 0 5.4 0 12s5.3 12 12 12zM10.4 4.4h3.3v8.7h-3.3V4.4zM12 15.3c1.2 0 2.2 1 2.2 2.2s-1 2.2-2.2 2.2c-1.2 0-2.2-1-2.2-2.2 0-1.3 1-2.2 2.2-2.2z" />
    </svg>
);

export default ExclamationIcon;
