import styled, { css } from 'styled-components';
import Icon from 'koddi-components/Icon';
import BaseModal from '../BaseModal';

export const ConfirmationModalBase = styled(BaseModal)<any>`
    ${({ displayVertically, v2, theme: { white, spaceUnit } }) => css`
        width: 375px;
        background: ${white};
        padding: ${displayVertically ? spaceUnit * 7 : spaceUnit * 6}px;
        box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.3);
        &:focus {
            outline: none;
        }
        ${v2 ? 'border-radius: 4px;' : ''}
    `}
`;

export const ConfirmationModalHeading = styled.h3<any>`
    ${({ displayVertically, theme: { spaceUnit } }) => css`
        margin: ${displayVertically
            ? `${spaceUnit * 1}px 0px ${spaceUnit * 1}px 0px`
            : `0px 0px ${spaceUnit * 5}px 0px`};
        font-weight: ${displayVertically ? 'bold' : 'normal'};
        font-size: 16px;
        text-align: ${displayVertically ? 'center' : undefined};
    `}
`;

export const ConfirmationModalMessage = styled.div<any>`
    ${({ displayVertically, theme: { spaceUnit } }) => css`
        margin: ${displayVertically
            ? `0px 0px ${spaceUnit * 6}px 0px`
            : `0px 0px ${spaceUnit * 5}px 0px`};
        text-align: ${displayVertically ? 'center' : undefined};
    `}
`;

export const ConfirmationModalIcon = styled(Icon)<{ iconPadding?: string }>`
    ${({ iconPadding, theme: { error, spaceUnit } }) => css`
        color: ${error};
        display: flex;
        justify-content: center;
        padding: ${iconPadding ||
        `${spaceUnit * 5}px 0px ${spaceUnit * 3}px 0px`};
    `}
`;
