import React, { FunctionComponent } from 'react';
import remove from 'lodash/remove';
import flatMap from 'lodash/flatMap';
import {
    components,
    InputProps,
    MenuProps,
    OptionProps,
    ControlProps,
    ValueContainerProps,
    GroupProps,
} from 'react-select';
import CheckboxIcon from 'koddi-components/Icon/icons/checkbox';
import Icon from 'koddi-components/Icon';
import { BodyText } from 'koddi-components/Typography';
import { isGroupedOptions } from 'koddi-components/utils';
import StatusDot from 'koddi-components/StatusDot/StatusDot';
import {
    Control,
    ControlAction,
    ControlActions,
    ControlContainer,
    ControlInnerContainer,
    Group,
    GroupHeadingOption,
    Input,
    Menu,
    MultiOptionWrapper,
    Option,
    OptionInner,
    OptionWrapper,
    StatusOptionWrapper,
    ValueContainer,
} from './select.styled';

export const MenuComponent: FunctionComponent<MenuProps<any>> = ({
    children,
    ...rest
}) => {
    return (
        <Menu>
            <components.Menu {...rest}>{children}</components.Menu>
        </Menu>
    );
};

export const InputComponent: FunctionComponent<InputProps> = (props) => {
    return (
        <Input>
            <components.Input {...props} />
        </Input>
    );
};

export const GroupComponent: FunctionComponent<GroupProps<any>> = (props) => {
    let icon;
    const {
        children,
        label,
        options,
        setValue,
        selectProps,
        getValue,
        isMulti,
    } = props;
    const { getOptionValue = (o) => o.value } = selectProps;
    const allSelected = options.every((option) => option.isSelected);
    const someSelected = options.some((option) => option.isSelected);

    const toggleSelectAll = () => {
        const currentValue = getValue() || [];

        if (allSelected) {
            const optionsToRemove = options.map(({ data: option }) =>
                getOptionValue(option)
            );
            const newValue = remove(
                currentValue,
                (option) =>
                    optionsToRemove.indexOf(getOptionValue(option)) === -1
            );
            setValue(newValue, 'set-value');
        } else {
            setValue(
                currentValue.concat(options.map(({ data }) => data)),
                'set-value'
            );
        }
    };

    if (allSelected) {
        icon = (
            <CheckboxIcon
                height={12}
                width={12}
                checked
                onClick={toggleSelectAll}
            />
        );
    } else if (someSelected) {
        icon = (
            <Icon
                icon="minusbox"
                height={12}
                width={12}
                onClick={toggleSelectAll}
            />
        );
    } else {
        icon = (
            <CheckboxIcon
                height={12}
                width={12}
                checked={false}
                onClick={toggleSelectAll}
            />
        );
    }

    return (
        <Group>
            <div>
                <GroupHeadingOption>
                    {isMulti ? (
                        <Option onClick={toggleSelectAll}>
                            {icon}
                            <OptionInner>{label}</OptionInner>
                        </Option>
                    ) : (
                        <BodyText tKey="">{label?.toString()}</BodyText>
                    )}
                </GroupHeadingOption>
                <div>{children}</div>
            </div>
        </Group>
    );
};

export const GroupHeaderComponent: FunctionComponent<any> = () => {
    return null;
};

export const MultiOptionComponent: FunctionComponent<OptionProps<any>> = (
    props
) => {
    const { isSelected, children } = props;
    return (
        <MultiOptionWrapper isSelected={isSelected}>
            <components.Option {...props} isSelected={isSelected}>
                <Option>
                    <CheckboxIcon height={12} width={12} checked={isSelected} />
                    <OptionInner>{children}</OptionInner>
                </Option>
            </components.Option>
        </MultiOptionWrapper>
    );
};

export const OptionComponent: FunctionComponent<OptionProps<any>> = ({
    isSelected,
    children,
    ...rest
}) => {
    return (
        <OptionWrapper isSelected={isSelected}>
            <components.Option {...rest} isSelected={isSelected}>
                <Option>{children}</Option>
            </components.Option>
        </OptionWrapper>
    );
};

export const StatusOptionComponent: FunctionComponent<any> = (props) => {
    const { value, isSelected, children } = props;
    return (
        <StatusOptionWrapper statusValue={value} isSelected={isSelected}>
            <components.Option {...props} isSelected={isSelected}>
                <Option>
                    <StatusDot
                        statusValue={value}
                        marginLeft={-18}
                        marginRight={8}
                    />
                    {children}
                </Option>
            </components.Option>
        </StatusOptionWrapper>
    );
};

export const CellOptionComponent: FunctionComponent<any> = (props) => {
    const { value, isSelected, children } = props;
    return (
        <StatusOptionWrapper statusValue={value} isSelected={isSelected}>
            <components.Option {...props} isSelected={isSelected}>
                <Option>{children}</Option>
            </components.Option>
        </StatusOptionWrapper>
    );
};

export const MultiControlComponent: FunctionComponent<ControlProps<any>> = (
    props
) => {
    const {
        options,
        selectProps: { isSearchable },
    } = props;
    function selectAll() {
        props.setValue(
            isGroupedOptions(options) ? flatMap(options, 'options') : options,
            'set-value'
        );
    }

    function deselectAll() {
        props.clearValue();
    }

    if (!isSearchable) return null;

    return (
        <Control>
            <ControlContainer isSearchable={isSearchable}>
                <Icon icon="magnifier" width={14} height={14} />
                <ControlInnerContainer>
                    <components.Control {...props} />
                </ControlInnerContainer>
            </ControlContainer>
            <ControlActions>
                <ControlAction onClick={selectAll}>Select All</ControlAction>
                <ControlAction onClick={deselectAll}>Clear</ControlAction>
            </ControlActions>
        </Control>
    );
};

export const ControlComponent: FunctionComponent<ControlProps<any>> = (
    props
) => {
    const {
        selectProps: { isSearchable = false },
    } = props;
    if (isSearchable) {
        return (
            <Control>
                <ControlContainer>
                    <Icon icon="magnifier" width={14} height={14} />
                    <ControlInnerContainer>
                        <components.Control {...props} />
                    </ControlInnerContainer>
                </ControlContainer>
            </Control>
        );
    }
    return (
        <Control isHidden isSearchable={isSearchable}>
            <components.Control {...props} />
        </Control>
    );
};

export const ValueContainerComponent: FunctionComponent<ValueContainerProps<
    any
>> = ({ children, ...props }) => (
    <ValueContainer>
        <components.ValueContainer {...props}>
            {children}
        </components.ValueContainer>
    </ValueContainer>
);
