/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { AxiosInstance } from 'axios';
import { KoddiAPIResponse } from 'types';
import { TemplateOverviewType } from './TemplateOverview.types';

class TemplateOverviewAPI {
    protected axios: AxiosInstance;

    constructor(axios: AxiosInstance) {
        this.axios = axios;
    }

    /* Get Active Templates by memberGroupId */
    public getActiveTemplates = async (
        memberGroupId: number
    ): Promise<TemplateOverviewType[]> => {
        const response = await this.axios.get(
            `v1/member_groups/${memberGroupId}/templates/active_templates`
        );
        return response.data.result;
    };

    /* Get Inactive Templates by memberGroupId */
    public getInactiveTemplates = async (
        memberGroupId: number
    ): Promise<any> => {
        const response = await this.axios.post(
            `v1/member_groups/${memberGroupId}/templates/non_active_templates_report`,
            {}
        );
        return response.data.result.campaign_templates;
    };

    /** Deletes the given template. */
    public deleteTemplate = async ({
        memberGroupId,
        campaignTemplateId,
    }: Record<string, number>): Promise<string> => {
        const response = await this.axios.delete<KoddiAPIResponse<any>>(
            `/v1/member_groups/${memberGroupId}/templates/${campaignTemplateId}`
        );

        return response.data.result;
    };

    /* Create Template Text Match Options */
    public updateTemplate = async ({
        memberGroupId,
        campaignTemplateId,
        payload,
    }: Record<string, any>) => {
        const response = await this.axios.patch(
            `/v1/member_groups/${memberGroupId}/templates/${campaignTemplateId}`,
            payload
        );
        if (response.data.status === 'success') return true;
        return false;
    };
}
export default TemplateOverviewAPI;
