import { combineReducers, Reducer, AnyAction } from 'redux';
import produce from 'immer';

import { AuthAction, LogoutSuccessAction } from 'redux-core/auth/types';

import { AppState } from './types';
import { themeReducer, themeInitialState } from './theme';
import {
    appAdvertisersInitialState,
    appAdvertisersReducer,
} from './advertisers';
import { appFiltersInitialState, appFiltersReducer } from './filters';
import { appConfigsInitialState, appConfigsReducer } from './configs';
import { appEntitiesInitialState, appEntitiesReducer } from './entities';
import {
    termsAndConditionsInitialState,
    termsAndConditionsReducer,
} from './termsAndConditions';
import { dashboardState, dashboardReducer } from './dashboard';
import { appContextReducer, appContextInitialState } from './context';
import { routeMatchReducer, routeMatchInitialState } from './routeMatch';
import { setupAppReducer, setupAppInitialState } from './setup';
import { appMessagesReducer, appMessagesInitialState } from './messages';

export const appInitialState: AppState = {
    advertisers: appAdvertisersInitialState,
    dashboard: dashboardState,
    entities: appEntitiesInitialState,
    configs: appConfigsInitialState,
    context: appContextInitialState,
    filters: appFiltersInitialState,
    messages: appMessagesInitialState,
    setup: setupAppInitialState,
    termsAndConditions: termsAndConditionsInitialState,
    theme: themeInitialState,
    routeMatch: routeMatchInitialState,
};

const appReducer = combineReducers<AppState>({
    advertisers: appAdvertisersReducer,
    dashboard: dashboardReducer,
    entities: appEntitiesReducer,
    configs: appConfigsReducer,
    context: appContextReducer,
    filters: appFiltersReducer,
    messages: appMessagesReducer,
    setup: setupAppReducer,
    termsAndConditions: termsAndConditionsReducer,
    theme: themeReducer,
    routeMatch: routeMatchReducer,
});

const appRootReducer = (
    state: AppState,
    action: LogoutSuccessAction
): AppState =>
    produce<AppState>(state, () => {
        switch (action.type) {
            case AuthAction.LOGOUT_SUCCESS: {
                return {
                    ...appInitialState,
                    theme: state.theme,
                };
            }
            default: {
                return appReducer(state, action);
            }
        }
    });

export default appRootReducer as Reducer<AppState, AnyAction>;
