/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import KoddiAPI from 'api';
import { takeEvery, call, all, put } from 'redux-saga/effects';
import SagaRegistry from 'redux-core/sagaRegistry';
import {
    fetchEntityAttributesSuccess,
    fetchEntityAttributesError,
} from './actions';

import { EntityAttributesAction, FetchEntityAttributesAction } from './types';

export function* fetchEntityAttributesSaga(
    action: FetchEntityAttributesAction
) {
    try {
        /* @ts-ignore */
        const response = yield call(
            KoddiAPI.Entities.getEntityAttributes,
            action.payload.memberGroupId
        );
        yield put(fetchEntityAttributesSuccess(response));
    } catch (e) {
        yield put(fetchEntityAttributesError(e.error.message));
    }
}

export function* watchConfig() {
    yield takeEvery(
        EntityAttributesAction.FETCH_ENTITY_ATTRIBUTES,
        fetchEntityAttributesSaga
    );
}

function* configSagas() {
    yield all([watchConfig()]);
}

SagaRegistry.registerSaga(configSagas);
