import React, { FunctionComponent } from 'react';
import Modal, { Props as ModalProps } from 'react-modal';
import { useKoddiTheme } from 'koddi-components/ThemeProvider';

const BaseModal: FunctionComponent<ModalProps> = (props) => {
    const { zIndex } = useKoddiTheme();
    return (
        <Modal
            {...props}
            closeTimeoutMS={300}
            style={{
                overlay: {
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: zIndex.modal,
                    background: `rgba(0, 0, 0, 0.7)`,
                },
            }}
            data={{
                test: 'modal',
            }}
        />
    );
};

export default BaseModal;
