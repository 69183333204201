import React, { FunctionComponent } from 'react';
import { BaseSVGProps } from '../icon.types';

const PieChart: FunctionComponent<BaseSVGProps> = ({ width, height }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        height={height}
        width={width}
        viewBox="-3 -4 20 24"
    >
        <path
            d="M-6451-12620.5a7.514,7.514,0,0,1,7-7.483v7.983h7.984a7.515,7.515,0,0,1-7.483,7A7.508,7.508,0,0,1-6451-12620.5Zm8-.5v-7.984c.165-.009.33-.016.5-.016a7.5,7.5,0,0,1,7.5,7.5c0,.168-.006.336-.019.5Z"
            transform="translate(6451.001 12629)"
        />
    </svg>
);

export default PieChart;
