import styled, { css } from 'styled-components';
import Button from 'koddi-components/Button';
import { HorizontalFlexBox } from 'koddi-components/FlexBox';

export const PageContainer = styled.div`
    ${({ theme }) => css`
        height: 100%;
        width: 100%;
        padding: ${(theme.spaceUnit ?? 1) * 8}px;
    `}
`;
export const PageHeader = styled.div`
    ${({ theme }) => css`
        margin-bottom: ${(theme.spaceUnit ?? 1) * 8}px;
    `}
`;
export const Row = styled(HorizontalFlexBox)`
    gap: 16px;
    @media (max-width: 768px) {
        flex-direction: column;
        > * {
            width: 100% !important;
        }
    }
`;

export const PageTitle = styled.h1<{
    align?: 'left' | 'center' | 'right';
    removeMargin?: boolean;
}>`
    ${({ align, removeMargin }) => css`
        text-align: ${align || 'left'};
        ${removeMargin ? `margin:0;` : null}
    `}
    font-weight: normal;
`;

export const PageSubTitle = styled.h2`
    font-weight: bold;
`;

export const PageSectionTitle = styled.h3`
    font-weight: bold;
    margin-top: 0;
`;

export const PageAction = styled(Button)``;

export const PageContent = styled.div``;

export const PageText = styled.p<{ align?: 'left' | 'center' | 'right' }>`
    ${({ align }) => css`
        text-align: ${align || 'left'};
    `}
`;
