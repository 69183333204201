import { ReduxActionWithPayload, ReduxAction } from 'types';
import { KoddiThemeBase } from 'koddi-components/ThemeProvider';

export type KoddiThemeResource = {
    theme: KoddiThemeBase;
    id?: number;
    member_group_id: number;
    created_at?: string;
    last_edited_at?: string;
    last_edited_by?: number; // user id of last user to update
    deleted?: boolean;
    is_default?: boolean;
    registration_enabled?: boolean;
    sso_organization_id?: string;
    sso_connection_id?: string;
};

export type ThemeError = {
    memberGroupId: number;
    message?: string;
};

export type ThemeState = {
    theme: KoddiThemeResource;
    themes: KoddiThemeResource[];
    status: string;
    error: ThemeError | null;
    initialized: boolean;
    registration_enabled: boolean;
    sso_organization_id?: string;
    sso_connection_id?: string;
};

export enum ThemeAction {
    CHANGE_THEME = 'app/theme/CHANGE_THEME',
    CREATE_THEME = 'app/theme/CREATE_THEME',
    DELETE_THEME = 'app/theme/DELETE_THEME',
    GET_THEME = 'app/theme/GET_THEME',
    UPDATE_THEME = 'app/theme/UPDATE_THEME',
    GET_THEME_BY_MEMBER_GROUP_ID = 'app/theme/GET_THEME_BY_MEMBER_GROUP_ID',
    GET_THEME_BY_MEMBER_GROUP_HOSTNAME = 'app/theme/GET_THEME_BY_MEMBER_GROUP_HOSTNAME',
    GET_THEME_LIST = 'app/theme/GET_THEME_LIST',
    GET_THEME_LIST_SUCCESS = 'app/theme/GET_THEME_LIST_SUCCESS',
    GET_THEME_LIST_ERROR = 'app/theme/GET_THEME_LIST_ERROR',
    SET_THEME_LIST = 'app/theme/SET_THEME_LIST',
    SET_THEME_STATUS = 'app/theme/SET_THEME_STATUS',
    THEME_ERROR = 'app/theme/THEME_ERROR',
    SET_REGISTRATION_ENABLED = 'app/theme/SET_REGISTRATION_ENABLED',
}

export type GetThemeAction = ReduxActionWithPayload<ThemeAction.GET_THEME>;

export type ChangeThemeAction = ReduxActionWithPayload<
    ThemeAction.CHANGE_THEME,
    { theme: KoddiThemeResource }
>;

export type UpdateThemeAction = ReduxActionWithPayload<
    ThemeAction.UPDATE_THEME,
    {
        theme: KoddiThemeResource;
        onSuccess?: () => void;
        onFailure?: () => void;
    }
>;

export type CreateThemeAction = ReduxActionWithPayload<
    ThemeAction.CREATE_THEME,
    {
        theme: KoddiThemeResource;
        onSuccess?: () => void;
        onFailure?: () => void;
    }
>;

export type DeleteThemeAction = ReduxActionWithPayload<
    ThemeAction.DELETE_THEME,
    {
        theme: KoddiThemeResource;
        onSuccess?: () => void;
        onFailure?: () => void;
    }
>;

export type SetThemeStatus = ReduxActionWithPayload<
    ThemeAction.SET_THEME_STATUS,
    {
        status: string;
    }
>;

export type SetRegistrationEnabled = ReduxActionWithPayload<
    ThemeAction.SET_REGISTRATION_ENABLED,
    {
        registrationEnabled: boolean;
    }
>;

export type ThemeErrorAction = ReduxActionWithPayload<
    ThemeAction.THEME_ERROR,
    {
        error: ThemeError;
    }
>;

export type GetThemeByMemberGroupIdAction = ReduxActionWithPayload<
    ThemeAction.GET_THEME_BY_MEMBER_GROUP_ID
>;

export type GetThemeByMemberGroupHostname = ReduxActionWithPayload<
    ThemeAction.GET_THEME_BY_MEMBER_GROUP_HOSTNAME,
    {
        hostname: string;
    }
>;

export type GetThemeListAction = ReduxAction<ThemeAction.GET_THEME_LIST>;

export type SetThemeListAction = ReduxActionWithPayload<
    ThemeAction.SET_THEME_LIST,
    { themes: KoddiThemeResource[] }
>;
export type ThemeActions =
    | GetThemeAction
    | ChangeThemeAction
    | CreateThemeAction
    | UpdateThemeAction
    | GetThemeListAction
    | GetThemeByMemberGroupIdAction
    | SetThemeListAction
    | SetThemeStatus
    | ThemeErrorAction
    | DeleteThemeAction
    | GetThemeByMemberGroupHostname
    | SetRegistrationEnabled;
