export const STATUS_ACTIVE = 'active';
export const STATUS_ENDED = 'ended';
export const STATUS_PAUSED = 'paused';
export const STATUS_PENDING = 'pending';
export const STATUS_INACTIVE = 'inactive';
export const STATUS_LOCKED = 'locked';
export const STATUS_DEACTIVATED = 'deactivated';
export const STATUS_OPTIONS = [
    { value: STATUS_ACTIVE, label: 'Active' },
    { value: STATUS_ENDED, label: 'Ended' },
    { value: STATUS_PAUSED, label: 'Paused' },
    { value: STATUS_PENDING, label: 'Pending' },
    { value: STATUS_LOCKED, label: 'Locked' },
    { value: STATUS_DEACTIVATED, label: 'Deactivated' },
];
