import CachedKoddiAPI from 'api/api.cachedObject';
import { KoddiAPIResponse, Channel } from 'types';
import { IndustryList } from './Industry.types';

class KoddiIndustryAPI extends CachedKoddiAPI {
    getList = async (): Promise<IndustryList> => {
        const response = await this.cache.makeCachedRequest(
            'getList',
            '',
            () => {
                return this.axios.get<KoddiAPIResponse<IndustryList>>(
                    `v1/industries`
                );
            }
        );
        return response.data.result;
    };

    getChannels = async (industryId: number): Promise<Channel[]> => {
        const response = await this.axios.get<
            KoddiAPIResponse<{ channels: Channel[] }>
        >(`v1/industries/${industryId}/channels`);
        return response.data.result.channels;
    };
}

export default KoddiIndustryAPI;
