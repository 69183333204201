import { KoddiAPIResponse } from 'types';
import KoddiObjectAPI from '../../api.object';
import {
    RolePermissionList,
    RolePermission,
    CreateRolePermissionInput,
} from './RolePermissions.types';

const KODDI_ROLE_PERMISSIONS_URL = '/role_permissions';

class RolePermissionsAPI extends KoddiObjectAPI {
    public create = async (
        newRolePermission: CreateRolePermissionInput
    ): Promise<RolePermission> => {
        const response = await this.axios.post<
            KoddiAPIResponse<RolePermission>
        >(`v1${KODDI_ROLE_PERMISSIONS_URL}`, newRolePermission);
        return response.data.result;
    };

    public get = async (): Promise<RolePermission> => {
        const response = await this.axios.get<KoddiAPIResponse<RolePermission>>(
            `v1${KODDI_ROLE_PERMISSIONS_URL}`
        );
        return response.data.result;
    };

    public update = async (): Promise<RolePermission> => {
        const response = await this.axios.put<KoddiAPIResponse<RolePermission>>(
            `v1${KODDI_ROLE_PERMISSIONS_URL}`
        );
        return response.data.result;
    };

    public delete = async (
        id: RolePermission['id']
    ): Promise<RolePermission> => {
        const response = await this.axios.delete<
            KoddiAPIResponse<RolePermission>
        >(`v1${KODDI_ROLE_PERMISSIONS_URL}/${id}`);
        return response.data.result;
    };

    public getList = async (): Promise<RolePermissionList> => {
        const response = await this.axios.get<
            KoddiAPIResponse<RolePermissionList>
        >(`v1${KODDI_ROLE_PERMISSIONS_URL}`);
        return response.data.result;
    };
}

export default RolePermissionsAPI;
