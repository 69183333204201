import { Reducer, Store } from 'redux';
import { Saga } from 'redux-saga';
import { OutputSelector } from 'reselect';
import { RouterState } from 'connected-react-router';
import { SagaInjectionModes } from 'redux-injectors';
import { AdminState } from 'redux-core/admin';
import { AppState } from 'redux-core/app';
import { AuthState } from 'redux-core/auth';

export interface InjectedStore extends Store {
    injectedReducers: any;
    injectedSagas: any;
    runSaga(saga: Saga<any[]> | undefined, args: any | undefined): any;
}

export interface InjectReducerParams {
    key: keyof ApplicationRootState;
    reducer: Reducer<any, any>;
}

export interface InjectSagaParams {
    key: keyof ApplicationRootState;
    saga: Saga;
    mode?: SagaInjectionModes;
}

/**
 * The Shape of the root reducer i.e. the global application state.
 */
export interface ApplicationRootState {
    readonly app: AppState;
    readonly auth: AuthState;
    readonly admin: AdminState;
    readonly router: RouterState;
}

export type ApplicationOutputSelector<R, C> = OutputSelector<
    ApplicationRootState,
    R,
    C
>;

export enum ErrorCauses {
    Auth0 = 'auth0',
}
