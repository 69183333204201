import React, { FunctionComponent } from 'react';
import { BaseSVGProps } from '../icon.types';

const TriangleDownIcon: FunctionComponent<BaseSVGProps> = ({
    width,
    height,
}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        height={height}
        width={width}
        viewBox="0 0 24 24"
    >
        <path d="M12 18.67L0 5.33h24z" />
    </svg>
);

export default TriangleDownIcon;
