/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import KoddiAPI from 'api';
import { createSimpleSagaWithCache } from 'utils/createSimpleSaga';
import { takeEvery, all } from 'redux-saga/effects';
import SagaRegistry from 'redux-core/sagaRegistry';
import { MemberList } from 'api/Admin/Member';
import { getMemberListError, getMemberListSuccess } from './actions';
import { MemberAction } from './types';
import { selectMemberList } from './selectors';

export const getMemberListApi = (): Promise<MemberList> =>
    KoddiAPI.Admin.Member.getList();

export const getMemberListSaga = createSimpleSagaWithCache(
    getMemberListApi,
    getMemberListSuccess,
    getMemberListError,
    selectMemberList
);

export function* watchGetMemberList() {
    yield takeEvery(MemberAction.GET_MEMBER_LIST, getMemberListSaga);
}

export function* memberSagas() {
    yield all([watchGetMemberList()]);
}

SagaRegistry.registerSaga(memberSagas);
