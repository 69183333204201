import React, {
    FunctionComponent,
    useState,
    useEffect,
    useCallback,
} from 'react';
import {
    TTabsProviderContext,
    TTabsProviderProps,
    TabIndex,
    TTabItem,
} from './Tabs.types';
import TabsHotKeys from './Tabs.hotkeys';
import { createTabItems, findTabByKey } from './Tabs.utils';

export const TabsContext = React.createContext<
    TTabsProviderContext | undefined
>(undefined);

/**
 * The `TabsProvider` provides the shared state
 * for rendering and managing tabs and panes.
 *
 * By using the `TabsProvider` in conjuction with `TabList`
 * and `TabContent` you can build custom Tab UI's
 * as well as manually dispatch tab change events in
 * child components.
 */
const TabsProvider: FunctionComponent<TTabsProviderProps> = ({
    activeTabKey,
    defaultActiveTabKey,
    onSelectTab,
    children,
    id,
    tabs,
    tabPlaceholders,
    className,
    renderPreContent = () => null,
    onTabChange,
}) => {
    const handleTabChange = useCallback(
        (tabIndex: React.ReactText, tab: TTabItem) => {
            if (onTabChange) onTabChange(tabIndex, tab);
        },
        [onTabChange]
    );
    const numberOfTabs =
        tabs?.length || React.Children.count(tabPlaceholders) || 0;

    const [currentActiveIndex, setCurrentActiveTabIndex] = useState<TabIndex>(
        defaultActiveTabKey || 0
    );

    const tabIndex =
        activeTabKey === undefined ? currentActiveIndex : activeTabKey;

    const tabItems = createTabItems(tabs, tabPlaceholders);

    function handleSelectTab(key: string | number, title: string) {
        if (onSelectTab) onSelectTab(key, title);
        setCurrentActiveTabIndex(key);
    }

    const hotKeysProps = {
        id,
        activeTabKey: tabIndex,
        onSelectTab: handleSelectTab,
        numberOfTabs,
        tabItems,
    };

    const value = {
        ...hotKeysProps,
        renderPreContent,
    };

    useEffect(() => {
        const tab =
            typeof tabIndex === 'number'
                ? tabItems[tabIndex]
                : findTabByKey(tabIndex, tabItems);
        if (tab) handleTabChange(tabIndex, tab);
    }, [handleTabChange, tabIndex, tabItems]);

    return (
        <TabsContext.Provider value={value}>
            <TabsHotKeys className={className} {...hotKeysProps}>
                {children}
            </TabsHotKeys>
        </TabsContext.Provider>
    );
};

export default TabsProvider;
