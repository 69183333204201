import {
    ApplicationRootState,
    ApplicationOutputSelector,
} from 'redux-core/types';
import { createSelector } from 'reselect';
import { RoleList } from 'api/Admin/Role';
import { AsyncState } from 'types';
import { initialState } from './reducer';
import { RoleState } from './types';
import {
    INTERNAL_ADMIN_USER_ROLE_IDS,
    CLIENT_USER_ROLE_IDS,
    ADVERTISER_USER_ROLE_IDS,
} from './constants';

export const selectAdminRole = (state: ApplicationRootState): RoleState =>
    state.admin.role || initialState;

export const selectRoleList: ApplicationOutputSelector<
    AsyncState<RoleList>,
    (res: RoleState) => AsyncState<RoleList>
> = createSelector(selectAdminRole, (state) => state.list);

export const internalAdminUserRoleList: ApplicationOutputSelector<
    AsyncState<RoleList>,
    (res: RoleState) => AsyncState<RoleList>
> = createSelector(selectAdminRole, (state) => {
    const roles =
        state.list.data?.filter((r) =>
            INTERNAL_ADMIN_USER_ROLE_IDS.includes(r.role_id)
        ) || [];
    return {
        ...state.list,
        data: roles,
    };
});

export const clientUserRoleList: ApplicationOutputSelector<
    AsyncState<RoleList>,
    (res: RoleState) => AsyncState<RoleList>
> = createSelector(selectAdminRole, (state) => {
    const roles =
        state.list.data?.filter((r) =>
            CLIENT_USER_ROLE_IDS.includes(r.role_id)
        ) || [];
    return {
        ...state.list,
        data: roles,
    };
});

export const advertiserUserRoleList: ApplicationOutputSelector<
    AsyncState<RoleList>,
    (res: RoleState) => AsyncState<RoleList>
> = createSelector(selectAdminRole, (state) => {
    const roles =
        state.list.data?.filter((r) =>
            ADVERTISER_USER_ROLE_IDS.includes(r.role_id)
        ) || [];
    return {
        ...state.list,
        data: roles,
    };
});
