import produce, { Draft } from 'immer';
import { EntityAttribute } from 'api/Entities/Entities.types';
import cuisineOptions from './cuisines';
import {
    AppConfigsState,
    EntityAttributesAction,
    EntityAttributesActions,
} from './types';

const WEEKLY_BUDGET_TYPE_OPTION = { label: 'Weekly', value: 'Weekly' };

const MONTHLY_BUDGET_TYPE_OPTION = { label: 'Monthly', value: 'Monthly' };

const CUSTOM_BUDGET_TYPE_OPTION = { label: 'Custom', value: 'Custom' };

const DAILY_BUDGET_TYPE_OPTION = { label: 'Daily', value: 'Daily' };

// The time period against which Budget is allocated to a given Media Plan
export const baseBudgetTypeOptions = [
    DAILY_BUDGET_TYPE_OPTION,
    WEEKLY_BUDGET_TYPE_OPTION,
    MONTHLY_BUDGET_TYPE_OPTION,
];

const campaignBudgetTypeOptions = [
    ...baseBudgetTypeOptions,
    CUSTOM_BUDGET_TYPE_OPTION,
];

const adGroupBudgetTypeOptions = [
    ...baseBudgetTypeOptions,
    CUSTOM_BUDGET_TYPE_OPTION,
];

// ROAS will be the only option for MVP.
const goalTypeOptions = [{ label: 'ROAS', value: 'ROAS' }];

const NO_CHANGE_STATUS_OPTION = { label: 'No Change', value: '' };
// A dimension object has a Start Date in the future
export const PENDING_STATUS_OPTION = { label: 'Pending', value: 'pending' };
// A dimension object has reached its Start Date but has not reached its End Date
export const ACTIVE_STATUS_OPTION = { label: 'Active', value: 'active' };
export const INACTIVE_STATUS_OPTION = { label: 'Inactive', value: 'inactive' };
export const INVALID_STATUS_OPTION = { label: 'Invalid', value: 'invalid' };
//  A dimension object has reached its Start Date, but has been manually set to a status of Paused
export const PAUSED_STATUS_OPTION = { label: 'Paused', value: 'paused' };
// A given dimension object has reached its End Date and can no longer spend
export const ENDED_STATUS_OPTION = { label: 'Ended', value: 'ended' };
// A given dimension object has been deleted by the user
export const ARCHIVED_STATUS_OPTION = { label: 'Archived', value: 'archived' };

const DISABLED_STATUS_OPTION = { label: 'Disabled', value: 'disabled' };

export const baseStatusOptions = [
    PENDING_STATUS_OPTION,
    ACTIVE_STATUS_OPTION,
    PAUSED_STATUS_OPTION,
    ENDED_STATUS_OPTION,
    ARCHIVED_STATUS_OPTION,
];

const editCampaignStatusOptions = [
    ACTIVE_STATUS_OPTION,
    PENDING_STATUS_OPTION,
    PAUSED_STATUS_OPTION,
];
const editClientStatusOptions = [ACTIVE_STATUS_OPTION, PAUSED_STATUS_OPTION];

const createCampaignStatusOptions = [
    ACTIVE_STATUS_OPTION,
    PENDING_STATUS_OPTION,
];

const editAdGroupStatusOptions = [
    ACTIVE_STATUS_OPTION,
    PAUSED_STATUS_OPTION,
    PENDING_STATUS_OPTION,
];

const createAdGroupStatusOptions = [PENDING_STATUS_OPTION];

const userStatusOptions = [ACTIVE_STATUS_OPTION, INACTIVE_STATUS_OPTION];

const advertiserStatusOptions = [
    PENDING_STATUS_OPTION,
    ACTIVE_STATUS_OPTION,
    INACTIVE_STATUS_OPTION,
];

// only relevant for Media Strategies, and indicates that a given Media Strategy has not been enabled for a given Media Plan/Line Item (i.e. toggle is set to Off)
const mediaStrategyStatusOptions = [
    ...baseStatusOptions,
    DISABLED_STATUS_OPTION,
];

// Pacing controls how much Budget can be spent over time. The setting lives at the Campaign level and controls all the Ad Groups within that campaign.
const basePacingOptions = [
    { label: 'ASAP', value: 'ASAP' },
    { label: 'Evenly', value: 'Evenly' },
];

const fontFamilyOptions = [
    {
        label: 'Uber',
        value: `'UberMoveText', sans-serif`,
    },
    {
        label: 'Koddi default',
        value: `'Noto Sans', sans-serif`,
    },
];

// Allows a user to select whether they want to target New Customers or Existing Customers
const userHistoryOptions = [
    { label: 'All customers', value: 'all' },
    { label: 'New customers', value: 'new' },
];

// These will eventually be supplied by Uber
const orderValueOptions = [
    { label: 'Large Orders', value: 'large' },
    { label: 'Medium Orders', value: 'medium' },
    { label: 'Small Orders', value: 'small' },
];

const bulkEditStatusOptions = [
    NO_CHANGE_STATUS_OPTION,
    ACTIVE_STATUS_OPTION,
    PAUSED_STATUS_OPTION,
];

const statusTableCellOptions = baseStatusOptions;

export const initialState: AppConfigsState = {
    options: {
        budgetTypeOptions: {
            mediaPlan: baseBudgetTypeOptions,
            mediaStrategy: baseBudgetTypeOptions,
            campaign: campaignBudgetTypeOptions,
            adGroup: adGroupBudgetTypeOptions,
        },
        goalTypeOptions: {
            campaign: goalTypeOptions,
        },
        pacingOptions: {
            campaign: basePacingOptions,
            adGroup: basePacingOptions,
        },
        statusOptions: {
            mediaStrategy: mediaStrategyStatusOptions,
            editCampaign: editCampaignStatusOptions,
            createCampaign: createCampaignStatusOptions,
            editAdGroup: editAdGroupStatusOptions,
            createAdGroup: createAdGroupStatusOptions,
            bulkEdit: bulkEditStatusOptions,
            tableCell: statusTableCellOptions,
            editClient: editClientStatusOptions,
        },
        cuisineOptions,
        userHistoryOptions,
        orderValueOptions,
        userStatusOptions,
        advertiserStatusOptions,
    },
    styles: {
        fontFamilyOptions,
    },
    tableColumns: {
        entityTableColumns: {
            data: {
                attributes: [],
                minified_attributes: [],
            },
            status: 'idle',
        },
    },
};

const attributeFormatter = (data: EntityAttribute[] | null) => {
    const result = data?.map((attribute: EntityAttribute) => {
        return {
            Header: attribute.label,
            accessor: attribute.key,
            order: attribute.order,
            isInternalId: attribute.is_internal_id,
            isName: attribute.is_name,
        };
    });
    return result;
};

const appConfigsReducer = (
    state: AppConfigsState = initialState,
    action: EntityAttributesActions
): AppConfigsState =>
    produce(state, (draft: Draft<AppConfigsState>) => {
        switch (action.type) {
            case EntityAttributesAction.FETCH_ENTITY_ATTRIBUTES: {
                draft.tableColumns.entityTableColumns.status = 'pending';
                break;
            }
            case EntityAttributesAction.FETCH_ENTITY_ATTRIBUTES_SUCCESS: {
                const formatAttributes = attributeFormatter(
                    action.payload.data.attributes
                );

                const formatMinifiedAttributes = attributeFormatter(
                    action.payload.data.minified_attributes
                );
                draft.tableColumns.entityTableColumns.data = {
                    attributes: formatAttributes || [],
                    minified_attributes: formatMinifiedAttributes || [],
                };
                draft.tableColumns.entityTableColumns.status = 'success';
                break;
            }
            case EntityAttributesAction.FETCH_ENTITY_ATTRIBUTES_ERROR:
                draft.tableColumns.entityTableColumns.status = 'failure';
                break;
            default:
                break;
        }
    });

export default appConfigsReducer;
