import React, { FunctionComponent } from 'react';
import { BaseSVGProps } from '../icon.types';

const PerformanceArrow: FunctionComponent<BaseSVGProps> = ({
    width,
    height,
}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 24 24"
    >
        <path
            d="M24 24V9.17l-4-4.32v12.27L2.83 0 0 2.83 17.12 20h-12l4.05 4z"
            fill="#d44333"
        />
    </svg>
);

export default PerformanceArrow;
