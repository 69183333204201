import React, { FunctionComponent } from 'react';
import { BaseSVGProps } from '../icon.types';

const PlacePinIcon: FunctionComponent<BaseSVGProps> = ({ height, width }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        height={height}
        width={width}
        viewBox="0 0 24 24"
    >
        <path d="M19 2.9a9.89 9.89 0 00-14 0A10 10 0 005 17l7 7 7-7.1a9.9 9.9 0 000-14zM12 12a2.09 2.09 0 112.09-2.1A2.1 2.1 0 0112 12z" />
    </svg>
);

export default PlacePinIcon;
