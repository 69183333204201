import { FunctionComponent } from 'react';
import { useDocumentTitle } from 'koddi-components/hooks';
import { DocumentTitleProps } from './DocumentTitle.types';

const DocumentTitle: FunctionComponent<DocumentTitleProps> = ({
    title,
}): null => {
    useDocumentTitle(title);
    return null;
};

export default DocumentTitle;
