import { UpdateKoddiUserInput } from './User.types';

function flattenIds(roleObject: any): number {
    if (typeof roleObject === 'object' && roleObject.id !== undefined) {
        return roleObject.id;
    }
    return roleObject;
}

export function formatUser(user: UpdateKoddiUserInput): UpdateKoddiUserInput {
    const formattedUser = { ...user };
    (formattedUser.roles as any) = user.roles?.map((role) => {
        return {
            ...role,
            access: {
                advertisers: role.access.advertisers
                    ? role.access.advertisers.map(flattenIds)
                    : [],
                advertiser_groups: role.access.advertiser_groups
                    ? role.access.advertiser_groups.map(flattenIds)
                    : [],
                clients: role.access.clients
                    ? role.access.clients.map(flattenIds)
                    : [],
            },
        };
    });
    return formattedUser;
}
