import {
    ReduxActionWithError,
    ReduxActionWithPayload,
    ReduxActionWithRefresh,
    AsyncState,
} from 'types';
import { IndustryList } from 'api/Admin/Industry';

export enum IndustryAction {
    GET_INDUSTRY_LIST = 'admin/industry/GET_INDUSTRY_LIST',
    GET_INDUSTRY_LIST_SUCCESS = 'admin/industry/GET_INDUSTRY_LIST_SUCCESS',
    GET_INDUSTRY_LIST_ERROR = 'admin/industry/GET_INDUSTRY_LIST_ERROR',
}

export type GetIndustryListAction = ReduxActionWithRefresh<
    IndustryAction.GET_INDUSTRY_LIST
>;

export type GetIndustryListSuccessAction = ReduxActionWithPayload<
    IndustryAction.GET_INDUSTRY_LIST_SUCCESS,
    { data: IndustryList }
>;

export type GetIndustryListErrorAction = ReduxActionWithError<
    IndustryAction.GET_INDUSTRY_LIST_ERROR
>;

export type IndustryActions =
    | GetIndustryListAction
    | GetIndustryListSuccessAction
    | GetIndustryListErrorAction;

export type IndustryState = {
    list: AsyncState<IndustryList>;
};
