import React, { useEffect, useState, useMemo } from 'react';
import { Redirect, useLocation, matchPath } from 'react-router';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { useAppTitle } from 'koddi/hooks/app';
import { TopNav } from 'features/Navigation';
import { LoginRouteParams } from 'features/Login/login.types';
import DocumentTitle from 'koddi-components/DocumentTitle';
import { AppContainer, AppHeader, AppBody } from 'koddi-components/Layout';
import { ADVERTISER_REGISTER_PAYMENT_ROUTE } from 'modules/constants/routes';
import { getThemeByMemberGroupHostname } from 'redux-core/app/theme/actions';
import {
    useKoddiThemeResource,
    useThemeStatus,
    useRegistrationEnabled,
} from 'redux-core/app/theme/hooks';
import { clearAppContext } from 'redux-core/app/context';
import { useAuthStatus, useAuthUser } from 'redux-core/auth/hooks';
import { fetchTermsAndConditions } from 'redux-core/app/termsAndConditions';

import { useAuth0 } from 'modules/Auth0';
import { Auth0Layout } from 'components/Auth0Layouts';
import { HorizontalFlexBox } from 'koddi-components/FlexBox';
import AdvertiserRegistrationContext from './AdvertiserRegistration.context';
import { AdvertiserRegistrationRoutes } from './AdvertiserRegistration.routes';

export const AdvertiserRegistration = (): JSX.Element | null => {
    const [initialized, setInitialized] = useState(false);
    const [loadingTheme, setLoadingTheme] = useState(false);
    const [clientSecret, setClientSecret] = useState<string | null>(null);
    const [advertiserValues, setAdvertiserValues] = useState<any | null>(null);
    const [advertiserId, setAdvertiserId] = useState<number | null>(null);
    const appTitle = useAppTitle();
    const dispatch = useDispatch();
    const params: LoginRouteParams = useParams();
    const theme = useKoddiThemeResource();
    const themeStatus = useThemeStatus();
    const featureFlags = useFlags();
    const registationEnabled = useRegistrationEnabled();
    const themeIsLoaded = themeStatus !== 'idle';
    const authStatus = useAuthStatus();
    const user = useAuthUser();
    const { ssoFeatureFlag } = useAuth0();

    const { pathname } = useLocation();

    const Auth0MaxWidth = useMemo((): number => {
        if (
            matchPath(pathname, {
                path: ADVERTISER_REGISTER_PAYMENT_ROUTE,
                exact: false,
            })
        ) {
            return 420;
        }
        return 600;
    }, [pathname]);

    useEffect(() => {
        setInitialized(false);
    }, [params.clientName]);

    useEffect(() => {
        setLoadingTheme(false);
    }, [theme]);

    useEffect(() => {
        dispatch(clearAppContext());
        if (!initialized) {
            setLoadingTheme(true);
            dispatch(getThemeByMemberGroupHostname(params.clientName));
            setInitialized(true);
        }
    }, [dispatch, initialized, params?.clientName]);

    useEffect(() => {
        if (theme?.member_group_id && user?.user_id) {
            dispatch(
                fetchTermsAndConditions(
                    user?.user_id,
                    theme?.member_group_id,
                    user?.locale?.id
                )
            );
        }
    }, [theme?.member_group_id, user?.user_id, user?.locale?.id, dispatch]);

    const contextValue = useMemo(
        () => ({
            clientName: params?.clientName,
            advertiserValues,
            setAdvertiserValues,
            memberGroupId: theme?.member_group_id,
            clientSecret,
            setClientSecret,
            advertiserId,
            setAdvertiserId,
        }),
        [
            params?.clientName,
            advertiserValues,
            theme?.member_group_id,
            clientSecret,
            advertiserId,
        ]
    );

    if (
        themeStatus === 'idle' ||
        featureFlags.registrationPath === undefined ||
        !initialized ||
        loadingTheme
    ) {
        return null;
    }

    if (
        (initialized &&
            themeIsLoaded &&
            !registationEnabled &&
            authStatus !== 'signedIn') ||
        !featureFlags.registrationPath
    ) {
        return <Redirect to={`/${params?.clientName}/login`} />;
    }

    return (
        <>
            <DocumentTitle title={`${appTitle} - Login`} />
            <AppContainer>
                {!ssoFeatureFlag && (
                    <AppHeader>
                        <TopNav showRightMenu={false} />
                    </AppHeader>
                )}
                <AppBody>
                    <AdvertiserRegistrationContext.Provider
                        value={contextValue}
                    >
                        {ssoFeatureFlag ? (
                            <Auth0Layout maxWidth={Auth0MaxWidth}>
                                <AdvertiserRegistrationRoutes isAuth0Styled />
                            </Auth0Layout>
                        ) : (
                            <HorizontalFlexBox
                                alignItems="center"
                                justifyContent="center"
                                fullWidth
                            >
                                <AdvertiserRegistrationRoutes />
                            </HorizontalFlexBox>
                        )}
                    </AdvertiserRegistrationContext.Provider>
                </AppBody>
            </AppContainer>
        </>
    );
};

export default AdvertiserRegistration;
