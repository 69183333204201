import styled, { css } from 'styled-components';

export const ToastsContainerBase = styled.div`
    ${({ theme: { zIndex, spaceUnit } }) => css`
        position: fixed;
        right: 0;
        bottom: 0;
        z-index: ${zIndex.toast};
        padding: ${spaceUnit * 5}px;
    `}
`;
