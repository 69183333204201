/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { KoddiListFilterParams, ReportFilter } from 'koddi/types';
import { KoddiAPIResponse, KoddiAPIQuery } from 'types';
import CachedKoddiAPI from 'api/api.cachedObject';
import {
    UpdateMemberGroupPayload,
    CreateMemberGroupPayload,
    MemberGroup,
    ReportMemberGroup,
    TermsAndConditionsResponse,
    SpendLimit,
    MemberGroupFundingType,
    PendingSpendOrder,
    CreatStripeUserPayload,
    ClientAdvertiserInvoicesReport,
} from './MemberGroup.types';

const KODDI_MEMBER_GROUP_URL = '/member_groups';

class MemberGroupAPI extends CachedKoddiAPI {
    public create = async (data: any): Promise<CreateMemberGroupPayload> => {
        const newMemberGroup = {
            ...data,
            default_currency: data.default_currency.id,
        };
        const response = await this.axios.post<KoddiAPIResponse<any>>(
            `v1${KODDI_MEMBER_GROUP_URL}`,
            newMemberGroup
        );
        return response.data.result;
    };

    public update = async (
        data: UpdateMemberGroupPayload,
        memberGroupId: number
    ): Promise<any> => {
        const newMemberGroup = {
            ...data,
            default_currency: data.default_currency.id,
            status: {
                id: 3,
                name: 'pending',
            },
        };
        const response = await this.axios.patch<KoddiAPIResponse<any>>(
            `v1${KODDI_MEMBER_GROUP_URL}/${memberGroupId}`,
            newMemberGroup
        );
        this.cache.break('getByID', memberGroupId);
        this.cache.breakMethod('getList');
        return response;
    };

    public getList = async (
        params?: KoddiListFilterParams
    ): Promise<MemberGroup[]> => {
        const response = await this.cache.makeCachedRequest(
            'getList',
            JSON.stringify(params),
            () => {
                return this.axios.get<
                    KoddiAPIResponse<{ member_groups: MemberGroup[] }>
                >(`v1${KODDI_MEMBER_GROUP_URL}`, { params });
            }
        );
        return response.data.result.member_groups;
    };

    public getByID = async (id: number): Promise<MemberGroup> => {
        const response = await this.cache.makeCachedRequest(
            'getByID',
            id,
            () => {
                return this.axios.get<KoddiAPIResponse<MemberGroup>>(
                    `v1${KODDI_MEMBER_GROUP_URL}/${id}`
                );
            }
        );
        let channels = [];
        try {
            // This will either contain an array of Channel info or 404, catch that error and continue with []
            const channelsResponse = await this.axios.get(
                `v1/member_groups/${id}/channels`
            );
            channels = channelsResponse.data.result.channels;
        } catch (error) {
            console.warn(`Could not fetch Channels for Member Group ${id}`);
        }

        return {
            ...response.data.result,
            channels,
        };
    };

    /** Gets a list member group reporting data */
    public getMemberGroupsbyUserReport = async (
        userId: number,
        params: KoddiListFilterParams,
        clientId?: string
    ): Promise<ReportMemberGroup[]> => {
        const [id, direction] = params?.sort?.split(' ') ?? [];
        const response = await this.axios.post<any>(
            `v1/users/${userId}/member_groups_report`,
            {
                pagination: {
                    start: params?.start || 0,
                    count: params?.count || 20,
                },
                filters: [
                    {
                        field: clientId ? 'member_group_id' : 'id_name',
                        operation: clientId ? '=' : 'ILIKE',
                        value: clientId || params.search || '%',
                    },
                ],
                ...(id
                    ? {
                          sort: [
                              {
                                  field: id,
                                  ...(direction ? { order: direction } : {}),
                              },
                          ],
                      }
                    : {}),
            }
        );

        return response.data.result.member_groups || [];
    };

    /** Gets the terms and conditions user acceptance and link for member group */
    public getTermsAndConditions = async (
        memberGroupId: number
    ): Promise<TermsAndConditionsResponse> => {
        const response = await this.axios.get(
            `v1${KODDI_MEMBER_GROUP_URL}/${memberGroupId}/users/terms_and_conditions`
        );
        return response.data.result;
    };

    /** Resets terms and conditions acceptance for all users in the member group */
    public resetTermsAndConditions = async (memberGroupId: number) => {
        await this.axios.put(
            `v1${KODDI_MEMBER_GROUP_URL}/${memberGroupId}/users/terms_and_conditions/reset`
        );
    };

    public acceptTermsAndConditions = async (
        memberGroupId: number,
        terms_conditions_url: string
    ) => {
        await this.axios.post(
            `v1${KODDI_MEMBER_GROUP_URL}/${memberGroupId}/users/terms_and_conditions`,
            {
                terms_conditions_url,
            }
        );
    };

    /** Gets the list of funding types available to the given client */
    public getFundingTypes = async (
        memberGroupId: number,
        category?: string
    ): Promise<any> => {
        const response = await this.axios.get<KoddiAPIResponse<any>>(
            `v1/member_groups/${memberGroupId}/funds/funding_types`,
            {
                params: {
                    category,
                },
            }
        );
        return response.data.result;
    };

    public getSpendLimits = async (memberGroupId: number): Promise<any> => {
        const response = await this.axios.get<KoddiAPIResponse<any>>(
            `v1/member_groups/${memberGroupId}/funds/spend_limits`
        );
        return response.data.result;
    };

    public createSpendLimit = async (
        memberGroupId: number,
        params: {
            currency_id: number;
            timeframe_id: number;
            amount: number;
        }
    ): Promise<SpendLimit[]> => {
        const response = await this.axios.post<KoddiAPIResponse<any>>(
            `v1/member_groups/${memberGroupId}/funds/spend_limits`,
            params
        );
        return response.data.result;
    };

    public updateSpendLimit = async (
        memberGroupId: number,
        spendLimitId: number,
        params: {
            currency_id: number;
            timeframe_id: number;
            amount: number;
        }
    ): Promise<SpendLimit[]> => {
        const response = await this.axios.patch<KoddiAPIResponse<any>>(
            `v1/member_groups/${memberGroupId}/funds/spend_limits/${spendLimitId}`,
            params
        );
        return response.data.result;
    };

    public updateFundingTypes = async (
        memberGroupId: number,
        data: MemberGroupFundingType[]
    ): Promise<any> => {
        const response = await this.axios.patch<KoddiAPIResponse<any>>(
            `v1/member_groups/${memberGroupId}/funds/funding_types`,
            {
                funding_types: data,
            }
        );
        return response.data.result;
    };

    public getPendingSpendRules = async (
        memberGroupId: number
    ): Promise<PendingSpendOrder[]> => {
        const response = await this.axios.get<KoddiAPIResponse<any>>(
            `v1/member_groups/${memberGroupId}/funds/pending_spend_orders`
        );
        return response.data.result.pending_spend_orders;
    };

    public getThemeByHostname = async (hostname: string): Promise<any> => {
        const resp = await this.axios.get(
            `${window.API_ROUTE}v1/member_groups/login_config_by_host_name?host_name=${hostname}`
        );
        return resp.data.result;
    };

    public createStripeCustomer = async (
        memberGroupId: number,
        data: CreatStripeUserPayload
    ): Promise<any> => {
        const resp = await this.axios.post<
            KoddiAPIResponse<{
                client_secret: string;
                advertiser_id: number;
            }>
        >(
            `${window.API_ROUTE}v1/member_groups/${memberGroupId}/advertisers/stripe/customer`,
            data
        );
        return resp.data.result;
    };

    public signup = async (
        memberGroupId: number,
        email: string
    ): Promise<any> => {
        const resp = await this.axios.post(
            `${window.API_ROUTE}v1/member_groups/${memberGroupId}/signup`,
            {
                email,
            }
        );
        return resp.data.result;
    };

    public signupV2 = async (
        memberGroupId: number,
        email: string,
        locale_id: number,
        password: string,
        first_name: string,
        last_name: string
    ): Promise<any> => {
        const resp = await this.axios.post(
            `${window.API_ROUTE}v2/member_groups/${memberGroupId}/signup`,
            {
                email,
                password,
                locale_id,
                first_name,
                last_name,
            }
        );
        return resp.data.result;
    };

    public getCurrencies = async (memberGroupId: number): Promise<any> => {
        const resp = await this.axios.get(
            `${window.API_ROUTE}v1/member_groups/${memberGroupId}/currencies`
        );
        return resp.data.result;
    };

    public getHostnameById = async (memberGroupId: number): Promise<any> => {
        const resp = await this.axios.get(
            `${window.API_ROUTE}v1/member_groups/${memberGroupId}/host_name`
        );
        return resp.data.result.host_name;
    };

    public getClientAdvertiserInvoiceReport = async (
        memberGroupId: number,
        params: KoddiAPIQuery & {
            filters: ReportFilter[];
        },
        v2?: boolean
    ): Promise<ClientAdvertiserInvoicesReport[]> => {
        const [id, direction] = params?.sort?.split(' ') ?? [];

        const response = await this.axios.post<
            KoddiAPIResponse<{ invoices: ClientAdvertiserInvoicesReport[] }>
        >(
            `${
                v2 ? 'v2' : 'v1'
            }/member_groups/${memberGroupId}/stripe/invoices_report`,
            {
                pagination: {
                    start: params.start || 0,
                    count: params.count || 20,
                },
                ...(id
                    ? {
                          sort: [
                              {
                                  field: id,
                                  ...(direction ? { order: direction } : {}),
                              },
                          ],
                      }
                    : {}),
                filters: params.filters,
            }
        );
        return response.data.result.invoices;
    };
}

export default MemberGroupAPI;
