import produce, { Draft } from 'immer';
import { EditorState, EditorActions, EditorAction } from './types';

export const initialState: EditorState = {
    header: null,
    open: false,
    formKey: null,
    error: null,
    message: null,
    saving: false,
    data: null,
};

/* eslint-disable default-case, no-param-reassign */
const editorReducer = (
    state: EditorState = initialState,
    action: EditorActions
): EditorState =>
    produce(state, (draft: Draft<EditorState>) => {
        switch (action.type) {
            case EditorAction.OPEN_EDITOR: {
                draft.header = action.payload.header;
                draft.open = true;
                draft.formKey = action.payload.formKey;
                draft.data = action.payload.data || null;
                break;
            }
            case EditorAction.CLOSE_EDITOR: {
                draft.header = null;
                draft.open = false;
                draft.formKey = null;
                draft.data = null;
                break;
            }
            case EditorAction.SET_EDITOR_MESSAGE: {
                draft.message = action.payload.message;
                break;
            }
            case EditorAction.EDITOR_SUBMIT: {
                draft.saving = true;
                break;
            }
            case EditorAction.EDITOR_SUBMIT_SUCCESS: {
                draft.saving = false;
                break;
            }
            case EditorAction.EDITOR_SUBMIT_ERROR: {
                draft.saving = false;
                draft.error = action.error;
                break;
            }
        }
    });

export default editorReducer;
