import produce, { Draft } from 'immer';
import subDays from 'date-fns/subDays';
import { AppFiltersState, FiltersActions, FiltersAction } from './types';
import { addAvailableOperations, mapDefaultMetrics } from './utils';

export const initialState: AppFiltersState = {
    dateRange: {
        startDate: subDays(new Date(), 30),
        endDate: subDays(new Date(), 1),
        buttonLabel: null,
    },
    slices: {
        campaign: { metrics: [], filters: [], dimensions: [] },
        ad_group: { metrics: [], filters: [] },
        media_plan: { metrics: [], filters: [] },
        entity: { metrics: [], filters: [] },
        ad_group_entity: { metrics: [], filters: [] },
        general: { metrics: [], filters: [], dimensions: [] },
    },
    filterDisplayOrder: {
        campaign: [],
        ad_group: [],
        entity: [],
    },
    reportConfig: null,
    reportConfigError: false,
    graphMetric: { label: 'Return on Ad Spend', value: 'ROAS' },
    currency: null,
    filterError: false,
};

/* eslint-disable default-case, no-param-reassign */
const appFiltersReducer = (
    state: AppFiltersState = initialState,
    action: FiltersActions
): AppFiltersState => {
    return produce(state, (draft: Draft<AppFiltersState>) => {
        switch (action.type) {
            case FiltersAction.UPDATE_DATE_RANGE_FILTER: {
                draft.dateRange = action.payload.dateRange;
                break;
            }
            case FiltersAction.UPDATE_GRAPH_METRIC: {
                draft.graphMetric = action.payload.metric;
                break;
            }
            case FiltersAction.DELETE_DATE_RANGE_FILTER: {
                draft.dateRange = {
                    startDate: subDays(new Date(), 7),
                    endDate: subDays(new Date(), 1),
                };
                break;
            }
            case FiltersAction.UPDATE_APP_SLICES: {
                const { context } = action.payload;
                draft.slices[context] = action.payload.slices;
                break;
            }
            case FiltersAction.UPDATE_APP_FILTER_SLICES: {
                const { context } = action.payload;
                draft.slices[context].filters = action.payload.filters;
                break;
            }
            case FiltersAction.GET_REPORT_CONFIG: {
                draft.reportConfigError = false;
                break;
            }
            case FiltersAction.GET_REPORT_CONFIG_SUCCESS: {
                draft.reportConfigError = false;
                draft.reportConfig = addAvailableOperations(
                    action.payload.data
                );

                draft.slices.general.dimensions = [
                    {
                        id: 'campaign_name',
                        name: 'Campaigns',
                        category: 'Media Structure',
                        description: '',
                        filterable: false,
                        presentation: 'text',
                        selectable: true,
                        type: 'string',
                        contexts: ['general'],
                        acceptedValues: null,
                    },
                ];

                const fieldOrder = action.payload.data.field_order;
                const reportConfig = action.payload.data;

                draft.slices.campaign.metrics = mapDefaultMetrics(
                    fieldOrder.campaign,
                    reportConfig,
                    'campaign',
                    true
                );

                draft.slices.ad_group.metrics = mapDefaultMetrics(
                    fieldOrder.ad_group,
                    reportConfig,
                    'ad_group'
                );

                draft.slices.entity.metrics = mapDefaultMetrics(
                    fieldOrder.entity,
                    reportConfig,
                    'entity'
                );
                draft.slices.ad_group_entity.metrics = mapDefaultMetrics(
                    fieldOrder.ad_group_entity,
                    reportConfig,
                    'ad_group_entity'
                );
                draft.slices.media_plan.metrics = mapDefaultMetrics(
                    fieldOrder.media_plan,
                    reportConfig,
                    'media_plan'
                );
                draft.slices.general.metrics = mapDefaultMetrics(
                    fieldOrder.general,
                    reportConfig,
                    'general'
                );

                draft.filterDisplayOrder.campaign = mapDefaultMetrics(
                    fieldOrder.campaign,
                    reportConfig,
                    'campaign'
                );
                draft.filterDisplayOrder.ad_group =
                    draft.slices.ad_group.metrics;
                draft.filterDisplayOrder.entity = draft.slices.entity.metrics;

                break;
            }
            case FiltersAction.GET_REPORT_CONFIG_ERROR: {
                draft.reportConfigError = true;
                break;
            }
            case FiltersAction.UPDATE_REPORT_CONFIG: {
                draft.reportConfigError = false;
                draft.reportConfig = action.payload.reportConfig;
                break;
            }
            case FiltersAction.UPDATE_FILTER_DISPLAY_ORDER: {
                draft.filterDisplayOrder[action.payload.context] =
                    action.payload.order;
                break;
            }
            case FiltersAction.SET_FILTER_ERROR: {
                draft.filterError = action.payload.error;
                break;
            }
        }
    });
};

export default appFiltersReducer;
