/* eslint-disable react/require-default-props */
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Route, RouteProps, useRouteMatch } from 'react-router';
import { GuardedRoute } from 'react-router-guards';
import { useCurrentRouteMatch } from 'redux-core/app/hooks';
import { routeMatchChanged } from 'redux-core/app/routeMatch/actions';

type KoddiRouteType = RouteProps & {
    meta?: any;
    guarded?: boolean;
};

const KoddiRoute = (props: KoddiRouteType): JSX.Element => {
    const { children, guarded, meta } = props;
    if (guarded && meta) {
        return (
            <GuardedRoute {...props}>
                <CrumbTracker>{children}</CrumbTracker>
            </GuardedRoute>
        );
    }
    return (
        <Route {...props}>
            <CrumbTracker>{children}</CrumbTracker>
        </Route>
    );
};

type CrumbTracker = {
    children: any;
};

export const CrumbTracker = (props: CrumbTracker): JSX.Element => {
    const routeMatch = useRouteMatch();
    const currentRouteMatch = useCurrentRouteMatch();
    const dispatch = useDispatch();
    useEffect(() => {
        if (!routeMatch) return;
        if (routeMatch?.url !== currentRouteMatch?.url) {
            dispatch(routeMatchChanged(routeMatch));
        }
    }, [currentRouteMatch, dispatch, routeMatch]);
    return props.children;
};

export default KoddiRoute;
