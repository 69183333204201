import React from 'react';
import ReactDOM from 'react-dom';
import Modal from 'react-modal';
import { Provider, ReactReduxContext } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { withLDProvider } from 'launchdarkly-react-client-sdk';
import { ConnectedRouter } from 'connected-react-router';
import { QueryParamProvider } from 'use-query-params';
import LocaleProvider from 'koddi-components/LocaleProvider';
import I18nextProvider from 'koddi-components/I18nextProvider';
import { datadogRum } from '@datadog/browser-rum';

import { DndProvider } from 'react-dnd';
import { TouchBackend } from 'react-dnd-touch-backend';

import KoddiAPI from 'api';

import EmbeddedApp from 'features/EmbeddedApp';

import store from 'redux-core/store';
import history from 'utils/history';

import App from './react-ui/App';

datadogRum.init({
    applicationId: '8d231e00-e3cc-426c-a759-7be37b03036e',
    clientToken: 'pub84524eb84df97e8bdde91c3591ccf996',
    site: 'datadoghq.com',
    service: 'koddi-one',
    env: window.TARGET_ENV,
    version: window.UI_VERSION,
    sampleRate: 100,
    trackInteractions: true,
    trackViewsManually: true,
    allowedTracingOrigins: [/https:\/\/.*\.koddi\.io/],
});

datadogRum.startSessionReplayRecording();

Modal.setAppElement('#root');

KoddiAPI.setStore(store);

const Root = () => {
    return (
        <Provider store={store} context={ReactReduxContext}>
            <ConnectedRouter
                history={history}
                context={ReactReduxContext}
                noInitialPop
            >
                <QueryParamProvider ReactRouterRoute={Route}>
                    <DndProvider
                        backend={TouchBackend}
                        options={{ enableMouseEvents: true }}
                    >
                        <LocaleProvider>
                            <I18nextProvider
                                onInit={(i18n) => KoddiAPI.setI18n(i18n)}
                            >
                                <Switch>
                                    <Route exact path="/embedded">
                                        <EmbeddedApp />
                                    </Route>
                                    <Route path="/">
                                        <App />
                                    </Route>
                                </Switch>
                            </I18nextProvider>
                        </LocaleProvider>
                    </DndProvider>
                </QueryParamProvider>
            </ConnectedRouter>
        </Provider>
    );
};

const RootWithFlags = withLDProvider({
    clientSideID: window.LAUNCH_DARKLY_ID,
    user: {
        key: 'anon',
        custom: {
            deployEnv: window.DEPLOY_ENV,
        },
    },
})(Root);

ReactDOM.render(<RootWithFlags />, document.getElementById('root'));
