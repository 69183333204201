import React, {
    useCallback,
    useContext,
    useEffect,
    useMemo,
    Suspense,
} from 'react';
import { useHistory, useLocation, Redirect } from 'react-router';
import { Form } from 'react-final-form';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { useGetMemberGroup } from 'api/Admin/MemberGroup/MemberGroup.hooks';

import { updateUser } from 'redux-core/auth/actions';
import { POST_PAY_AUTO } from 'modules/constants/invoiceMethods';
import { useStripeService } from 'components/StripeElement';
import AdvertiserRegistrationContext from 'features/Registration/AdvertiserRegistration/AdvertiserRegistration.context';
import { pathToAdvertiserRegistration } from 'modules/constants/routes';
import FormFooter from 'koddi-components/FormFooter';
import LoadingSpinner from 'koddi-components/LoadingSpinner';

import { useAuth0 } from 'modules/Auth0';
import { RegisterFormSectionHeader } from './RegisterAdvertiserForm.styled';

const StripeElement = React.lazy(() =>
    import('components/StripeElement/StripeElement')
);

const StripeProvider = React.lazy(() =>
    import('components/StripeElement/StripeProvider')
);

const PaymentForm = (): JSX.Element => {
    const history = useHistory();
    const params: { advertiserId: string } = useParams();
    const { clientName, clientSecret } = useContext(
        AdvertiserRegistrationContext
    );
    const { confirmSetup, getSetupIntent } = useStripeService();

    const entitiesUrl = useMemo(() => {
        return pathToAdvertiserRegistration(
            clientName,
            'entities',
            params.advertiserId
        );
    }, [clientName, params.advertiserId]);

    useEffect(() => {
        getSetupIntent(clientSecret || '')?.then(({ setupIntent }: any) => {
            if (setupIntent?.status === 'succeeded') {
                history.push(entitiesUrl);
            }
        });
    }, [
        getSetupIntent,
        clientSecret,
        history,
        clientName,
        params.advertiserId,
        entitiesUrl,
    ]);

    const handleSubmission = useCallback(() => {
        confirmSetup(
            `${window.location.protocol}//${window.location.host}/#${entitiesUrl}`
        );
    }, [confirmSetup, entitiesUrl]);
    const { ssoFeatureFlag } = useAuth0();
    return (
        <Form
            style={{ width: '100%' }}
            onSubmit={handleSubmission}
            render={({ handleSubmit }) => {
                return (
                    <form onSubmit={handleSubmit}>
                        <RegisterFormSectionHeader
                            isAuth0Styled={ssoFeatureFlag}
                            tKey=""
                            text="Payment Details"
                        />
                        <Suspense fallback={<div />}>
                            <StripeElement />
                        </Suspense>
                        <FormFooter
                            submitButtonText="Submit"
                            noBorderTop
                            hideCancel
                            v2
                        />
                    </form>
                );
            }}
        />
    );
};

const AdvertiserPayment = (): JSX.Element | null => {
    const history = useHistory();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const secret = queryParams.get('clientSecret');
    const {
        clientSecret,
        setClientSecret,
        clientName,
        memberGroupId,
    } = useContext(AdvertiserRegistrationContext);
    const {
        getMemberGroup,
        status: memberGroupStatus,
        response: memberGroup,
    } = useGetMemberGroup(memberGroupId || 1);
    const memberGroupLoading =
        memberGroupStatus === 'idle' || memberGroupStatus === 'pending';
    const params: { advertiserId: string } = useParams();
    const dispatch = useDispatch();

    const clientIsAutoBill = useMemo(() => {
        return memberGroup?.billing_methods?.find((billingMethod) => {
            return billingMethod.billing_method_id === POST_PAY_AUTO;
        });
    }, [memberGroup]);

    const entitiesUrl = useMemo(() => {
        return pathToAdvertiserRegistration(
            clientName,
            'entities',
            params.advertiserId
        );
    }, [clientName, params.advertiserId]);

    useEffect(() => {
        getMemberGroup();
    }, [memberGroupId, getMemberGroup]);

    useEffect(() => {
        dispatch(updateUser());
    }, [dispatch]);

    useEffect(() => {
        if (
            clientSecret &&
            !location.search.includes('?clientSecret') &&
            clientIsAutoBill &&
            !memberGroupLoading
        ) {
            history.push({
                pathname: location.pathname,
                search: `?clientSecret=${clientSecret}`,
            });
        }

        if (!clientSecret && secret) {
            setClientSecret(secret);
        }
    }, [
        clientSecret,
        location,
        history,
        secret,
        setClientSecret,
        clientIsAutoBill,
        memberGroupLoading,
    ]);

    if (memberGroupLoading) {
        return <LoadingSpinner id="register-payment-spinner" />;
    }
    if (!clientIsAutoBill && !memberGroupLoading && memberGroup) {
        return <Redirect to={entitiesUrl} />;
    }
    if (!clientSecret) return null;
    return (
        <Suspense fallback={<div />}>
            <StripeProvider clientSecret={clientSecret}>
                <PaymentForm />
            </StripeProvider>
        </Suspense>
    );
};

export default AdvertiserPayment;
