import { combineReducers } from 'redux';
import createAsyncReducer from 'utils/createAsyncReducer';
import { UserState, UserAction } from './types';

export const initialState: UserState = {
    list: {
        last_updated: null,
        loading: false,
        error: null,
        data: null,
    },
};

export const userListReducer = createAsyncReducer(initialState.list, {
    start: UserAction.GET_USER_LIST,
    success: UserAction.GET_USER_LIST_SUCCESS,
    error: UserAction.GET_USER_LIST_ERROR,
});

const userReducer = combineReducers<UserState>({
    list: userListReducer,
});

export default userReducer;
