/* eslint-disable no-console */
// Documentation for Auth0's JS SDK https://auth0.com/docs/libraries/auth0-single-page-app-sdk
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { createAuth0Client } from '@auth0/auth0-spa-js';

import { Auth0State } from './Auth0.types';
import { AUTH0_CONFIG } from './Auth0.const';

export class KoddiAuth0 {
    auth0Client: any;

    isAuthenticated = false;

    public getState = async (): Promise<Auth0State> => {
        const isAuthenticated = await this.auth0Client.isAuthenticated();
        const user = await this.auth0Client.getUser();

        const accessToken = await this.auth0Client.getTokenSilently({
            authorizationParams: {
                audience: AUTH0_CONFIG.authorizationParams.audience,
            },
        });

        const claims = await this.auth0Client.getIdTokenClaims();

        this.isAuthenticated = isAuthenticated;

        const tokenValues = JSON.parse(
            Object.values(localStorage).find((value: any) =>
                value.includes('body')
            )
        );
        const tokenExpiration = tokenValues.body.expires_in;
        const tokenExpirationTime = tokenValues.expiresAt;

        return {
            isAuthenticated,
            user,
            accessToken,
            expires_in: tokenExpiration,
            // eslint-disable-next-line
            idToken: claims.__raw,
            expirationTime: tokenExpirationTime,
        };
    };

    private handleCallBack = async () => {
        const { search, hash } = window.location;
        if (search.includes('code=') && search.includes('state=')) {
            return (async () => {
                try {
                    await this.auth0Client.handleRedirectCallback();
                    window.history.replaceState({}, document.title, hash);
                } catch (error) {
                    console.error(error);
                }
                return this.getState();
            })();
        }
        const isLoggedIn = await this.auth0Client.isAuthenticated();
        if (isLoggedIn) {
            return this.getState();
        }
        return null;
    };

    /**
     * Starts the sign up flow
     */
    private configureClient = async () => {
        this.auth0Client = await createAuth0Client(AUTH0_CONFIG);
    };

    /**
     * Starts the sign up flow
     */
    public signUp = async (targetUrl?: string) => {
        const options = {
            authorizationParams: {
                redirect_uri: targetUrl ?? `${window.location.origin}`,
                screen_hint: 'signup',
            },
            appState: targetUrl ?? null,
        };
        await this.auth0Client.loginWithRedirect(options);
    };

    /**
     * Starts the authentication flow
     */
    public login = async ({
        sso_organization_id,
        targetUrl,
        sso_connection_id,
    }: {
        sso_organization_id?: string;
        targetUrl?: string;
        sso_connection_id?: string;
    }) => {
        const options = {
            authorizationParams: {
                redirect_uri: targetUrl ?? `${window.location.origin}/#/login`,
                organization: sso_organization_id ?? undefined,
                connection: sso_connection_id ?? undefined,
            },
            appState: targetUrl ?? null,
        };
        await this.auth0Client.loginWithRedirect(options);
    };

    /**
     * Executes the logout flow
     */
    public logout = async (returnTo?: string) => {
        if (!this.isAuthenticated) return;
        await this.auth0Client.logout({
            logoutParams: {
                returnTo: returnTo || `${window.location.origin}/#/login`,
            },
        });
    };

    public async initializeAuth0() {
        await this.configureClient();
        return this.handleCallBack();
    }
}

export const Auth0 = new KoddiAuth0();
