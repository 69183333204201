import {
    TermsAndConditionsAction,
    FetchTermsAndConditionsSuccessAction,
    FetchTermsAndConditionsAction,
    FetchTermsAndConditionsErrorAction,
    AcceptTermsAndConditionsAction,
    AcceptTermsAndConditionsSuccessAction,
    TermsAndConditionsErrorType,
    MemberGroupIdType,
    LocaleIdType,
} from './types';

export function fetchTermsAndConditions(
    userId: number,
    memberGroupId: MemberGroupIdType,
    localeId: LocaleIdType
): FetchTermsAndConditionsAction {
    return {
        type: TermsAndConditionsAction.FETCH_TERMS_AND_CONDITIONS,
        payload: {
            userId,
            memberGroupId,
            localeId,
        },
    };
}

export function fetchTermsAndConditionsSuccess(
    hasAccepted: boolean,
    url: string,
    lastModified: string | null,
    memberGroupId: MemberGroupIdType,
    localeId: LocaleIdType
): FetchTermsAndConditionsSuccessAction {
    return {
        type: TermsAndConditionsAction.FETCH_TERMS_AND_CONDITIONS_SUCCESS,
        payload: {
            hasAccepted,
            lastModified,
            url,
            memberGroupId,
            localeId,
        },
    };
}

export function termsAndConditionsError(
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    error: any,
    memberGroupId: MemberGroupIdType,
    localeId: LocaleIdType,
    type: TermsAndConditionsErrorType
): FetchTermsAndConditionsErrorAction {
    return {
        type: TermsAndConditionsAction.FETCH_TERMS_AND_CONDITIONS_ERROR,
        payload: {
            memberGroupId,
            localeId,
            error,
            type,
        },
    };
}

export function acceptTermsAndConditions(
    memberGroupId: MemberGroupIdType,
    url: string,
    localeId: LocaleIdType
): AcceptTermsAndConditionsAction {
    return {
        type: TermsAndConditionsAction.ACCEPT_TERMS_AND_CONDITIONS,
        payload: {
            memberGroupId,
            url,
            localeId,
        },
    };
}

export function acceptTermsAndConditionsSuccess(): AcceptTermsAndConditionsSuccessAction {
    return {
        type: TermsAndConditionsAction.ACCEPT_TERMS_AND_CONDITIONS_SUCCESS,
    };
}
