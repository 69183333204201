/* eslint-disable @typescript-eslint/no-empty-function */
import React, { FunctionComponent } from 'react';
import { CSSTransition } from 'react-transition-group';
import { SlideTransitionProps } from './SlideTransition.types';
import { SlideTransitionContent } from './SlideTransition.styled';

/**
 * The `SlideTransition` makes it easy to slide an element in and out
 * of the dom in a safe and effecient way. The duration of the fade
 * can be adjusted using the `duration` prop.
 */
const SlideTransition: FunctionComponent<SlideTransitionProps> = ({
    children,
    in: inProp,
    duration = 300,
    unmountOnExit = false,
    appear = false,
    className,
    ...rest
}) => {
    return (
        <CSSTransition
            addEndListener={() => {}}
            timeout={duration}
            classNames=""
            in={inProp}
            unmountOnExit={unmountOnExit}
            appear={appear}
            {...rest}
        >
            <SlideTransitionContent className={className} duration={duration}>
                {children}
            </SlideTransitionContent>
        </CSSTransition>
    );
};

export default SlideTransition;
