import { useCallback, useEffect, useState } from 'react';

import KoddiAPI from 'api';
import { KoddiAPIHook, KoddiAPIStatus } from 'api/api.types';
import { pathToAdvertiserRegistration } from 'modules/constants/routes';
import { useAuth0 } from 'modules/Auth0';
import { loginSubmit } from 'redux-core/auth/actions';
import { useDispatch } from 'react-redux';
import { useAuthError } from 'redux-core/auth/hooks';
import {
    MemberGroup,
    CreatStripeUserPayload,
    CreateStripeCustomerResponse,
} from './MemberGroup.types';

export const useGetMemberGroup = (
    memberGroupId: number
): KoddiAPIHook & {
    getMemberGroup: () => void;
    response: MemberGroup | null;
} => {
    const [error, setError] = useState<string | null>(null);
    const [status, setStatus] = useState<KoddiAPIStatus>('idle');
    const [response, setResponse] = useState<MemberGroup | null>(null);
    const getMemberGroup = useCallback(async () => {
        try {
            setError(null);
            setStatus('pending');
            const resp = await KoddiAPI.Admin.MemberGroup.getByID(
                memberGroupId
            );
            setStatus('success');
            setResponse(resp);
        } catch (err) {
            setStatus('error');
            setError(err.response.data.error);
        }
    }, [memberGroupId]);

    return {
        getMemberGroup,
        error,
        status,
        response,
    };
};

export const useCreateStripeCustomer = (
    memberGroupId: number
): KoddiAPIHook & {
    createStripeCustomer: (payload: CreatStripeUserPayload) => void;
    response: CreateStripeCustomerResponse | null;
} => {
    const [error, setError] = useState<string | null>(null);
    const [status, setStatus] = useState<KoddiAPIStatus>('idle');
    const [
        response,
        setResponse,
    ] = useState<CreateStripeCustomerResponse | null>(null);
    const createStripeCustomer = useCallback(
        async (payload: CreatStripeUserPayload) => {
            try {
                setError(null);
                setStatus('pending');
                const resp = await KoddiAPI.Admin.MemberGroup.createStripeCustomer(
                    memberGroupId,
                    payload
                );
                setStatus('success');
                setResponse(resp);
            } catch (err) {
                setStatus('error');
                setError(err?.errorResponse?.data?.error);
            }
        },
        [memberGroupId]
    );

    return {
        createStripeCustomer,
        error,
        status,
        response,
    };
};

export const useSignup = (
    memberGroupId: number
): KoddiAPIHook & {
    signup: (
        email: string,
        password?: string,
        locale_id?: number,
        first_name?: string,
        last_name?: string,
        clientName?: string
    ) => void;
    successMessage: string | null;
} => {
    const [successMessage, setSuccessMessage] = useState<string | null>(null);
    const [error, setError] = useState<string | null>(null);
    const [status, setStatus] = useState<KoddiAPIStatus>('idle');
    const [clientName, setClientName] = useState<string>();
    const {
        ssoFeatureFlag,
        handleLogin,
        handleReturnRouteStorage,
    } = useAuth0();
    const dispatch = useDispatch();
    const AuthError = useAuthError();

    useEffect(() => {
        // If the user successfully signsup but our login endpoint fails we want to send the user to Auth0
        if (status === 'success' && AuthError && ssoFeatureFlag && clientName) {
            handleReturnRouteStorage(
                pathToAdvertiserRegistration(clientName, 'billing'),
                true
            );
            handleLogin();
        }
    }, [
        status,
        AuthError,
        handleLogin,
        ssoFeatureFlag,
        handleReturnRouteStorage,
        clientName,
    ]);

    const signup = useCallback(
        async (
            email: string,
            password?: string,
            locale_id?: number,
            first_name?: string,
            last_name?: string,
            client?: string
        ): Promise<any> => {
            try {
                setClientName(client);
                setError(null);
                setStatus('pending');
                setSuccessMessage(null);
                const response =
                    ssoFeatureFlag &&
                    password &&
                    first_name &&
                    last_name &&
                    locale_id
                        ? await KoddiAPI.Admin.MemberGroup.signupV2(
                              memberGroupId,
                              email,
                              locale_id,
                              password,
                              first_name,
                              last_name
                          )
                        : await KoddiAPI.Admin.MemberGroup.signup(
                              memberGroupId,
                              email
                          );
                setStatus('success');
                if (ssoFeatureFlag && password) {
                    dispatch(loginSubmit(email, password));
                }
                if (!ssoFeatureFlag) {
                    setSuccessMessage(
                        `An email will be sent to ${email} with a temporary password. You can use this password to finish setting up your user. If you do not receive an email, please contact support.`
                    );
                }
                return response;
            } catch (err) {
                setStatus('error');

                setError(err.response.data.error);
            }
            return '';
        },
        [memberGroupId, ssoFeatureFlag, dispatch]
    );
    return {
        signup,
        error,
        status,
        successMessage,
    };
};

export const useMemberGroupCurrencies = (
    memberGroupId: number
): KoddiAPIHook & {
    getCurrencies: () => void;
    currencies: any[];
} => {
    const [currencies, setCurrencies] = useState<any>(null);
    const [error, setError] = useState<string | null>(null);
    const [status, setStatus] = useState<KoddiAPIStatus>('idle');
    const getCurrencies = useCallback(async (): Promise<any> => {
        try {
            setError(null);
            setStatus('pending');
            const response = await KoddiAPI.Admin.MemberGroup.getCurrencies(
                memberGroupId
            );
            setStatus('success');
            setCurrencies(response.currency);
        } catch (err) {
            setStatus('error');
            setError(err.response.data.error);
        }
        return '';
    }, [memberGroupId]);
    return {
        getCurrencies,
        error,
        status,
        currencies,
    };
};
