import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { BaseSVGProps } from '../icon.types';

const SVGWrapper = styled.span`
    color: inherit;
    margin-top: 3px;
    svg path {
        fill: currentColor;
    }
`;

const BillIcon: FunctionComponent<BaseSVGProps> = ({ height, width }) => (
    <SVGWrapper>
        <svg
            xmlns="http://www.w3.org/2000/svg"
            height={height}
            width={width}
            viewBox="0 0 24 24"
        >
            <path
                id="bill"
                d="M14.3,0.26H2.66c-0.33,0-0.6,0.27-0.6,0.6v17.86c0,0.33,0.27,0.6,0.61,0.6c0.14,0,0.27-0.05,0.38-0.13l1.23-0.99
	l0.95,0.95c0.23,0.23,0.6,0.23,0.84,0.01l1.01-0.94l1,0.94c0.24,0.22,0.61,0.22,0.84-0.01l0.9-0.9l0.9,0.9
	c0.23,0.23,0.6,0.24,0.84,0.01l1.04-0.98l1.34,1.02c0.27,0.2,0.65,0.15,0.85-0.12c0.08-0.1,0.12-0.23,0.12-0.36V0.86
	C14.91,0.53,14.63,0.26,14.3,0.26C14.3,0.26,14.3,0.26,14.3,0.26z M5.19,9.32h4.24c0.33,0,0.6,0.27,0.6,0.6s-0.27,0.6-0.6,0.6H5.19
	c-0.33,0-0.6-0.27-0.6-0.6S4.85,9.32,5.19,9.32L5.19,9.32z M4.58,7.08c0-0.33,0.27-0.6,0.6-0.6H7.8c0.33,0,0.6,0.27,0.6,0.6
	c0,0.33-0.27,0.6-0.6,0.6H5.19C4.85,7.68,4.58,7.41,4.58,7.08z M10.82,13.37H5.19c-0.33,0-0.6-0.27-0.6-0.6c0-0.33,0.27-0.6,0.6-0.6
	l0,0h5.63c0.33,0,0.6,0.27,0.6,0.6C11.42,13.1,11.15,13.37,10.82,13.37z M11.6,4.84H5.19c-0.33,0-0.6-0.27-0.6-0.6s0.27-0.6,0.6-0.6
	l0,0h6.41c0.33,0,0.6,0.27,0.6,0.6S11.93,4.84,11.6,4.84z"
            />
        </svg>
    </SVGWrapper>
);

export default BillIcon;
