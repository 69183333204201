/* eslint-disable @typescript-eslint/no-empty-function */
import React from 'react';
import { AdvertiserFormValues } from './AdvertiserRegistration.types';

export type AdvertiserContextValue = {
    clientName: string | null;
    setAdvertiserValues: (advertiser: AdvertiserFormValues) => void;
    advertiserValues: AdvertiserFormValues | null;
    memberGroupId: number | null;
    clientSecret?: string | null;
    advertiserId?: number | null;
    setClientSecret: (secret: string) => void;
    setAdvertiserId: (advertierId: number) => void;
};

const initialContext = {
    clientName: null,
    setAdvertiserValues: () => {},
    advertiserValues: null,
    memberGroupId: null,
    clientSecret: null,
    advertiserId: null,
    setClientSecret: () => {},
    setAdvertiserId: () => {},
};

const AdvertiserRegistrationContext = React.createContext<
    AdvertiserContextValue
>(initialContext);

export default AdvertiserRegistrationContext;
