/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Validators, ValidationSchema } from '@lemoncode/fonk';
import { createFinalFormValidation } from '@lemoncode/fonk-final-form';
import { FieldState } from 'final-form';

import postalCodeValidator from 'validators/postalCodeValidator';

const validationSchema: ValidationSchema = {
    field: {
        first_name: [
            {
                validator: Validators.required,
                message: 'Required',
            },
        ],
        last_name: [
            {
                validator: Validators.required,
                message: 'Required',
            },
        ],
        billing_email: [
            {
                validator: Validators.required,
                message: 'Required',
            },
            {
                validator: Validators.required,
                message: 'Required',
            },
        ],
        billing_address: [
            {
                validator: Validators.required,
                message: 'Required',
            },
        ],
        billing_city: [
            {
                validator: Validators.required,
                message: 'Required',
            },
        ],
        billing_state: [
            {
                validator: Validators.required,
                message: 'Required',
            },
        ],
        billing_zipcode: [
            {
                validator: Validators.required,
                message: 'Required',
            },
            {
                validator: postalCodeValidator,
            },
        ],
    },
};

const advertiserRegistrationValidation = createFinalFormValidation(
    validationSchema
);

export const validateField = (
    value: any,
    _: any,
    meta: FieldState<any>
): Promise<string> =>
    advertiserRegistrationValidation.validateField(meta.name, value);

export default advertiserRegistrationValidation;
