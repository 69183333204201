import React, { FunctionComponent } from 'react';
import { BaseSVGProps } from '../icon.types';

const LockIcon: FunctionComponent<BaseSVGProps> = ({ width, height }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 24 24"
    >
        <path d="M19.88 9.18h-1V6.86a6.86 6.86 0 00-13.71 0v2.32H4.5a1.56 1.56 0 00-1.59 1.4v12A1.56 1.56 0 004.5 24h15.38a1.23 1.23 0 001.22-1.25V10.6A1.26 1.26 0 0020 9.19zm-7.18 9.24a.85.85 0 01-.85.83.83.83 0 01-.83-.83v-2a.83.83 0 01.83-.84.85.85 0 01.85.83zm4.47-9.24H6.82V6.86a5.18 5.18 0 0110.35 0z" />
    </svg>
);

export default LockIcon;
