import styled, { css } from 'styled-components';
import { FormRow, FieldContainer, ErrorText } from 'koddi-components/Form';
import { Heading3 } from 'koddi-components/Typography';
import { fadeIn } from 'koddi-components/animations';

export const TermsAndConditionsFormRow = styled(FormRow)`
    display: block;
    position: relative;
    margin-bottom: 24px;
    ${ErrorText} {
        position: absolute;
        margin-top: 23px;
`;

export const StyledRegisterForm = styled.form<{ isAuth0Styled: boolean }>`
    ${({ theme, isAuth0Styled }) => css`
        ${isAuth0Styled
            ? `
                height: 70vh ;
                max-width:  500px ;
                width:100%;
                padding-right:8px;
            `
            : `
                height: $calc(100vh - ${theme.spaceUnit * 16}px);
                padding: 0 ${theme.spaceUnit * 6}px;
                width: 400px;
            `}
    `}
`;

export const RegisterFormSectionHeader = styled(Heading3)<{
    isAuth0Styled: boolean;
}>`
    ${({ isAuth0Styled }) => css`
        ${isAuth0Styled
            ? `
                font-size: 22px;
                text-align:center;
                margin-bottom:16px;
            `
            : ''}
    `}
`;

export const RegisterFormFieldContainer = styled(FieldContainer)``;

export const TermsAndConditionsLabel = styled.label`
    animation: ${fadeIn} 0.2s linear both;
    animation-delay: 0.4s;
    cursor: pointer;
`;

export const TermsLink = styled.a`
    ${({ theme }) => css`
        margin-left: ${theme?.spaceUnit}px;
    `}
`;

export const AddEntitiesForm = styled.form`
    max-width: 400px;
    padding: 24px 24px 0;
    overflow-y: auto;
`;
