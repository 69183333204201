/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import KoddiAPI from 'api';
import { takeEvery, call, all, put } from 'redux-saga/effects';
import SagaRegistry from 'redux-core/sagaRegistry';

import { DEFAULT_MEMBER_GROUP_ID } from 'modules/constants/app';

import {
    fetchTermsAndConditionsSuccess,
    termsAndConditionsError,
    acceptTermsAndConditionsSuccess,
} from './actions';

import {
    TermsAndConditionsAction,
    FetchTermsAndConditionsAction,
    AcceptTermsAndConditionsAction,
    TermsAndConditionsErrors,
} from './types';

export function* fetchTermsAndConditionsSaga(
    action: FetchTermsAndConditionsAction
) {
    const { memberGroupId, localeId } = action.payload;

    if (memberGroupId === DEFAULT_MEMBER_GROUP_ID) {
        yield put(
            fetchTermsAndConditionsSuccess(
                false,
                '',
                null,
                memberGroupId,
                localeId
            )
        );
    } else {
        try {
            const response = yield call(
                KoddiAPI.Admin.MemberGroup.getTermsAndConditions,
                memberGroupId
            );
            yield put(
                fetchTermsAndConditionsSuccess(
                    response.has_accepted,
                    response.terms_conditions_url,
                    response.last_modified,
                    memberGroupId,
                    localeId
                )
            );
        } catch (e) {
            yield put(
                termsAndConditionsError(
                    e.error,
                    memberGroupId,
                    localeId,
                    TermsAndConditionsErrors.GET
                )
            );
        }
    }
}

export function* acceptTermsAndConditionsSaga(
    action: AcceptTermsAndConditionsAction
) {
    const { memberGroupId, localeId, url } = action.payload;
    try {
        yield call(
            KoddiAPI.Admin.MemberGroup.acceptTermsAndConditions,
            memberGroupId,
            url
        );
        yield put(acceptTermsAndConditionsSuccess());
    } catch (e) {
        yield put(
            termsAndConditionsError(
                e.error,
                memberGroupId,
                localeId,
                TermsAndConditionsErrors.POST
            )
        );
    }
}

export function* watchTermsAndConditions() {
    yield takeEvery(
        TermsAndConditionsAction.ACCEPT_TERMS_AND_CONDITIONS,
        acceptTermsAndConditionsSaga
    );
    yield takeEvery(
        TermsAndConditionsAction.FETCH_TERMS_AND_CONDITIONS,
        fetchTermsAndConditionsSaga
    );
}

function* termsAndConditionsSagas() {
    yield all([watchTermsAndConditions()]);
}

SagaRegistry.registerSaga(termsAndConditionsSagas);
