import React, { FunctionComponent, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import CanView from 'koddi-components/CanView';
import { Link, useLocation } from 'react-router-dom';
import { getDataTestName } from 'koddi-components/utils';
import { MenuItemProps } from './MenuItem.types';
import {
    MenuItemLI,
    MenuItemIcon,
    MenuItemLink,
    MenuItemText,
} from './MenuItem.styled';
import { SubMenuUL } from '../Menu.styled';

const MenuItem: FunctionComponent<MenuItemProps> = ({
    translationKey,
    to,
    title,
    icon,
    permission,
    condition = true,
    role = [],
    items = [],
    horizontal,
    vertical,
    any = false,
    anyRole = false,
    nested = false,
    isSelected = () => false,
    iconWidth = 16,
    iconHeight = 16,
    disabled = false,
}) => {
    const { pathname } = useLocation();
    const { t } = useTranslation();
    const active = pathname.includes(to) || isSelected();
    const menuItemIndicatorActive = pathname === to || isSelected();

    const dataTest = getDataTestName(title, 'navigation-');

    const LinkComponent = useCallback(
        ({ children }) => {
            if (disabled) {
                return (
                    <MenuItemLink
                        disabled={disabled}
                        data-test={`${dataTest}-disabled`}
                        isActive={active}
                    >
                        {children}
                    </MenuItemLink>
                );
            }
            return (
                <MenuItemLink isActive={active}>
                    <Link data-test={dataTest} to={to}>
                        {children}
                    </Link>
                </MenuItemLink>
            );
        },
        [dataTest, to, active, disabled]
    );

    return (
        <CanView
            permission={permission || []}
            role={role}
            any={any}
            anyRole={anyRole}
            condition={condition}
        >
            <MenuItemLI isActive={menuItemIndicatorActive} nested={nested}>
                <LinkComponent>
                    <>
                        <MenuItemIcon
                            width={iconWidth}
                            height={iconHeight}
                            icon={icon}
                            isActive={active}
                        />
                        <MenuItemText isActive={active}>
                            {t(translationKey, title)}
                        </MenuItemText>
                    </>
                </LinkComponent>
                <SubMenuUL active={active && items.length > 0} nested>
                    {items.map((item) => (
                        <MenuItem
                            {...item}
                            key={item.title}
                            horizontal={horizontal}
                            vertical={vertical}
                            nested
                        />
                    ))}
                </SubMenuUL>
            </MenuItemLI>
        </CanView>
    );
};

export default React.memo(MenuItem);
