import React, { FunctionComponent } from 'react';
import { MenuProps } from './Menu.types';
import { MenuContainer, MenuUL } from './Menu.styled';
import MenuItem from './MenuItem';

/**
 * The `Menu` component is an abstract component for creating
 * either a horizontal or vertical menu.
 */
const Menu: FunctionComponent<MenuProps> = ({
    items,
    horizontal = false,
    vertical = true,
    ...rest
}) => {
    return (
        <MenuContainer {...rest}>
            <MenuUL isHorizontal={horizontal} isVertical={vertical}>
                {items.map((item) => (
                    <MenuItem
                        key={item.title}
                        {...item}
                        horizontal={horizontal}
                        vertical={vertical}
                    />
                ))}
            </MenuUL>
        </MenuContainer>
    );
};

export default Menu;
