/* eslint-disable react/require-default-props */
import React from 'react';
import { KoddiSuspense } from 'koddi-components/KoddiSuspense';
import TableV1 from './Table.v1';
import { TableV2Props } from './v2/Table.types';
import { TableComponentProps } from './Table.types';

const TableV2 = React.lazy(() => import('./v2/Table'));

/**
 * This component is a passes props from our existing into our
 * v1 and v2 table components. v1 uses React Table, v2 uses AG Grid.
 * All new tables should be implemented using v2.
 */
export function Table<D extends Record<string, unknown>>(
    props: TableComponentProps<D> & TableV2Props & { v2?: boolean }
): JSX.Element {
    const { v2 = false } = props;

    if (v2) {
        return (
            <KoddiSuspense>
                <TableV2 {...props} />
            </KoddiSuspense>
        );
    }

    return <TableV1 {...props} />;
}

export function StickyTable<D extends Record<string, unknown>>(
    props: TableComponentProps<D> & TableV2Props & { v2?: boolean }
): JSX.Element {
    return <Table {...props} sticky />;
}

export default Table;
