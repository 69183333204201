import React, { FunctionComponent, Suspense } from 'react';
import { IconKey, BaseSVGProps, LazyIconKey } from './icon.types';

import AccountProfileIcon from './icons/account_profile';
import BarChartIcon from './icons/bar_chart';
import BellIcon from './icons/bell';
import BillIcon from './icons/bill';
import CheckIcon from './icons/check';
import ChevronDownIcon from './icons/chevron_down';
import ChevronLeftIcon from './icons/chevron_left';
import ChevronUpIcon from './icons/chevron_up';
import ChevronRightIcon from './icons/chevron_right';
import CloseXIcon from './icons/close_x';
import DoubleEllipsesIcon from './icons/double_ellipses';
import DownloadIcon from './icons/download';
import EllipsesIcon from './icons/ellipses';
import ExclamationIcon from './icons/exclamation';
import GraphIcon from './icons/graph';
import InfoIcon from './icons/info';
import LineChartIcon from './icons/line_chart';
import LockIcon from './icons/lock';
import MagnifierIcon from './icons/magnifier';
import MinusIcon from './icons/minus';
import MinusBoxIcon from './icons/minusbox';
import PencilIcon from './icons/pencil';
import PercentIcon from './icons/percent';
import PerformanceArrowIcon from './icons/performance_arrow';
import PlacePinIcon from './icons/place_pin';
import PlacePinShadowIcon from './icons/place_pin_shadow';
import PlusIcon from './icons/plus';
import ProfileIcon from './icons/profile';
import RadioIcon from './icons/radio';
import CheckboxIcon from './icons/checkbox';
import RefreshIcon from './icons/refresh';
import SettingsIcon from './icons/settings';
import TargetIcon from './icons/target';
import TriangleDown from './icons/triangle_down';
import Thermometer from './icons/thermometer';
import DollarSignIcon from './icons/dollar_sign';
import ProductsIcon from './icons/products';
import CampaignIcon from './icons/campaign';
import AGroupIcon from './icons/ad_groups';
import OverviewIcon from './icons/overview';
import ArrowTargetIcon from './icons/arrow_target';
import PieChart from './icons/pie_chart';
import ThumbsUp from './icons/thumbsUp';
import Warning from './icons/warning';
import AddFileIcon from './icons/add_file';
import TrashCanIcon from './icons/trash_can';
import VisaIcon from './icons/visa';
import MastercardIcon from './icons/mastercard';
import AmericanExpressIcon from './icons/american_express';
import DiscoverIcon from './icons/discover';
import DinersClubIcon from './icons/diners_club';
import JCBIcon from './icons/jcb';
import UnionPayIcon from './icons/union_pay';
import GenericCardIcon from './icons/generic_card';
import FilterIcon from './icons/filter';
import BankIcon from './icons/bank';
import SaveIcon from './icons/save';

const PaidIcon = React.lazy(() => import('./icons/paid'));

const LazyIconKeys: string[] = ['paid'];

export const LAZY_ICONS: any = {
    paid: PaidIcon,
};

export const ICON_MAP: Record<IconKey, FunctionComponent<BaseSVGProps>> = {
    adGroup: AGroupIcon,
    addFile: AddFileIcon,
    accountProfile: AccountProfileIcon,
    americanExpress: AmericanExpressIcon,
    arrowTarget: ArrowTargetIcon,
    barChart: BarChartIcon,
    bell: BellIcon,
    bill: BillIcon,
    campaign: CampaignIcon,
    check: CheckIcon,
    chevronDown: ChevronDownIcon,
    chevronLeft: ChevronLeftIcon,
    chevronRight: ChevronRightIcon,
    chevronUp: ChevronUpIcon,
    closeX: CloseXIcon,
    dinersClub: DinersClubIcon,
    discover: DiscoverIcon,
    dollarSign: DollarSignIcon,
    doubleEllipses: DoubleEllipsesIcon,
    download: DownloadIcon,
    ellipses: EllipsesIcon,
    exclamation: ExclamationIcon,
    genericCard: GenericCardIcon,
    graph: GraphIcon,
    info: InfoIcon,
    jcb: JCBIcon,
    lineChart: LineChartIcon,
    lock: LockIcon,
    magnifier: MagnifierIcon,
    mastercard: MastercardIcon,
    minus: MinusIcon,
    minusbox: MinusBoxIcon,
    overview: OverviewIcon,
    pencil: PencilIcon,
    percent: PercentIcon,
    performanceArrow: PerformanceArrowIcon,
    pieChart: PieChart,
    placePin: PlacePinIcon,
    placePinShadow: PlacePinShadowIcon,
    plus: PlusIcon,
    profile: ProfileIcon,
    product: ProductsIcon,
    radio: RadioIcon,
    checkbox: CheckboxIcon,
    refresh: RefreshIcon,
    settings: SettingsIcon,
    target: TargetIcon,
    trashCan: TrashCanIcon,
    triangleDown: TriangleDown,
    thermometer: Thermometer,
    thumbsUp: ThumbsUp,
    unionPay: UnionPayIcon,
    warning: Warning,
    visa: VisaIcon,
    filter: FilterIcon,
    bank: BankIcon,
    save: SaveIcon,
};

const Icons = ({
    height,
    width,
    icon,
}: {
    height: number;
    width: number;
    icon: IconKey | LazyIconKey;
}): JSX.Element => {
    if (LazyIconKeys.includes(icon)) {
        const LazyIcon = LAZY_ICONS[icon];
        return (
            <Suspense fallback={<div />}>
                <LazyIcon height={height} width={width} />
            </Suspense>
        );
    }
    /* @ts-ignore */
    const IconComponent = ICON_MAP[icon];
    return <IconComponent height={height} width={width} />;
};

export default Icons;
