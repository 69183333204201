import { ReduxActionWithPayload, ReduxAction } from 'types';
import { MemberGroup, MemberGroupFundingType } from 'api/Admin/MemberGroup';
import { Advertiser } from 'api/Advertiser/Advertiser.types';
import { AdvertiserGroup } from 'api/Admin/AdvertiserGroups';
import { CurrencyList } from 'api/Admin/Currency';
import { BiddingConfigReport } from 'api/Admin/Currency/Currency.types';

export type DefaultMemberGroup = {
    member_group_id: number;
};

export type AdvertiserGroupError = {
    id: number;
};

export type ContextMemberGroup =
    | (Partial<MemberGroup> & {
          funding_types?: MemberGroupFundingType[];
      })
    | null;
export type ContextAdvertiser = Partial<Advertiser> | null;

export type ContextTaxType = {
    label: string;
    value: string;
};

export type AppContextState = {
    memberGroup: ContextMemberGroup;
    previousMemberGroupId: number | null;
    advertiser: ContextAdvertiser;
    advertiserHasFunds: boolean;
    advertiserGroup: AdvertiserGroup | AdvertiserGroupError | null;
    status: string;
    initialized: boolean;
    errorCode: number | null;
    memberGroupCurrencies: CurrencyList | null;
    calculatedPageCurrency: number | null;
    memberGroupBiddingConfigs: BiddingConfigReport | null;
    taxTypes: ContextTaxType[] | null;
    savedReportTitle: string | null;
};

export type MemberGroupIndustrySelectorReturn = {
    singular: string;
    plural: string;
};

export enum AppContextAction {
    FETCH_APP_CONTEXT = 'app/context/FETCH_APP_CONTEXT',
    FETCH_APP_CONTEXT_SUCCESS = 'app/context/FETCH_APP_CONTEXT_SUCCESS',
    FETCH_APP_CONTEXT_ERROR = 'app/context/FETCH_APP_CONTEXT_ERROR',
    FETCH_EMBEDDED_APP_CONTEXT = 'app/context/FETCH_EMBEDDED_APP_CONTEXT',
    SET_PREVIOUS_MEMBER_GROUP = 'app/context/SET_PREVIOUS_MEMBER_GROUP',
    SET_ERROR_CODE = 'app/context/SET_ERROR_CODE',
    CLEAR_APP_CONTEXT = 'app/context/CLEAR_APP_CONTEXT',
    SET_MEMBER_GROUP_CURRENCIES = 'app/context/SET_MEMBER_GROUP_CURRENCIES',
    SET_MEMBER_GROUP_BIDDING_CONFIGS = 'app/context/SET_MEMBER_GROUP_BIDDING_CONFIGS',
    SET_CALCULATED_PAGE_CURRENCY = 'app/context/SET_CALCULATED_PAGE_CURRENCY',
    SET_TAX_TYPES = 'app/context/SET_TAX_TYPES',
    SET_ADVERTISER_HAS_FUNDS = 'app/context/SET_ADVERTISER_HAS_FUNDS',
    SET_SAVED_REPORT_TITLE = 'app/context/SET_SAVED_REPORT_TITLE',
}

export type FetchAppContextAction = ReduxActionWithPayload<
    AppContextAction.FETCH_APP_CONTEXT,
    {
        experiencesConfigurationFF: boolean;
        memberGroupId: number;
        advertiserId: number | null;
        advertiserGroupId: number | null;
    }
>;

export type FetchEmbeddedAppContextAction = ReduxActionWithPayload<
    AppContextAction.FETCH_EMBEDDED_APP_CONTEXT,
    {
        memberGroupId: number;
    }
>;

export type FetchAppContextSuccessAction = ReduxActionWithPayload<
    AppContextAction.FETCH_APP_CONTEXT_SUCCESS,
    {
        memberGroup: ContextMemberGroup;
        advertiser: ContextAdvertiser;
        advertiserGroup: AdvertiserGroup | null;
    }
>;

export type FetchAppContextError = ReduxActionWithPayload<
    AppContextAction.FETCH_APP_CONTEXT_ERROR,
    {
        memberGroupId: number;
        advertiserId: number | null;
        advertiserGroupId: number | null;
    }
>;

export type SetPreviousMemberGroup = ReduxActionWithPayload<
    AppContextAction.SET_PREVIOUS_MEMBER_GROUP,
    {
        previousMemberGroupId: number | null;
    }
>;

export type SetSavedReportTitle = ReduxActionWithPayload<
    AppContextAction.SET_SAVED_REPORT_TITLE,
    {
        savedReportTitle: string | null;
    }
>;

export type SetErrorCodeAction = ReduxActionWithPayload<
    AppContextAction.SET_ERROR_CODE,
    {
        errorCode: number | null;
    }
>;

export type ClearAppContext = ReduxAction<AppContextAction.CLEAR_APP_CONTEXT>;

export type SetMemberGroupCurrencies = ReduxActionWithPayload<
    AppContextAction.SET_MEMBER_GROUP_CURRENCIES,
    {
        memberGroupCurrencies: CurrencyList;
    }
>;
export type SetMemberGroupBiddingConfigs = ReduxActionWithPayload<
    AppContextAction.SET_MEMBER_GROUP_BIDDING_CONFIGS,
    {
        biddingConfigs: BiddingConfigReport;
    }
>;

export type SetCalculatedPageCurrency = ReduxActionWithPayload<
    AppContextAction.SET_CALCULATED_PAGE_CURRENCY,
    {
        calculatedPageCurrency: number;
    }
>;

export type SetTaxTypes = ReduxActionWithPayload<
    AppContextAction.SET_TAX_TYPES,
    {
        taxTypes: ContextTaxType[];
    }
>;

export type SetAdvertiserHasFunds = ReduxActionWithPayload<
    AppContextAction.SET_ADVERTISER_HAS_FUNDS,
    {
        hasFunds: boolean;
    }
>;

export type AppContextActions =
    | FetchAppContextSuccessAction
    | FetchAppContextError
    | FetchAppContextAction
    | SetPreviousMemberGroup
    | SetErrorCodeAction
    | ClearAppContext
    | SetMemberGroupCurrencies
    | SetMemberGroupBiddingConfigs
    | SetCalculatedPageCurrency
    | SetAdvertiserHasFunds
    | SetTaxTypes
    | SetAdvertiserHasFunds
    | SetSavedReportTitle
    | FetchEmbeddedAppContextAction
    | { type: 'ROUTE_MATCH_CHANGED' };
