import React, { FunctionComponent } from 'react';
import { BaseSVGProps } from '../icon.types';

const PlacePinShadowIcon: FunctionComponent<BaseSVGProps> = ({
    width,
    height,
}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 24 24"
    >
        <path d="M20.38 7.58A8.37 8.37 0 0011.25 0l-.51.1A8 8 0 004 5.83a8.15 8.15 0 002 8.58c1.22 1.23 2.46 2.41 3.66 3.59.78.78 1.54 1.56 2.33 2.36a1.79 1.79 0 00.19-.18L18 14.29a8.11 8.11 0 002.38-6.71zM12 10.18a1.77 1.77 0 111.8-1.79 1.78 1.78 0 01-1.8 1.79z" />
        <path
            d="M12 21.71c-3.79 0-6.86.52-6.86 1.15S8.21 24 12 24s6.86-.51 6.86-1.14-3.07-1.15-6.86-1.15z"
            className="icon-primary"
        />
    </svg>
);

export default PlacePinShadowIcon;
