import styled, { css } from 'styled-components';
import Select from 'koddi-components/Select';
import { TabList } from 'koddi-components/Tabs';

export const ChartCardHeaderWrapper = styled.div`
    ${({ theme }) => css`
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: ${theme.spaceUnit * 4}px 0px;
        nav:before {
            display: none;
        }
    `}
`;

export const ChartCardSelect = styled(Select)``;

export const ChartCardControls = styled.div`
    display: flex;
    align-items: center;
`;

export const ChartCardToggleText = styled.span`
    &:hover {
        cursor: pointer;
        text-decoration: underline;
    }
`;

export const ChartCardTabList = styled(TabList)`
    display: flex;
    align-items: center;
`;
