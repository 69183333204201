/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import KoddiAPI from 'api';
import { createSimpleSagaWithCache } from 'utils/createSimpleSaga';
import { takeEvery, all } from 'redux-saga/effects';
import SagaRegistry from 'redux-core/sagaRegistry';
import { RolePermissionList } from 'api/Admin/RolePermission';
import {
    getRolePermissionListError,
    getRolePermissionListSuccess,
} from './actions';
import { RolePermissionAction } from './types';
import { selectRolePermissionList } from './selectors';

export const getRolePermissionListApi = (): Promise<RolePermissionList> =>
    KoddiAPI.Admin.RolePermission.getList();

export const getRolePermissionListSaga = createSimpleSagaWithCache(
    getRolePermissionListApi,
    getRolePermissionListSuccess,
    getRolePermissionListError,
    selectRolePermissionList
);

export function* watchGetRolePermissionList() {
    yield takeEvery(
        RolePermissionAction.GET_ROLE_PERMISSION_LIST,
        getRolePermissionListSaga
    );
}

function* rolePermissionSagas() {
    yield all([watchGetRolePermissionList()]);
}

SagaRegistry.registerSaga(rolePermissionSagas);
