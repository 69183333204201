import produce, { Draft } from 'immer';

import { AppContextState, AppContextAction, AppContextActions } from './types';

export const initialState: AppContextState = {
    memberGroup: null,
    advertiser: null,
    advertiserHasFunds: false,
    advertiserGroup: null,
    previousMemberGroupId: null,
    status: 'idle',
    initialized: false,
    errorCode: null,
    memberGroupCurrencies: null,
    memberGroupBiddingConfigs: null,
    calculatedPageCurrency: null,
    taxTypes: null,
    savedReportTitle: null,
};

const appContextReducer = (
    state: AppContextState = initialState,
    action: AppContextActions
): AppContextState =>
    produce(state, (draft: Draft<AppContextState>) => {
        switch (action.type) {
            case AppContextAction.FETCH_APP_CONTEXT:
                draft.status = 'pending';
                break;
            case AppContextAction.FETCH_EMBEDDED_APP_CONTEXT:
                draft.status = 'pending';
                break;
            case AppContextAction.FETCH_APP_CONTEXT_SUCCESS:
                draft.status = 'success';
                draft.memberGroup = action.payload.memberGroup;
                draft.advertiser = action.payload.advertiser;
                draft.advertiserGroup = action.payload.advertiserGroup;
                draft.initialized = true;
                break;
            case AppContextAction.FETCH_APP_CONTEXT_ERROR:
                draft.status = 'error';
                draft.memberGroup = {
                    member_group_id: action.payload.memberGroupId,
                };
                draft.advertiser = action.payload.advertiserId
                    ? {
                          advertiser_id: action.payload.advertiserId,
                      }
                    : null;
                break;
            case AppContextAction.SET_PREVIOUS_MEMBER_GROUP:
                draft.previousMemberGroupId =
                    action.payload.previousMemberGroupId;
                break;
            case AppContextAction.SET_SAVED_REPORT_TITLE:
                draft.savedReportTitle = action.payload.savedReportTitle;
                break;
            case AppContextAction.SET_ERROR_CODE:
                draft.errorCode = action.payload.errorCode;
                break;
            case AppContextAction.CLEAR_APP_CONTEXT:
                draft.memberGroup = null;
                draft.advertiser = null;
                draft.advertiserGroup = null;
                draft.previousMemberGroupId = null;
                draft.savedReportTitle = null;
                draft.status = 'idle';
                break;
            case AppContextAction.SET_MEMBER_GROUP_CURRENCIES:
                draft.memberGroupCurrencies =
                    action.payload.memberGroupCurrencies;
                break;
            case AppContextAction.SET_MEMBER_GROUP_BIDDING_CONFIGS:
                draft.memberGroupBiddingConfigs = action.payload.biddingConfigs;
                break;
            case AppContextAction.SET_CALCULATED_PAGE_CURRENCY:
                draft.calculatedPageCurrency =
                    action.payload.calculatedPageCurrency;
                break;
            case AppContextAction.SET_ADVERTISER_HAS_FUNDS:
                draft.advertiserHasFunds = action.payload.hasFunds;
                break;
            case AppContextAction.SET_TAX_TYPES:
                draft.taxTypes = action.payload.taxTypes;
                break;
            default:
                break;
        }
    });

export default appContextReducer;
