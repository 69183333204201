/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import KoddiAPI from 'api';
import { createSimpleSagaWithCache } from 'utils/createSimpleSaga';
import { takeEvery, all } from 'redux-saga/effects';
import SagaRegistry from 'redux-core/sagaRegistry';
import { IndustryList } from 'api/Admin/Industry';
import { getIndustryListError, getIndustryListSuccess } from './actions';
import { IndustryAction } from './types';
import { selectIndustryList } from './selectors';

export const getIndustryListApi = (): Promise<IndustryList> =>
    KoddiAPI.Admin.Industry.getList();

export const getIndustryListSaga = createSimpleSagaWithCache(
    getIndustryListApi,
    getIndustryListSuccess,
    getIndustryListError,
    selectIndustryList
);

export function* watchGetIndustryList() {
    yield takeEvery(IndustryAction.GET_INDUSTRY_LIST, getIndustryListSaga);
}

function* industrySagas() {
    yield all([watchGetIndustryList()]);
}

SagaRegistry.registerSaga(industrySagas);
