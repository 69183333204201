import { ActivityList } from 'api/Admin/Activity';
import {
    GetActivityListAction,
    ActivityAction,
    GetActivityListSuccessAction,
    GetActivityListErrorAction,
} from './types';

export function getActivityList(refresh?: boolean): GetActivityListAction {
    return {
        type: ActivityAction.GET_ACTIVITY_LIST,
        payload: { refresh: refresh || false },
    };
}

export function getActivityListSuccess(
    data: ActivityList
): GetActivityListSuccessAction {
    return {
        type: ActivityAction.GET_ACTIVITY_LIST_SUCCESS,
        payload: { data },
    };
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function getActivityListError(error: any): GetActivityListErrorAction {
    return {
        type: ActivityAction.GET_ACTIVITY_LIST_ERROR,
        error,
    };
}
