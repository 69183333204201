/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
import React, { FunctionComponent } from 'react';
import { useKoddiTabs } from '../../Tabs.hooks';
import { TTabListProps } from './TabList.types';
import Tab from '../Tab';
import { createTabId, createTabPaneId } from '../../Tabs.utils';
import { TTabItemWithIndex } from '../../Tabs.types';
import { TabListBase } from './TabList.styled';

/**
 * The `TabList` renders all of the nav items
 * for the `Tabs`. This component uses a hook
 * to hook into the `TabsContext` to render
 * the tab items.
 *
 * This component can be rendered anywhere in the component
 * tree so long as it is wrapped in the `TabsProvider`.
 * This allows for building custom tab ui's.
 */
const TabList: FunctionComponent<TTabListProps> = ({
    forwardedRef,
    ...tabListProps
}) => {
    const { id, tabItems } = useKoddiTabs();

    function renderTab(props: TTabItemWithIndex) {
        const { title, index, tabComponent, key, ...restTabProps } = props;
        const tabId = createTabId(id, index);
        return (
            <Tab
                {...restTabProps}
                id={tabId}
                title={title}
                tabPaneId={createTabPaneId(id, index)}
                tabKey={key || index}
                key={tabId}
                tabComponent={tabComponent}
                index={index}
            >
                {title}
            </Tab>
        );
    }

    function renderTabs() {
        return tabItems.map((tabItem: TTabItemWithIndex) => renderTab(tabItem));
    }

    return (
        <TabListBase
            {...tabListProps}
            id={`${id}-tablist`}
            role="tablist"
            ref={forwardedRef}
        >
            {renderTabs()}
        </TabListBase>
    );
};

const TabListWithForwardedRef = React.forwardRef<HTMLElement, TTabListProps>(
    (props, ref) => <TabList {...props} forwardedRef={ref} />
);

export default TabListWithForwardedRef;
