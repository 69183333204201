/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import KoddiAPI from 'api';
import createSimpleSaga, {
    createSimpleSagaWithCache,
} from 'utils/createSimpleSaga';
import { takeEvery, all } from 'redux-saga/effects';
import SagaRegistry from 'redux-core/sagaRegistry';
import { KoddiUserList, KoddiUser } from 'api/Admin/User';
import { updateUserSuccess } from 'redux-core/auth/actions';
import {
    getUserListError,
    getUserListSuccess,
    createNewUserSuccess,
    createNewUserError,
    updateUserError,
} from './actions';
import { UserAction, CreateNewUserAction, UpdateUserAction } from './types';
import { showAdminAlertSuccess } from '../alert/actions';
import {
    closeEditor,
    editorSubmitSuccess,
    editorSubmitError,
} from '../editor/actions';
import { selectUserList } from './selectors';

// Get User List Sagas

export const getUserListApi = (): Promise<KoddiUserList> =>
    KoddiAPI.Admin.User.getList();

export const getUserListSaga = createSimpleSagaWithCache(
    getUserListApi,
    getUserListSuccess,
    getUserListError,
    selectUserList
);

export function* watchGetUserList() {
    yield takeEvery(UserAction.GET_USER_LIST, getUserListSaga);
}

// Create New User Sagas

export const createNewUserApi = (
    action: CreateNewUserAction,
    memberGroupId?: number,
    advertiserId?: string
): Promise<KoddiUser> => {
    return KoddiAPI.Admin.User.create(
        action.payload.newUser,
        memberGroupId,
        advertiserId
    );
};

export const createNewUserSaga = createSimpleSaga(
    createNewUserApi,
    [
        createNewUserSuccess,
        editorSubmitSuccess,
        closeEditor,
        () => showAdminAlertSuccess(),
    ],
    [createNewUserError, editorSubmitError]
);

// Update USer Sagas

export const updateUserApi = (action: UpdateUserAction): Promise<any> =>
    KoddiAPI.Admin.User.update(
        action.payload.userData.user_id,
        action.payload.userData
    );

export const updateUserSaga = createSimpleSaga(
    updateUserApi,
    [
        updateUserSuccess,
        editorSubmitSuccess,
        closeEditor,
        () => showAdminAlertSuccess(),
    ],
    [updateUserError, editorSubmitError]
);

export function* watchUpdateUser() {
    yield takeEvery(UserAction.UPDATE_USER, updateUserSaga);
}

export function* watchCreateNewUser() {
    yield takeEvery(UserAction.CREATE_NEW_USER, createNewUserSaga);
}

export function* watchAdminEditor() {
    yield takeEvery(UserAction.GET_USER_LIST, getUserListSaga);
}

function* userSagas() {
    yield all([watchGetUserList(), watchCreateNewUser(), watchUpdateUser()]);
}

SagaRegistry.registerSaga(userSagas);
