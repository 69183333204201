import styled, { css } from 'styled-components';

export const FadeTransitionContent = styled.div<{ duration: number }>`
    ${({ duration }) => css`
        will-change: opacity;
        transform: transale3d(0, 0, 0);
        &.appear {
            opacity: 0;
        }
        &.appear-active {
            opacity: 1;
            transition: opacity ${duration}ms;
        }
        &.appear-done {
            opacity: 1;
        }

        &.enter {
            opacity: 0;
        }
        &.enter-active {
            opacity: 1;
            transition: opacity ${duration}ms;
        }
        &.enter-done {
            opacity: 1;
        }

        &.exit {
            opacity: 1;
        }
        &.exit-active {
            opacity: 0;
            transition: opacity ${duration}ms;
        }
        &.exit-done {
            opacity: 0;
        }
    `}
`;
