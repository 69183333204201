import { useCallback } from 'react';
import { usePermissions } from 'koddi-components/PermissionsProvider';
import { useAuthStatus } from 'redux-core/auth/hooks';

import { GuardType, GuardsType } from './types';
import canViewGuard from './canView';
import requireAuthentication from './requireAuthentication';

export function useCanViewGuard(): GuardType {
    const { map: userPermissionMap, roles: userRoles } = usePermissions();
    const requirePermission = useCallback(
        (to: any, from: any, next: any) =>
            canViewGuard(to, from, next, { userPermissionMap, userRoles }),
        [userPermissionMap, userRoles]
    );
    return requirePermission;
}

export function useAuthGuard(): GuardType {
    const authStatus = useAuthStatus();
    const authGuard = useCallback(
        (to: any, from: any, next: any) =>
            requireAuthentication(to, from, next, {
                authStatus,
            }),
        [authStatus]
    );
    return authGuard;
}

export function useGuards(): GuardsType {
    const canView = useCanViewGuard();
    const auth = useAuthGuard();
    return [canView, auth];
}
