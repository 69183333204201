import { KoddiThemeBase } from '../ThemeProvider.types';
import logoSrc from '../images/koddi_logo.png';
import Favicon from '../images/favicons/koddi.png';

const defaultKoddiTheme: KoddiThemeBase = {
    name: 'Koddi',
    spaceUnit: 4,
    logoSrc,
    loginLogoSrc: logoSrc,
    faviconSrc: Favicon,
    primary: '#00a5df',
    linkColor: '#3A7BF4',
    secondary: '#231f20',
    grayLightest: '#F6F6F6',
    grayLighter: '#EEEEEE',
    grayLight: '#E2E2E2',
    gray: '#AFAFAF',
    grayDark: '#757575',
    grayDarker: '#545454',
    grayDarkest: '#2b2b2b',
    black: '#000000',
    white: '#ffffff',
    btnPrimary: '#00a5df',
    btnColorPrimary: '#ffffff',
    btnSecondary: 'transparent',
    btnColorSecondary: '#000000',
    success: '#21A453',
    error: '#D44333',
    errorBackground: '#FBEFEE',
    warning: '#EAB655',
    info: '#3A7BF4',
    graphColors: ['#5B91F4', '#3CCECE'],
    opacity: {
        disabled: 0.6,
    },
    typography: {
        fontFamily: `'Noto Sans', Arial, sans-serif`,
        baseFontSize: '13px',
    },
    headerBackground: '#000000',
    headerTextColor: '#ffffff',
    advertiserSelectorBackground: '#2b2b2b',
    advertiserSelectorFontColor: '#ffffff',
    advertiserSelectorHoverBackground: '#545454',
    advertiserSelectorActiveBackground: '#bbb',
    pclScaleBackgroundColor: '#f3f2f2',
    pclScalePlayerCell: '#40a5ed',
    pclScaleLeaderCell: '#FF8042',
    pclScaleCompetitorCell: '#FFBB28',
    login: {
        layout: 'default',
        key: '',
    },
    tooltip: {
        arrowSize: 10,
        maxWidth: 250,
    },
    zIndex: {
        default: 1,
        sticky: 300,
        header: 400,
        toast: 500,
        dropdown: 600,
        overlay: 700,
        spinner: 800,
        modal: 900,
        popup: 950,
        popper: 1000,
    },
};

export default defaultKoddiTheme;
