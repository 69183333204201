/* eslint-disable no-unused-vars */
import React, {
    FunctionComponent,
    useState,
    useEffect,
    useCallback,
} from 'react';
import numeral from 'numeral';
import { Currency } from 'api/Admin/Currency';
import {
    LocaleProviderContextValue,
    KoddiLocaleKey,
    LocaleProviderProps,
} from './LocaleProvider.types';
import { formatDate, isValidKoddiLocale } from './LocaleProvider.utils';
import { KODDI_LOCALE_TO_NUMERAL } from './LocaleProvider.constants';

export const LocaleProviderContext = React.createContext<
    LocaleProviderContextValue | undefined
>(undefined);

/**
 * The `LocaleProvider` provides child components with the current
 * global locale and also provides utility functions for localizing text
 * using the current locale.
 */
const LocaleProvider: FunctionComponent<LocaleProviderProps> = ({
    children,
    initialLocale,
}) => {
    const initialLocaleIsValid = isValidKoddiLocale(initialLocale as string);
    const [locale, changeLocale] = useState<KoddiLocaleKey>(
        initialLocaleIsValid ? initialLocale || 'en-US' : 'en-US'
    );
    const [currencySymbol, setCurrencySymbol] = useState<string>('$');
    const [currencyDigits, setCurrencyDigits] = useState<number | undefined>(2);
    const [currencyVal, setCurrencyValue] = useState<any>(null);

    const handleChangeLocale = useCallback((possibleLocale: KoddiLocaleKey) => {
        const localeIsValid = isValidKoddiLocale(possibleLocale as string);
        changeLocale(localeIsValid ? possibleLocale : 'en-US');
        numeral.locale(KODDI_LOCALE_TO_NUMERAL[possibleLocale] || 'en');
    }, []);

    const handleCurrencyChange = useCallback(
        (currency: Currency) => {
            setCurrencySymbol(currency.symbol);
            setCurrencyDigits(currency.digits);
            setCurrencyValue(currency);
        },
        [setCurrencySymbol, setCurrencyDigits]
    );

    const value: LocaleProviderContextValue = {
        locale,
        changeLocale: handleChangeLocale,
        formatDate: (date: Date, format?: string) =>
            formatDate(date, locale, format),
        setCurrencySymbol,
        setCurrencyDigits,
        currencySymbol,
        currencyDigits,
        changeCurrency: handleCurrencyChange,
        currency: currencyVal,
    };
    // remove

    useEffect(() => {
        if (initialLocale && isValidKoddiLocale(initialLocale)) {
            handleChangeLocale(initialLocale);
        }
    }, [handleChangeLocale, initialLocale, locale]);

    if (numeral.locales) {
        return (
            <LocaleProviderContext.Provider value={value}>
                {children}
            </LocaleProviderContext.Provider>
        );
    }
    return null;
};

export default LocaleProvider;
