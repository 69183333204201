import React, { useMemo, useState, useEffect, useCallback } from 'react';
import differenceInDays from 'date-fns/differenceInDays';
import format from 'date-fns/format';
import subDays from 'date-fns/subDays';
import addDays from 'date-fns/addDays';

import KoddiAPI from 'api/api.instance';

import ChartCard from 'koddi-components/ChartCard';
import { valueFormatter } from 'koddi-components/hooks';
import { useLocale } from 'koddi-components/LocaleProvider';

import { ChartCardWrapper } from 'components/DimensionOverview/DimensionOverview.styled';

import { useSelectedAdvertisersIds } from 'redux-core/app/advertisers/hooks';
import {
    useReportConfig,
    useAppDateRangeFilter,
    useReportMetricOptions,
} from 'redux-core/app/filters/hooks';
import { useKPICardData } from 'redux-core/app/dashboard/hooks';
import {
    useMemberGroupId,
    useAppContextStatus,
} from 'redux-core/app/context/hooks';

import { selectValueType } from 'features/Dashboard/dashboard.types';
import { getOverviewReportPayload } from 'features/Dashboard/dashboard.utils';

type DashboardChartData = {
    date: string;
    prior: number;
    prior_date: string;
    selected: number;
};

const ChartComponent = (): JSX.Element => {
    const { currencySymbol, currencyDigits, currency } = useLocale();
    const reportConfig = useReportConfig();
    const KPICardData = useKPICardData();
    const advertiserIds = useSelectedAdvertisersIds();
    const memberGroupId = useMemberGroupId();
    const { startDate, endDate } = useAppDateRangeFilter();
    const reportMetricOptions = useReportMetricOptions();
    const contextStatus = useAppContextStatus();

    const [selectValue, setSelectValue] = useState<selectValueType>({
        label:
            reportConfig?.metrics[reportConfig?.default_graph_metric]?.name ||
            '',
        value: reportConfig?.default_graph_metric || '',
    });

    useEffect(() => {
        if (selectValue.label === '' && reportConfig) {
            setSelectValue({
                label:
                    reportConfig.metrics[reportConfig.default_graph_metric]
                        .name,
                value: reportConfig.default_graph_metric,
            });
        }
    }, [selectValue.label, reportConfig]);

    const kpiCardMetrics = useMemo(() => {
        if (!reportConfig) return [];
        return reportConfig.field_order.general
            ?.filter((field) => {
                return (
                    reportConfig.metrics[field.field_id] &&
                    field.is_selected_by_default
                );
            })
            .sort((field1, field2) => {
                return field1.order - field2.order;
            })
            .map((field) => {
                return reportConfig.metrics[field.field_id];
            })
            .filter((field) => {
                return field.id in KPICardData;
            });
    }, [reportConfig, KPICardData]);

    const currentChartMetric = kpiCardMetrics.find(
        (data) => data.name === selectValue.label
    );

    const filteredReportMetricOptions = useMemo(() => {
        return reportMetricOptions.filter(
            (metricOption) => metricOption.value !== 'remaining_days'
        );
    }, [reportMetricOptions]);

    const trendReportPayload = useMemo(() => {
        return getOverviewReportPayload(
            startDate,
            endDate,
            'trend',
            selectValue.value,
            memberGroupId,
            currency || null,
            advertiserIds
        );
    }, [
        advertiserIds,
        endDate,
        startDate,
        selectValue,
        memberGroupId,
        currency,
    ]);

    const changeMetric = useCallback((option: any) => {
        setSelectValue(option);
    }, []);

    /* @ts-ignore */
    const getTrendData: Promise<DashboardChartData[]> = useCallback(() => {
        async function fetchTrendData(resolve: any, reject: any) {
            if (contextStatus === 'pending') return;
            if (!advertiserIds.length) {
                const datesArray: DashboardChartData[] = [];
                const days = differenceInDays(endDate, startDate);

                let i = 0;
                let date = subDays(new Date(startDate), 1);
                do {
                    i += 1;
                    const today = addDays(date, 1);
                    date = today;
                    datesArray.push({
                        date: format(today, 'yyyy-LL-dd'),
                        prior_date: format(subDays(today, 1), 'yyyy-LL-dd'),
                        selected: 0,
                        prior: 0,
                    });
                } while (i < days);

                resolve({
                    dates: datesArray,
                    totals: {
                        selected: 0,
                        prior: 0,
                    },
                });
            }
            try {
                const response = await KoddiAPI.Report.fetchTrendReport(
                    trendReportPayload
                );
                resolve(response);
            } catch (error) {
                reject(error);
            }
        }

        return new Promise(fetchTrendData);
    }, [advertiserIds, trendReportPayload, startDate, endDate, contextStatus]);

    return (
        <ChartCardWrapper>
            <ChartCard<DashboardChartData>
                allowHide={false}
                id="overview-chart"
                responsiveness={{
                    width: '100%',
                    minHeight: '250px',
                    maxHeight: '250px',
                }}
                asyncWrapperDimensions={{
                    minHeight: '370px',
                }}
                dataMarkers={[
                    {
                        dataKey: 'prior',
                        key: 'prior',
                        date: 'Prior Period',
                        dataType: 'total',
                        presentation: currentChartMetric?.presentation,
                    },
                    {
                        dataKey: 'selected',
                        key: 'selected',
                        date: 'Selected Period',
                        dataType: 'total',
                        presentation: currentChartMetric?.presentation,
                    },
                ]}
                formatYAxisTick={(value: any) => {
                    return valueFormatter(
                        value,
                        currentChartMetric?.presentation,
                        {
                            currencySymbol,
                            currencyDigits,
                        }
                    );
                }}
                xAxisDataKey="date"
                /* @ts-ignore */
                api={getTrendData}
                reloadApi={trendReportPayload}
                selectOptions={filteredReportMetricOptions}
                selectValue={selectValue}
                onSelectChange={changeMetric}
            />
        </ChartCardWrapper>
    );
};

export default ChartComponent;
