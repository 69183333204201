import React, { FunctionComponent } from 'react';
import { BaseSVGProps } from '../icon.types';

const GraphIcon: FunctionComponent<BaseSVGProps> = ({ width, height }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width={width}
        height={height}
    >
        <path d="M16.36 10.91H12v7.64h4.36zm6.55-7.64h-4.36v15.28h4.36zM3.27 20.73V0H0v24h24v-3.27zM9.82 4.36H5.45v14.19h4.37z" />
    </svg>
);

export default GraphIcon;
