import { CreateReportInput } from 'api/Reports';
import {
    KPICardDataAction,
    FetchKPICardDataAction,
    FetchKPICardDataSuccessAction,
    FetchKPICardDataErrorAction,
    ReportTotal,
} from './types';

export function fetchKPICardData(
    reportPayload: CreateReportInput
): FetchKPICardDataAction {
    return {
        type: KPICardDataAction.FETCH_KPI_CARD_DATA,
        payload: {
            reportPayload,
        },
    };
}

export function fetchKPICardDataSuccess(
    data: ReportTotal[]
): FetchKPICardDataSuccessAction {
    return {
        type: KPICardDataAction.FETCH_KPI_CARD_DATA_SUCCESS,
        payload: {
            data,
        },
    };
}

export function fetchKPICardDataError(
    error: string
): FetchKPICardDataErrorAction {
    return {
        type: KPICardDataAction.FETCH_KPI_CARD_DATA_ERROR,
        payload: {
            error,
        },
    };
}
