import { checkPermissionsAndRole } from 'koddi-components/CanView';

import { GuardFunction } from './types';

export const canViewGuard: GuardFunction = (to, from, next, params): void => {
    if (to.meta.canView) {
        const { userRoles, userPermissionMap } = params;
        const {
            permission: routePermission,
            role: routeRole,
            anyPermission = false,
            anyRole = false,
            condition,
        } = to.meta.canView;

        if (routePermission && !Object.keys(userPermissionMap).length) {
            next();
        }
        if (routeRole && !userRoles.length) {
            next();
        }

        const canView = checkPermissionsAndRole(
            routeRole,
            routePermission,
            userRoles,
            userPermissionMap,
            condition,
            anyRole,
            anyPermission
        );
        if (canView) {
            next();
        } else {
            throw new Error();
        }
    } else {
        next();
    }
};

export default canViewGuard;
