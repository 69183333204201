/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import KoddiAPI from 'api';
import { takeEvery, call, all, put } from 'redux-saga/effects';
import SagaRegistry from 'redux-core/sagaRegistry';
import {
    ThemeAction,
    UpdateThemeAction,
    CreateThemeAction,
    DeleteThemeAction,
    GetThemeByMemberGroupHostname,
} from './types';
import {
    setThemeList,
    changeTheme,
    setThemeStatus,
    themeError,
} from './actions';

export function* getThemeListSaga() {
    try {
        const themes = yield call(KoddiAPI.Theme.getList);
        yield put(setThemeList(themes));
        yield put(setThemeStatus('success'));
    } catch {
        yield put(setThemeStatus('error'));
    }
}

export function* getThemeByMemberGroupIdSaga(action: any) {
    try {
        const theme = yield call(
            KoddiAPI.Theme.get,
            action.payload.memberGroupId as number
        );
        yield put(changeTheme(theme));
        yield put(setThemeStatus('success'));
    } catch {
        yield put(setThemeStatus('error'));
        yield put(
            themeError({
                memberGroupId: action.payload.memberGroupId,
            })
        );
    }
}

export function* getThemeByMemberGroupHostnameSaga(
    action: GetThemeByMemberGroupHostname
) {
    try {
        const theme = yield call(
            KoddiAPI.Admin.MemberGroup.getThemeByHostname,
            action.payload.hostname as string
        );
        yield put(changeTheme(theme));
        yield put(setThemeStatus('success'));
    } catch {
        yield put(setThemeStatus('error'));
        yield put(
            themeError({
                memberGroupId: 1,
            })
        );
    }
}

export function* updateThemeSaga(action: UpdateThemeAction) {
    const {
        payload: { theme, onSuccess, onFailure },
    } = action;
    const response = yield call(KoddiAPI.Theme.update, theme);
    if (response.status === 'success') {
        const themes = yield call(KoddiAPI.Theme.getList);
        yield put(changeTheme(theme));
        yield put(setThemeList(themes));
        if (onSuccess) onSuccess();
    } else if (onFailure) {
        onFailure();
    }
}

export function* createThemeSaga(action: CreateThemeAction) {
    const {
        payload: { theme, onSuccess, onFailure },
    } = action;
    const response = yield call(KoddiAPI.Theme.create, theme);
    if (response.status === 'success') {
        const themes = yield call(KoddiAPI.Theme.getList);
        yield put(changeTheme(theme));
        yield put(setThemeList(themes));
        if (onSuccess) onSuccess();
    } else if (onFailure) {
        onFailure();
    }
}

export function* deleteThemeSaga(action: DeleteThemeAction) {
    const {
        payload: { theme, onSuccess, onFailure },
    } = action;
    const response = yield call(
        KoddiAPI.Theme.delete as any,
        theme.member_group_id
    );
    if (response.status === 'success') {
        yield getThemeListSaga();
        if (onSuccess) onSuccess();
    } else if (onFailure) {
        onFailure();
    }
}

export function* watchGetThemeList() {
    yield takeEvery(ThemeAction.GET_THEME_LIST, getThemeListSaga);
    yield takeEvery(
        ThemeAction.GET_THEME_BY_MEMBER_GROUP_ID,
        getThemeByMemberGroupIdSaga
    );
    yield takeEvery(
        ThemeAction.GET_THEME_BY_MEMBER_GROUP_HOSTNAME,
        getThemeByMemberGroupHostnameSaga
    );
    yield takeEvery(ThemeAction.UPDATE_THEME, updateThemeSaga);
    yield takeEvery(ThemeAction.CREATE_THEME, createThemeSaga);
    yield takeEvery(ThemeAction.DELETE_THEME, deleteThemeSaga);
}

function* appThemeSagas() {
    yield all([watchGetThemeList()]);
}

SagaRegistry.registerSaga(appThemeSagas);
