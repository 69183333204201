import { KoddiAPIResponse } from 'types';
import KoddiObjectAPI from '../../api.object';
import { ActivityList, Activity } from './Activity.types';

const KODDI_ACTIVITY_URL = '/activity';

class ActivityAPI extends KoddiObjectAPI {
    public create = async (): Promise<Activity> => {
        const response = await this.axios.get<KoddiAPIResponse<Activity>>(
            `v1${KODDI_ACTIVITY_URL}`
        );
        return response.data.result;
    };

    public get = async (): Promise<Activity> => {
        const response = await this.axios.get<KoddiAPIResponse<Activity>>(
            `v1${KODDI_ACTIVITY_URL}`
        );
        return response.data.result;
    };

    public update = async (): Promise<Activity> => {
        const response = await this.axios.get<KoddiAPIResponse<Activity>>(
            `v1${KODDI_ACTIVITY_URL}`
        );
        return response.data.result;
    };

    public delete = async (): Promise<Activity> => {
        const response = await this.axios.get<KoddiAPIResponse<Activity>>(
            `v1${KODDI_ACTIVITY_URL}`
        );
        return response.data.result;
    };

    public getList = async (): Promise<ActivityList> => {
        const response = await this.axios.get<KoddiAPIResponse<ActivityList>>(
            `v1${KODDI_ACTIVITY_URL}`
        );
        return response.data.result;
    };
}

export default ActivityAPI;
