import React from 'react';
import { VerticalFlexBox, HorizontalFlexBox } from 'koddi-components/FlexBox';
import { valueFormatter } from 'koddi-components/hooks';
import { useLocale } from 'koddi-components/LocaleProvider';
import { ChartLegendProps } from './ChartLegend.types';
import {
    ChartLegendWrapper,
    ChartLegendItem,
    ChartLegendItemColor,
    ChartLegendItemHeader,
    ChartLegendItemText,
} from './ChartLegend.styled';

function ChartLegend<Data extends Record<string, unknown>>({
    dataMarkers,
    colorScheme,
    data,
    id,
}: ChartLegendProps<Data>): JSX.Element {
    const { currencySymbol, currencyDigits, locale } = useLocale();
    return (
        <ChartLegendWrapper>
            <HorizontalFlexBox>
                {dataMarkers.map((dataMarker, index) => {
                    return (
                        <ChartLegendItem
                            key={dataMarker.key}
                            data-test={`${id}-legend-${dataMarker.key}`}
                        >
                            <VerticalFlexBox>
                                <ChartLegendItemHeader
                                    data-test={`${id}-legend-${dataMarker.key}-value`}
                                >
                                    {valueFormatter(
                                        data[dataMarker?.key],
                                        dataMarker.presentation,
                                        {
                                            currencySymbol,
                                            currencyDigits,
                                        },
                                        locale
                                    )}
                                </ChartLegendItemHeader>
                                <HorizontalFlexBox alignItems="center">
                                    <ChartLegendItemColor
                                        backgroundColor={colorScheme[index]}
                                        data-test={`${id}-legend-${dataMarker.key}-color`}
                                    />
                                    <ChartLegendItemText
                                        data-test={`${id}-legend-${dataMarker.key}-name`}
                                    >
                                        {dataMarker.date}
                                    </ChartLegendItemText>
                                </HorizontalFlexBox>
                            </VerticalFlexBox>
                        </ChartLegendItem>
                    );
                })}
            </HorizontalFlexBox>
        </ChartLegendWrapper>
    );
}

export default ChartLegend;
