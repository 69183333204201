import styled, { css } from 'styled-components';
import { fadeIn } from 'koddi-components/animations';
import Menu, {
    MenuItemText,
    MenuItemIcon,
    MenuItemLink,
    MenuItemLI,
    SubMenuUL,
} from 'koddi-components/Menu';
import Icon from 'koddi-components/Icon';
import { SideNavContainerProps } from './SideNav.types';

export const SideNavContainer = styled.div<SideNavContainerProps>`
    ${({ theme, open, contextChanging }) => css`
        height: 100%;
        width: ${open ? 200 : 78}px;
        transition: width 0.4s ease-in-out;
        padding: ${theme.spaceUnit * 8}px 0px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: relative;
        z-index: ${theme.zIndex.sticky};
        ${MenuItemText} {
            opacity: ${open ? 1 : 0};
            transition: opacity 0.2s ease-in-out;
            transition-delay: ${open ? 0.2 : 0}s;
            word-break: break-word;
        }
        ${contextChanging &&
        css`
            ${MenuItemIcon} {
                background-color: ${theme.grayLighter};
                color: transparent;
                opacity: 0.1;
                border-radius: 100px;
                &:hover {
                    fill: #444;
                    color: transparent;
                    z-index: -1000;
                }
            }
            ${MenuItemLI} {
                &:before {
                    content: '';
                    position: absolute;
                    background-color: transparent;
                }
            }
        `}

        ${contextChanging &&
        open &&
        css`
            @keyframes fading {
                0% {
                    opacity: 0.1;
                }

                50% {
                    opacity: 0.2;
                }

                100% {
                    opacity: 0.1;
                }
            }
            &:hover ${MenuItemText}, &:hover ${MenuItemIcon} {
                color: transparent;
            }
            ${MenuItemText} {
                display: inline-block;
                background-color: ${theme.gray};
                height: 20px;
                border-radius: 100px;
                width: ${open ? 140 : 78}px;
                color: transparent;
                opacity: ${open ? 0.1 : 0};
                animation: fading 1.5s infinite;
                &:hover {
                    color: transparent;
                }
            }
            ${MenuItemLink} {
                color: transparent;
            }
        `}
    `}
`;

export const NavMenu = styled(Menu)`
    ${({ theme, contextChanging, isOpen }) => css`
        width: 200px;
        padding: 0px ${theme.spaceUnit * 8}px;
        ${!isOpen &&
        css`
            ${SubMenuUL} {
                border: none;
                height: 0px;
                margin: 0px;
            }
        `}
        ${contextChanging &&
        css`
            ${MenuItemIcon} {
                background-color: ${theme.grayLighter};
                color: transparent;
                opacity: 0.001;
                border-radius: 100px;
                &:hover,
                &:active {
                    fill: #444;
                    color: ${theme.grayLighter};
                    z-index: -1000;
                }
            }
        `}
    `}
`;

export const CloseNavButton = styled(Icon)<{ open: boolean }>`
    ${({ theme, open }) => css`
        height: 20px;
        width: 20px;
        border-radius: 50%;
        position: absolute;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: ${theme.grayLightest};
        right: -10px;
        top: ${theme.spaceUnit * 8}px;
        color: ${theme.grayDark};
        z-index: ${theme.zIndex.modal};
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12);
        &:hover {
            cursor: pointer;
        }
        svg {
            transform: rotateY(${open ? '0deg' : '180deg'});
            transition: transform 0.6s ease-in-out;
        }
    `}
`;

export const Footer = styled.div`
    ${({ theme }) => css`
        display: flex;
        justify-content: center;
        a {
            text-decoration: underline;
            font-size: 12px;
            color: ${theme.grayDark};
        }
    `}
`;

export const TermsAndConditions = styled.label`
    animation: ${fadeIn} 0.2s linear both;
    animation-delay: 0.4s;
    cursor: pointer;
`;
