import React, { FunctionComponent, useCallback } from 'react';
import Button from 'koddi-components/Button';
import { HorizontalFlexBox, VerticalFlexBox } from 'koddi-components/FlexBox';
import { ConfirmationModalProps } from './ConfirmationModal.types';
import {
    ConfirmationModalHeading,
    ConfirmationModalMessage,
    ConfirmationModalBase,
    ConfirmationModalIcon,
} from './ConfirmationModal.styled';

/**
 * A generic confirmation modal for confirming some action.
 */
export const ConfirmationModal: FunctionComponent<ConfirmationModalProps> = ({
    title,
    message,
    submitButtonText = 'OK',
    submitButtonStyle,
    cancelButtonText = 'Cancel',
    onCancel,
    onSubmit,
    closeModal,
    shouldCloseOnEsc = true,
    shouldCloseOnOverlayClick = true,
    closeOnSubmit = true,
    btnErrorBackground = false,
    displayVertically = false,
    hideCancelButton = false,
    iconPadding,
    icon,
    v2,
    ...rest
}) => {
    const handleCancel = useCallback(() => {
        if (onCancel) onCancel();
        closeModal();
    }, [closeModal, onCancel]);

    const handleSubmit = useCallback(() => {
        if (closeOnSubmit) closeModal();
        onSubmit();
    }, [onSubmit, closeModal, closeOnSubmit]);

    return (
        <ConfirmationModalBase
            {...rest}
            role="alertdialog"
            shouldCloseOnEsc={shouldCloseOnEsc}
            shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
            aria={{
                labelledby: 'modal-title',
                describedby: 'modal-message',
            }}
            onRequestClose={closeModal}
            displayVertically
            v2={v2}
        >
            {icon && (
                <ConfirmationModalIcon
                    icon={icon}
                    width={60}
                    height={60}
                    iconPadding={iconPadding}
                />
            )}
            <ConfirmationModalHeading
                displayVertically={displayVertically}
                id="modal-title"
            >
                {title}
            </ConfirmationModalHeading>
            <ConfirmationModalMessage
                displayVertically={displayVertically}
                id="modal-message"
            >
                {message}
            </ConfirmationModalMessage>
            {displayVertically ? (
                <VerticalFlexBox alignItems="center" justifyContent="flex-end">
                    <Button
                        autoFocus
                        errorBackground={btnErrorBackground}
                        onClick={handleSubmit}
                        btnStyle={submitButtonStyle}
                        v2={v2}
                    >
                        {submitButtonText}
                    </Button>
                    {!hideCancelButton && (
                        <Button
                            v2={v2}
                            onClick={handleCancel}
                            btnStyle="secondary"
                        >
                            {cancelButtonText}
                        </Button>
                    )}
                </VerticalFlexBox>
            ) : (
                <HorizontalFlexBox
                    alignItems="center"
                    justifyContent="flex-end"
                >
                    <Button v2={v2} onClick={handleCancel} btnStyle="secondary">
                        {cancelButtonText}
                    </Button>
                    <Button
                        autoFocus
                        errorBackground={btnErrorBackground}
                        onClick={handleSubmit}
                        btnStyle={submitButtonStyle}
                        v2={v2}
                    >
                        {submitButtonText}
                    </Button>
                </HorizontalFlexBox>
            )}
        </ConfirmationModalBase>
    );
};

export default ConfirmationModal;
