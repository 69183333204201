/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import produce, { Draft } from 'immer';
import { Reducer, AnyAction } from 'redux';
import { AsyncState } from 'types';

type ActionMap = {
    start: string;
    success: string;
    error: string;
};

type AsyncReducerOptions<Data, InitialData, State, Actions> = {
    transformDataOnSuccess?: (data: InitialData) => Data;
    extendReducer?: (
        action: Actions,
        draft: Draft<State>,
        state: State
    ) => void;
};

/**
 * A higher order function that creates a reducer that handles asynchronous
 * data. Most of our asynchronous reducers follow the same paradigm so
 * we extract it into a factory function to cover most use cases.
 * @param initialState The initial state of the reducer.
 * @param start The name of the start action.
 * @param success The name of the success action.
 * @param error The name of the error action.
 */
function createAsyncReducer<
    Data,
    State extends AsyncState<Data> = AsyncState<Data>,
    Actions extends AnyAction = AnyAction,
    InitialData = any
>(
    initialState: State,
    { start, success, error }: ActionMap,
    options?: AsyncReducerOptions<Data, InitialData, State, Actions>
): Reducer<State, Actions> {
    /* eslint-disable default-case, no-param-reassign */
    return (state: State = initialState, action: Actions | any): State =>
        produce(state, (draft: Draft<State>) => {
            switch (action.type) {
                case start: {
                    draft.loading = true;
                    break;
                }
                case success: {
                    draft.last_updated = new Date().toTimeString();
                    draft.loading = false;
                    draft.data = options?.transformDataOnSuccess
                        ? options.transformDataOnSuccess(action.payload.data)
                        : action.payload.data;
                    break;
                }
                case error: {
                    draft.loading = false;
                    draft.error = action.error;
                    break;
                }
            }
            if (options?.extendReducer)
                options.extendReducer(action, draft, state);
        });
}

export default createAsyncReducer;
