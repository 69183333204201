import { useSelector } from 'react-redux';
import { SelectOption } from 'koddi-components/Select';
import { ReportContext } from 'api/Reports';
import { formattedEntityAttributes } from './types';
import {
    selectCampaignBudgetTypeOptions,
    selectCampaignPacingOptions,
    selectCampaignGoalTypeOptions,
    selectEditCampaignStatusOptions,
    selectEditClientStatusOptions,
    selectAdGroupBudgetTypeOptions,
    selectAdGroupPacingOptions,
    selectAdGroupGoalTypeOptions,
    selectFontFamilyOptions,
    selectCuisineOptions,
    selectUserHistoryOptions,
    selectBulkEditStatusOptions,
    selectTableCellStatusOptions,
    selectCreateCampaignStatusOptions,
    selectCreateAdGroupStatusOptions,
    selectEditAdGroupStatusOptions,
    selectOrderValueOptions,
    selectUserStatusOptions,
    selectAdvertiserStatusOptions,
    selectEntityAttributes,
    selectMinifiedEntityAttributes,
    selectEntityTableColumnsStatus,
} from './selectors';
import {
    ACTIVE_STATUS_OPTION,
    baseStatusOptions,
    ENDED_STATUS_OPTION,
    PAUSED_STATUS_OPTION,
    PENDING_STATUS_OPTION,
    INVALID_STATUS_OPTION,
} from './reducer';

export function useCampaignBudgetTypeOptions(): SelectOption[] {
    return useSelector(selectCampaignBudgetTypeOptions);
}

export function useCampaignPacingOptions(): SelectOption[] {
    return useSelector(selectCampaignPacingOptions);
}

export function useCampaignGoalTypeOptions(): SelectOption[] {
    return useSelector(selectCampaignGoalTypeOptions);
}

export function useEditCampaignStatusOptions(): SelectOption[] {
    return useSelector(selectEditCampaignStatusOptions);
}

export function useEditClientStatusOptions(): SelectOption[] {
    return useSelector(selectEditClientStatusOptions);
}

export function useCreateCampaignStatusOptions(): SelectOption[] {
    return useSelector(selectCreateCampaignStatusOptions);
}

export function getAdGroupStatusOptions(
    currentStatus?: string
): SelectOption[] {
    switch (currentStatus) {
        case 'active': {
            return [ACTIVE_STATUS_OPTION, PAUSED_STATUS_OPTION];
        }
        case 'paused': {
            return [ACTIVE_STATUS_OPTION, PAUSED_STATUS_OPTION];
        }
        case 'ended': {
            return [ENDED_STATUS_OPTION];
        }
        case 'pending': {
            return [PENDING_STATUS_OPTION];
        }
        case 'invalid': {
            return [INVALID_STATUS_OPTION];
        }

        default:
            return baseStatusOptions;
    }
}

// non-hook util to support usage outside of components
export function getCampaignStatusOptions(
    currentStatus?: string
): SelectOption[] {
    switch (currentStatus) {
        case 'active': {
            return [ACTIVE_STATUS_OPTION, PAUSED_STATUS_OPTION];
        }
        case 'paused': {
            return [ACTIVE_STATUS_OPTION, PAUSED_STATUS_OPTION];
        }
        case 'ended': {
            return [ENDED_STATUS_OPTION];
        }
        case 'pending': {
            return [PENDING_STATUS_OPTION];
        }

        default:
            return baseStatusOptions;
    }
}

export function useCampaignStatusOptions(
    currentStatus?: string
): SelectOption[] {
    return getCampaignStatusOptions(currentStatus);
}

export function useAdGroupBudgetTypeOptions(): SelectOption[] {
    return useSelector(selectAdGroupBudgetTypeOptions);
}

export function useBudgetTypeOptions(context: ReportContext): SelectOption[] {
    const campaignBudgetTypeOptions = useSelector(
        selectCampaignBudgetTypeOptions
    );
    const adGroupBudgetTypeOptions = useSelector(
        selectAdGroupBudgetTypeOptions
    );
    switch (context) {
        case 'campaign': {
            return campaignBudgetTypeOptions;
        }
        case 'ad_group': {
            return adGroupBudgetTypeOptions;
        }
        default:
            return campaignBudgetTypeOptions;
    }
}

export function useAdGroupPacingOptions(): SelectOption[] {
    return useSelector(selectAdGroupPacingOptions);
}

export function useAdGroupGoalTypeOptions(): SelectOption[] {
    return useSelector(selectAdGroupGoalTypeOptions);
}

export function useEditAdGroupStatusOptions(): SelectOption[] {
    return useSelector(selectEditAdGroupStatusOptions);
}

export function useCreateAdGroupStatusOptions(): SelectOption[] {
    return useSelector(selectCreateAdGroupStatusOptions);
}

export function useBulkEditStatusOptions(): SelectOption[] {
    return useSelector(selectBulkEditStatusOptions);
}

export function useTableCellStatusOptions(): SelectOption[] {
    return useSelector(selectTableCellStatusOptions);
}

export function useFontFamilyOptions(): SelectOption[] {
    return useSelector(selectFontFamilyOptions);
}

export function useCuisineOptions(): SelectOption[] {
    return useSelector(selectCuisineOptions);
}

export function useUserHistoryOptions(): SelectOption[] {
    return useSelector(selectUserHistoryOptions);
}

export function useOrderValueOptions(): SelectOption[] {
    return useSelector(selectOrderValueOptions);
}

export function useUserStatusOptions(): SelectOption[] {
    return useSelector(selectUserStatusOptions);
}

export function useAdvertiserStatusOptions(): SelectOption[] {
    return useSelector(selectAdvertiserStatusOptions);
}

export function useEntityAttributes(): formattedEntityAttributes[] {
    return useSelector(selectEntityAttributes);
}

export function useMinifiedEntityAttributes(): formattedEntityAttributes[] {
    return useSelector(selectMinifiedEntityAttributes);
}

export function useEntityTableColumnsStatus(): string {
    return useSelector(selectEntityTableColumnsStatus);
}
