import { createSelector } from 'reselect';
import { ApplicationRootState } from 'redux-core/types';
import { AppMessagesState } from './types';

export const selectAppMessage = (
    state: ApplicationRootState
): AppMessagesState => state.app.messages;

export const selectCurrentMessage = createSelector(
    selectAppMessage,
    (state: AppMessagesState) => state.current
);
