import { AxiosInstance } from 'axios';
import { KoddiListFilterParams } from 'koddi/types';
import { LineItem, LineItemGetListResponse } from './LineItems.types';

class LineItemAPI {
    protected axios: AxiosInstance;

    constructor(axios: AxiosInstance) {
        this.axios = axios;
    }

    /**
     * Gets a list of line items across a given set of advertisers or an advertiser.
     * @param advertiser_ids A list of advertiser ids to get line items for.
     * @param query Query parameters for searching, sorting and filtering.
     */
    public getList = async (
        advertiser_ids: number[],
        params?: KoddiListFilterParams
    ): Promise<LineItem[]> => {
        const config = {
            params: { advertiser_ids: advertiser_ids.join(','), ...params },
        };
        const response = await this.axios.get<LineItemGetListResponse>(
            `v1/lineItems`,
            config
        );
        return response.data.result.line_items;
    };
}

export default LineItemAPI;
