import { KoddiUser } from 'api/Admin/User';
import { ReduxAction, ReduxActionWithPayload } from 'types';

export type AppSetupState = {
    status: string;
};

export enum AppSetupAction {
    SETUP_APP = 'app/setup/SETUP_APP',
    SETUP_SUCCESS = 'app/setup/SETUP_SUCCESS',
    SETUP_EMBEDDED = 'app/setup/SETUP_EMBEDDED',
}

export type SetupAppAction = ReduxActionWithPayload<
    AppSetupAction.SETUP_APP,
    {
        user: KoddiUser;
        memberGroupId: number;
        advertiserId: number | null;
        advertiserGroupId: number | null;
        pathname: string;
        featureFlags: Record<string, boolean>;
    }
>;

export type SetupEmbeddedAppAction = ReduxActionWithPayload<
    AppSetupAction.SETUP_EMBEDDED,
    {
        memberGroupId: number;
    }
>;

export type SetupAppSuccessAction = ReduxAction<AppSetupAction.SETUP_SUCCESS>;

export type SetupAppActions = SetupAppAction | SetupAppSuccessAction;
