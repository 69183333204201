import { useContext } from 'react';
import { ModalProviderContext } from './ModalProvider';
import { ModalProviderContextValue } from './ModalProvider.types';

/**
 * A custom hook to hook manage the modals provider
 * by the Koddi `ModalProvider`. Modals can be opened
 * and closed as such:
 *
 * ```ts
 * const { openModal, closeModal } = useKoddiModal();
 * ```
 */
export function useKoddiModal(): ModalProviderContextValue {
    const context = useContext(ModalProviderContext);
    if (context === undefined) {
        throw new Error(
            '`useKoddiModal` must be used within a `ModalProvider`'
        );
    }
    return context;
}
