/* eslint-disable no-nested-ternary */
import React from 'react';
import { ActionBarItem } from 'koddi-components/Table';
import { useKoddiTable } from '../TableProvider';
import {
    ActionBarContainer,
    ActionBarButton,
    ActionBarText,
} from './ActionBar.styled';

const ActionBar = (): JSX.Element => {
    const {
        actionBarItems,
        getActionBarTitle,
        customSelectedRows,
    } = useKoddiTable();
    const selectedCount = customSelectedRows
        ? Object.keys(customSelectedRows)?.length
        : 0;

    return (
        <ActionBarContainer justifyContent="flex-start" alignItems="center">
            <ActionBarText>
                {getActionBarTitle
                    ? getActionBarTitle(customSelectedRows, selectedCount)
                    : ''}
            </ActionBarText>
            {actionBarItems.map((item: ActionBarItem) => (
                <ActionBarButton
                    disabled={item.disabled}
                    key={item.label}
                    btnStyle="secondary"
                    onClick={() => item.onClick(customSelectedRows)}
                >
                    {item.label}
                </ActionBarButton>
            ))}
        </ActionBarContainer>
    );
};

export default ActionBar;
