import React from 'react';
import { OptionTypeBase } from 'react-select';
import StatusDot from 'koddi-components/StatusDot';
import { STATUS_OPTIONS } from 'modules/constants/status';
import { SelectOption, StatusSelectFieldProps } from './select.types';
import { StatusOptionComponent } from './select.components';
import { SelectButton } from './select.styled';
import { SelectField } from './selectField';

export function StatusSelectField(
    props: StatusSelectFieldProps<OptionTypeBase>
): JSX.Element {
    const { options = STATUS_OPTIONS, isDisabled } = props;

    function renderTarget(
        option: SelectOption<string> | null,
        toggleOpen: () => void,
        isOpen: boolean
    ) {
        const statusOption = {
            label: 'Select status',
            value: 'pending',
            ...option,
        };
        const icon = isOpen ? 'chevronUp' : 'chevronDown';

        return (
            <SelectButton
                onClick={toggleOpen}
                btnStyle="rect"
                iconRight={isDisabled ? null : icon}
                disabled={isDisabled}
            >
                <StatusDot statusValue={statusOption.value} marginRight={8} />
                <span>{statusOption.label}</span>
            </SelectButton>
        );
    }
    return (
        <SelectField
            target={renderTarget}
            customOption={StatusOptionComponent}
            options={options}
            {...props}
        />
    );
}
