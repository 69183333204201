import { useSelector } from 'react-redux';
import uniq from 'lodash/uniq';
import { KoddiUser } from 'api/Admin/User';
import {
    SUPER_USER_ROLE_ID,
    INTERNAL_ADMIN_ROLE_ID,
    CLIENT_ADMIN_ROLE_ID,
    CLIENT_VIEWER_ROLE_ID,
    ADVERTISER_ADMIN_ROLE_ID,
    ADVERTISER_MANAGER_ROLE_ID,
    ADVERTISER_VIEWER_ROLE_ID,
} from 'modules/constants/roles';
import { usePermissions } from 'koddi-components/PermissionsProvider';
import { checkRoles } from 'koddi-components/CanView';
import {
    selectAuthUser,
    selectAuthenticated,
    selectAuthStatus,
    selectAuthLoading,
    selectAuthError,
    selectEmail,
    selectUsername,
    selectAuthMessage,
} from './selectors';
import { AuthStateStatus } from './types';
import { getSessionFromLocalStorage } from './sagas';

/** Selected the current authenticated user from the redux auth state. */
export function useAuthUser(): KoddiUser | null {
    return useSelector(selectAuthUser);
}

/** Returns a boolean indicating whether or not a user is current authenticated. */
export function useAuthenticated(): boolean {
    const userSession = getSessionFromLocalStorage();
    const authenticated = useSelector(selectAuthenticated);
    return !!userSession || !!authenticated;
}

/** Selects the current auth status from the redux auth state. */
export function useAuthStatus(): AuthStateStatus {
    return useSelector(selectAuthStatus);
}

/** Returns a boolean indicating whether or not the auth store is currently fetching data for the current auth state. */
export function useAuthLoading(): boolean {
    return useSelector(selectAuthLoading);
}

/** Selects the current auth error from the redux auth state for the current auth status. */
export function useAuthError(): Error | null {
    return useSelector(selectAuthError);
}

/** Selects the email of the current authenticated user from the redux auth state. */
export function useEmail(): string | null {
    return useSelector(selectEmail);
}

/** Selects the username of the current authenticated user from the redux auth state. */
export function useUsername(): string | null {
    return useSelector(selectUsername);
}

/** Selects the username of the current authenticated user from the redux auth state. */
export function useAuthMessage(): string | null {
    return useSelector(selectAuthMessage);
}

export function useUserHasSuperAdminAccess(): boolean {
    const { roles } = usePermissions();
    return checkRoles(roles, [SUPER_USER_ROLE_ID]);
}

export function useUserHasInternalAdminAccess(): boolean {
    const { roles } = usePermissions();
    return checkRoles(roles, [INTERNAL_ADMIN_ROLE_ID]);
}

// user accesses admin at the global level
export function useUserHasGlobalAdminAccess(): boolean {
    const { roles } = usePermissions();
    return checkRoles(
        roles,
        [SUPER_USER_ROLE_ID, INTERNAL_ADMIN_ROLE_ID],
        true
    );
}

// user accesses admin at the client level
export function useUserHasClientAdminAccess(): boolean {
    const { roles } = usePermissions();
    return checkRoles(
        roles,
        [CLIENT_ADMIN_ROLE_ID, CLIENT_VIEWER_ROLE_ID],
        true
    );
}

// user accesses admin at the advertiser level
export function useUserHasAdvertiserAdminAccess(): boolean {
    const { roles } = usePermissions();
    return checkRoles(
        roles,
        [
            ADVERTISER_ADMIN_ROLE_ID,
            ADVERTISER_MANAGER_ROLE_ID,
            ADVERTISER_VIEWER_ROLE_ID,
        ],
        true
    );
}

export function useUserOnlyHasAdvertiserViewerAccess(): boolean {
    const { roles } = usePermissions();
    return (
        roles.length === 1 &&
        checkRoles(roles, [ADVERTISER_VIEWER_ROLE_ID], true)
    );
}

export function useUserHasAdvertiserLevelAccess(): boolean {
    const { roles } = usePermissions();
    return checkRoles(roles, [ADVERTISER_ADMIN_ROLE_ID], true);
}

export function useUserHasSingleClientAcccess(): boolean {
    const user = useAuthUser();
    const { access } = usePermissions();
    const advertiserUser = useUserHasAdvertiserAdminAccess();
    if (advertiserUser && user?.advertiser_member_group) {
        const values = uniq(Object.values(user?.advertiser_member_group));
        return values.length === 1;
    }
    return access?.memberGroupIds?.length === 1;
}

export function useUserHasSingleAdvertiserAcccess(): boolean {
    const { access } = usePermissions();
    return access?.advertiserIds?.length === 1;
}
