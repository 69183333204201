/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import KoddiAPI from 'api';
import { createSimpleSagaWithCache } from 'utils/createSimpleSaga';
import { takeEvery, all } from 'redux-saga/effects';
import SagaRegistry from 'redux-core/sagaRegistry';
import { CurrencyList } from 'api/Admin/Currency';
import { getCurrencyListError, getCurrencyListSuccess } from './actions';
import { CurrencyAction } from './types';
import { selectCurrencyList } from './selectors';

export const getCurrencyListApi = (): Promise<CurrencyList> =>
    KoddiAPI.Admin.Currency.getList();

export const getCurrencyListSaga = createSimpleSagaWithCache(
    getCurrencyListApi,
    getCurrencyListSuccess,
    getCurrencyListError,
    selectCurrencyList
);

export function* watchGetCurrencyList() {
    yield takeEvery(CurrencyAction.GET_CURRENCY_LIST, getCurrencyListSaga);
}

function* currencySagas() {
    yield all([watchGetCurrencyList()]);
}

SagaRegistry.registerSaga(currencySagas);
