import React, { FunctionComponent } from 'react';
import { BaseSVGProps } from '../icon.types';

const DoubleEllipsesIcon: FunctionComponent<BaseSVGProps> = ({
    width,
    height,
}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        height={height}
        width={width}
        viewBox="0 0 24 24"
    >
        <path d="M7.86,13.24a2.07,2.07,0,1,0,2.07,2.07A2.07,2.07,0,0,0,7.86,13.24Zm0-6.62A2.07,2.07,0,1,0,9.93,8.69,2.07,2.07,0,0,0,7.86,6.62Zm0,13.24a2.07,2.07,0,1,0,2.07,2.07A2.07,2.07,0,0,0,7.86,19.86ZM16.14,4.14a2.07,2.07,0,1,0-2.07-2.07A2.07,2.07,0,0,0,16.14,4.14Zm0,15.72a2.07,2.07,0,1,0,2.07,2.07A2.07,2.07,0,0,0,16.14,19.86Zm0-13.24a2.07,2.07,0,1,0,2.07,2.07A2.07,2.07,0,0,0,16.14,6.62ZM7.86,0A2.07,2.07,0,1,0,9.93,2.07,2.07,2.07,0,0,0,7.86,0Zm8.28,13.24a2.07,2.07,0,1,0,2.07,2.07A2.07,2.07,0,0,0,16.14,13.24Z" />
    </svg>
);

export default DoubleEllipsesIcon;
