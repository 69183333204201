import { Heading3 } from 'koddi-components/Typography';
import styled, { css } from 'styled-components';
import StatusDot from 'koddi-components/StatusDot';
import { VerticalFlexBox } from 'koddi-components/FlexBox';
import LoadingSpinner from 'koddi-components/LoadingSpinner';
import { EmptyTableWrapper } from 'koddi-components/EmptyTable/EmptyTable.styled';

import { DimensionOverviewContainerProps as ContainerProps } from './DimensionOverview.types';

const getTableHeight = (v2?: boolean, buttonRight?: boolean): string => {
    if (v2) {
        if (buttonRight) return 'calc(100vh - 365px)';
        return 'calc(100vh - 308px)';
    }
    return 'max-height: calc(100vh - 318px)';
};

export const DimensionOverviewContainer = styled.div<
    ContainerProps & {
        buttonsRight?: boolean;
    }
>`
    ${({ theme: { spaceUnit }, noPadding, v2, buttonsRight }) => css`
        display: flex;
        flex-direction: column;
        padding: ${noPadding ? 0 : spaceUnit * 9}px;

        .ag-root.ag-layout-auto-height {
            max-height: ${getTableHeight(v2, buttonsRight)};
        }

        ${EmptyTableWrapper} {
            max-height: ${getTableHeight(v2, buttonsRight)};
            div {
                a {
                    button {
                        border-radius: ${v2 && '5px'};
                    }
                }
            }
        }
    `}
`;

export const FilterAreaWrapper = styled(VerticalFlexBox)<{ minHeight: string }>`
    ${({ minHeight }) => css`
        min-height: ${minHeight};
        width: fit-content;
    `}
`;

export const DimensionOverviewTableHeader = styled(Heading3)`
    ${({ theme: { spaceUnit } }) => css`
        margin: ${spaceUnit * 2}px 0px ${spaceUnit * 6}px 0px;
        font-weight: 500;
    `}
`;

export const ToolTipTitle = styled.span`
    font-size: 16px;
`;

export const ToolTipDot = styled(StatusDot)`
    ${({ theme: { spaceUnit } }) => css`
        margin-right: ${spaceUnit * 6}px;
    `}
`;

export const ToolTipStatusLabel = styled.span`
    ${({ theme: { spaceUnit } }) => css`
        margin-left: ${spaceUnit}px;
    `}
`;

export const ToolTipEntityCount = styled.div`
    ${({ theme: { spaceUnit } }) => css`
        margin-top: ${spaceUnit * 3}px;
    `}
`;

export const ButtonWrapper = styled(VerticalFlexBox)<{
    editButtonVisible: boolean;
}>`
    ${({ editButtonVisible }) => css`
        margin-top: ${editButtonVisible ? '43px' : '11px'};
        width: fit-content;
        gap: 5px;
        align-items: end;
        float: right;
    `}
`;

export const DownloadSpinner = styled(LoadingSpinner)``;

export const ButtonLabel = styled.div`
    white-space: nowrap;
`;

export const OuterEditBtnWrapper = styled.div`
    ${({ theme: { spaceUnit } }) => css`
        margin-bottom: ${spaceUnit * 2}px;
    `}
`;

export const DimensionOverviewBalanceText = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-bottom: 10px;
`;

export const BalanceAndDownloadButtonV2 = styled.div`
    margin: 2px;
`;

export const ChartCardWrapper = styled(VerticalFlexBox)`
    margin-top: 10px;
    margin-bottom: 20px;
`;

export const TableWrapper = styled(VerticalFlexBox)`
    margin-top: 10px;
`;
