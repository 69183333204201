import {
    ApplicationRootState,
    ApplicationOutputSelector,
} from 'redux-core/types';
import { createSelector } from 'reselect';
import { AsyncState } from 'types';
import { MemberGroupList } from 'api/Admin/MemberGroup';
import { initialState } from './reducer';
import { MemberGroupState } from './types';

const selectAdminMemberGroup = (state: ApplicationRootState) =>
    state.admin.memberGroup || initialState;

export const selectMemberGroupList: ApplicationOutputSelector<
    AsyncState<MemberGroupList>,
    (res: MemberGroupState) => AsyncState<MemberGroupList>
> = createSelector(selectAdminMemberGroup, (state) => state.list);

export const selectAdminMemberGroupLoading = createSelector(
    selectAdminMemberGroup,
    (state: MemberGroupState) => state.list.loading
);

export const selectMemberGroupActiveTemplates = createSelector(
    selectAdminMemberGroup,
    (state: MemberGroupState) => state.activeTemplates
);
