import React, { FunctionComponent } from 'react';
import { BaseSVGProps } from '../icon.types';

const RefreshIcon: FunctionComponent<BaseSVGProps> = ({ width, height }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        height={height}
        width={width}
        viewBox="0 0 24 24"
    >
        <path d="M4.38 12.43v-.06A8 8 0 0115.57 5l-1.41 1.4a.75.75 0 000 1 .72.72 0 00.41.2l7.25 1a.73.73 0 00.83-.6.67.67 0 000-.2l-1-7.24a.73.73 0 00-.87-.56.72.72 0 00-.41.2l-1.54 1.49A12.45 12.45 0 000 12a.73.73 0 00.62.75l2.92.42a.79.79 0 00.84-.74zm19-1.17l-2.92-.42a.79.79 0 00-.84.73v.06A8 8 0 018.43 19l1.41-1.4a.75.75 0 000-1 .72.72 0 00-.41-.2l-7.25-1a.73.73 0 00-.83.63.67.67 0 000 .2l1 7.24a.73.73 0 00.83.62.72.72 0 00.41-.2l1.54-1.54A12.45 12.45 0 0024 12a.73.73 0 00-.62-.74z" />
    </svg>
);

export default RefreshIcon;
