import React, { FunctionComponent } from 'react';
import { BaseSVGProps } from '../icon.types';

const ChevronRightIcon: FunctionComponent<BaseSVGProps> = ({
    width,
    height,
}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 24 24"
    >
        <path d="M7.67 24l-3.33-3.33L13.01 12 4.34 3.33 7.67 0l11.99 12L7.67 24z" />
    </svg>
);

export default ChevronRightIcon;
