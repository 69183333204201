import { createSelector } from 'reselect';
import { KoddiUser } from 'api/Admin/User';
import {
    ApplicationRootState,
    ApplicationOutputSelector,
} from 'redux-core/types';
import { initialState } from './reducer';
import { AuthStateStatus, AuthState } from './types';

const selectAuth = (state: ApplicationRootState): AuthState =>
    state.auth || initialState;

export const selectAuthUser: ApplicationOutputSelector<
    KoddiUser | null,
    (res: AuthState) => KoddiUser | null
> = createSelector(selectAuth, (authState) => authState.context.user);

export const selectAuthenticated: ApplicationOutputSelector<
    boolean,
    (res: AuthState) => boolean
> = createSelector(selectAuth, (authState) => authState.context.user !== null);

export const selectAuthStatus: ApplicationOutputSelector<
    AuthStateStatus,
    (res: AuthState) => AuthStateStatus
> = createSelector(
    selectAuth,
    (authState) => authState.value as AuthStateStatus
);

export const selectAuthLoading: ApplicationOutputSelector<
    boolean,
    (res: AuthState) => boolean
> = createSelector(selectAuth, (authState) => authState.context.loading);

export const selectAuthError: ApplicationOutputSelector<
    Error | null,
    (res: AuthState) => Error | null
> = createSelector(selectAuth, (authState) => authState.context.error);

export const selectEmail: ApplicationOutputSelector<
    string | null,
    (res: AuthState) => string | null
> = createSelector(selectAuth, (authState) => authState.context.email);

export const selectUsername: ApplicationOutputSelector<
    string | null,
    (res: AuthState) => string | null
> = createSelector(selectAuth, (authState) => authState.context.username);

export const selectAuthMessage: ApplicationOutputSelector<
    string | null,
    (res: AuthState) => string | null
> = createSelector(selectAuth, (authState) => authState.context.message);
