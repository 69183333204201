import { ReduxAction, ReduxActionWithPayload } from 'types';
import { AdvertiserGroup } from 'api/Admin/AdvertiserGroups';

export enum AdvertiserGroupAction {
    GET_ADVERTISER_GROUP_LIST = 'admin/advertiserGroups/GET_ADVERTISER_GROUP_LIST',
    GET_ADVERTISER_GROUP_LIST_SUCCESS = 'admin/advertiserGroups/GET_ADVERTISER_GROUP_LIST_SUCCESS',
    GET_ADVERTISER_GROUP_LIST_ERROR = 'admin/advertiserGroups/GET_ADVERTISER_GROUP_LIST_ERROR',
    DELETE_ADVERTISER_GROUP = 'admin/advertiserGroups/DELETE_ADVERTISER_GROUP',
    DELETE_ADVERTISER_GROUP_SUCCESS = 'admin/advertiserGroups/DELETE_ADVERTISER_GROUP_SUCCESS',
    DELETE_ADVERTISER_GROUP_FAILURE = 'admin/advertiserGroups/DELETE_ADVERTISER_GROUP_FAILURE',
}

export type AdvertiserGroupsState = {
    status: string;
    list: AdvertiserGroup[];
};

export type GetAdvertiserGroupListAction = ReduxActionWithPayload<
    AdvertiserGroupAction.GET_ADVERTISER_GROUP_LIST,
    { userId: number }
>;

export type GetAdvertiserGroupListSuccessAction = ReduxActionWithPayload<
    AdvertiserGroupAction.GET_ADVERTISER_GROUP_LIST_SUCCESS,
    { adGroups: AdvertiserGroup[] }
>;

export type GetAdvertiserGroupListFailureAction = ReduxAction<
    AdvertiserGroupAction.GET_ADVERTISER_GROUP_LIST_ERROR
>;

export type DeleteAdvertiserGroupAction = ReduxActionWithPayload<
    AdvertiserGroupAction.DELETE_ADVERTISER_GROUP,
    { memberGroupId: number; advertiserGroupId: number }
>;

export type DeleteAdvertiserGroupSuccessAction = ReduxAction<
    AdvertiserGroupAction.DELETE_ADVERTISER_GROUP_SUCCESS
>;

export type DeleteAdvertiserGroupFailureAction = ReduxAction<
    AdvertiserGroupAction.DELETE_ADVERTISER_GROUP_FAILURE
>;

export type AdvertiserGroupActions =
    | GetAdvertiserGroupListAction
    | GetAdvertiserGroupListSuccessAction
    | GetAdvertiserGroupListFailureAction
    | DeleteAdvertiserGroupAction
    | DeleteAdvertiserGroupSuccessAction
    | DeleteAdvertiserGroupFailureAction;
