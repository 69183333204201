import React, { useState, useCallback } from 'react';
import { Portal } from 'react-portal';
import { usePopper } from 'react-popper';
import OnOutsideClick from 'koddi-components/OnOutsideClick';
import Button from '../Button';
import DropdownMenu from './dropdown.menu';
import DropdownMultiOptionMenu from './DropdownMultiOptionMenu';
import { DropdownProps } from './dropdown.types';
import * as Styled from './dropdown.styled';

/**
 * The Koddi `Dropdown` component renders a button and a togglable
 * dropdown list of items. The `Dropdown` is useful for displaying
 * a simple list of items that is opened via a Koddi `Button`.
 */
export function Dropdown<Target extends HTMLElement = HTMLButtonElement>({
    btnText,
    btnTextIsVar,
    items,
    categoryItems,
    btnStyle,
    className,
    iconRight,
    iconLeft,
    altIconRight,
    altIconLeft,
    'data-test': dataTest = 'dropdown-component',
    usePortal = true,
    maxHeight = 200,
    popperPlacement = 'bottom-start',
    minWidth,
    width,
    dropdownOffset = [0, 0],
    dropdownMenuClassName,
    btnDisabled,
    Target,
    v2,
    hasIconWithNumber = false,
    numberToDisplay,
    useMultiOptionMenu = false,
    dropDownMenuTitle,
    linkText,
    onLinkClick,
    iconSize,
}: DropdownProps<Target>): JSX.Element {
    const [showMenu, setShowMenu] = useState(false);
    const [referenceElement, setReferenceElement] = useState<
        Target | HTMLButtonElement | null
    >(null);
    const [popperElement, setPopperElement] = useState<HTMLUListElement | null>(
        null
    );
    const { styles, attributes } = usePopper(referenceElement, popperElement, {
        modifiers: [
            {
                name: 'offset',
                options: {
                    offset: dropdownOffset,
                },
            },
        ],
        placement: popperPlacement,
    });

    const RenderTarget = useCallback(() => {
        const onClick = () => setShowMenu(!showMenu);

        if (Target) {
            return (
                <Target
                    onClick={onClick}
                    ref={setReferenceElement}
                    data-test={dataTest}
                />
            );
        }
        return (
            <Button
                btnStyle={btnStyle}
                ref={setReferenceElement}
                iconRight={altIconRight && showMenu ? altIconRight : iconRight}
                iconLeft={altIconLeft && showMenu ? altIconLeft : iconLeft}
                iconSize={iconSize}
                data-test={dataTest}
                onClick={onClick}
                disabled={btnDisabled}
                v2={v2}
                hasIconWithNumber={hasIconWithNumber}
                numberToDisplay={numberToDisplay}
            >
                {btnTextIsVar ? <div translate="no">{btnText}</div> : btnText}
            </Button>
        );
    }, [
        Target,
        btnStyle,
        btnText,
        dataTest,
        iconRight,
        iconLeft,
        showMenu,
        altIconRight,
        altIconLeft,
        iconSize,
        btnDisabled,
        btnTextIsVar,
        v2,
        hasIconWithNumber,
        numberToDisplay,
    ]);

    const MenuPortal = usePortal ? Portal : React.Fragment;

    return (
        <Styled.DropdownWrapper className={className}>
            <RenderTarget />
            <MenuPortal>
                <OnOutsideClick
                    listenForOutsideClick={showMenu}
                    onOutsideClick={() => {
                        if (useMultiOptionMenu) {
                            return;
                        }
                        setShowMenu(false);
                    }}
                    additionalElement={referenceElement}
                >
                    {showMenu &&
                        (useMultiOptionMenu ? (
                            <DropdownMultiOptionMenu
                                visible={showMenu}
                                closeMenu={() => setShowMenu(!showMenu)}
                                items={items}
                                categoryItems={categoryItems}
                                ref={setPopperElement}
                                style={
                                    usePortal
                                        ? styles.popper
                                        : {
                                              position: 'relative',
                                              display: 'block',
                                          }
                                }
                                maxHeight={maxHeight}
                                usePortal={usePortal}
                                minWidth={minWidth}
                                width={width}
                                className={dropdownMenuClassName}
                                data-test={`${dataTest}-menu`}
                                {...(usePortal ? attributes.popper : {})}
                                dropDownMenuTitle={dropDownMenuTitle}
                                linkText={linkText}
                                onLinkClick={() => {
                                    setShowMenu(!showMenu);
                                    if (onLinkClick) {
                                        onLinkClick();
                                    }
                                }}
                            />
                        ) : (
                            <DropdownMenu
                                visible={showMenu}
                                closeMenu={() => setShowMenu(!showMenu)}
                                items={items}
                                ref={setPopperElement}
                                style={usePortal ? styles.popper : {}}
                                maxHeight={maxHeight}
                                usePortal={usePortal}
                                minWidth={minWidth}
                                className={dropdownMenuClassName}
                                data-test={`${dataTest}-menu`}
                                {...(usePortal ? attributes.popper : {})}
                                width={width}
                            />
                        ))}
                </OnOutsideClick>
            </MenuPortal>
        </Styled.DropdownWrapper>
    );
}

export default Dropdown;
