/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import CachedKoddiAPI from 'api/api.cachedObject';
import { KoddiAPIQuery, KoddiAPIResponse } from 'types';
import { KoddiListFilterParams, ReportFilter } from 'koddi/types';
import {
    Advertiser,
    AdvertiserBillingAddress,
    AdvertiserGetListResponse,
    CreateAdvertiserPayload,
    UpdateAdvertiserPayload,
    ReportAdvertiserResponse,
    ReportAdvertiserList,
    AdvertiserEntity,
    AdvertiserBalanceByFundingType,
    PCLSum,
    AdvertiserInvoicesReport,
    AdvertiserPrePayBalanceReport,
    AdvertiserTransactionHistory,
    PaymentMethod,
    UpdateAdvertiserPaymentPayload,
    AutoBillSettings,
    TaxTypeOptions,
    AdvertiserBillingEvent,
} from './Advertiser.types';
import { AdGroupBidder } from '../AdGroups/AdGroups.types';
import KoddiAdvertiserCampaignsAPI from './Campaigns';

class KoddiAdvertiserAPI extends CachedKoddiAPI {
    public create = async (
        memberGroupId: number,
        data: CreateAdvertiserPayload
    ): Promise<Advertiser> => {
        const CREATE_KODDI_ADVERTISER_URL = `v1/member_groups/${memberGroupId}/advertisers`;
        const response = await this.axios.post<
            KoddiAPIResponse<{ advertisers: Advertiser[] }>
        >(CREATE_KODDI_ADVERTISER_URL, data);
        return response.data.result.advertisers[0];
    };

    public update = async (
        data: UpdateAdvertiserPayload,
        memberGroupId: number,
        advertiserId: number
    ): Promise<any> => {
        const response = await this.axios.put<KoddiAPIResponse<any>>(
            `v1/member_groups/${memberGroupId}/advertisers/${advertiserId}`,
            data
        );
        this.cache.break('get', `${memberGroupId}-${advertiserId}`);
        return response.data.result.advertisers[0];
    };

    public updateAdvertiserBillingAddress = async (
        billingAddress: Partial<AdvertiserBillingAddress>,
        memberGroupId: number,
        advertiserId: number
    ): Promise<any> => {
        const newAddress = {
            ...billingAddress,
        };
        const response = await this.axios.put<KoddiAPIResponse<any>>(
            `v1/member_groups/${memberGroupId}/advertisers/${advertiserId}`,
            {
                billing: newAddress,
            }
        );
        this.cache.break('getByID', `${memberGroupId}-${advertiserId}`);
        return response.data.result.advertisers[0];
    };

    /** Gets an `Advertiser`'s information. */
    public getByID = async (
        clientId: number,
        advertiserId: number
    ): Promise<Advertiser> => {
        const response = await this.cache.makeCachedRequest(
            'get',
            `${clientId}-${advertiserId}`,
            () => {
                return this.axios.get(
                    `v1/member_groups/${clientId}/advertisers/${advertiserId}`
                );
            }
        );
        let channels = [];
        try {
            const channelResponse = await this.axios.get(
                `v1/member_groups/${clientId}/advertisers/${advertiserId}/channels`
            );
            channels = channelResponse.data.result.channels;
        } catch (error) {
            // eslint-disable-next-line no-console
            console.warn(
                `Could not fetch Channels for Advertiser (${advertiserId})`,
                error
            );
        }
        return {
            ...response.data.result.advertiser,
            channels,
        };
    };

    /** Removes a advertiser from its current advertiser group */
    public removeAdvertiserFromGroup = async (
        memberGroupId: number,
        advertiserId: number
    ): Promise<any> => {
        const response = await this.axios.patch<AdvertiserGetListResponse>(
            `v1/member_groups/${memberGroupId}/advertisers/${advertiserId}/remove_group`
        );
        return response.data.status;
    };

    /** Gets a list advertiser reporting data */
    public getAdvertiserReportingData = async (
        params?: KoddiListFilterParams
    ): Promise<Advertiser[]> => {
        const [id, direction] = params?.sort?.split(' ') ?? [];
        const reportPayload: Record<string, any> = {
            pagination: {
                start: params?.start || 0,
                count: params?.count || 20,
            },
            filters: [],
        };
        if (id && direction) {
            reportPayload.sort = [
                {
                    field: id,
                    order: direction,
                },
            ];
        }
        if (params?.search) {
            reportPayload.filters.push({
                field: 'id_name',
                operation: 'ILIKE',
                value: params?.search,
            });
        }
        const response = await this.axios.post<AdvertiserGetListResponse>(
            `v2/users/advertisers_report`,
            reportPayload
        );

        return response.data.result.advertisers || [];
    };

    /** Deletes an `Advertiser`'s by their id. */
    public delete = async (id: Advertiser['advertiser_id']): Promise<null> => {
        return null;
    };

    /** Gets a list of available `Advertiser`'s for a user. */
    public getList = async (
        memberGroupId: number,
        userId: number
    ): Promise<Advertiser[]> => {
        const response = await this.axios.get<AdvertiserGetListResponse>(
            `v1/member_groups/${memberGroupId}/advertisers/get_user_advertisers`
        );
        return response.data?.result?.advertisers;
    };

    /** Gets the channel data relating to a specific member group */
    public getMemberGroupChannels = async (
        memberGroupId: number
    ): Promise<any> => {
        const response = await this.axios.get(
            `v1/member_groups/${memberGroupId}/channels`
        );
        return response.data?.result.channels;
    };

    /** Adds advertisers to advertier group */
    public bulkAddAdvertisers = async (
        memberGroupId: number | undefined,
        advertiserGroupId: number,
        advertiserIds: number[]
    ): Promise<any> => {
        if (!memberGroupId) return [];
        const response = await this.axios.patch<KoddiAPIResponse<any>>(
            `v1/advertiser_groups/${advertiserGroupId}/advertisers/bulk`,
            {
                advertiser_ids: advertiserIds,
                member_group_id: memberGroupId,
            }
        );
        return response.data.result;
    };

    public bulkAddAllAdvertisers = async (
        advertiserGroupId: number,
        searchInput?: string
    ): Promise<any> => {
        const response = await this.axios.patch<KoddiAPIResponse<any>>(
            `v1/advertiser_groups/${advertiserGroupId}/advertisers/bulk/all`,
            {
                filters: [
                    {
                        field: 'name',
                        operation: 'ILIKE',
                        value: searchInput,
                    },
                ],
            }
        );
        return response.data.result;
    };

    /** Gets a list of available `Advertiser`'s for a client */
    public getAdvertiserEntitiesReport = async (
        clientId: number,
        advertiserId: number,
        params: KoddiListFilterParams & {
            filters: ReportFilter[];
        },
        v2?: boolean
    ): Promise<any> => {
        const [id, direction] = params?.sort?.split(' ') ?? [];
        const response = await this.axios.post<
            KoddiAPIResponse<{ entities: AdvertiserEntity[] }>
        >(
            `v1/member_groups/${clientId}/advertisers/${advertiserId}/entities_report`,
            {
                pagination: {
                    count: params?.count || 20,
                    start: params?.start || 0,
                },
                filters: params.filters || [
                    {
                        field: 'name',
                        operation: 'ILIKE',
                        value: params?.search || '%',
                    },
                ],
                ...(id
                    ? {
                          sort: [
                              {
                                  field: id,
                                  ...(direction ? { order: direction } : {}),
                              },
                          ],
                      }
                    : {}),
            }
        );
        if (v2) {
            return response.data.result;
        }
        return response.data.result.entities;
    };

    /** Gets a list of available `Advertiser`'s for a client */
    public getAdvertiserReportByClient = async (
        memberGroupId: number,
        params?: KoddiListFilterParams,
        isAdvertiserGroupNull?: boolean
    ): Promise<any> => {
        const [id, direction] = params?.sort?.split(' ') ?? [];
        const reportPayload: Record<string, any> = {
            pagination: {
                start: params?.start || 0,
                count: params?.count || 20,
            },
            filters: [
                {
                    field: 'id_name',
                    operation: 'ILIKE',
                    value: params?.search || '%',
                },
            ],
        };
        if (id && direction) {
            reportPayload.sort = [
                {
                    field: id,
                    order: direction,
                },
            ];
        }
        if (isAdvertiserGroupNull) {
            reportPayload.filters.push({
                field: 'advertiser_group_id',
                operation: 'IS NULL',
                value: 'NULL',
            });
        }
        const response = await this.axios.post<KoddiAPIResponse<any>>(
            `v1/member_groups/${memberGroupId}/advertisers_report`,
            reportPayload
        );
        return response.data.result.advertisers || [];
    };

    /** Gets a list of available `Advertiser`'s for an Advertiser Group */
    public getAdvertiserReportByGroup = async (
        clientId: number,
        advertiserGroupId: number,
        params?: KoddiListFilterParams
    ): Promise<ReportAdvertiserList> => {
        const [id, direction] = params?.sort?.split(' ') ?? [];
        const response = await this.axios.post<
            KoddiAPIResponse<ReportAdvertiserResponse>
        >(
            `v1/member_groups/${clientId}/advertiser_groups/${advertiserGroupId}/advertisers_report`,
            {
                pagination: {
                    start: params?.start || 0,
                    count: params?.count || 20,
                },
                filters: [
                    {
                        field: 'id_name',
                        operation: 'ILIKE',
                        value: params?.search || '%',
                    },
                ],
                ...(id
                    ? {
                          sort: [
                              {
                                  field: id,
                                  ...(direction ? { order: direction } : {}),
                              },
                          ],
                      }
                    : {}),
            }
        );
        return response.data.result.advertisers || [];
    };

    public getAdvertiserIndustry = async (
        clientId: number,
        advertiserId: number
    ): Promise<any> => {
        const response = await this.cache.makeCachedRequest(
            'getAdvertiserIndustry',
            `${clientId}-${advertiserId}`,
            () => {
                return this.axios.get(
                    `v1/member_groups/${clientId}/advertisers/${advertiserId}/industry`
                );
            }
        );
        return response.data.result;
    };

    public getAdvertiserFundingBalances = async (
        memberGroupId: number,
        advertiserId: number,
        params: KoddiAPIQuery & {
            filters: ReportFilter[];
        }
    ): Promise<any> => {
        const [id, direction] = params?.sort?.split(' ') ?? [];
        const response = await this.axios.post(
            `v1/member_groups/${memberGroupId}/advertisers/${advertiserId}/funds/balances`,
            {
                pagination: {
                    start: params.start || 0,
                    count: params.count || 20,
                },
                ...(id
                    ? {
                          sort: [
                              {
                                  field: id,
                                  ...(direction ? { order: direction } : {}),
                              },
                          ],
                      }
                    : {}),
                filters: params.filters,
            }
        );
        return response.data.result?.events || [];
    };

    /** Gets a list of balances by funding type for a given advertiser */
    public getFundingBalances = async (
        memberGroupId: number,
        advertiserId: number
    ): Promise<{
        balances: AdvertiserBalanceByFundingType[];
        total: number;
    }> => {
        const response = await this.axios.get<KoddiAPIResponse<any>>(
            `v1/member_groups/${memberGroupId}/advertisers/${advertiserId}/funds/balances_by_funding_type`
        );
        return response.data.result;
    };

    public getAdvertiserPCLSum = async (
        memberGroupId: number,
        advertiserId: number
    ): Promise<PCLSum> => {
        const response = await this.axios.get<KoddiAPIResponse<PCLSum>>(
            `v1/member_groups/${memberGroupId}/advertisers/${advertiserId}/pcl`
        );
        return response.data.result;
    };

    public calculateAdvertiserPCLSum = async (
        memberGroupId: number,
        advertiserId: number,
        allSelected?: boolean,
        bidders?: AdGroupBidder[] | null,
        filters?: ReportFilter[]
    ): Promise<PCLSum> => {
        const response = await this.axios.post<KoddiAPIResponse<PCLSum>>(
            `v1/member_groups/${memberGroupId}/advertisers/${advertiserId}/pcl`,
            {
                all_selected: allSelected,
                bidders,
                filters,
            }
        );
        return response.data.result;
    };

    public getAdvertiserInvoiceReport = async (
        memberGroupId: number,
        advertiserId: number,
        params: KoddiAPIQuery & {
            filters: ReportFilter[];
        },
        v2?: boolean
    ): Promise<AdvertiserInvoicesReport[]> => {
        const [id, direction] = params?.sort?.split(' ') ?? [];

        const response = await this.axios.post<
            KoddiAPIResponse<{ invoices: AdvertiserInvoicesReport[] }>
        >(
            `${
                v2 ? 'v2' : 'v1'
            }/member_groups/${memberGroupId}/advertisers/${advertiserId}/stripe/invoices_report`,
            {
                pagination: {
                    start: params.start || 0,
                    count: params.count || 20,
                },
                ...(id
                    ? {
                          sort: [
                              {
                                  field: id,
                                  ...(direction ? { order: direction } : {}),
                              },
                          ],
                      }
                    : {}),
                filters: params.filters,
            }
        );
        return response.data.result.invoices;
    };

    public createStripeClient = async (
        memberGroupId: number,
        advertiserId: number
    ): Promise<any> => {
        const response = await this.axios.post<
            KoddiAPIResponse<{ client_secret: string }>
        >(
            `v1/member_groups/${memberGroupId}/advertisers/${advertiserId}/stripe/customer`
        );
        return response.data.result.client_secret;
    };

    public getStripeClientSecret = async (
        memberGroupId: number,
        advertiserId: number
    ): Promise<any> => {
        const response = await this.axios.get<
            KoddiAPIResponse<{ client_secret: string }>
        >(
            `v1/member_groups/${memberGroupId}/advertisers/${advertiserId}/stripe/client_secret`
        );
        return response.data.result.client_secret;
    };

    public createPaymentIntent = async (
        memberGroupId: number,
        advertiserId: number,
        amount: number,
        payment_method_id?: string
    ): Promise<any> => {
        const response = await this.axios.post<
            KoddiAPIResponse<{ client_secret: string }>
        >(
            `v1/member_groups/${memberGroupId}/advertisers/${advertiserId}/stripe/payment_intent`,
            {
                amount,
                payment_method_id,
            }
        );
        return response.data.result.client_secret;
    };

    public getStripeTaxTypes = async (): Promise<any> => {
        const response = await this.axios.get<
            KoddiAPIResponse<{ tax_types: TaxTypeOptions }>
        >(`v1/stripe/tax_types`);
        return response.data.result.tax_types;
    };

    public getAdvertiserPrePayBalance = async (
        memberGroupId: number,
        advertiserId: number
    ): Promise<AdvertiserPrePayBalanceReport> => {
        const response = await this.axios.get<
            KoddiAPIResponse<AdvertiserPrePayBalanceReport>
        >(
            `v1/member_groups/${memberGroupId}/advertisers/${advertiserId}/funds/total_pre_pay_balance`
        );
        return response.data.result;
    };

    public getAdvertiserTransactionHistory = async (
        memberGroupId: number,
        advertiserId: number,
        params: KoddiAPIQuery & {
            filters: ReportFilter[];
        }
    ): Promise<AdvertiserTransactionHistory[]> => {
        const [id, direction] = params?.sort?.split(' ') ?? [];
        const response = await this.axios.post<
            KoddiAPIResponse<{ balance_events: AdvertiserTransactionHistory[] }>
        >(
            `v1/member_groups/${memberGroupId}/advertisers/${advertiserId}/funds/balance_events_report`,
            {
                pagination: {
                    start: params.start || 0,
                    count: params.count || 20,
                },
                ...(id
                    ? {
                          sort: [
                              {
                                  field: id,
                                  ...(direction ? { order: direction } : {}),
                              },
                          ],
                      }
                    : {}),
                filters: params.filters,
            }
        );
        return response.data.result.balance_events;
    };

    public getAdvertiserPaymentMethods = async (
        memberGroupId: number,
        advertiserId: number
    ): Promise<PaymentMethod[]> => {
        const response = await this.axios.get<
            KoddiAPIResponse<{ payment_methods: PaymentMethod[] }>
        >(
            `v1/member_groups/${memberGroupId}/advertisers/${advertiserId}/stripe/payment_methods`
        );
        return response.data.result.payment_methods;
    };

    public deleteAdvertiserPaymentMethod = async (
        memberGroupId: number,
        advertiserId: number,
        paymentMethodId: string
    ): Promise<null> => {
        const response = await this.axios.delete<KoddiAPIResponse<null>>(
            `v1/member_groups/${memberGroupId}/advertisers/${advertiserId}/stripe/payment_methods/${paymentMethodId}`
        );
        return response.data.result;
    };

    public updateAdvertiserPaymentMethod = async (
        memberGroupId: number | string,
        advertiserId: number | string,
        paymentMethodId: string,
        payload: UpdateAdvertiserPaymentPayload
    ): Promise<null> => {
        const { name, billing_address, type, exp_month, exp_year } = payload;

        const response = await this.axios.patch<KoddiAPIResponse<null>>(
            `v1/member_groups/${memberGroupId}/advertisers/${advertiserId}/stripe/payment_methods/${paymentMethodId}`,
            {
                name,
                billing_address,
                type,
                data: {
                    exp_month,
                    exp_year,
                },
            }
        );
        return response.data.result;
    };

    public updateAdvertiserDefaultPayment = async (
        memberGroupId: number | string,
        advertiserId: number | string,
        paymentMethodId: string
    ): Promise<any> => {
        const response = await this.axios.patch<KoddiAPIResponse<any>>(
            `v1/member_groups/${memberGroupId}/advertisers/${advertiserId}/stripe/payment_methods/set_default_payment_method`,
            {
                payment_method_id: paymentMethodId,
            }
        );

        return response.data.result;
    };

    public createAdvertiserAutoBillSettings = async (
        memberGroupId: number,
        advertiserId: number,
        paymentMethodId: string,
        billingCycleId: number,
        startDate: string,
        amount: number
    ): Promise<AutoBillSettings> => {
        const response = await this.axios.post<
            KoddiAPIResponse<AutoBillSettings>
        >(
            `v1/member_groups/${memberGroupId}/advertisers/${advertiserId}/auto_rebills`,
            {
                payment_provider: {
                    id: 1,
                },
                payment_provider_payment_method_id: paymentMethodId,
                billing_cycle: {
                    id: billingCycleId,
                },
                start_date: startDate,
                amount,
            }
        );

        return response.data.result;
    };

    public getAdvertiserAutoBillSettings = async (
        memberGroupId: number,
        advertiserId: number
    ): Promise<AutoBillSettings> => {
        const response = await this.axios.get<
            KoddiAPIResponse<AutoBillSettings>
        >(
            `v1/member_groups/${memberGroupId}/advertisers/${advertiserId}/auto_rebills/get_by_advertiser`
        );

        return response.data.result;
    };

    public deleteAdvertiserAutoBillSettings = async (
        memberGroupId: number,
        advertiserId: number,
        autoRebillId: number
    ): Promise<any> => {
        const response = await this.axios.delete(
            `${window.API_ROUTE}v1/member_groups/${memberGroupId}/advertisers/${advertiserId}/auto_rebills/${autoRebillId}`
        );

        return response.data.result;
    };

    public updateAdvertiserAutoBillSettings = async (
        memberGroupId: number,
        advertiserId: number,
        autoRebillId: number,
        payload: Partial<AutoBillSettings>
    ): Promise<any> => {
        const response = await this.axios.patch(
            `${window.API_ROUTE}v1/member_groups/${memberGroupId}/advertisers/${advertiserId}/auto_rebills/${autoRebillId}`,
            payload
        );

        return response.data.result;
    };

    public getAdvertiserDetails = async (
        advertiserId: number
    ): Promise<Advertiser> => {
        const response = await this.axios.get<
            KoddiAPIResponse<{ advertiser: Advertiser }>
        >(`v1/advertisers/${advertiserId}`);
        return response.data.result.advertiser;
    };

    public getAdvertiserBillingEventDetails = async (
        memberGroupId: number | string,
        advertiserId: number | string,
        balanceEventId: number | string
    ): Promise<AdvertiserBillingEvent> => {
        const response = await this.axios.get<
            KoddiAPIResponse<AdvertiserBillingEvent>
        >(
            `v1/member_groups/${memberGroupId}/advertisers/${advertiserId}/funds/balance_events/${balanceEventId}/invoice`
        );
        return response.data.result;
    };

    public Campaigns = new KoddiAdvertiserCampaignsAPI(this.axios);
}

export default KoddiAdvertiserAPI;
