import React, { FunctionComponent } from 'react';
import { BaseSVGProps } from '../icon.types';

const CheckIcon: FunctionComponent<BaseSVGProps> = ({ width, height }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 24 24"
    >
        <path d="M20.4 2.84L9.44 13.8 3.6 7.97 0 11.56l9.59 9.6L24 6.44l-3.6-3.6z" />
    </svg>
);

export default CheckIcon;
