import {
    ReduxAction,
    ReduxActionWithError,
    ReduxActionWithPayload,
    ReduxActionWithRefresh,
    AsyncState,
} from 'types';
import {
    KoddiUserList,
    CreateKoddiUserInput,
    UpdateKoddiUserInput,
} from 'api/Admin/User';

export enum UserAction {
    GET_USER_LIST = 'admin/user/GET_USER_LIST',
    GET_USER_LIST_SUCCESS = 'admin/user/GET_USER_LIST_SUCCESS',
    GET_USER_LIST_ERROR = 'admin/user/GET_USER_LIST_ERROR',

    CREATE_NEW_USER = 'admin/user/CREATE_NEW_USER',
    CREATE_NEW_USER_SUCCESS = 'admin/user/CREATE_NEW_USER_SUCCESS',
    CREATE_NEW_USER_ERROR = 'admin/user/CREATE_NEW_USER_ERROR',

    UPDATE_USER = 'admin/user/UPDATE_USER',
    UPDATE_USER_SUCCESS = 'admin/user/UPDATE_USER_SUCCESS',
    UPDATE_USER_ERROR = 'admin/user/UPDATE_USER_ERROR',
}

// Get User List Actions

export type GetUserListAction = ReduxActionWithRefresh<
    UserAction.GET_USER_LIST
>;

export type GetUserListSuccessAction = ReduxActionWithPayload<
    UserAction.GET_USER_LIST_SUCCESS,
    { data: KoddiUserList }
>;

export type GetUserListErrorAction = ReduxActionWithError<
    UserAction.GET_USER_LIST_ERROR
>;

// Create New User Actions

export type CreateNewUserAction = ReduxActionWithPayload<
    UserAction.CREATE_NEW_USER,
    { newUser: CreateKoddiUserInput }
>;

export type CreateNewUserSuccessAction = ReduxAction<
    UserAction.CREATE_NEW_USER_SUCCESS
>;

export type CreateNewUserErrorAction = ReduxActionWithError<
    UserAction.CREATE_NEW_USER_ERROR
>;

// Update User Actions

export type UpdateUserAction = ReduxActionWithPayload<
    UserAction.UPDATE_USER,
    {
        userData: UpdateKoddiUserInput & {
            user_id: number;
        };
    }
>;

export type UpdateUserSuccessAction = ReduxAction<
    UserAction.UPDATE_USER_SUCCESS
>;

export type UpdateUserErrorAction = ReduxActionWithError<
    UserAction.UPDATE_USER_ERROR
>;

export type UserActions =
    | GetUserListAction
    | GetUserListSuccessAction
    | GetUserListErrorAction
    | CreateNewUserAction
    | CreateNewUserSuccessAction
    | CreateNewUserErrorAction;

export type UserState = {
    list: AsyncState<KoddiUserList>;
};
