import { AdvertiserGroup } from 'api/Admin/AdvertiserGroups';
import { Advertiser } from 'api/Advertiser/Advertiser.types';
import { CurrencyList } from 'api/Admin/Currency';
import { BiddingConfigReport } from 'api/Admin/Currency/Currency.types';
import {
    FetchAppContextAction,
    FetchAppContextSuccessAction,
    FetchAppContextError,
    AppContextAction,
    DefaultMemberGroup,
    SetPreviousMemberGroup,
    SetErrorCodeAction,
    ClearAppContext,
    SetMemberGroupCurrencies,
    SetMemberGroupBiddingConfigs,
    SetCalculatedPageCurrency,
    SetAdvertiserHasFunds,
    ContextMemberGroup,
    SetTaxTypes,
    ContextTaxType,
    SetSavedReportTitle,
    FetchEmbeddedAppContextAction,
} from './types';

export function fetchAppContext(
    experiencesConfigurationFF: boolean,
    memberGroupId: number,
    advertiserId?: number | null,
    advertiserGroupId?: number | null
): FetchAppContextAction {
    return {
        type: AppContextAction.FETCH_APP_CONTEXT,
        payload: {
            experiencesConfigurationFF,
            memberGroupId,
            advertiserId: advertiserId || null,
            advertiserGroupId: advertiserGroupId || null,
        },
    };
}

export function fetchAppContextSuccess(
    memberGroup: ContextMemberGroup | DefaultMemberGroup | null,
    advertiser: Advertiser | null,
    advertiserGroup: AdvertiserGroup | null
): FetchAppContextSuccessAction {
    return {
        type: AppContextAction.FETCH_APP_CONTEXT_SUCCESS,
        payload: {
            memberGroup,
            advertiser,
            advertiserGroup,
        },
    };
}

export function fetchEmbeddedAppContext(
    memberGroupId: number
): FetchEmbeddedAppContextAction {
    return {
        type: AppContextAction.FETCH_EMBEDDED_APP_CONTEXT,
        payload: {
            memberGroupId,
        },
    };
}

export function fetchAppContextError(
    memberGroupId: number,
    advertiserId: number | null,
    advertiserGroupId: number | null
): FetchAppContextError {
    return {
        type: AppContextAction.FETCH_APP_CONTEXT_ERROR,
        payload: {
            memberGroupId,
            advertiserId,
            advertiserGroupId,
        },
    };
}

export function setPreviousMemberGroupId(
    previousMemberGroupId: number | null
): SetPreviousMemberGroup {
    return {
        type: AppContextAction.SET_PREVIOUS_MEMBER_GROUP,
        payload: {
            previousMemberGroupId,
        },
    };
}

export function setSavedReportTitle(
    savedReportTitle: string | null
): SetSavedReportTitle {
    return {
        type: AppContextAction.SET_SAVED_REPORT_TITLE,
        payload: {
            savedReportTitle,
        },
    };
}

export function setErrorCode(errorCode: number | null): SetErrorCodeAction {
    return {
        type: AppContextAction.SET_ERROR_CODE,
        payload: {
            errorCode,
        },
    };
}

export function clearAppContext(): ClearAppContext {
    return {
        type: AppContextAction.CLEAR_APP_CONTEXT,
    };
}

export function setMemberGroupCurrencies(
    memberGroupCurrencies: CurrencyList
): SetMemberGroupCurrencies {
    return {
        type: AppContextAction.SET_MEMBER_GROUP_CURRENCIES,
        payload: {
            memberGroupCurrencies,
        },
    };
}
export function setMemberGroupBiddingConfigs(
    biddingConfigs: BiddingConfigReport
): SetMemberGroupBiddingConfigs {
    return {
        type: AppContextAction.SET_MEMBER_GROUP_BIDDING_CONFIGS,
        payload: {
            biddingConfigs,
        },
    };
}

export function setCalculatedPageCurrency(
    calculatedPageCurrency: number
): SetCalculatedPageCurrency {
    return {
        type: AppContextAction.SET_CALCULATED_PAGE_CURRENCY,
        payload: {
            calculatedPageCurrency,
        },
    };
}

export function setTaxTypes(taxTypes: ContextTaxType[]): SetTaxTypes {
    return {
        type: AppContextAction.SET_TAX_TYPES,
        payload: {
            taxTypes,
        },
    };
}

export function setAdvertiserHasFunds(
    hasFunds: boolean
): SetAdvertiserHasFunds {
    return {
        type: AppContextAction.SET_ADVERTISER_HAS_FUNDS,
        payload: {
            hasFunds,
        },
    };
}
