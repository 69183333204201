import React, { useCallback } from 'react';
import { Row } from 'react-table';

import { TableCellComponentProps } from 'koddi-components/Table/TableRow/TableRow.types';
import FlexBox from 'koddi-components/FlexBox';

import { valueFormatter } from 'koddi-components/hooks';

import { ClickableCell } from './ClickableCell.styled';

export type CellClickOptions<Data extends Record<string, unknown>> = {
    row: Row<Data>;
};

export type ClickableCellProps = TableCellComponentProps<
    Record<string, unknown>,
    any,
    {
        onCellClick?: (options: CellClickOptions<any>) => void;
        underline?: boolean;
        isDisabled?: (options: CellClickOptions<any>) => boolean;
        noTranslate?: boolean;
    }
> & {
    onCellClick?: (options: CellClickOptions<any>) => void;
    noTranslate?: boolean;
    underline?: boolean;
    isDisabled?: (options: CellClickOptions<any>) => boolean;
};

const ClickableCellComponent = (props: ClickableCellProps): JSX.Element => {
    const {
        column: {
            onCellClick: onClickLegacy,
            Header,
            underline: underlineLegacy,
            isDisabled: isDisabledLegacy,
            noTranslate: legacyIsVar,
        },
        row: legacyRow,
        value,
        noTranslate,
        data,
        underline: underlineProp,
        onCellClick: onClickProp,
        isDisabled: isDisabledProp,
    } = props;
    const row = legacyRow || data;
    const isDisabled = isDisabledProp || isDisabledLegacy;
    const underline = underlineProp || underlineLegacy;
    const onCellClick = onClickProp || onClickLegacy;
    let displayValue;
    if (typeof value === 'number') {
        displayValue = valueFormatter(Number(value), 'number');
    } else displayValue = value;
    const handleClick = useCallback(() => {
        if (onCellClick) onCellClick({ row });
        else {
            // eslint-disable-next-line no-console
            console.warn(
                `You forgot to pass onChange to the column definition for ${Header}. The StatusCell will not work correctly.`
            );
        }
    }, [onCellClick, Header, row]);
    /* eslint-disable jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */
    return (
        <FlexBox alignItems="center" fillContainer>
            {isDisabled && isDisabled({ row }) ? (
                <div>{displayValue}</div>
            ) : (
                <ClickableCell underline={underline} onClick={handleClick}>
                    {noTranslate || legacyIsVar ? (
                        <div translate="no">{displayValue}</div>
                    ) : (
                        displayValue
                    )}
                </ClickableCell>
            )}
        </FlexBox>
    );
};

export default ClickableCellComponent;
