import { getLuminance, darken, lighten } from 'polished';
import { KoddiTheme, KoddiThemeBase } from './ThemeProvider.types';

export function addThemeUtils(theme: KoddiThemeBase): KoddiTheme {
    return {
        ...theme,
        createStateColor: (color: string, amount?: number | string) => {
            const isLightColor = getLuminance(color) > 0.179;
            return isLightColor
                ? darken(amount || 0.2, color)
                : lighten(amount || 0.2, color);
        },
        lighten: (color: string, amount?: number | string) => {
            return lighten(amount || 0.2, color);
        },
        darken: (color: string, amount?: number | string) => {
            return darken(amount || 0.2, color);
        },
    };
}
