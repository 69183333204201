import React, { FunctionComponent } from 'react';
import {
    PermissionsProviderValue,
    PermissionsProviderProps,
    Permission,
    Role,
} from './PermissionsProvider.types';

export const PermissionsProviderContext = React.createContext<
    PermissionsProviderValue | undefined
>(undefined);

const createPermissionsMap = (
    user_permissions: Permission[]
): Record<string, string[]> => {
    return user_permissions.reduce(
        (permissions, permission) => ({
            ...permissions,
            [permission.name]: permission.permissions,
        }),
        {}
    );
};

const createPermissionsList = (user_permissions: Permission[]): string[] => {
    return user_permissions.reduce<string[]>(
        (permissionsList, { name, permissions }) => {
            return permissionsList.concat(
                permissions.map((permission) => `${name}.${permission}`)
            );
        },
        []
    );
};

type RoleList = {
    roles: number[];
    access: {
        memberGroupIds: number[];
        advertiserGroupIds: number[];
        advertiserIds: number[];
    };
};

const createRoleList = (userRoles: Role[]): RoleList => {
    const roles: number[] = [];
    const memberGroupIds: number[] = [];
    const advertiserGroupIds: number[] = [];
    const advertiserIds: number[] = [];
    userRoles?.forEach((role) => {
        roles.push(role.id);
        role.access?.clients?.forEach((clientId) => {
            if (!memberGroupIds.includes(clientId)) {
                memberGroupIds.push(clientId);
            }
        });
        role.access?.advertiser_groups?.forEach((advertiserGroup) => {
            if (!advertiserGroupIds.includes(advertiserGroup)) {
                advertiserGroupIds.push(advertiserGroup);
            }
        });
        role.access?.advertisers?.forEach((advertiser) => {
            if (!advertiserIds.includes(advertiser)) {
                advertiserIds.push(advertiser);
            }
        });
    });
    return {
        roles,
        access: {
            memberGroupIds,
            advertiserGroupIds,
            advertiserIds,
        },
    };
};

/*
 * The `PermissionsProvider` is responsible for taking the users permissions
 * and transforming them into a usable format for child components to use.
 *
 * A hook called `usePermissions` is provided to make it easy to access the provided permissions.
 */
const PermissionsProvider: FunctionComponent<PermissionsProviderProps> = ({
    children,
    permissions,
    roles: userRoles,
}) => {
    const permissionsMap = createPermissionsMap(permissions);
    const permissionsList = createPermissionsList(permissions);
    const { roles, access } = createRoleList(userRoles);
    const value = {
        map: permissionsMap,
        list: permissionsList,
        roles,
        access,
    };
    return (
        <PermissionsProviderContext.Provider value={value}>
            {children}
        </PermissionsProviderContext.Provider>
    );
};

export default PermissionsProvider;
