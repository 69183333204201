import React, { FunctionComponent } from 'react';
import { BaseSVGProps } from '../icon.types';

const StatusPausedIcon: FunctionComponent<BaseSVGProps> = ({
    width,
    height,
}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        height={height}
        width={width}
        viewBox="0 0 24 24"
    >
        <title>Status Paused</title>
        <path d="M12 0a12 12 0 1012 12A12 12 0 0012 0zm-1.89 17.14h-2.4V7.2h2.4zm6.27 0H14V7.2h2.4z" />
    </svg>
);

export default StatusPausedIcon;
