import styled, { css } from 'styled-components';
import { TabsProvider, TabContent } from 'koddi-components/Tabs';
import Icon from 'koddi-components/Icon';

// eslint-disable-next-line prettier/prettier
export const ChartCardTabsProvider = styled(TabsProvider)`
    transition: transform 0.6s ease-in-out;
    > div {
        margin: 0px;
    }
`;

export const ChartCardIconWrapper = styled.button`
    ${({ theme }) => css`
        background: transparent;
        border: 0px;
        padding: 0px 0px 0px ${theme.spaceUnit * 3}px;
        &[aria-selected='true'] {
            color: ${theme.primary};
        }
        &:focus {
            outline: none;
        }
        &:hover {
            cursor: pointer;
            color: ${theme.primary};
        }
    `}
`;

export const ChartCardIcon = styled(Icon)`
    display: flex;
`;

export const ChartCardTabContent = styled(TabContent)`
    > div {
        padding: 0px;
    }
`;
