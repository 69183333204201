import {
    ApplicationRootState,
    ApplicationOutputSelector,
} from 'redux-core/types';
import { createSelector } from 'reselect';
import { KoddiTheme, addThemeUtils } from 'koddi-components/ThemeProvider';
import { KoddiThemeResource, ThemeState } from './types';
import { initialState } from './reducer';

const selectApp = (state: ApplicationRootState) =>
    state.app.theme || initialState;

export const selectAppTheme: ApplicationOutputSelector<
    KoddiTheme,
    (res: ThemeState) => KoddiTheme
> = createSelector(selectApp, (state) => {
    return addThemeUtils(state.theme.theme);
});

export const selectAppThemeResource: ApplicationOutputSelector<
    KoddiThemeResource,
    (res: ThemeState) => KoddiThemeResource
> = createSelector(selectApp, (state) => state.theme);

export const selectAppThemeList: ApplicationOutputSelector<
    KoddiThemeResource[],
    (res: ThemeState) => KoddiThemeResource[]
> = createSelector(selectApp, (state) => state.themes);

export const selectThemeStatus = createSelector(
    selectApp,
    (state: ThemeState) => state.status
);

export const selectThemeError = createSelector(
    selectApp,
    (state: ThemeState) => state.error
);

export const selectThemeInitialized = createSelector(
    selectApp,
    (state: ThemeState) => state.initialized
);
export const selectThemeRegistration = createSelector(
    selectApp,
    (state: ThemeState) => {
        return state.registration_enabled;
    }
);

export const selectThemeSSOIds = createSelector(
    selectApp,
    (state: ThemeState) => {
        return {
            sso_organization_id: state.sso_organization_id,
            sso_connection_id: state.sso_connection_id,
        };
    }
);
