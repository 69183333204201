import React, { FunctionComponent } from 'react';
import { BaseSVGProps } from '../icon.types';

const LineChartIcon: FunctionComponent<BaseSVGProps> = ({ width, height }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 24 24"
    >
        <path
            d="M3.8,17.707l6.261-6.545,5.217,5.455,9.391-9.949-2.15-2.247L15.275,11.99,10.058,6.536,3.8,13.081V.667H.667v24H23.623V21.394H3.8Z"
            transform="translate(-0.667 -0.667)"
        />
    </svg>
);

export default LineChartIcon;
