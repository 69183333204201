import React, { FunctionComponent } from 'react';
import { BaseSVGProps } from '../icon.types';

const OverviewIcon: FunctionComponent<BaseSVGProps> = ({ width, height }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        height={height}
        width={width}
        viewBox="0 0 24 24"
    >
        <g id="statistics" transform="translate(0 -0.05)">
            <g id="Group_1198" transform="translate(0 5.81)">
                <g id="Group_1197">
                    <path
                        id="Path_3031"
                        d="M8.52,4.72H5.46c-0.29,0-0.51,0.24-0.51,0.52v7.86c0,0.29,0.22,0.52,0.51,0.52c0,0,0,0,0,0h3.06
				c0.29,0,0.51-0.24,0.51-0.52c0,0,0,0,0,0V5.24C9.03,4.96,8.8,4.72,8.52,4.72C8.52,4.72,8.52,4.72,8.52,4.72z"
                    />
                </g>
            </g>
            <g id="Group_1200" transform="translate(5.238 0.05)">
                <g id="Group_1199" transform="translate(0 0)">
                    <path
                        id="Path_3032"
                        d="M8.62,4.72H5.47c-0.29,0-0.52,0.23-0.52,0.52v13.62c0,0.29,0.23,0.52,0.52,0.52c0,0,0,0,0,0h3.14
				c0.29,0,0.52-0.24,0.52-0.52V5.24C9.14,4.95,8.91,4.72,8.62,4.72C8.62,4.72,8.62,4.72,8.62,4.72z"
                    />
                </g>
            </g>
            <g id="Group_1202" transform="translate(10.72 4.239)">
                <g id="Group_1201">
                    <path
                        id="Path_3033"
                        d="M8.4,4.72H5.44c-0.28,0.01-0.5,0.24-0.49,0.52c0,0,0,0,0,0v9.43c-0.01,0.28,0.21,0.52,0.49,0.52H8.4
				c0.28-0.01,0.5-0.24,0.49-0.52V5.24C8.9,4.96,8.68,4.73,8.4,4.72z"
                    />
                </g>
            </g>
        </g>
    </svg>
);

export default OverviewIcon;
