import React from 'react';
import { FieldContainer } from 'koddi-components/Form';
import { SelectField } from 'koddi-components/Select';
import required from 'validators/required';
import { useDynamicStateField } from './DynamicStateField.hooks';
import { DynamicStateFieldProps } from './DynamicStateField.types';

export const DynamicStateField = ({
    name,
    isDisabled,
    defaultCountry = 'US',
    connectedCountryFieldName = 'country',
}: DynamicStateFieldProps): JSX.Element | null => {
    const { isHidden, selectedCountry, stateOptions } = useDynamicStateField({
        fieldName: name,
        defaultCountry,
        connectedCountryFieldName,
    });

    if (isHidden) return null;

    return (
        <FieldContainer>
            <SelectField
                name={name}
                options={stateOptions}
                label="State/Province"
                autoComplete="address-level1"
                isDisabled={isHidden || !!selectedCountry || isDisabled}
                validate={isHidden ? undefined : required}
                isSearchable
            />
        </FieldContainer>
    );
};
