import { useContext } from 'react';
import { LocaleProviderContext } from './LocaleProvider.component';
import { LocaleProviderContextValue } from './LocaleProvider.types';

export function useLocale(): LocaleProviderContextValue {
    const context = useContext(LocaleProviderContext);
    if (context === undefined) {
        throw new Error('`useLocale` must be used within a `LocaleProvider`');
    }
    return context;
}
