import React, { FunctionComponent } from 'react';
import { BaseSVGProps } from '../icon.types';

const ThermometerIcon: FunctionComponent<BaseSVGProps> = ({
    width,
    height,
}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 24 24"
    >
        <path
            d="M12.65 0h-1.3a1.94 1.94 0 00-1.94 2V22.05A1.94 1.94 0 0011 24a1.72 1.72 0 00.39 0h1.3a1.61 1.61 0 00.44-.06 1.92 1.92 0 001.46-1.49 1.75 1.75 0 000-.4V2a1.94 1.94 0 00-1.94-2zM14 8.94a2 2 0 00-1.3-.51h-1.3a2 2 0 00-1.3.51V2A1.3 1.3 0 0111.4.7h1.3A1.3 1.3 0 0114 2zm2.37-.18l2.59 1.94V6.81z"
            stroke="none"
            fill="#000"
        />
    </svg>
);

export default ThermometerIcon;
