/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { FunctionComponent } from 'react';

import { LabelProps } from './Label.types';
import { Label } from './Label.styled';

const LabelComponent: FunctionComponent<LabelProps> = ({
    htmlFor,
    preventFocusOnClick = false,
    children,
    onClick,
}) => {
    function handleClick(e: React.MouseEvent<HTMLLabelElement, MouseEvent>) {
        if (preventFocusOnClick) {
            e.preventDefault();
        }
        if (onClick) onClick(e);
    }

    return (
        <Label onClick={handleClick} htmlFor={htmlFor}>
            {children}
        </Label>
    );
};

export default LabelComponent;
