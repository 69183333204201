import { Experience } from 'api/Admin/MemberGroupExperiences/MemberGroupExperiences.types';
import { ExperiencesAction, SetExperiencesAction } from './types';

export function setMemberGroupExperiences(
    experiences: Experience[]
): SetExperiencesAction {
    return {
        type: ExperiencesAction.SET_MEMBER_GROUP_EXPERIENCES,
        payload: {
            experiences,
        },
    };
}
