import enUS from 'date-fns/locale/en-US';
import es from 'date-fns/locale/es';
import de from 'date-fns/locale/de';
import ja from 'date-fns/locale/ja';
import zhTW from 'date-fns/locale/zh-TW';
import fr from 'date-fns/locale/fr';
import frCA from 'date-fns/locale/fr-CA';
import ptBR from 'date-fns/locale/pt-BR';
import it from 'date-fns/locale/it';
import tr from 'date-fns/locale/tr';

import { KoddiLocaleKey } from './LocaleProvider.types';

export const KODDI_LOCALE_KEYS: Record<KoddiLocaleKey, KoddiLocaleKey> = {
    'en-US': 'en-US',
    es: 'es',
    de: 'de',
    ja: 'ja',
    'zh-Hant-TW': 'zh-Hant-TW',
    'ja-JP': 'ja-JP',
    'Es-419': 'es',
    'fr-FR': 'fr-FR',
    'fr-CA': 'fr-CA',
    'pt-BR': 'pt-BR',
    'zh-TW': 'zh-TW',
};

/** Maps Koddi to date-fns locale configuration */
export const KODDI_LOCALE_TO_DATEFNS: Record<KoddiLocaleKey, Locale> = {
    'en-US': enUS,
    es,
    de,
    ja,
    it,
    tr,
    'zh-Hant-TW': zhTW,
    'ja-JP': ja,
    'fr-FR': fr,
    'fr-CA': frCA,
    'zh-TW': zhTW,
    'Es-419': es,
    'pt-BR': ptBR,
};

/** Maps Koddi locale codes to locale codes supported by numeral */
export const KODDI_LOCALE_TO_NUMERAL: Record<string, string> = {
    'en-US': 'en',
    'Es-419': 'es-es',
    'fr-CA': 'fr-ca',
    'fr-FR': 'fr',
    'pt-BR': 'pt-br',
    'ja-JP': 'ja',
    de: 'de',
    tr: 'tr',
    it: 'it',
};
