/* eslint-disable react/require-default-props */
import { useKoddiTheme } from 'koddi-components/ThemeProvider';
import React, { useMemo } from 'react';
import { Auth0Container, Logo, Auth0Content } from './Auth0Layouts.styled';

type Auth0LayoutProps = {
    children: JSX.Element;
    maxWidth?: number;
};

export const Auth0Layout = ({
    maxWidth = 420,
    children,
}: Auth0LayoutProps): JSX.Element => {
    const theme = useKoddiTheme();
    const logoSrc = useMemo(() => theme.loginLogoSrc || theme.logoSrc, [theme]);
    return (
        <Auth0Container maxWidth={maxWidth}>
            <Logo src={logoSrc} alt="Client Logo" />
            <Auth0Content>{children}</Auth0Content>
        </Auth0Container>
    );
};
