import React, { FunctionComponent } from 'react';
import { BaseSVGProps } from '../icon.types';

const TargetIcon: FunctionComponent<BaseSVGProps> = ({ width, height }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        height={height}
        width={width}
        viewBox="0 0 24 24"
    >
        <path d="M12 0a12 12 0 1012 12A12 12 0 0012 0zm0 23a11 11 0 1111-11 11 11 0 01-11 11zm0-19.72A8.72 8.72 0 1020.72 12 8.73 8.73 0 0012 3.28zm0 16.41A7.69 7.69 0 1119.69 12 7.7 7.7 0 0112 19.69zm0-11.77A4.08 4.08 0 1016.08 12 4.07 4.07 0 0012 7.92zm0 6.47a2.45 2.45 0 112.44-2.45A2.45 2.45 0 0112 14.39z" />
    </svg>
);

export default TargetIcon;
