import CachedKoddiAPI from 'api/api.cachedObject';
import { KoddiAPIResponse } from 'types';
import { LocalesList } from './Locales.types';

class KoddiLocalesAPI extends CachedKoddiAPI {
    getList = async (): Promise<LocalesList> => {
        const response = await this.cache.makeCachedRequest(
            'getList',
            '',
            () => {
                return this.axios.get<
                    KoddiAPIResponse<{ locales: LocalesList }>
                >(`v1/locales`);
            }
        );

        return response.data.result.locales;
    };
}

export default KoddiLocalesAPI;
