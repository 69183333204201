import React, {
    useState,
    FunctionComponent,
    useCallback,
    useMemo,
} from 'react';
import { useLocation } from 'react-router-dom';
import {
    ADMIN_ROUTE,
    CREATE_CAMPAIGNS_ROUTE,
    CREATE_CAMPAIGNS_ROUTE_V2,
} from 'modules/constants/routes';
import {
    SUPER_USER_ROLE_ID,
    INTERNAL_ADMIN_ROLE_ID,
    CLIENT_ADMIN_ROLE_ID,
    CLIENT_VIEWER_ROLE_ID,
    ADVERTISER_ADMIN_ROLE_ID,
    ADVERTISER_MANAGER_ROLE_ID,
    ADVERTISER_VIEWER_ROLE_ID,
} from 'modules/constants/roles';
import {
    useDashboardRoute,
    useCampaignOverviewRoute,
    useAdGroupOverviewRoute,
    useEntityOverviewRoute,
    useReportingOverviewRoute,
    useSavedReportsRoute,
} from 'modules/hooks/routes';
import Icon from 'koddi-components/Icon';
import { useTranslation } from 'react-i18next';
import { MenuItem } from 'koddi-components/Menu';
import { useTermsAndConditionsUrl } from 'redux-core/app/termsAndConditions/hooks';
import {
    useMemberGroupEntityLabel,
    useMemberGroupId,
    useAppContextStatus,
} from 'redux-core/app/context/hooks';
import {
    useAuthUser,
    useUserHasClientAdminAccess,
    useUserHasGlobalAdminAccess,
    useUserHasSingleClientAcccess,
    useUserHasAdvertiserAdminAccess,
    useUserHasSingleAdvertiserAcccess,
} from 'redux-core/auth/hooks';
import { useAppAdvertisers } from 'redux-core/app/advertisers/hooks';
import { usePermissions } from 'koddi-components/PermissionsProvider';
import { useFlags } from 'launchdarkly-react-client-sdk';
import {
    SideNavContainer,
    CloseNavButton,
    Footer,
    TermsAndConditions,
    NavMenu,
} from './SideNav.styled';

const tKey = 'Features.SideNavigation';

export const SideNav: FunctionComponent = (): JSX.Element => {
    const [open, setOpen] = useState(true);
    const { t } = useTranslation();
    const location = useLocation();
    const isCreatingCampaign = location.pathname.includes(
        CREATE_CAMPAIGNS_ROUTE || CREATE_CAMPAIGNS_ROUTE_V2
    );
    const dashboardRoute = useDashboardRoute();
    const campaignOverviewRoute = useCampaignOverviewRoute();
    const adGroupOverviewRoute = useAdGroupOverviewRoute();
    const entityOverviewRoute = useEntityOverviewRoute();
    const reportingOverviewRoute = useReportingOverviewRoute();
    const savedReportsRoute = useSavedReportsRoute();
    const appAdvertisers = useAppAdvertisers();
    const termsAndConditionsUrl = useTermsAndConditionsUrl();
    const entityLabel = useMemberGroupEntityLabel();
    const memberGroupId = useMemberGroupId();
    const { access } = usePermissions();
    const userHasClientAccess = useUserHasClientAdminAccess();
    const userHasGlobalAccess = useUserHasGlobalAdminAccess();
    const userHasAdvertiserAdminAccess = useUserHasAdvertiserAdminAccess();
    const userHasSingleClientAccess = useUserHasSingleClientAcccess();
    const userHasSingleAdvertiserAcccess = useUserHasSingleAdvertiserAcccess();
    const authUser = useAuthUser();
    const contextStatus = useAppContextStatus();
    const featureFlags = useFlags();

    const adminLink = useMemo(() => {
        const { memberGroupIds, advertiserIds } = access;
        const clientId = memberGroupIds?.[0];
        const advertiserId = advertiserIds?.[0];
        const advertiserMemberGroupId =
            (advertiserId &&
                authUser?.advertiser_member_group?.[advertiserId]) ||
            memberGroupId;

        if (userHasGlobalAccess) {
            return ADMIN_ROUTE;
        }
        if (userHasClientAccess && !userHasSingleClientAccess) {
            return `${ADMIN_ROUTE}/clients`;
        }
        if (userHasClientAccess && userHasSingleClientAccess) {
            return `${ADMIN_ROUTE}/clients/${clientId}/advertisers`;
        }

        // advertiser user has access to multiple advertisers under multiple clients
        if (userHasAdvertiserAdminAccess && !userHasSingleClientAccess) {
            return `${ADMIN_ROUTE}/advertisers`;
        }
        // advertiser user has access to multiple advertisers under 1 client
        if (
            userHasAdvertiserAdminAccess &&
            userHasSingleClientAccess &&
            !userHasSingleAdvertiserAcccess
        ) {
            return `${ADMIN_ROUTE}/clients/${advertiserMemberGroupId}/advertisers`;
        }
        // advertiser user has access to one advertiser
        if (userHasAdvertiserAdminAccess && userHasSingleAdvertiserAcccess) {
            return `${ADMIN_ROUTE}/clients/${advertiserMemberGroupId}/advertisers/${advertiserId}/entities`;
        }
        return '';
    }, [
        access,
        memberGroupId,
        userHasClientAccess,
        userHasGlobalAccess,
        userHasSingleClientAccess,
        userHasAdvertiserAdminAccess,
        userHasSingleAdvertiserAcccess,
        authUser,
    ]);
    const contextChanging = contextStatus === 'pending';

    const setMenuItems = useCallback((): MenuItem[] => {
        const defaultNavItems = [
            {
                title: 'Overview',
                to: dashboardRoute,
                isSelected: () => location.pathname === dashboardRoute,
                icon: 'overview',
                translationKey: `${tKey}.Menu.Overview.Text`,
                iconWidth: 25,
                iconHeight: 25,
                disabled: contextChanging,
            },
            {
                title: 'Campaigns',
                to: campaignOverviewRoute,
                icon: 'campaign',
                translationKey: `${tKey}.Menu.MediaPlans.Menu.Campaigns.Text`,
                iconWidth: 25,
                iconHeight: 25,
                condition: appAdvertisers?.data.length > 0,
                disabled: contextChanging,
                items: !isCreatingCampaign
                    ? [
                          {
                              title: 'Ad Groups',
                              to: adGroupOverviewRoute,
                              iconWidth: 0,
                              iconHeight: 0,
                              condition: appAdvertisers?.data.length > 0,
                              translationKey: `${tKey}.Menu.MediaPlans.Menu.AdGroups.Text`,
                              disabled: contextChanging,
                          },
                          {
                              title: entityLabel.plural,
                              to: entityOverviewRoute,
                              iconWidth: 0,
                              iconHeight: 0,
                              condition: appAdvertisers?.data.length > 0,
                              translationKey: `${tKey}.Menu.Entities.Text`,
                              disabled: contextChanging,
                          },
                      ]
                    : [],
            },
            {
                title: 'Reporting',
                to: reportingOverviewRoute,
                isSelected: () => location.pathname.includes('/reporting'),
                icon: 'pieChart',
                translationKey: `${tKey}.Menu.Reporting.Text`,
                iconWidth: 24,
                iconHeight: 24,
                condition:
                    appAdvertisers?.data.length > 0 &&
                    featureFlags.targetingReporting,
                disabled: contextChanging,
                items: featureFlags.savedReports
                    ? [
                          {
                              title: 'Saved Reports',
                              to: savedReportsRoute,
                              iconWidth: 0,
                              iconHeight: 0,
                              translationKey: ``,
                              disabled: contextChanging,
                          },
                      ]
                    : [],
            },
            {
                title: 'Admin Tools',
                to: adminLink,
                isSelected: () => location.pathname.includes('/admin'),
                icon: 'settings',
                translationKey: `${tKey}.Menu.AdminTools.Text`,
                anyRole: true,
                iconWidth: 25,
                iconHeight: 20,
                disabled: contextChanging,
                role: [
                    SUPER_USER_ROLE_ID,
                    INTERNAL_ADMIN_ROLE_ID,
                    CLIENT_ADMIN_ROLE_ID,
                    CLIENT_VIEWER_ROLE_ID,
                    ADVERTISER_ADMIN_ROLE_ID,
                    ADVERTISER_MANAGER_ROLE_ID,
                    ADVERTISER_VIEWER_ROLE_ID,
                ],
            },
        ];
        return defaultNavItems as MenuItem[];
    }, [
        entityLabel,
        isCreatingCampaign,
        adminLink,
        location.pathname,
        dashboardRoute,
        campaignOverviewRoute,
        adGroupOverviewRoute,
        entityOverviewRoute,
        reportingOverviewRoute,
        savedReportsRoute,
        appAdvertisers?.data,
        featureFlags.targetingReporting,
        contextChanging,
        featureFlags.savedReports,
    ]);

    const renderTermsAndConditions = () => {
        const terms = (
            <span>
                {open ? (
                    <TermsAndConditions>
                        {t(`${tKey}.TermsAndConditions`, 'Terms & Conditions')}
                    </TermsAndConditions>
                ) : (
                    <Icon icon="info" />
                )}
            </span>
        );
        if (termsAndConditionsUrl) {
            return (
                <a
                    href={termsAndConditionsUrl}
                    target="_blank"
                    rel="noreferrer"
                    data-test="sidenav-terms-external-link"
                >
                    {terms}
                </a>
            );
        }
        return null;
    };
    return (
        <SideNavContainer
            data-test="sidenav-component"
            open={open}
            contextChanging={contextChanging}
        >
            <CloseNavButton
                open={open}
                height={6}
                width={6}
                icon="chevronLeft"
                onClick={() => setOpen(!open)}
            />
            <NavMenu items={setMenuItems()} vertical isOpen={open} />
            <Footer>{renderTermsAndConditions()}</Footer>
        </SideNavContainer>
    );
};

export default SideNav;
