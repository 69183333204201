import React, { FunctionComponent } from 'react';
import { BaseSVGProps } from '../icon.types';

const TrashCanIcon: FunctionComponent<BaseSVGProps> = ({ width, height }) => (
    <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        x="0px"
        y="0px"
        viewBox="0 0 230 288"
    >
        <g>
            <path
                d="M211.2,15.1c-16.2,0-32.5-0.1-48.7,0.1c-3.1,0-5.3-0.8-7.3-3.1c-3.5-3.8-7.2-7.3-10.9-10.9c-19.2,0-38.5,0-57.7,0
		C83.1,4.6,79.5,8,76.2,11.7c-2.3,2.6-4.8,3.5-8.2,3.4c-17.4-0.2-34.8-0.2-52.2,0C7.1,15.2,1.6,20.7,1.4,29
		c-0.2,8.1,4.8,13.7,13.2,14.6c2,0.2,4,0.2,6,0.2c63.1,0,126.3-0.1,189.4,0.1c9,0,16.3-1.7,20-10.8c0-2,0-4,0-6
		C225.7,16.8,223,15.1,211.2,15.1z"
            />
            <path d="M144.4,1.1c0-0.1,0-0.3,0-0.4c-0.1,0-0.2,0-0.3,0C144,0.8,144.1,1,144.4,1.1z" />
            <path
                d="M210.3,72c-31.5,0.1-63,0.1-94.5,0.1c-31.8,0-63.7,0.1-95.5-0.1c-3.7,0-4.7,0.9-4.7,4.6c0.1,59.7,0.1,119.5,0.1,179.2
		c0,18.7,11.7,30.3,30.5,30.3c46.1,0,92.2,0,138.2,0c19,0,30.7-11.6,30.7-30.6c0-59.6,0-119.1,0.1-178.7
		C215.3,72.8,214.1,72,210.3,72z M86.9,241.9c0,11.7-7.5,17.8-18.2,15.2c-6.4-1.6-10-6.3-10-14.1c-0.1-16.9,0-33.8,0-50.7
		c0-4.5,0-9,0-13.4c0-21.2-0.1-42.4,0.1-63.6c0.1-11.1,9-17.2,19.4-13.6c5,1.7,7.8,5.3,8.7,10.5c0.2,1.5,0.2,3,0.2,4.5
		C86.9,158.3,86.9,200.1,86.9,241.9z M172.4,243.1c-0.1,10.6-7.7,16.4-17.9,14.1c-5.7-1.3-9.5-5.5-10.2-11.3
		c-0.1-1.1-0.2-2.3-0.2-3.5c0-42.1-0.1-84.2,0-126.3c0-11.8,8.9-18.2,19.6-14.4c5.6,2,8.6,6.4,8.7,13.6c0.1,15.4,0,30.8,0,46.2
		c0,6,0,11.9,0,17.9C172.4,200.6,172.6,221.8,172.4,243.1z"
            />
        </g>
    </svg>
);

export default TrashCanIcon;
