import styled, { css } from 'styled-components';
import Dropdown from 'koddi-components/Dropdown';

export const EmptyTableWrapper = styled.div<{
    tableHeight: number;
    v2Styling?: boolean;
}>`
    ${({
        theme: { grayLightest, white, grayLight },
        tableHeight,
        v2Styling,
    }) => css`
        min-height: ${tableHeight || 0}px;
        height: 100%;
        width: 100%;
        min-width: 280px;
        text-align: center;
        background-color: ${v2Styling ? white : grayLightest};
        position: relative;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        button {
            margin-top: 17px;
            display: inline-block;
        }
        border: 2px dashed ${v2Styling ? grayLight : 'transparent'};
    `}
`;

export const EmptyTableButtonWrapper = styled.div`
    button {
        margin-left: 5px;
        margin-right: 5px;
    }
`;

export const EmptyTableDropDown = styled(Dropdown)`
    button {
        display: flex;
        min-width: 10rem;
        span {
            text-align: center;
        }
    }
    ul {
        text-align: left;
    }
`;

export const TextWrapper = styled.div`
    ${({ theme: { gray } }) => css`
        color: ${gray};
    `}
`;
