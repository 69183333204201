import { useContext } from 'react';
import { ToastProviderContext } from './ToastProvider';
import { ToastProviderContextValue } from './ToastProvider.types';

export function useKoddiToast(): ToastProviderContextValue {
    const context = useContext(ToastProviderContext);
    if (context === undefined) {
        throw new Error(
            '`useKoddiToast` must be used within a `ToastProvider`'
        );
    }
    return context;
}
