import styled, { css } from 'styled-components';

export const ChartLegendWrapper = styled.div`
    ${({ theme }) => css`
        display: flex;
        flex-wrap: wrap;
        margin: 0px 5px ${theme.spaceUnit * 9}px 5px;
    `}
`;

export const ChartLegendItem = styled.div`
    ${({ theme }) => css`
        margin-right: ${theme.spaceUnit * 10}px;
    `}
`;

export const ChartLegendItemHeader = styled.div`
    ${({ theme }) => css`
        font-size: 30px;
        font-weight: 500;
        padding: ${theme.spaceUnit * 3}px 0px;
    `}
`;

// eslint-disable-next-line prettier/prettier
export const ChartLegendItemColor = styled.div<{ backgroundColor: string; }>`
    ${({ theme, backgroundColor }) => css`
        background-color: ${backgroundColor};
        width: 10px;
        height: 10px;
        margin-right: ${theme.spaceUnit * 2}px;
    `}
`;

export const ChartLegendItemText = styled.div``;
