/* eslint-disable @typescript-eslint/no-empty-function */
import React, { FunctionComponent } from 'react';
import { CSSTransition } from 'react-transition-group';
import { FadeTransitionProps } from './FadeTransition.types';
import { FadeTransitionContent } from './FadeTransition.styled';

/**
 * The `FadeTransition` makes it easy to fade an element in and out
 * of the dom in a safe and effecient way. The duration of the fade
 * can be adjusted using the `duration` prop.
 */
const FadeTransition: FunctionComponent<FadeTransitionProps> = ({
    children,
    in: inProp,
    duration = 300,
    unmountOnExit = false,
    appear = false,
    className,
    mountOnEnter = false,
}) => {
    return (
        <CSSTransition
            addEndListener={() => {}}
            timeout={duration}
            classNames=""
            in={inProp}
            mountOnEnter={mountOnEnter}
            unmountOnExit={unmountOnExit}
            appear={appear}
        >
            <FadeTransitionContent className={className} duration={duration}>
                {children}
            </FadeTransitionContent>
        </CSSTransition>
    );
};

export default FadeTransition;
