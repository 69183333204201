/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import KoddiAPI from 'api';
import { takeEvery, put, call } from 'redux-saga/effects';
import SagaRegistry from 'redux-core/sagaRegistry';
import {
    deleteAdvertiserGroupSuccess,
    deleteAdvertiserGroupFailure,
    GetAdvertiserGroupListAction,
    getAdvertiserGroupListSuccess,
    getAdvertiserGroupListFailure,
} from './actions';
import { AdvertiserGroupAction } from './types';

export function* deleteAdvertiserGroup({ payload }: any) {
    const { memberGroupId, advertiserGroupId } = payload;
    const response = yield call(
        KoddiAPI.Admin.AdvertiserGroup.deleteAdvertiserGroup,
        memberGroupId,
        advertiserGroupId
    );
    if (response.data.status === 'success') {
        yield put(deleteAdvertiserGroupSuccess());
    } else {
        yield put(deleteAdvertiserGroupFailure());
    }
}

export function* getAdvertiserGroupList({
    payload,
}: GetAdvertiserGroupListAction) {
    try {
        const response = yield call(
            KoddiAPI.Admin.AdvertiserGroup.getAdvertiserGroupsReport,
            payload.userId,
            { search: '%' }
        );
        yield put(getAdvertiserGroupListSuccess(response));
    } catch {
        yield put(getAdvertiserGroupListFailure());
    }
}

export function* watchDeleteAdvertiserGroups() {
    yield takeEvery(
        AdvertiserGroupAction.DELETE_ADVERTISER_GROUP,
        deleteAdvertiserGroup
    );
    yield takeEvery(
        AdvertiserGroupAction.GET_ADVERTISER_GROUP_LIST,
        getAdvertiserGroupList
    );
}

SagaRegistry.registerSaga(watchDeleteAdvertiserGroups);
