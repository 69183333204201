import React, { FunctionComponent } from 'react';
import { HorizontalFlexBox, VerticalFlexBox } from 'koddi-components/FlexBox';
import { IconKey } from 'koddi-components/Icon';
import {
    ToastBase,
    ToastStatusIcon,
    ToastTitle,
    ToastDetails,
    ToastStatusIconWrapper,
} from './Toast.styled';
import { KoddiToast, KoddiToastType } from '../ToastProvider.types';

type ToastProps = KoddiToast & {
    hideToast: VoidFunction;
};

const icons: Record<KoddiToastType, IconKey> = {
    success: 'check',
    error: 'closeX',
    warning: 'closeX',
};

const Toast: FunctionComponent<ToastProps> = ({
    id,
    title,
    details,
    type,
    hideToast,
    onClick,
}) => {
    const icon = icons[type];
    const handleClick = () => {
        if (onClick) onClick();
        hideToast();
    };
    return (
        <ToastBase role="status" data-test={id} onClick={handleClick}>
            <HorizontalFlexBox alignItems="center" justifyContent="flex-start">
                <ToastStatusIconWrapper
                    alignItems="center"
                    justifyContent="center"
                >
                    <ToastStatusIcon
                        icon={icon}
                        type={type}
                        width={16}
                        height={16}
                    />
                </ToastStatusIconWrapper>
                <VerticalFlexBox alignItems="flex-start">
                    <ToastTitle>{title}</ToastTitle>
                    <ToastDetails>{details}</ToastDetails>
                </VerticalFlexBox>
            </HorizontalFlexBox>
        </ToastBase>
    );
};

export default Toast;
