import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import CustomModal from 'koddi-components/Modals/CustomModal';
import { useKoddiToast } from 'koddi-components/ToastProvider';
import { ModalComponentProps } from 'koddi-components/Modals/Modals.types';
import Checkbox from 'koddi-components/Checkbox';
import { VerticalFlexBox } from 'koddi-components/FlexBox';
import Button from 'koddi-components/Button';
import { BodyText, Heading3 } from 'koddi-components/Typography';
import {
    useAppContextErrorCode,
    useAppContextMemberGroup,
} from 'redux-core/app/context/hooks';
import { acceptTermsAndConditions } from 'redux-core/app/termsAndConditions/actions';
import {
    useTermsAndConditionsUrl,
    useTermsMemberGroupId,
    useTermsLocaleId,
    useTermsError,
} from 'redux-core/app/termsAndConditions/hooks';
import { TermsAndConditionsModalProps } from './types';

const Modal = ({ onSubmit }: ModalComponentProps): JSX.Element => {
    const [accepted, setAcceptance] = useState(false);
    const termsUrl = useTermsAndConditionsUrl();
    const memberGroup = useAppContextMemberGroup();
    const clientName = memberGroup?.name;
    const checkboxText = `I accept ${clientName} `;
    const labelComponent = (
        <span>
            {checkboxText}
            <a target="_blank" rel="noreferrer" href={termsUrl}>
                Terms and Conditions
            </a>
        </span>
    );
    const bodyText = `Please accept the ${clientName} Terms & Conditions`;
    const handleCheckboxClick = () => setAcceptance(!accepted);

    return (
        <div data-test="terms-and-conditions-modal">
            <VerticalFlexBox padding={20} style={{ height: 200 }}>
                <Heading3 tKey="" text="Please Accept Our Terms & Conditions" />
                <VerticalFlexBox
                    flex={1}
                    alignItems="flex-start"
                    justifyContent="space-between"
                >
                    <BodyText tKey="">{bodyText}</BodyText>
                    <Checkbox
                        data-test="terms-and-conditions-checkbox"
                        name="tc-checkbox"
                        id="tc-checkbox"
                        label={labelComponent}
                        checked={accepted}
                        onChange={handleCheckboxClick}
                    />
                    <VerticalFlexBox
                        style={{ width: '100%' }}
                        alignItems="flex-end"
                    >
                        <Button
                            data-test="terms-and-conditions-submit"
                            disabled={!accepted}
                            onClick={onSubmit}
                        >
                            Submit
                        </Button>
                    </VerticalFlexBox>
                </VerticalFlexBox>
            </VerticalFlexBox>
        </div>
    );
};

export const TermsAndConditionsModal = ({
    show,
}: TermsAndConditionsModalProps): JSX.Element | null => {
    const [showError, setShowError] = useState(false);
    const dispatch = useDispatch();
    const { showErrorToast } = useKoddiToast();
    const termsError = useTermsError();
    const termsUrl = useTermsAndConditionsUrl();
    const memberGroupId = useTermsMemberGroupId();
    const localeId = useTermsLocaleId();
    const handleSubmit = () => {
        if (memberGroupId !== null && localeId !== null) {
            dispatch(
                acceptTermsAndConditions(memberGroupId, termsUrl, localeId)
            );
        }
    };

    useEffect(() => {
        if (termsError.errorType === 'POST' && !showError) {
            setShowError(true);
            showErrorToast(
                'Error',
                'There was an error accepting the Terms and Conditions'
            );
        }
    }, [termsError, showErrorToast, showError]);

    /* eslint-disable @typescript-eslint/no-empty-function */
    const handleClose = () => {};
    const closeModal = () => {};
    const contextErrorCode = useAppContextErrorCode();

    if (contextErrorCode) return null;
    return (
        <CustomModal
            title=""
            shouldCloseOnEsc={false}
            shouldCloseOnOverlayClick={false}
            Component={Modal}
            onSubmit={handleSubmit}
            onClose={handleClose}
            closeModal={closeModal}
            width={400}
            message="Please accept our terms and conditions"
            placeholder="Please accept our terms and conditions"
            labelledby="tcModal"
            describedby="tcModal"
            isOpen={show}
        />
    );
};

export default TermsAndConditionsModal;
