import CachedKoddiAPI from 'api/api.cachedObject';
import { KoddiAPIResponse } from 'types';
import { MemberGroupTypesList } from './MemberGroupTypes.types';

class KoddiMemberGroupTypesAPI extends CachedKoddiAPI {
    getList = async (): Promise<MemberGroupTypesList> => {
        const response = await this.cache.makeCachedRequest(
            'getList',
            '',
            () => {
                return this.axios.get<KoddiAPIResponse<MemberGroupTypesList>>(
                    `v1/member_group_type`
                );
            }
        );
        return response.data.result;
    };
}

export default KoddiMemberGroupTypesAPI;
