import { KoddiUserList, CreateKoddiUserInput, KoddiUser } from 'api/Admin/User';
import {
    GetUserListAction,
    UserAction,
    GetUserListSuccessAction,
    GetUserListErrorAction,
    CreateNewUserAction,
    CreateNewUserSuccessAction,
    CreateNewUserErrorAction,
    UpdateUserAction,
    UpdateUserErrorAction,
} from './types';

export function getUserList(refresh?: boolean): GetUserListAction {
    return {
        type: UserAction.GET_USER_LIST,
        payload: { refresh: refresh || false },
    };
}

export function getUserListSuccess(
    data: KoddiUserList
): GetUserListSuccessAction {
    return {
        type: UserAction.GET_USER_LIST_SUCCESS,
        payload: { data },
    };
}

export function getUserListError(
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    error: any
): GetUserListErrorAction {
    return {
        type: UserAction.GET_USER_LIST_ERROR,
        error,
    };
}

export function createNewUser(
    newUser: CreateKoddiUserInput
): CreateNewUserAction {
    return {
        type: UserAction.CREATE_NEW_USER,
        payload: { newUser },
    };
}

export function createNewUserSuccess(): CreateNewUserSuccessAction {
    return {
        type: UserAction.CREATE_NEW_USER_SUCCESS,
    };
}

export function createNewUserError(
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    error: any
): CreateNewUserErrorAction {
    return {
        type: UserAction.CREATE_NEW_USER_ERROR,
        error,
    };
}

export function updateUser(userData: KoddiUser): UpdateUserAction {
    return {
        type: UserAction.UPDATE_USER,
        payload: { userData },
    };
}

export function updateUserError(
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    error: any
): UpdateUserErrorAction {
    return {
        type: UserAction.UPDATE_USER_ERROR,
        error,
    };
}
