import React from 'react';
import InfiniteLoader from 'react-window-infinite-loader';
import { FixedSizeList } from 'react-window';

import TableRow from '../TableRow';

import { TableContainerProps } from './TableContainer.types';
import { TableContainerBase } from './TableContainer.styled';
import TableContainerInnerElement from './TableContainer.element';
import { useKoddiTable } from '../TableProvider';

/**
 * The `TableContainer` renders the table head and the table body
 * and configures support for sticky columns with `react-window`.
 *
 * We take advatage of the `innerElementType` of the `FixedSizeList`
 * to render the TableHead and the TableBody together so that the
 * element positioning required for sticky positioning works correctly.
 */
function TableContainer<D extends Record<string, unknown>>({
    loading,
    itemCount,
    rowHeight,
    loadMoreItems,
    data,
    threshold,
    minimumBatchSize,
    innerRef,
}: TableContainerProps<D>): JSX.Element {
    const {
        id,
        headerRowHeight,
        tableHeight,
        sticky,
        headless,
    } = useKoddiTable();
    return (
        <TableContainerBase
            sticky={sticky}
            isLoading={loading}
            data-test={`${id}-container`}
        >
            <InfiniteLoader
                isItemLoaded={(index) => data[index] !== undefined}
                loadMoreItems={loadMoreItems}
                itemCount={itemCount}
                threshold={threshold}
                minimumBatchSize={minimumBatchSize}
            >
                {({ onItemsRendered, ref }) => {
                    return (
                        <FixedSizeList
                            height={
                                tableHeight + (headless ? 0 : headerRowHeight)
                            }
                            itemCount={itemCount}
                            itemSize={rowHeight}
                            width="100%"
                            onItemsRendered={onItemsRendered}
                            ref={ref}
                            outerRef={innerRef}
                            data-test={`${id}-list`}
                            innerElementType={TableContainerInnerElement}
                            itemData={data}
                            useIsScrolling
                        >
                            {TableRow}
                        </FixedSizeList>
                    );
                }}
            </InfiniteLoader>
        </TableContainerBase>
    );
}

export default TableContainer;
