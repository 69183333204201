import { cssProp } from 'koddi-components/utils';
import styled, { css } from 'styled-components';
import { CellBase } from '../Table.styled';
import {
    TableHeadCellBaseProps,
    SortArrowProps,
    TableHeadBaseProps,
} from './TableHead.types';

export const TableHeadBase = styled.div<TableHeadBaseProps>`
    ${({ theme: { grayLight, white }, sticky, borderless }) => css`
        position: sticky;
        z-index: 10;
        top: 0;
        background: ${white};

        &:before {
            content: '';
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: ${borderless ? '0px' : '1px'};
            background: ${grayLight};
            z-index: 1;
        }

        ${sticky &&
        css`
            width: fit-content;
        `}
    `}
`;

export const SortArrow = styled.span<SortArrowProps>`
    ${({ isSorted }) => css`
        opacity: ${isSorted ? 1 : 0.3};
    `}
`;

export const TableHeadCellBase = styled(CellBase)<TableHeadCellBaseProps>`
    ${({
        theme: { grayLight, white, spaceUnit },
        height,
        sticky,
        align,
        maxWidth,
        sortDisabled,
        disableSelection,
    }) => css`
        height: ${height}px;
        user-select: ${disableSelection ? 'none' : 'text'};
        cursor: ${sortDisabled ? 'inherit' : 'pointer'};
        background: ${white};
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: ${align || 'flex-start'};
        font-weight: 500;
        ${cssProp('max-width', maxWidth)};
        ${SortArrow} {
            margin-left: ${spaceUnit * 3}px;
        }

        &:hover ${SortArrow} {
            opacity: 1;
        }

        ${sticky &&
        css`
            display: flex !important;
            border-bottom: 1px solid ${grayLight};
        `}
    `}
`;
