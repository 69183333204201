import styled, { css } from 'styled-components';
import { cssProp } from 'koddi-components/utils';
import { CellBase } from '../Table.styled';
import { StyledTableCellProps } from '../Table.types';

export const TableRowLoading = styled.div<{ isOdd: boolean }>`
    ${({ theme: { white, spaceUnit } }) => css`
        display: flex;
        align-items: center;
        background-color: ${white};
        padding: 0px ${spaceUnit * 2}px;
    `}
`;

export const TableCell = styled(CellBase)<StyledTableCellProps>`
    ${({
        theme: { grayLightest, grayLighter, grayLight, white, spaceUnit },
        odd,
        padding,
        borderless,
        maxWidth,
        textAlign,
    }) => css`
        border-right: 1px solid ${borderless ? 'transparent' : grayLight};
        margin: 0;
        padding: ${padding || `${spaceUnit * 2}px`};
        background-color: ${odd ? white : grayLightest};
        align-items: ${textAlign === 'right' ? 'flex-end' : 'auto'};
        ${cssProp('max-width', maxWidth)};

        &:hover {
            background-color: ${grayLighter};
        }
        &:focus {
            outline: none;
        }
    `}
`;
