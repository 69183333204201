import produce, { Draft } from 'immer';

import {
    ResourceHeaderState,
    ResourceHeaderActions,
    ResourceHeaderAction,
} from './types';

export const initialState: ResourceHeaderState = {
    hidden: false,
};

const resourceHeaderReducer = (
    state: ResourceHeaderState = initialState,
    action: ResourceHeaderActions
): ResourceHeaderState =>
    produce(state, (draft: Draft<ResourceHeaderState>) => {
        switch (action.type) {
            case ResourceHeaderAction.SET_HIDDEN_STATUS:
                draft.hidden = action.payload.status;
                break;
            default:
                break;
        }
    });

export default resourceHeaderReducer;
