import {
    ReduxActionWithError,
    ReduxActionWithPayload,
    AsyncState,
    ReduxActionWithRefresh,
} from 'types';
import { CurrencyList } from 'api/Admin/Currency';

export enum CurrencyAction {
    GET_CURRENCY_LIST = 'admin/currency/GET_CURRENCY_LIST',
    GET_CURRENCY_LIST_SUCCESS = 'admin/currency/GET_CURRENCY_LIST_SUCCESS',
    GET_CURRENCY_LIST_ERROR = 'admin/currency/GET_CURRENCY_LIST_ERROR',
}

export type GetCurrencyListAction = ReduxActionWithRefresh<
    CurrencyAction.GET_CURRENCY_LIST
>;

export type GetCurrencyListSuccessAction = ReduxActionWithPayload<
    CurrencyAction.GET_CURRENCY_LIST_SUCCESS,
    { data: CurrencyList }
>;

export type GetCurrencyListErrorAction = ReduxActionWithError<
    CurrencyAction.GET_CURRENCY_LIST_ERROR
>;

export type CurrencyActions =
    | GetCurrencyListAction
    | GetCurrencyListSuccessAction
    | GetCurrencyListErrorAction;

export type CurrencyState = {
    list: AsyncState<CurrencyList>;
};
