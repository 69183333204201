/* eslint-disable no-underscore-dangle */
import React, { ChangeEvent, FunctionComponent, useState } from 'react';

import { Field } from 'react-final-form';
import Icon from 'koddi-components/Icon';
import Button from 'koddi-components/Button';
import { ErrorText } from 'koddi-components/Form';
import { useForwardedRef } from 'koddi-components/hooks';
import Label from '../Label';

import {
    InputProps,
    InputFieldProps,
    InputFieldComponentProps,
    AutoCompleteValues,
} from './input.types';
import {
    Input,
    InputContainer,
    InputInnerContainer,
    InputButtonGroup,
    LabelToolTip,
    LabelWrapper,
    CharacterCount,
} from './input.styled';

/**
 * The Koddi `Input` Component
 *
 * Text, Textarea, or Number input with label and error styling support.
 *
 * Example Usage:

        <Input
            name="my-input"
            iconLeft="magnifier"
            type="text"
            disabled={false}
            label="My Input"
        />
 */
export const InputComponent: FunctionComponent<InputProps> = (props) => {
    const {
        id,
        required,
        readOnly,
        disabled,
        label,
        pattern,
        meta,
        hasError = false,
        errorText = '',
        showErrors = true,
        onChange: onChangeProp,
        onFocus: onFocusProp,
        onBlur: onBlurProp,
        value: valueProp,
        checked: checkProp,
        name: nameProp,
        placeholder,
        input: { value, name, onBlur, onFocus, onChange, type, checked } = {},
        type: typeProp,
        forwardedRef,
        iconLeft,
        iconRight,
        fullWidth,
        flexDirection,
        outerRef,
        inputContainerRef,
        onContainerClick,
        useAriaLabel = false,
        hideIcons = false,
        autoComplete = AutoCompleteValues.Off,
        labelToolTip = false,
        minWidth,
        toolTipText,
        customChevronIconMargin,
        v2 = false,
        maxLength,
        hasCharacterCount = false,
        unitLabelContent,
        fixedBorderWidth,
        fieldWidth,
        height,
        paddingLeft,
        borderRadius,
        fitErrorTextContent = false,
        ...restProps
    } = props;
    // internal var for accepting either `hasError` from the top level or `meta.error` from react-final-form
    const _hasError = hasError || (meta && meta.error && meta.touched);
    const [characterCount, setCharacterCount] = useState(maxLength);

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (hasCharacterCount && maxLength) {
            setCharacterCount(maxLength - event.currentTarget.value.length);
        }

        if (onChange) {
            onChange(event);
        }
    };

    const inputProps = {
        ...restProps,
        required,
        readOnly,
        disabled,
        minWidth,
        height,
        value: valueProp === undefined ? value : valueProp,
        name: nameProp || name,
        onBlur: onBlurProp || onBlur,
        onFocus: onFocusProp || onFocus,
        onChange: hasCharacterCount
            ? (event: ChangeEvent<HTMLInputElement>) => handleChange(event)
            : onChangeProp || onChange,
        type: typeProp || type,
        placeholder,
        pattern,
        checked: checkProp || checked,
        autoComplete,
        id: id || name || nameProp,
        paddingLeft,
    };

    const isTextArea = type === 'textarea' || typeProp === 'textarea';
    const isNumber = type === 'number' || typeProp === 'number';
    const ref = useForwardedRef(inputProps.ref || forwardedRef);
    const visibility =
        !inputProps.disabled && inputProps.required ? 'visible' : 'hidden';

    return (
        <InputContainer
            fullWidth={fullWidth}
            flexDirection={flexDirection}
            ref={outerRef}
            onClick={onContainerClick}
            fieldWidth={fieldWidth}
        >
            {label && useAriaLabel === false ? (
                <LabelWrapper
                    visibility={visibility}
                    labelToolTip={labelToolTip}
                >
                    <Label htmlFor={inputProps.id}>{label}</Label>
                    {labelToolTip && toolTipText && (
                        <LabelToolTip
                            translationKey=""
                            defaultText={toolTipText}
                            icon="info"
                            iconHeight={13}
                            iconWidth={13}
                        />
                    )}
                </LabelWrapper>
            ) : null}

            <InputInnerContainer
                hasError={_hasError}
                disabled={disabled}
                readOnly={readOnly}
                ref={inputContainerRef}
                customChevronIconMargin={customChevronIconMargin}
                v2={v2}
                fixedBorderWidth={fixedBorderWidth}
                borderRadius={borderRadius}
                minWidth={minWidth}
            >
                {iconLeft && !isTextArea && (
                    <Icon icon={iconLeft} height={12} width={12} />
                )}
                <Input
                    {...inputProps}
                    as={isTextArea ? 'textarea' : 'input'}
                    ref={ref}
                    hasError={_hasError}
                    aria-label={useAriaLabel ? label : inputProps['aria-label']}
                    v2={v2}
                    maxLength={maxLength}
                    height={height}
                />
                {iconRight && !isTextArea && iconRight}
                {isNumber && !readOnly && !disabled && !hideIcons && (
                    <InputButtonGroup>
                        <Button
                            data-test={`${inputProps.id}-input-minus`}
                            btnStyle="secondary"
                            iconLeft="minus"
                            iconSize={10}
                            noPadding
                            onClick={() => {
                                ref.current?.stepDown();
                                if (inputProps?.onChange) {
                                    const event = new Event('input', {
                                        bubbles: true,
                                    });
                                    ref?.current?.dispatchEvent(event);
                                }
                            }}
                        />
                        <Button
                            data-test={`${inputProps.id}-input-plus`}
                            btnStyle="secondary"
                            iconLeft="plus"
                            iconSize={10}
                            noPadding
                            onClick={() => {
                                ref.current?.stepUp();
                                if (inputProps?.onChange) {
                                    const event = new Event('input', {
                                        bubbles: true,
                                    });
                                    ref?.current?.dispatchEvent(event);
                                }
                            }}
                        />
                    </InputButtonGroup>
                )}
            </InputInnerContainer>
            {hasCharacterCount && maxLength && (
                <CharacterCount>
                    {`Max ${characterCount} characters remaining`}
                </CharacterCount>
            )}
            {showErrors && (
                <ErrorText
                    hasError={_hasError}
                    fitErrorTextContent={fitErrorTextContent}
                >
                    {errorText || meta?.error}
                </ErrorText>
            )}
            {unitLabelContent && unitLabelContent}
        </InputContainer>
    );
};

const InputWithForwardedRef = React.forwardRef<HTMLInputElement, InputProps>(
    (props, ref) => <InputComponent {...props} forwardedRef={ref} />
);

/**
 * The Koddi `Input` Component
 *
 * Text, Textarea, or Number input with label, icon, and error styling support.
 *
 * Example Usage:

        <Input
            name="my-input"
            iconLeft="magnifier"
            type="textarea"
            disabled={false}
            label="My Input"
            onChange={(e) => console.log(e.target.value)}
        />
 */
const InputField = (props: InputFieldProps): JSX.Element => {
    return (
        <Field<string, InputFieldComponentProps, HTMLInputElement>
            {...props}
            component={
                InputWithForwardedRef as FunctionComponent<
                    InputFieldComponentProps
                >
            }
        />
    );
};

export const InputFieldWithForwardedRef = React.forwardRef<
    HTMLInputElement,
    InputFieldProps
>((props, ref) => <InputField {...props} forwardedRef={ref} />);

export default InputWithForwardedRef;
