import React from 'react';
import { ResponsiveContainer } from 'recharts';
import AsyncStateManager from 'koddi-components/AsyncStateManager';
import {
    useChartColorScheme,
    trendReportFormatter,
} from 'koddi-components/hooks';
import {
    ChartWrapperProps,
    ChartWrapperDataMarker,
} from './ChartWrapper.types';
import { ChartWrapperContainer } from './ChartWrapper.styled';
import ChartLegend from './ChartLegend';

/**
 * The `ChartWrapper` is a wrapper component for charts in Koddi
 * that standardizes responsiveness, chart legends and asynchronous
 * loading of the chart data from an api.
 */
function ChartWrapper<
    Data extends Record<string, unknown>,
    DataMarker extends ChartWrapperDataMarker = ChartWrapperDataMarker
>({
    responsiveness,
    className,
    id,
    api,
    fullWidth = false,
    fillContainer = false,
    asyncWrapperDimensions,
    dataMarkers,
    reloadApi,
    renderChart,
    locale,
    ...rest
}: ChartWrapperProps<Data, DataMarker>): JSX.Element {
    const colorScheme = useChartColorScheme();
    const responsiveWidth =
        fullWidth || fillContainer ? '100%' : responsiveness?.width;
    const responsiveHeight = fillContainer ? '100%' : responsiveness?.height;
    const asyncWidth = responsiveWidth || rest.width;
    const asyncHeight = responsiveHeight || rest.height;
    return (
        <AsyncStateManager<Data[]>
            api={api}
            width={asyncWidth}
            height={asyncHeight}
            maxHeight={responsiveness?.maxHeight}
            minHeight={responsiveness?.minHeight}
            minWidth={responsiveness?.minWidth}
            reloadApi={reloadApi}
            {...asyncWrapperDimensions}
            id={id}
            errorMessage="There was an error loading this graph. Click retry to try again or try reloading the page."
            renderData={(data) => {
                /* @ts-ignore */
                const { dates, totals } = data;

                return (
                    <ChartWrapperContainer
                        className={className}
                        id={`${id}-chart-wrapper`}
                        data-test={`${id}-chart-wrapper`}
                    >
                        <ChartLegend<Data>
                            dataMarkers={dataMarkers}
                            colorScheme={colorScheme}
                            data={totals}
                            id={id}
                        />
                        <ResponsiveContainer
                            {...responsiveness}
                            height={responsiveHeight}
                            width={responsiveWidth}
                        >
                            {renderChart({
                                ...rest,
                                data: trendReportFormatter({
                                    dates,
                                    locale: locale ?? 'en-US',
                                }),
                                id,
                                responsiveness,
                                dataMarkers,
                            })}
                        </ResponsiveContainer>
                    </ChartWrapperContainer>
                );
            }}
        />
    );
}

export default ChartWrapper;
