import {
    RouteMatchChangedAction,
    RouteMatchAction,
    ClearRouteMatchAction,
} from './types';

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export function routeMatchChanged(routeMatch: any): RouteMatchChangedAction {
    return {
        type: RouteMatchAction.ROUTE_MATCH_CHANGED,
        payload: {
            routeMatch,
        },
    };
}

export function clearRouteMatch(): ClearRouteMatchAction {
    return {
        type: RouteMatchAction.CLEAR_ROUTE_MATCH,
    };
}
