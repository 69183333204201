import React, { useMemo } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useKoddiThemeResource } from 'redux-core/app/theme/hooks';
import { useLocale } from 'koddi-components/LocaleProvider';

const STRIPE_PUBLIC_KEYS: Record<string, string> = {
    production: 'pk_live_O3SMSXCJQFTSDGTPK6mJgOA0',
    development: 'pk_test_PKeQ9Niz7Xs2yZfQ74stiqqs',
    staging: 'pk_test_PKeQ9Niz7Xs2yZfQ74stiqqs',
    local: 'pk_test_PKeQ9Niz7Xs2yZfQ74stiqqs',
};

const stripePromise = loadStripe(STRIPE_PUBLIC_KEYS[window.TARGET_ENV]);

type StripeProviderProps = {
    clientSecret: string;
    children: any;
};

export const StripeProvider = ({
    clientSecret,
    children,
}: StripeProviderProps): JSX.Element | null => {
    const { theme } = useKoddiThemeResource();
    const { locale } = useLocale();
    const appearance = useMemo(() => {
        return {
            theme: 'none',

            variables: {
                colorPrimary: '#0570de',
                colorBackground: theme.grayLightest,
                colorText: '#30313d',
                colorDanger: '#df1b41',
                fontFamily: theme.typography.fontFamily,
                fontSizeBase: theme.typography.baseFontSize,
                spacingUnit: '3px',
                borderRadius: '0px',
                spacingGridRow: '20px',
            },
        };
    }, [theme]);

    const stripeOptions = useMemo(() => {
        return clientSecret
            ? {
                  clientSecret,
                  appearance,
                  locale,
              }
            : null;
    }, [clientSecret, appearance, locale]);

    return (
        <span data-heap-redact-text="true">
            {clientSecret && stripeOptions ? (
                /* @ts-ignore */
                <Elements stripe={stripePromise} options={stripeOptions}>
                    {children}
                </Elements>
            ) : null}
        </span>
    );
};

export default StripeProvider;
