import {
    ReduxActionWithError,
    ReduxActionWithPayload,
    ReduxActionWithRefresh,
    AsyncState,
} from 'types';
import { LocalesList } from 'api/Admin/Locales';

export enum LocalesAction {
    GET_LOCALES_LIST = 'admin/locales/GET_LOCALES_LIST',
    GET_LOCALES_LIST_SUCCESS = 'admin/locales/GET_LOCALES_LIST_SUCCESS',
    GET_LOCALES_LIST_ERROR = 'admin/locales/GET_LOCALES_LIST_ERROR',
}

export type GetLocalesListAction = ReduxActionWithRefresh<
    LocalesAction.GET_LOCALES_LIST
>;

export type GetLocalesListSuccessAction = ReduxActionWithPayload<
    LocalesAction.GET_LOCALES_LIST_SUCCESS,
    { data: LocalesList }
>;

export type GetLocalesListErrorAction = ReduxActionWithError<
    LocalesAction.GET_LOCALES_LIST_ERROR
>;

export type LocalesActions =
    | GetLocalesListAction
    | GetLocalesListSuccessAction
    | GetLocalesListErrorAction;

export type LocalesState = {
    list: AsyncState<LocalesList>;
};
