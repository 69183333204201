import ConfirmationModal from './ConfirmationModal';
import CustomModal from './CustomModal';
import DeleteConfirmationModal from './DeleteConfirmationModal';

const Modals = {
    confirm: ConfirmationModal,
    confirmDelete: DeleteConfirmationModal,
    custom: CustomModal,
};

export default Modals;
