import {
    useMemberGroupId,
    usePreviousMemberGroupId,
    useAdvertiserId,
    useMemberGroupEntityLabel,
    useAppContextErrorCode,
    useAppContextMemberGroup,
} from 'redux-core/app/context/hooks';
import { useAppAdvertisers } from 'redux-core/app/advertisers/hooks';
import {
    DASHBOARD_ROUTE,
    pathToCampaignOverview,
    pathToAdGroupOverview,
    pathToEntityOverview,
    pathToReportingOverview,
    pathToSavedReports,
} from 'modules/constants/routes';
import { DEFAULT_MEMBER_GROUP_ID } from 'modules/constants/app';
import { usePermissions } from 'koddi-components/PermissionsProvider';
import {
    useUserHasSingleClientAcccess,
    useAuthUser,
} from 'redux-core/auth/hooks';

export function useDashboardRoute(): string {
    const memberGroupId = useMemberGroupId();
    const previousMemberGroupId = usePreviousMemberGroupId();
    const contextErrorCode = useAppContextErrorCode();
    const contextHasError =
        contextErrorCode === 404 ||
        contextErrorCode === 403 ||
        contextErrorCode === 400;
    const userHasSingleClientAccess = useUserHasSingleClientAcccess();
    const authUser = useAuthUser();
    const { access } = usePermissions();

    const { memberGroupIds, advertiserIds } = access;
    const clientId = memberGroupIds?.[0];
    const advertiserId = advertiserIds?.[0];
    const advertiserMemberGroupId =
        advertiserId && authUser?.advertiser_member_group?.[advertiserId];

    if (userHasSingleClientAccess) {
        return DASHBOARD_ROUTE.replace(
            ':clientId',
            String(clientId ?? advertiserMemberGroupId)
        );
    }

    if (contextHasError && previousMemberGroupId) {
        return DASHBOARD_ROUTE.replace(
            ':clientId',
            String(previousMemberGroupId)
        );
    }

    if (contextHasError && clientId) {
        return DASHBOARD_ROUTE.replace(':clientId', String(clientId));
    }

    if (memberGroupId === DEFAULT_MEMBER_GROUP_ID && previousMemberGroupId) {
        return DASHBOARD_ROUTE.replace(
            ':clientId',
            String(previousMemberGroupId)
        );
    }

    if (memberGroupId) {
        return DASHBOARD_ROUTE.replace(':clientId', String(memberGroupId));
    }
    return '/dashboard';
}

export function useMemberGroupIdForRoute(): number | null {
    const memberGroupId = useMemberGroupId();
    const memberGroup = useAppContextMemberGroup();
    const previousMemberGroupId = usePreviousMemberGroupId();
    return (
        memberGroup?.member_group_id || memberGroupId || previousMemberGroupId
    );
}

export function useCampaignOverviewRoute(): string {
    const advertiserId = useAdvertiserId();
    const appAdvertisers = useAppAdvertisers();
    const appAdvertiserId = appAdvertisers.data[0]?.advertiser_id;
    const memberGroupIdValue = useMemberGroupIdForRoute();

    if (!advertiserId && !appAdvertiserId) {
        return '/';
    }

    return pathToCampaignOverview(
        memberGroupIdValue,
        advertiserId || appAdvertiserId
    );
}

export function useAdGroupOverviewRoute(): string {
    const advertiserId = useAdvertiserId();
    const appAdvertisers = useAppAdvertisers();
    const appAdvertiserId = appAdvertisers.data[0]?.advertiser_id;
    const memberGroupIdValue = useMemberGroupIdForRoute();

    if (!advertiserId && !appAdvertiserId) {
        return '/';
    }

    return pathToAdGroupOverview(
        memberGroupIdValue,
        advertiserId || appAdvertiserId
    );
}

export function useEntityOverviewRoute(): string {
    const entityLabel = useMemberGroupEntityLabel();
    const advertiserId = useAdvertiserId();
    const appAdvertisers = useAppAdvertisers();
    const appAdvertiserId = appAdvertisers.data[0]?.advertiser_id;
    const memberGroupIdValue = useMemberGroupIdForRoute();

    if (!advertiserId && !appAdvertiserId) {
        return '/';
    }

    return pathToEntityOverview(
        memberGroupIdValue,
        advertiserId || appAdvertiserId,
        entityLabel
    );
}
export function useReportingOverviewRoute(): string {
    const advertiserId = useAdvertiserId();
    const appAdvertisers = useAppAdvertisers();
    const appAdvertiserId = appAdvertisers.data[0]?.advertiser_id;
    const memberGroupIdValue = useMemberGroupIdForRoute();

    if (!advertiserId && !appAdvertiserId) {
        return '/';
    }

    return pathToReportingOverview(
        memberGroupIdValue,
        advertiserId || appAdvertiserId
    );
}

export function useSavedReportsRoute(): string {
    const advertiserId = useAdvertiserId();
    const appAdvertisers = useAppAdvertisers();
    const appAdvertiserId = appAdvertisers.data[0]?.advertiser_id;
    const memberGroupIdValue = useMemberGroupIdForRoute();

    if (!advertiserId && !appAdvertiserId) {
        return '/';
    }

    return pathToSavedReports(
        memberGroupIdValue,
        advertiserId || appAdvertiserId
    );
}
