/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import KoddiAPI from 'api';
import { createSimpleSagaWithCache } from 'utils/createSimpleSaga';
import { takeEvery, all, call } from 'redux-saga/effects';
import SagaRegistry from 'redux-core/sagaRegistry';
import { getActivityListError, getActivityListSuccess } from './actions';
import { ActivityAction } from './types';
import { selectActivityList } from './selectors';

export function* getActivitiesApi() {
    const activities = yield call(KoddiAPI.Admin.Activity.getList);
    return activities;
}

export const getActivitiesSaga = createSimpleSagaWithCache(
    getActivitiesApi,
    getActivityListSuccess,
    getActivityListError,
    selectActivityList
);

export function* watchGetActivitiesList() {
    yield takeEvery(ActivityAction.GET_ACTIVITY_LIST, getActivitiesSaga);
}

function* activitySagas() {
    yield all([watchGetActivitiesList()]);
}

SagaRegistry.registerSaga(activitySagas);
