/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import KoddiAPI from 'api';
import { takeEvery, call, all, put, select } from 'redux-saga/effects';
import SagaRegistry from 'redux-core/sagaRegistry';
import { AuthAction } from 'redux-core/auth/types';
import { DEFAULT_MEMBER_GROUP_ID } from 'modules/constants/app';
import { KoddiUser } from 'api/Admin/User';
import { Advertiser, TaxTypeOptions } from 'api/Advertiser';
import {
    getReportConfig,
    updateAppFilterSlices,
} from 'redux-core/app/filters/actions';
import { selectAuthUser } from 'redux-core/auth/selectors';
import {
    getAvailableAdvertisersListError,
    getAvailableAdvertisersListSuccess,
    resetAppAdvertisers,
} from 'redux-core/app/advertisers/actions';
import { setMemberGroupActiveTemplates } from 'redux-core/admin/memberGroup/actions';
import { setMemberGroupExperiences } from 'redux-core/admin/memberGroupExperiences/actions';

import {
    AppContextAction,
    FetchAppContextAction,
    FetchEmbeddedAppContextAction,
} from './types';
import {
    fetchAppContextSuccess,
    fetchAppContextError,
    setErrorCode,
    setMemberGroupCurrencies,
    setCalculatedPageCurrency,
    setMemberGroupBiddingConfigs,
    setAdvertiserHasFunds,
    setTaxTypes,
} from './actions';

import { CONTEXT_STORAGE_KEY } from './constants';

export function* fetchEmbeddedAppContextSaga(
    action: FetchEmbeddedAppContextAction
): any {
    const user: KoddiUser | null = yield select(selectAuthUser);
    const { memberGroupId } = action.payload;
    const memberGroup = yield call(
        KoddiAPI.Admin.MemberGroup.getByID,
        memberGroupId
    );
    const advertisers: Advertiser[] = user
        ? yield call(
              KoddiAPI.Advertiser.getList,
              memberGroup?.member_group_id,
              user.user_id
          )
        : null;
    if (advertisers) {
        yield put(getAvailableAdvertisersListSuccess(advertisers));
    } else {
        yield put(getAvailableAdvertisersListSuccess([]));
    }
    yield put(fetchAppContextSuccess(memberGroup, null, null));
}

export function* fetchAppContextSaga(action: FetchAppContextAction): any {
    const user: KoddiUser | null = yield select(selectAuthUser);
    const {
        advertiserId,
        advertiserGroupId,
        memberGroupId,
        experiencesConfigurationFF,
    } = action.payload;
    try {
        let memberGroup = null;
        let memberGroupCurrencies = null;
        let advertiser = null;
        let advertiserData = null;
        let advertiserBalance = null;
        let advertiserGroup = null;
        let hostname = null;
        let taxTypes = null;

        if (memberGroupId) {
            try {
                yield put(resetAppAdvertisers());
                hostname = yield call(
                    KoddiAPI.Admin.MemberGroup.getHostnameById,
                    memberGroupId
                );

                memberGroup = yield call(
                    KoddiAPI.Admin.MemberGroup.getByID,
                    memberGroupId
                );

                yield put(getReportConfig(memberGroupId));
                yield put(setErrorCode(null));
                yield put(
                    setCalculatedPageCurrency(
                        memberGroup.calculated_page_currency
                    )
                );
                memberGroupCurrencies = yield call(
                    KoddiAPI.Admin.Currency.getMemberGroupCurrencies,
                    memberGroupId
                );
                yield put(setMemberGroupCurrencies(memberGroupCurrencies));

                const memberGroupBiddingConfigs = experiencesConfigurationFF
                    ? yield call(
                          KoddiAPI.Admin.Currency
                              .getMemberGroupBiddingConfigsReportV2,
                          memberGroupId
                      )
                    : yield call(
                          KoddiAPI.Admin.Currency
                              .getMemberGroupBiddingConfigsReport,
                          memberGroupId
                      );

                yield put(
                    setMemberGroupBiddingConfigs(memberGroupBiddingConfigs)
                );
                const memberGroupActiveTemplates = yield call(
                    KoddiAPI.Admin.TemplateOverview.getActiveTemplates,
                    memberGroupId
                );
                yield put(
                    setMemberGroupActiveTemplates(memberGroupActiveTemplates)
                );
                const memberGroupFundingTypes = yield call(
                    KoddiAPI.Admin.MemberGroup.getFundingTypes,
                    memberGroupId
                );
                memberGroup = {
                    ...memberGroup,
                    funding_types: memberGroupFundingTypes?.funding_types,
                };
                if (experiencesConfigurationFF) {
                    const memberGroupExperiences = yield call(
                        KoddiAPI.Admin.MemberGroupExperiences
                            .getMemberGroupExperiences,
                        memberGroupId
                    );
                    yield put(
                        setMemberGroupExperiences(memberGroupExperiences)
                    );
                }

                if (!advertiserId) {
                    yield put(updateAppFilterSlices('campaign', []));
                    yield put(updateAppFilterSlices('ad_group', []));
                    yield put(updateAppFilterSlices('entity', []));
                    yield put(updateAppFilterSlices('general', []));
                }
            } catch (e) {
                if (e.errorResponse?.status || e?.response?.status) {
                    yield put(
                        setErrorCode(
                            e.errorResponse?.status || e.response?.status
                        )
                    );
                }
                if (
                    e?.errorResponse.status === 403 ||
                    e?.response?.status === 403
                ) {
                    memberGroup = {
                        member_group_id: memberGroupId,
                        host_name: hostname,
                        name:
                            memberGroupId === DEFAULT_MEMBER_GROUP_ID
                                ? 'Koddi'
                                : undefined,
                    };
                } else {
                    throw new Error(e.error?.message);
                }
            }
        }

        if (
            memberGroup &&
            memberGroup?.member_group_id &&
            memberGroup?.member_group_id !== DEFAULT_MEMBER_GROUP_ID &&
            user
        ) {
            try {
                const advertisers: Advertiser[] = yield call(
                    KoddiAPI.Advertiser.getList,
                    memberGroup?.member_group_id,
                    user.user_id
                );
                if (advertisers) {
                    yield put(getAvailableAdvertisersListSuccess(advertisers));
                } else {
                    yield put(getAvailableAdvertisersListSuccess([]));
                }
            } catch (e) {
                yield put(getAvailableAdvertisersListError(e));
            }
        }
        if (advertiserId) {
            try {
                advertiserData = yield call(
                    KoddiAPI.Advertiser.getByID,
                    memberGroupId,
                    advertiserId
                );
                advertiserBalance = yield call(
                    KoddiAPI.Advertiser.getAdvertiserPrePayBalance,
                    memberGroupId,
                    advertiserId
                );
                advertiser = {
                    ...advertiserData,
                    ...advertiserBalance,
                };
                taxTypes = yield call(KoddiAPI.Advertiser.getStripeTaxTypes);
                const formattedTaxTypes = taxTypes.map(
                    (type: TaxTypeOptions) => {
                        return {
                            value: type.name,
                            label: type.label,
                        };
                    }
                );
                yield put(setTaxTypes(formattedTaxTypes));
                const advertisersFunds = yield call(
                    KoddiAPI.Advertiser.getFundingBalances,
                    memberGroupId,
                    advertiserId as number
                );
                if (advertisersFunds.total > 0) {
                    yield put(setAdvertiserHasFunds(true));
                }
            } catch (e) {
                if (e.errorResponse.status) {
                    yield put(setErrorCode(e.errorResponse.status));
                }
                throw new Error(e.error.message);
            }
        }
        if (advertiserGroupId && memberGroupId) {
            advertiserGroup = yield call(
                KoddiAPI.Admin.AdvertiserGroup.getByID,
                memberGroupId,
                advertiserGroupId
            );
        }

        yield put(
            fetchAppContextSuccess(memberGroup, advertiser, advertiserGroup)
        );
    } catch {
        yield put(
            fetchAppContextError(memberGroupId, advertiserId, advertiserGroupId)
        );
    }
}

export function removeContextFromLocalStorage() {
    localStorage.removeItem(CONTEXT_STORAGE_KEY);
}

export function* watchAppContext() {
    yield takeEvery(AppContextAction.FETCH_APP_CONTEXT, fetchAppContextSaga);
    yield takeEvery(
        [AuthAction.LOGOUT_SUBMIT, AuthAction.LOGOUT_WITH_SSO_SUBMIT],
        removeContextFromLocalStorage
    );
    yield takeEvery(
        AppContextAction.FETCH_EMBEDDED_APP_CONTEXT,
        fetchEmbeddedAppContextSaga
    );
}

function* appContextSagas() {
    yield all([watchAppContext()]);
}

SagaRegistry.registerSaga(appContextSagas);
