import React, { FunctionComponent } from 'react';
import { ThemeProvider as SCThemeProvider } from 'styled-components';
import defaultTheme from 'koddi-components/ThemeProvider/themes/default';
import { addThemeUtils } from './ThemeProvider.utils';
import KoddiGlobalStyles from './ThemeProvider.styled';
import { ThemeProviderProps } from './ThemeProvider.types';

/** The `Koddi` Theme Provider */
const KoddiThemeProvider: FunctionComponent<ThemeProviderProps> = ({
    children,
    theme = defaultTheme,
}) => {
    const themeWithUtils = addThemeUtils(theme);
    return (
        <>
            <SCThemeProvider theme={themeWithUtils}>
                <KoddiGlobalStyles theme={themeWithUtils} />
                {children}
            </SCThemeProvider>
        </>
    );
};

export default KoddiThemeProvider;
