/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { AxiosInstance } from 'axios';
import { KoddiAPIQuery } from 'types';
import { MediaStrategy } from './MediaStrategy.types';

class MediaStrategyAPI {
    protected axios: AxiosInstance;

    constructor(axios: AxiosInstance) {
        this.axios = axios;
    }

    /**
     * Gets a list of media strategies across a given set of advertisers or an advertiser.
     * @param advertiser_ids A list of advertiser ids to get media strategies for.
     */
    public getList = async (
        advertiser_ids: number[],
        query?: KoddiAPIQuery
    ): Promise<MediaStrategy[]> => {
        return [];
    };
}

export default MediaStrategyAPI;
