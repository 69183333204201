/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import debounce from 'lodash/debounce';
import { useHistory, useLocation } from 'react-router';
import styled, { css } from 'styled-components';
import { usePopper } from 'react-popper';
import { Portal } from 'react-portal';

import Button from 'koddi-components/Button';
import Input from 'koddi-components/Input';
import Table from 'koddi-components/Table';
import { useAppAdvertisers } from 'redux-core/app/advertisers/hooks';
import {
    useAppContextAdvertiser,
    useMemberGroupId,
    useAppContextStatus,
    useMemberGroupEntityLabel,
} from 'redux-core/app/context/hooks';
import OnOutsideClick from 'koddi-components/OnOutsideClick';
import ClickableCellComponent from 'koddi-components/TableCells/ClickableCell';
import { TableFilter } from 'koddi-components/Table/Table.types';
import {
    pathToCampaignOverview,
    pathToAdGroupOverview,
    pathToEntityOverview,
    pathToReportingOverview,
} from 'modules/constants/routes';
import {
    useCampaignOverviewRoute,
    useAdGroupOverviewRoute,
    useEntityOverviewRoute,
    useReportingOverviewRoute,
} from 'modules/hooks/routes';
import { MultiAdvertiserWrapper } from './AdvertiserSelector.styled';

const SingleAdvertiserModal = styled.div`
    ${({ theme: { spaceUnit, white } }) => css`
        display: flex;
        flex-direction: column;
        background-color: ${white};
        padding: ${spaceUnit * 4}px;
        min-width: 380px;
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
        border-radius: 4px;
    `}
`;

const TableWrapper = styled.div`
    ${({ theme: { spaceUnit } }) => css`
        margin: ${spaceUnit * 4}px ${spaceUnit * 2}px;
        flex: 1;
        &:first-of-type {
            margin-left: 0px;
        }
        &:last-of-type {
            margin-right: 0px;
        }
    `}
`;

const TableTitle = styled.div`
    ${({ theme: { spaceUnit } }) => css`
        font-size: 14px;
        text-align: center;
        margin-bottom: ${spaceUnit * 2}px;
    `}
`;

const SingleAdvertiserSelector = (): JSX.Element | null => {
    const [isOpen, setIsOpen] = useState(false);
    const [advertiserLabel, setAdvertiserLabel] = useState('');
    const [allSearchValue, setAllSearchValue] = useState('');
    const [allFilters, setAllFilters] = useState<TableFilter[]>([]);
    const advertiser = useAppContextAdvertiser();
    const memberGroupId = useMemberGroupId();
    const contextChanging = useAppContextStatus() === 'pending';
    const campaignsRoute = useCampaignOverviewRoute();
    const adGroupsRoute = useAdGroupOverviewRoute();
    const entitiesRoute = useEntityOverviewRoute();
    const entityLabel = useMemberGroupEntityLabel();
    const reportingRoute = useReportingOverviewRoute();
    const history = useHistory();
    const location = useLocation();
    const {
        data: appAdvertisers,
        loading: fetchingAppAdvertisers,
    } = useAppAdvertisers();
    const [rootRef, setRootRef] = useState<HTMLDivElement | null>(null);
    const [popperRef, setPopperRef] = useState<HTMLDivElement | null>(null);

    const toggleIsOpen = useCallback(() => {
        if (!fetchingAppAdvertisers && !contextChanging) {
            setIsOpen(!isOpen);
        }
    }, [isOpen, fetchingAppAdvertisers, contextChanging]);

    const { styles, attributes } = usePopper(rootRef, popperRef, {
        modifiers: [
            {
                name: 'offset',
                options: {
                    offset: [-40, 18],
                },
            },
        ],
        placement: 'bottom-start',
    });

    const updateAllFilters = debounce((value: string) => {
        // id here must match the id of the column to be searched
        setAllFilters([{ id: 'name', value }]);
    }, 300);

    const handleAllSearchChange = useCallback(
        (e) => {
            setAllSearchValue(e.target.value);
            updateAllFilters(e.target.value);
        },
        [updateAllFilters]
    );

    const handleCancel = useCallback(() => {
        setIsOpen(false);
    }, []);

    const allColumns = useMemo(() => {
        return [
            {
                id: 'name',
                accessor: ({ name, advertiser_id }: any) =>
                    `${name} (${advertiser_id})`,
                Filter: () => null,
                noTranslate: true,
                onCellClick: ({ row: { original } }: any) => {
                    setAdvertiserLabel(
                        `${original?.name} (${original?.advertiser_id})`
                    );
                    if (location.pathname === campaignsRoute) {
                        history.push(
                            pathToCampaignOverview(
                                memberGroupId,
                                original.advertiser_id
                            )
                        );
                    }
                    if (location.pathname === adGroupsRoute) {
                        history.push(
                            pathToAdGroupOverview(
                                memberGroupId,
                                original.advertiser_id
                            )
                        );
                    }
                    if (location.pathname === entitiesRoute) {
                        history.push(
                            pathToEntityOverview(
                                memberGroupId,
                                original.advertiser_id,
                                entityLabel
                            )
                        );
                    }
                    if (location.pathname === reportingRoute) {
                        history.push(
                            pathToReportingOverview(
                                memberGroupId,
                                original.advertiser_id
                            )
                        );
                    }
                    setIsOpen(false);
                    setAllSearchValue('');
                    setAllFilters([]);
                },
                Cell: ClickableCellComponent,
            },
        ];
    }, [
        memberGroupId,
        setAllFilters,
        history,
        campaignsRoute,
        location?.pathname,
        adGroupsRoute,
        entitiesRoute,
        entityLabel,
        reportingRoute,
    ]);

    const initialState = useMemo(() => {
        const selectedIndex = appAdvertisers.findIndex(
            (a) => a.advertiser_id === advertiser?.advertiser_id
        );
        if (selectedIndex) {
            return {
                selectedRowIds: { [selectedIndex]: true },
            };
        }
        return {};
    }, [appAdvertisers, advertiser]);

    useEffect(() => {
        if (advertiser) {
            setAdvertiserLabel(
                `${advertiser?.name} (${advertiser?.advertiser_id})`
            );
        }
    }, [advertiser]);

    return (
        <MultiAdvertiserWrapper
            ref={setRootRef}
            contextChanging={contextChanging}
        >
            <Button
                btnStyle="selector"
                onClick={toggleIsOpen}
                iconRight={isOpen ? 'chevronUp' : 'chevronDown'}
                disabled={contextChanging}
            >
                {contextChanging ? 'loading...' : advertiserLabel}
            </Button>
            {!contextChanging ? (
                <Portal>
                    <OnOutsideClick
                        listenForOutsideClick={isOpen}
                        onOutsideClick={handleCancel}
                        getRef={setPopperRef}
                        style={{
                            ...styles.popper,
                            zIndex: 2,
                        }}
                        additionalElement={rootRef}
                        {...attributes.popper}
                    >
                        {isOpen && (
                            <SingleAdvertiserModal>
                                <TableWrapper>
                                    <TableTitle>Select Advertiser</TableTitle>
                                    <Input
                                        iconLeft="magnifier"
                                        value={allSearchValue}
                                        onChange={handleAllSearchChange}
                                        placeholder="Search selected advertisers"
                                        showErrors={false}
                                    />
                                    <Table
                                        id="multi-advertiser-select-all"
                                        data={appAdvertisers}
                                        columns={allColumns}
                                        filters={allFilters}
                                        initialState={initialState}
                                        headless
                                        useSpacer={false}
                                        tableHeight={400}
                                        headerRowHeight={0}
                                    />
                                </TableWrapper>
                            </SingleAdvertiserModal>
                        )}
                    </OnOutsideClick>
                </Portal>
            ) : null}
        </MultiAdvertiserWrapper>
    );
};

export default SingleAdvertiserSelector;
