import { combineReducers } from 'redux';
import createAsyncReducer from 'utils/createAsyncReducer';
import { MemberState, MemberAction } from './types';

export const initialState: MemberState = {
    list: {
        last_updated: null,
        loading: false,
        error: null,
        data: null,
    },
};

export const memberListReducer = createAsyncReducer(initialState.list, {
    start: MemberAction.GET_MEMBER_LIST,
    success: MemberAction.GET_MEMBER_LIST_SUCCESS,
    error: MemberAction.GET_MEMBER_LIST_ERROR,
});

const memberReducer = combineReducers<MemberState>({
    list: memberListReducer,
});

export default memberReducer;
