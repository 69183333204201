import { cssProp } from 'koddi-components/utils';
import styled, {
    css,
    DefaultTheme,
    FlattenInterpolation,
    ThemeProps,
} from 'styled-components';
import { TypographyComponentProps } from './Typography.types';

const createTypographyStyles = ({
    textColor,
    isBold,
    marginTop,
    marginBottom,
    marginRight,
    width,
}: TypographyComponentProps): FlattenInterpolation<
    ThemeProps<DefaultTheme>
> => css`
    ${({
        theme: {
            typography: { fontFamily },
            ...restTheme
        },
    }) => css`
        font-family: ${fontFamily};
        color: ${restTheme[textColor]};
        ${cssProp('font-weight', isBold ? '500' : undefined)};
        ${cssProp('margin-top', marginTop)};
        ${cssProp('margin-bottom', marginBottom)};
        ${cssProp('width', width)};
        ${cssProp('margin-right', marginRight)}
    `}
`;

export const StyledBodyText = styled.div<TypographyComponentProps>`
    ${({
        theme: {
            typography: { baseFontSize },
        },
        ...props
    }) => css`
        font-size: ${baseFontSize};
        ${createTypographyStyles(props)}
    `}
`;

export const StyledSmallText = styled.div<TypographyComponentProps>`
    ${({ ...props }) => css`
        font-size: 11px;
        ${createTypographyStyles(props)}
    `}
`;

export const StyledLabelText = styled.label<TypographyComponentProps>`
    ${({ ...props }) => css`
        font-size: 12px;
        ${createTypographyStyles(props)}
    `}
`;

export const StyledErrorText = styled.div<TypographyComponentProps>`
    ${({ ...props }) => css`
        font-size: 12px;
        ${createTypographyStyles(props)}
    `}
`;

export const StyledSuccessText = StyledErrorText;

export const StyledWarningText = StyledErrorText;

export const StyledHeading3Text = styled.h3<TypographyComponentProps>`
    ${({ ...props }) => css`
        font-size: 16px;
        font-weight: normal;
        ${createTypographyStyles(props)}
    `}
`;

export const StyledHeading4Text = styled.h4<TypographyComponentProps>`
    ${({ ...props }) => css`
        font-size: 14px;
        font-weight: normal;
        ${createTypographyStyles(props)}
    `}
`;

export const StyledHeading1Text = styled.h1<TypographyComponentProps>`
    ${({ ...props }) => css`
        font-size: 40px;
        font-weight: normal;
        margin: 0px;
        ${createTypographyStyles(props)}
    `}
`;

export const StyledHeading2Text = styled.h2<TypographyComponentProps>`
    ${({ ...props }) => css`
        font-size: 22px;
        font-weight: normal;
        margin: 0px;
        ${createTypographyStyles(props)}
    `}
`;
