import styled, { css } from 'styled-components';
import { fadeIn } from 'koddi-components/animations';
import { cssProp, cssValue } from 'koddi-components/utils';
import FadeTransition from 'koddi-components/Transitions/FadeTransition';
import { LoadingSpinnerContainerProps } from './loadingSpinner.types';

export const LoadingSpinnerContainer = styled.div<LoadingSpinnerContainerProps>`
    ${({ theme, absolutePosition, hideContentBelow, height }) => css`
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: ${absolutePosition ? 'absolute' : 'relative'};
        background: ${cssValue(hideContentBelow ? theme.white : undefined)};
        top: 0;
        left: 0;
        z-index: ${theme.zIndex.spinner};
        ${cssProp('height', height)};
        svg {
            z-index: ${theme.zIndex.spinner + 1};
        }
    `}
`;

export const LoadingSpinnerOverlay = styled.div`
    position: absolute;
    background-color: rgba(0, 0, 0, 0.2);
    animation: ${fadeIn} 0.3s ease-in-out;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
`;

// eslint-disable-next-line prettier/prettier
export const LoadingSpinnerFadeTransition = styled(FadeTransition)<LoadingSpinnerContainerProps>`
    ${({ theme: { zIndex, white }, absolutePosition, hideContentBelow }) => css`
        z-index: ${zIndex.spinner};
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        top: 0;
        left: 0;
        position: ${absolutePosition ? 'absolute' : 'relative'};
        background: ${cssValue(hideContentBelow ? white : undefined)};
    `}
`;
