import produce, { Draft } from 'immer';

import {
    AppMessagesState,
    AppMessagesAction,
    AppMessagesActions,
} from './types';

export const initialState: AppMessagesState = {
    list: [],
    current: null,
};

const appMessagesReducer = (
    state: AppMessagesState = initialState,
    action: AppMessagesActions
): AppMessagesState =>
    produce(state, (draft: Draft<AppMessagesState>) => {
        switch (action.type) {
            case AppMessagesAction.SET_MESSAGE:
                draft.current = action.payload;
                draft.list.push(action.payload);
                break;
            case AppMessagesAction.CLEAR_MESSAGE:
                draft.current = null;
                break;
            default:
                break;
        }
    });

export default appMessagesReducer;
