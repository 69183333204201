import React, { FunctionComponent } from 'react';
import { BaseSVGProps } from '../icon.types';

const CloseXIcon: FunctionComponent<BaseSVGProps> = ({ width, height }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 24 24"
    >
        <path d="M24 2.9L21.1 0 12 9.23 2.9 0 0 2.9 9.23 12 0 21.1 2.9 24l9.1-9.23L21.1 24l2.9-2.9-9.23-9.1z" />
    </svg>
);
export default CloseXIcon;
