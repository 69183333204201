import {
    ApplicationRootState,
    ApplicationOutputSelector,
} from 'redux-core/types';
import { createSelector } from 'reselect';
import { AsyncState } from 'types';
import { PermissionList } from 'api/Admin/Permission';
import { initialState } from './reducer';
import { PermissionState } from './types';

const selectAdminPermission = (state: ApplicationRootState) =>
    state.admin.permission || initialState;

export const selectPermissionList: ApplicationOutputSelector<
    AsyncState<PermissionList>,
    (res: PermissionState) => AsyncState<PermissionList>
> = createSelector(selectAdminPermission, (state) => state.list);
