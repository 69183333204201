/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ReduxAction, ReduxActionWithPayload, AsyncState } from 'types';
import { put, call, select, all } from 'redux-saga/effects';
import isEmpty from 'lodash/isEmpty';
import { ApplicationRootState } from 'redux-core/types';
import { OutputSelector } from 'reselect';
import { SuccessFn, ErrorFn, ApiFn } from './createSimpleSaga.types';

/**
 * A factory function for creating a simple saga that responds to a redux action.
 * The returned function will call the api with the `action` that was recieved.
 * If it succeeds it will call the `successActionFn`
 * with the result of the api. If it fails, it will call the `errorActionFn` with the error from
 * the try catch block.
 * @param api The API to call.
 * @param successActionFn The action creator to excute if the api call succeeds. OR an array of action creators.
 * @param errorActionFn The action creator to execute if the api call fails. Or an array of action creators.
 *
 * @returns function
 */

function createSimpleSaga<
    Action extends ReduxAction<string>,
    Data extends any = void
>(
    api: ApiFn<Action, Data>,
    successActionFnOrType: SuccessFn<Data> | SuccessFn<Data>[] | string,
    errorActionFnOrType: ErrorFn | ErrorFn[] | string
) {
    function* saga(action: Action) {
        try {
            const data = yield call(api, action);
            if (Array.isArray(successActionFnOrType)) {
                yield all(
                    successActionFnOrType.map((fn) => put(fn(data, action)))
                );
            } else if (typeof successActionFnOrType === 'string') {
                yield put({
                    type: successActionFnOrType,
                    payload: { data, initialAction: action },
                });
            } else {
                yield put(successActionFnOrType(data, action));
            }
        } catch (error) {
            if (Array.isArray(errorActionFnOrType)) {
                yield all(errorActionFnOrType.map((fn) => put(fn(error))));
            } else if (typeof errorActionFnOrType === 'string') {
                yield put({ type: errorActionFnOrType, error });
            } else {
                yield put(errorActionFnOrType(error));
            }
        }
    }

    return saga;
}

/**
 * Builds on top of the `createSimpleSaga` factory
 * function but provides caching capabilities.
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function createSimpleSagaWithCache<
    Action extends ReduxActionWithPayload<string, { refresh?: boolean }>,
    Data extends any = void
>(
    api: ApiFn<Action, Data>,
    successActionFn: SuccessFn<Data>,
    errorActionFn: ErrorFn,
    selector: OutputSelector<
        ApplicationRootState,
        AsyncState<Data>,
        (res: any) => AsyncState<Data>
    >,
    timeToLive?: number
) {
    function* sagaWithCache(action: Action) {
        const { refresh = false } = action.payload;
        const { data: cache } = yield select(selector);
        const cacheIsExpired = timeToLive !== undefined;
        const cacheIsEmpty = isEmpty(cache);
        if (refresh || cacheIsEmpty || cacheIsExpired) {
            try {
                const data = yield call(api, action);
                yield put(successActionFn(data));
            } catch (error) {
                yield put(errorActionFn(error));
            }
        } else {
            yield put(successActionFn(cache));
        }
    }

    return sagaWithCache;
}

export default createSimpleSaga;
