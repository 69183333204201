import { ReduxAction, ReduxActionWithPayload } from 'types';

export enum AdvertiserAction {
    REMOVE_ADVERTISER_FROM_GROUP = 'admin/advertisers/REMOVE_ADVERTISER_FROM_GROUP',
    ADVERTISER_IN_ADVERTISER_GROUP_API_PENDING = 'admin/advertisers/ADVERTISER_IN_ADVERTISER_GROUP_API_PENDING',
    ADVERTISER_IN_ADVERTISER_GROUP_API_SUCCESS = 'admin/advertisers/ADVERTISER_IN_ADVERTISER_GROUP_API_SUCCESS',
    ADVERTISER_IN_ADVERTISER_GROUP_API_FAILURE = 'admin/advertisers/ADVERTISER_IN_ADVERTISER_GROUP_API_FAILURE',
}

export type AdvertisersState = {
    status: string;
};

export type Payload<PayloadType> = {
    payload: PayloadType;
};

export type RemoveAdvertiserFromGroupParams = {
    advertiserId: number;
    memberGroupId: number;
};

export type RemoveAdvertiserFromGroup = ReduxActionWithPayload<
    AdvertiserAction.REMOVE_ADVERTISER_FROM_GROUP,
    RemoveAdvertiserFromGroupParams
>;

export type AdvertiserInAdvertiserGroupAPIPending = ReduxAction<
    AdvertiserAction.ADVERTISER_IN_ADVERTISER_GROUP_API_PENDING
>;

export type AdvertiserInAdvertiserGroupAPISuccess = ReduxAction<
    AdvertiserAction.ADVERTISER_IN_ADVERTISER_GROUP_API_SUCCESS
>;

export type AdvertiserInAdvertiserGroupAPIFailure = ReduxAction<
    AdvertiserAction.ADVERTISER_IN_ADVERTISER_GROUP_API_FAILURE
>;

export type AdvertiserActions =
    | RemoveAdvertiserFromGroup
    | AdvertiserInAdvertiserGroupAPIPending
    | AdvertiserInAdvertiserGroupAPISuccess
    | AdvertiserInAdvertiserGroupAPIFailure;
