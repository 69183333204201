import { ReportConfig, FieldOrderConfig, ReportContext } from 'api/Reports';

/**
 * Iterate over operations object and create map of 'accepted operations'
 * on a type-by-type basic. Extend the reportConfig object with a new key 'formattedOperations'
 * for use in the DynamicFilterRow component when choosing options to display for fieldConfig.type === 'string' etc
 */
export function addAvailableOperations(
    reportConfig: ReportConfig
): ReportConfig {
    const formattedOperations: Record<string, any> = {};
    Object.keys(reportConfig.operations).forEach((key: string) => {
        const operationConfig = reportConfig.operations[key];
        operationConfig.acceptedFieldTypes.forEach(
            (acceptedFieldType: string) => {
                if (formattedOperations[acceptedFieldType]) {
                    formattedOperations[acceptedFieldType].push(
                        operationConfig.operation
                    );
                } else {
                    formattedOperations[acceptedFieldType] = [
                        operationConfig.operation,
                    ];
                }
            }
        );
    });
    return {
        ...reportConfig,
        formattedOperations,
    };
}

export function mapDefaultMetrics(
    metrics: FieldOrderConfig[] | undefined,
    reportConfig: ReportConfig,
    context: ReportContext,
    allFields?: boolean
): string[] {
    if (!metrics) return [];
    return metrics
        .filter((metric) => {
            const contextualField = reportConfig.settings[metric.field_id];
            const showDefault = allFields
                ? true
                : metric.is_selected_by_default;
            return (
                showDefault &&
                !reportConfig.dimensions[metric.field_id] &&
                (reportConfig.metrics[metric.field_id] ||
                    (contextualField &&
                        contextualField?.contexts?.includes(context)))
            );
        })
        .sort((metric: FieldOrderConfig, metric2: FieldOrderConfig) => {
            if (metric.order < metric2.order) {
                return -1;
            }
            if (metric.order > metric2.order) {
                return 1;
            }
            return 0;
        })
        .map((metric) => metric.field_id);
}
