import {
    ApplicationRootState,
    ApplicationOutputSelector,
} from 'redux-core/types';
import { createSelector } from 'reselect';
import { AsyncState } from 'types';
import { MemberList } from 'api/Admin/Member';
import { initialState } from './reducer';
import { MemberState } from './types';

const selectAdminMember = (state: ApplicationRootState) =>
    state.admin.member || initialState;

export const selectMemberList: ApplicationOutputSelector<
    AsyncState<MemberList>,
    (res: MemberState) => AsyncState<MemberList>
> = createSelector(selectAdminMember, (state) => state.list);
