import React, { FunctionComponent } from 'react';
import { BaseSVGProps } from '../icon.types';

const ProfileIcon: FunctionComponent<BaseSVGProps> = ({ width, height }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        height={height}
        width={width}
        viewBox="0 0 24 24"
    >
        <path d="M8.67 14.66h6.66A6.65 6.65 0 0122 21.31v3.33H2v-3.33a6.65 6.65 0 016.67-6.65z" />
        <circle cx="11.45" cy="7.46" r="6.09" />
    </svg>
);

export default ProfileIcon;
