/* eslint-disable react/display-name */
import React, { forwardRef } from 'react';
import { TTabItem, TTabItemComponentProps } from 'koddi-components/Tabs';
import VerticalBarChart, {
    VerticalBarChartProps,
} from 'koddi-components/VerticalBarChart';
import LineChart, { LineChartProps } from 'koddi-components/LineChart';
import { ChartCardIconWrapper, ChartCardIcon } from './ChartCard.styled';

/**
 * Creates the tabs that will be rendered in the `Tab` component.
 *
 * The tabs will be rendered as icons using our `Icon` component.
 */
// eslint-disable-next-line @typescript-eslint/ban-types
function createTabs<Data extends Record<string, unknown>>(
    id: string
): TTabItem[] {
    const tabs: TTabItem[] = [
        {
            title: 'Show Line Chart',
            tabComponent: forwardRef<HTMLButtonElement, TTabItemComponentProps>(
                (tabProps, ref) => (
                    <ChartCardIconWrapper
                        {...tabProps}
                        id={`${id}-chart-icon-lineChart`}
                        data-test={`${id}-chart-icon-lineChart`}
                        className=""
                        aria-label="Show Line Chart"
                        ref={ref}
                    >
                        <ChartCardIcon
                            icon="lineChart"
                            width={14}
                            height={14}
                        />
                    </ChartCardIconWrapper>
                )
            ),
            children: ({
                id: lineChartId,
                ...lineChartBarProps
            }: LineChartProps<Data>) => (
                <LineChart {...lineChartBarProps} id={lineChartId} />
            ),
        },
        {
            title: 'Show Bar Chart',
            tabComponent: forwardRef<HTMLButtonElement, TTabItemComponentProps>(
                (tabProps, ref) => (
                    <ChartCardIconWrapper
                        {...tabProps}
                        id={`${id}-chart-icon-barChart`}
                        data-test={`${id}-chart-icon-barChart`}
                        className=""
                        aria-label="Show Bar Chart"
                        ref={ref}
                    >
                        <ChartCardIcon icon="barChart" width={14} height={14} />
                    </ChartCardIconWrapper>
                )
            ),
            children: ({
                id: barChartId,
                ...restBarProps
            }: VerticalBarChartProps<Data>) => (
                <VerticalBarChart {...restBarProps} id={barChartId} />
            ),
        },
    ];

    return tabs;
}

export default createTabs;
