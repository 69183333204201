import get from 'lodash/get';
import {
    ApplicationRootState,
    ApplicationOutputSelector,
} from 'redux-core/types';
import { SelectOption } from 'koddi-components/Select';
import { createSelector } from 'reselect';
import { initialState } from './reducer';
import { AppConfigsState } from './types';

const selectState = (state: ApplicationRootState) =>
    state.app.configs || initialState;

export const selectCampaignBudgetTypeOptions: ApplicationOutputSelector<
    SelectOption[],
    (res: AppConfigsState) => SelectOption[]
> = createSelector(selectState, (state) => {
    return get(state, 'options.budgetTypeOptions.campaign');
});

export const selectCampaignPacingOptions: ApplicationOutputSelector<
    SelectOption[],
    (res: AppConfigsState) => SelectOption[]
> = createSelector(selectState, (state) => {
    return get(state, 'options.pacingOptions.campaign');
});

export const selectCampaignGoalTypeOptions: ApplicationOutputSelector<
    SelectOption[],
    (res: AppConfigsState) => SelectOption[]
> = createSelector(selectState, (state) => {
    return get(state, 'options.goalTypeOptions.campaign');
});

export const selectEditCampaignStatusOptions: ApplicationOutputSelector<
    SelectOption[],
    (res: AppConfigsState) => SelectOption[]
> = createSelector(selectState, (state) => {
    return get(state, 'options.statusOptions.editCampaign');
});

export const selectEditClientStatusOptions: ApplicationOutputSelector<
    SelectOption[],
    (res: AppConfigsState) => SelectOption[]
> = createSelector(selectState, (state) => {
    return get(state, 'options.statusOptions.editCampaign');
});

export const selectCreateCampaignStatusOptions: ApplicationOutputSelector<
    SelectOption[],
    (res: AppConfigsState) => SelectOption[]
> = createSelector(selectState, (state) => {
    return get(state, 'options.statusOptions.createCampaign');
});

export const selectAdGroupBudgetTypeOptions: ApplicationOutputSelector<
    SelectOption[],
    (res: AppConfigsState) => SelectOption[]
> = createSelector(selectState, (state) => {
    return get(state, 'options.budgetTypeOptions.adGroup');
});

export const selectAdGroupPacingOptions: ApplicationOutputSelector<
    SelectOption[],
    (res: AppConfigsState) => SelectOption[]
> = createSelector(selectState, (state) => {
    return get(state, 'options.pacingOptions.adGroup');
});

export const selectAdGroupGoalTypeOptions: ApplicationOutputSelector<
    SelectOption[],
    (res: AppConfigsState) => SelectOption[]
> = createSelector(selectState, (state) => {
    return get(state, 'options.goalTypeOptions.adGroup');
});

export const selectEditAdGroupStatusOptions: ApplicationOutputSelector<
    SelectOption[],
    (res: AppConfigsState) => SelectOption[]
> = createSelector(selectState, (state) => {
    return get(state, 'options.statusOptions.editAdGroup');
});

export const selectCreateAdGroupStatusOptions: ApplicationOutputSelector<
    SelectOption[],
    (res: AppConfigsState) => SelectOption[]
> = createSelector(selectState, (state) => {
    return get(state, 'options.statusOptions.createAdGroup');
});

export const selectBulkEditStatusOptions: ApplicationOutputSelector<
    SelectOption[],
    (res: AppConfigsState) => SelectOption[]
> = createSelector(selectState, (state) => {
    return get(state, 'options.statusOptions.bulkEdit');
});

export const selectTableCellStatusOptions: ApplicationOutputSelector<
    SelectOption[],
    (res: AppConfigsState) => SelectOption[]
> = createSelector(selectState, (state) => {
    return get(state, 'options.statusOptions.tableCell');
});

export const selectFontFamilyOptions: ApplicationOutputSelector<
    SelectOption[],
    (res: AppConfigsState) => SelectOption[]
> = createSelector(selectState, (state) => {
    return get(state, 'styles.fontFamilyOptions');
});

export const selectCuisineOptions: ApplicationOutputSelector<
    SelectOption[],
    (res: AppConfigsState) => SelectOption[]
> = createSelector(selectState, (state) => {
    return get(state, 'options.cuisineOptions');
});

export const selectUserHistoryOptions: ApplicationOutputSelector<
    SelectOption[],
    (res: AppConfigsState) => SelectOption[]
> = createSelector(selectState, (state) => {
    return get(state, 'options.userHistoryOptions');
});

export const selectOrderValueOptions: ApplicationOutputSelector<
    SelectOption[],
    (res: AppConfigsState) => SelectOption[]
> = createSelector(selectState, (state) => {
    return get(state, 'options.orderValueOptions');
});

export const selectUserStatusOptions: ApplicationOutputSelector<
    SelectOption[],
    (res: AppConfigsState) => SelectOption[]
> = createSelector(selectState, (state) => {
    return get(state, 'options.userStatusOptions');
});

export const selectAdvertiserStatusOptions: ApplicationOutputSelector<
    SelectOption[],
    (res: AppConfigsState) => SelectOption[]
> = createSelector(selectState, (state) => {
    return get(state, 'options.advertiserStatusOptions');
});

export const selectEntityAttributes = createSelector(selectState, (state) => {
    return get(state, 'tableColumns.entityTableColumns.data.attributes');
});

export const selectMinifiedEntityAttributes = createSelector(
    selectState,
    (state) => {
        return get(
            state,
            'tableColumns.entityTableColumns.data.minified_attributes'
        );
    }
);

export const selectEntityTableColumnsStatus = createSelector(
    selectState,
    (state) => {
        return get(state, 'tableColumns.entityTableColumns.status');
    }
);
