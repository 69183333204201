import styled, { css } from 'styled-components';
import { cssValue } from 'koddi-components/utils';
import BaseModal from '../BaseModal';

export const CustomModalBase = styled(BaseModal)<{
    width: number | string;
    minWidth?: number | string;
    minHeight?: number | string;
    v2?: boolean;
}>`
    ${({ v2, theme: { white }, width, minWidth, minHeight }) => css`
        width: ${cssValue(width)};
        min-width: ${minWidth ? cssValue(minWidth) : null};
        min-height: ${minHeight ? cssValue(minHeight) : null};
        background: ${white};
        box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.3);
        &:focus {
            outline: none;
        }
        ${v2 ? 'border-radius: 4px;' : ''}
    `}
`;
