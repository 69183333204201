import { IndustryList } from 'api/Admin/Industry';
import {
    IndustryAction,
    GetIndustryListAction,
    GetIndustryListSuccessAction,
    GetIndustryListErrorAction,
} from './types';

export function getIndustryList(refresh = false): GetIndustryListAction {
    return {
        type: IndustryAction.GET_INDUSTRY_LIST,
        payload: { refresh },
    };
}

export function getIndustryListSuccess(
    data: IndustryList
): GetIndustryListSuccessAction {
    return {
        type: IndustryAction.GET_INDUSTRY_LIST_SUCCESS,
        payload: { data },
    };
}

export function getIndustryListError(error: Error): GetIndustryListErrorAction {
    return {
        type: IndustryAction.GET_INDUSTRY_LIST_ERROR,
        error,
    };
}
