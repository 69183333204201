import React, { FunctionComponent } from 'react';
import { useLocation } from 'react-router-dom';
import { useAppAdvertisers } from 'redux-core/app/advertisers/hooks';
import {
    useDashboardRoute,
    useAdGroupOverviewRoute,
    useEntityOverviewRoute,
    useCampaignOverviewRoute,
} from 'modules/hooks/routes';
import { useNoContextError } from 'redux-core/app/context/hooks';
import { AdvertiserSelectContainer } from './AdvertiserSelector.styled';
import MultiAdvertiserSelector from './MultiAdvertiserSelector';
import SingleAdvertiserSelector from './SingleAdvertiserSelector';

// we can't use useCurrentRouteMatch because we don't use KoddiRoute outside of admin yet
// previously we compared useLocation pathname but that will have :campaignId filled in as 123
// and therefor not match EDIT_CAMPAIGN_ROUTE. Use regex with \d+ to match one or more number
const AD_GROUP_REGEX = /\/advertisers\/\d+\/campaigns\/\d+\/ad_groups.*/gi;
const CAMPAIGN_REGEX = /\/advertisers\/\d+\/campaigns\/.*/gi;

export const AdvertiserSelectComponent: FunctionComponent = () => {
    const { pathname } = useLocation();
    const { loading: fetchingAppAdvertisers } = useAppAdvertisers();
    const contextError = useNoContextError();
    const adGroupsRoute = useAdGroupOverviewRoute();
    const entitiesRoute = useEntityOverviewRoute();
    const campaignRoute = useCampaignOverviewRoute();
    const isOverviewPage = pathname === useDashboardRoute();
    const wizardRoute =
        pathname.match(CAMPAIGN_REGEX) || pathname.match(AD_GROUP_REGEX);
    const isHidden =
        contextError ||
        (wizardRoute &&
            pathname !== campaignRoute &&
            pathname !== adGroupsRoute &&
            pathname !== entitiesRoute);

    if (isHidden) return null;

    return fetchingAppAdvertisers ? null : (
        <AdvertiserSelectContainer>
            {isOverviewPage ? (
                <MultiAdvertiserSelector />
            ) : (
                <SingleAdvertiserSelector />
            )}
        </AdvertiserSelectContainer>
    );
};

export default AdvertiserSelectComponent;
