import styled, { css } from 'styled-components';
import { StyledTableContainerProps } from './TableContainer.types';

export const TableContainerBase = styled.div<StyledTableContainerProps>`
    ${({ sticky }) => css`
        flex: 1;
        ${sticky &&
        css`
            position: relative;
            z-index: 0;
        `}
    `}
`;
