import {
    RemoveAdvertiserFromGroup,
    AdvertiserInAdvertiserGroupAPIPending,
    AdvertiserInAdvertiserGroupAPISuccess,
    AdvertiserInAdvertiserGroupAPIFailure,
    AdvertiserAction,
} from './types';

export function removeAdvertiserFromGroup(
    memberGroupId: number,
    advertiserId: number
): RemoveAdvertiserFromGroup {
    return {
        type: AdvertiserAction.REMOVE_ADVERTISER_FROM_GROUP,
        payload: {
            advertiserId,
            memberGroupId,
        },
    };
}

export function advertiserInAdvertiserGroupAPIPending(): AdvertiserInAdvertiserGroupAPIPending {
    return {
        type: AdvertiserAction.ADVERTISER_IN_ADVERTISER_GROUP_API_PENDING,
    };
}

export function advertiserInAdvertiserGroupAPISuccess(): AdvertiserInAdvertiserGroupAPISuccess {
    return {
        type: AdvertiserAction.ADVERTISER_IN_ADVERTISER_GROUP_API_SUCCESS,
    };
}

export function advertiserInAdvertiserGroupAPIFailure(): AdvertiserInAdvertiserGroupAPIFailure {
    return {
        type: AdvertiserAction.ADVERTISER_IN_ADVERTISER_GROUP_API_FAILURE,
    };
}
