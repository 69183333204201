/* eslint-disable react/require-default-props */
import React, { Suspense } from 'react';
import LoadingSpinner from 'koddi-components/LoadingSpinner';

type SuspenseProps = {
    children: JSX.Element;
    fallback?: JSX.Element;
};

const DefaultFallback = <LoadingSpinner id="fallback-loading" />;

export const KoddiSuspense = ({
    fallback = DefaultFallback,
    children,
}: SuspenseProps): JSX.Element => (
    <Suspense fallback={fallback}>{children}</Suspense>
);
