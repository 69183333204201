import React, {
    useCallback,
    useContext,
    useMemo,
    useState,
    useEffect,
} from 'react';
import { useHistory, useLocation } from 'react-router';
import { Form } from 'react-final-form';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { EntityBasic } from 'api/Entities';
import { fetchAppContext } from 'redux-core/app/context';
import {
    useAppContextMemberGroup,
    useAppContextStatus,
} from 'redux-core/app/context/hooks';
import { initiateEntityRegistration } from 'redux-core/admin/entityRegistration/actions';

import LoadingSpinner from 'koddi-components/LoadingSpinner';
import { ErrorText } from 'koddi-components/Form';

import AddEntityIds from 'features/Registration/AdvertiserRegistration/components/AddEntityIds';
import AdvertiserRegistrationContext from 'features/Registration/AdvertiserRegistration/AdvertiserRegistration.context';
import FormFooter from 'koddi-components/FormFooter';
import { useAuth0 } from 'modules/Auth0';

import { useFlags } from 'launchdarkly-react-client-sdk';
import { AddEntitiesForm } from './RegisterAdvertiserForm.styled';

const RegisterEntitiesForm = (): JSX.Element => {
    const history = useHistory();
    const location = useLocation();
    const [entityArr, setEntityArr] = useState<EntityBasic[]>([]);
    const [files, setFiles] = useState<File[]>([]);
    const { memberGroupId } = useContext(AdvertiserRegistrationContext);
    const params: { advertiserId: string } = useParams();
    const dispatch = useDispatch();
    const memberGroup = useAppContextMemberGroup();
    const appContextStatus = useAppContextStatus();
    const { experiencesConfiguration } = useFlags();
    const {
        checkAuth0State,
        ssoFeatureFlag,
        handleReturnRouteStorage,
    } = useAuth0();
    const initialValues = useMemo(() => {
        return {
            entities: [],
        };
    }, []);

    const handleSubmission = useCallback(() => {
        const ids = entityArr.map((entity: EntityBasic) =>
            entity.internal_id.toString()
        );
        if (ssoFeatureFlag) {
            checkAuth0State();
            handleReturnRouteStorage(
                `/admin/clients/${memberGroupId}/advertisers/${params?.advertiserId}/entities`,
                true
            );
        }

        if (memberGroupId && params?.advertiserId && ids?.length) {
            dispatch(
                initiateEntityRegistration(
                    memberGroupId,
                    Number(params?.advertiserId),
                    ids
                )
            );
        }
        history.push(
            `/admin/clients/${memberGroupId}/advertisers/${params?.advertiserId}/entities`
        );
    }, [
        dispatch,
        memberGroupId,
        params?.advertiserId,
        history,
        entityArr,
        checkAuth0State,
        ssoFeatureFlag,
        handleReturnRouteStorage,
    ]);

    useEffect(() => {
        if (!memberGroup && appContextStatus === 'idle') {
            if (memberGroupId && params?.advertiserId) {
                dispatch(
                    fetchAppContext(
                        experiencesConfiguration,
                        memberGroupId,
                        Number(params?.advertiserId)
                    )
                );
            }
        }
    }, [
        memberGroup,
        dispatch,
        params,
        memberGroupId,
        appContextStatus,
        experiencesConfiguration,
    ]);

    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        if (queryParams.get('setup_intent')) {
            window.history.pushState({}, '', `#${location.pathname}`);
        }
    }, [location]);

    if (appContextStatus === 'pending')
        return <LoadingSpinner id="registration-entity-assignment" />;

    return (
        <Form
            initialValues={initialValues}
            onSubmit={handleSubmission}
            render={(renderProps) => {
                const { submitError, handleSubmit } = renderProps;
                return (
                    <AddEntitiesForm onSubmit={handleSubmit}>
                        <AddEntityIds
                            files={files}
                            setFiles={setFiles}
                            entityArr={entityArr}
                            setEntityArr={setEntityArr}
                        />
                        {submitError?.message && (
                            <ErrorText>{submitError?.message}</ErrorText>
                        )}
                        <FormFooter
                            submitButtonText="Submit"
                            noBorderTop={ssoFeatureFlag}
                            hideCancel
                            submitButtonDisabled={!entityArr.length}
                            v2
                        />
                    </AddEntitiesForm>
                );
            }}
        />
    );
};

export default RegisterEntitiesForm;
