import { FormRowProps } from './Form.types';

export const createFormRowHorizontalSpacing = (
    spaceUnit: number,
    value?: FormRowProps['horizontalSpacing']
): string => {
    if (value === 'xsmall') return `${spaceUnit * 2}px`;
    if (value === 'small') return `${spaceUnit * 6}px`;
    if (value === 'medium') return `${spaceUnit * 14}px`;
    return `${spaceUnit * 20}px`;
};

export const createFormRowMarginBottom = (
    spaceUnit: number,
    value?: FormRowProps['verticalSpacing']
): string => {
    if (value === 'none') return `0px`;
    if (value === 'xsmall') return `${spaceUnit * 2}px`;
    if (value === 'small') return `${spaceUnit * 6}px`;
    if (value === 'medium') return `${spaceUnit * 14}px`;
    return `${spaceUnit * 20}px`;
};
