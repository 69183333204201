import React, { FunctionComponent } from 'react';
import { BaseSVGProps } from '../icon.types';

const StatusActiveIcon: FunctionComponent<BaseSVGProps> = ({
    width,
    height,
}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        height={height}
        width={width}
        viewBox="0 0 10 10"
    >
        <title>Status Active</title>
        <g
            id="Group_1220"
            data-name="Group 1220"
            transform="translate(-12 -18)"
        >
            <circle
                id="Ellipse_663"
                data-name="Ellipse 663"
                cx="5"
                cy="5"
                r="5"
                transform="translate(12 18)"
                fill="#21a453"
            />
            <g
                id="Group_984"
                data-name="Group 984"
                transform="translate(-53 -42.5)"
            >
                <g
                    id="Group_982"
                    data-name="Group 982"
                    transform="translate(-0.5)"
                >
                    <line
                        id="Line_333"
                        data-name="Line 333"
                        y2="2"
                        transform="translate(70.5 61.5)"
                        fill="none"
                        stroke="#fff"
                    />
                    <line
                        id="Line_334"
                        data-name="Line 334"
                        y2="2"
                        transform="translate(70.5 67.5)"
                        fill="none"
                        stroke="#fff"
                    />
                </g>
                <g
                    id="Group_983"
                    data-name="Group 983"
                    transform="translate(135.5 -5) rotate(90)"
                >
                    <line
                        id="Line_333-2"
                        data-name="Line 333"
                        y2="2"
                        transform="translate(70.5 61.5)"
                        fill="none"
                        stroke="#fff"
                    />
                    <line
                        id="Line_334-2"
                        data-name="Line 334"
                        y2="2"
                        transform="translate(70.5 67.5)"
                        fill="none"
                        stroke="#fff"
                    />
                </g>
            </g>
            <circle
                id="Ellipse_674"
                data-name="Ellipse 674"
                cx="5"
                cy="5"
                r="5"
                transform="translate(12 18)"
                fill="#21a453"
            />
            <path
                id="Polygon_82"
                data-name="Polygon 82"
                d="M2,0,4,4H0Z"
                transform="translate(19.5 21) rotate(90)"
                fill="#fff"
            />
        </g>
    </svg>
);

export default StatusActiveIcon;
