import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import {
    ReportConfig,
    AppSlices,
    ReportContext,
    MetricConfig,
    SettingsConfig,
    DimensionConfig,
    FormattedOperations,
    FieldOrderConfig,
} from 'api/Reports';
import { SelectOption } from 'koddi-components/Select';
import { useMemberGroupEntityLabel } from 'redux-core/app/context/hooks';
import {
    selectAppDateRangeFilter,
    selectAppGraphMetric,
    selectAppSlices,
    selectReportConfig,
    selectNonContextualFields,
    selectReportConfigError,
    selectFilterDisplayOrder,
    selectOperationDefinitions,
    selectFieldOrder,
    selectContextualFields,
    selectFields,
    selectFilterError,
} from './selectors';
import { DateRangeFilter } from './types';
import { mapDefaultMetrics } from './utils';

export function useAppDateRangeFilter(): DateRangeFilter {
    return useSelector(selectAppDateRangeFilter);
}

export function useNonContextualFields(): SelectOption[] {
    return useSelector(selectNonContextualFields);
}

export function useAppGraphMetricFilter(): SelectOption {
    return useSelector(selectAppGraphMetric);
}

export function useAppSlices(context: ReportContext): AppSlices {
    const appSlices = useSelector(selectAppSlices);
    return appSlices[context];
}

export function useReportConfig(): ReportConfig | null {
    return useSelector(selectReportConfig);
}

export function useReportConfigError(): boolean {
    return useSelector(selectReportConfigError);
}

export function useReportMetricOptions(): SelectOption[] {
    const reportConfig = useSelector(selectReportConfig);
    if (!reportConfig) return [];
    return Object.keys(reportConfig.metrics).map((key) => {
        const fieldConfig = reportConfig.metrics[key];
        return {
            label: fieldConfig.name,
            value: fieldConfig.id,
        };
    });
}

export function useAvailableFieldOptions(
    currentFields: string[],
    context: ReportContext
): SelectOption<MetricConfig | SettingsConfig>[] {
    const reportConfig = useSelector(selectReportConfig);
    const combinedFields = {
        ...reportConfig?.metrics,
        ...Object.values(reportConfig?.settings || {}).reduce(
            (reduced: any, field: any) => {
                if (field.contexts) {
                    return {
                        ...reduced,
                        [field.id]: field,
                    };
                }
                return reduced;
            },
            {}
        ),
    };

    return Object.keys(combinedFields)
        .filter((key) => {
            const fieldConfig = combinedFields[key];
            const alreadyDisplayed = currentFields.includes(key);
            let inContext;
            if (!fieldConfig.contexts) {
                // metric configs don't have a contexts array and are always 'in context'
                inContext = true;
            } else if (
                fieldConfig.contexts &&
                fieldConfig.contexts.includes(context)
            ) {
                // if a context array is present, check that the current report is in this field's context
                inContext = true;
            } else {
                // not in context, remove from list of available options
                inContext = false;
            }
            return !alreadyDisplayed && inContext;
        })
        .map((key) => {
            const fieldConfig = combinedFields[key];
            return { label: fieldConfig.name, value: fieldConfig };
        });
}

export function useDimensionConfigOptions(
    context: ReportContext
): DimensionConfig[] {
    const reportConfig = useReportConfig();
    const entityLabel = useMemberGroupEntityLabel();
    return useMemo(() => {
        if (reportConfig) {
            const contextualDimensions = Object.values(
                reportConfig?.dimensions
            ).filter((dimension) => {
                return dimension.contexts?.includes(context);
            });

            const mappedValues = contextualDimensions.reduce(
                (reduced: any, dimension: any) => {
                    const isClientAttribute =
                        dimension.description === 'Client attribute';
                    const isTargetingDimension =
                        dimension.description === 'Client targeting dimension';
                    if (isClientAttribute || dimension.id === 'user_location') {
                        return {
                            ...reduced,
                            details: [
                                ...reduced.details,
                                {
                                    ...dimension,
                                    category: `${entityLabel.singular} Details`,
                                    acceptedValues: null,
                                },
                            ],
                        };
                    }
                    if (isTargetingDimension) {
                        return {
                            ...reduced,
                            targeting: [
                                ...reduced.targeting,
                                {
                                    ...dimension,
                                    category: 'Targeting Dimensions',
                                    acceptedValues: null,
                                },
                            ],
                        };
                    }
                    if (dimension.contexts?.includes('general')) {
                        return {
                            ...reduced,
                            media: [
                                ...reduced.media,
                                {
                                    ...dimension,
                                    category: 'Media Structure',
                                },
                            ],
                        };
                    }
                    return reduced;
                },
                {
                    details: [],
                    targeting: [],
                    media: [],
                }
            );
            return [
                ...mappedValues.media,
                ...mappedValues.targeting,
                ...mappedValues.details,
            ];
        }
        return [];
    }, [reportConfig, context, entityLabel]);
}
export function useOperations(): FormattedOperations | null {
    return useSelector(selectReportConfig)?.formattedOperations || null;
}

export function useOperationDefinitions(): any {
    return useSelector(selectOperationDefinitions);
}

export function useDefaultMetricValues(context: ReportContext): string[] {
    const reportConfig = useReportConfig();
    return useMemo(() => {
        if (!reportConfig) return [];
        return mapDefaultMetrics(
            reportConfig?.field_order[context],
            reportConfig,
            context
        );
    }, [context, reportConfig]);
}

export function useFilterDisplayOrder(context: ReportContext): string[] {
    const filterDisplayOrder = useSelector(selectFilterDisplayOrder);
    return filterDisplayOrder[context];
}

export function useFieldOrderc(
    context: ReportContext
): FieldOrderConfig[] | undefined {
    const fieldOrder = useSelector(selectFieldOrder);
    return fieldOrder?.[context];
}

export function useAllFields(context: ReportContext): MetricConfig[] {
    const allContextual = useSelector(selectContextualFields);
    const allFields = useSelector(selectFields);
    return useMemo(() => {
        return [
            ...Object.values(allContextual || {})?.filter((field) => {
                return field?.contexts?.includes(context);
            }),
            ...Object.values(allFields || {}),
        ] as MetricConfig[];
    }, [allContextual, allFields, context]);
}

export function useFilterError(): boolean {
    return useSelector(selectFilterError);
}
