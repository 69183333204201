import {
    ReduxActionWithError,
    ReduxActionWithPayload,
    ReduxActionWithRefresh,
    AsyncState,
} from 'types';
import { MemberGroupTypesList } from 'api/Admin/MemberGroupTypes';

export enum MemberGroupTypesAction {
    GET_MEMBER_GROUP_TYPES = 'admin/MemberGroupTypes/GET_MEMBER_GROUP_TYPES',
    GET_MEMBER_GROUP_TYPES_SUCCESS = 'admin/MemberGroupTypes/GET_MEMBER_GROUP_TYPES_SUCCESS',
    GET_MEMBER_GROUP_TYPES_ERROR = 'admin/MemberGroupTypes/GET_MEMBER_GROUP_TYPES_ERROR',
}

export type GetMemberGroupTypesAction = ReduxActionWithRefresh<
    MemberGroupTypesAction.GET_MEMBER_GROUP_TYPES
>;

export type GetMemberGroupTypesSuccessAction = ReduxActionWithPayload<
    MemberGroupTypesAction.GET_MEMBER_GROUP_TYPES_SUCCESS,
    { data: MemberGroupTypesList }
>;

export type GetMemberGroupTypesErrorAction = ReduxActionWithError<
    MemberGroupTypesAction.GET_MEMBER_GROUP_TYPES_ERROR
>;

export type MemberGroupTypesActions =
    | GetMemberGroupTypesAction
    | GetMemberGroupTypesSuccessAction
    | GetMemberGroupTypesErrorAction;

export type MemberGroupTypesState = {
    list: AsyncState<MemberGroupTypesList>;
};
