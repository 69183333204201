import { RolePermissionList } from 'api/Admin/RolePermission';
import {
    GetRolePermissionListAction,
    RolePermissionAction,
    GetRolePermissionListSuccessAction,
    GetRolePermissionListErrorAction,
} from './types';

export function getRolePermissionList(
    refresh?: boolean
): GetRolePermissionListAction {
    return {
        type: RolePermissionAction.GET_ROLE_PERMISSION_LIST,
        payload: { refresh: refresh || false },
    };
}

export function getRolePermissionListSuccess(
    data: RolePermissionList
): GetRolePermissionListSuccessAction {
    return {
        type: RolePermissionAction.GET_ROLE_PERMISSION_LIST_SUCCESS,
        payload: { data },
    };
}

export function getRolePermissionListError(
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    error: any
): GetRolePermissionListErrorAction {
    return {
        type: RolePermissionAction.GET_ROLE_PERMISSION_LIST_ERROR,
        error,
    };
}
