import styled, { css } from 'styled-components';

export const TabButtonBase = styled.button`
    ${({ theme: { primary, grayDark, black, spaceUnit } }) => css`
        text-decoration: none;
        color: ${grayDark};
        background: none;
        border: 0px;
        border-bottom: 3px solid transparent;
        font-size: 14px;
        line-height: 40px;
        text-align: center;
        position: relative;
        display: inline-block;
        padding: 0px ${spaceUnit * 7}px;

        &:hover {
            cursor: pointer;
            border-bottom: 3px solid ${primary};
            color: ${black};
        }

        &:focus {
            outline: none;
        }

        &[aria-selected='true'] {
            border-bottom: 3px solid ${primary};
            color: ${black};
        }
    `}
`;

export const CompactTabButton = styled(TabButtonBase)`
    ${({ theme: { black } }) => css`
        font-size: 12px;
        line-height: normal;
        padding: 8px 0px;
        min-width: auto;
        flex: 1;
        border-bottom: 2px solid transparent;
        &:hover {
            border-bottom: 2px solid transparent;
        }
        &[aria-selected='true'] {
            border-bottom: 2px solid ${black};
        }
    `}
`;
