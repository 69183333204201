import { ReduxAction, ReduxActionWithPayload } from 'types';

export type RouteMatchState = {
    path: string;
    url: string;
    isExact: boolean;
    params: {
        clientId?: string;
        advertiserId?: string;
        advertiserGroupId?: string;
        resourceType?: string;
    };
};

export enum RouteMatchAction {
    ROUTE_MATCH_CHANGED = 'app/routeMatch/ROUTE_MATCH_CHANGED',
    CLEAR_ROUTE_MATCH = 'app/routeMatch/CLEAR_ROUTE_MATCH',
}

export type RouteMatchChangedAction = ReduxActionWithPayload<
    RouteMatchAction.ROUTE_MATCH_CHANGED,
    {
        routeMatch: any;
    }
>;

export type ClearRouteMatchAction = ReduxAction<
    RouteMatchAction.CLEAR_ROUTE_MATCH
>;
