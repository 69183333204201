/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { AxiosInstance } from 'axios';
import { KoddiListFilterParams } from 'koddi/types';
import { EntityRegistrationResponse } from './EntityRegistration.types';

class EntityRegistrationAPI {
    protected axios: AxiosInstance;

    constructor(axios: AxiosInstance) {
        this.axios = axios;
    }

    /* Initiates the registration process for the UUIDs provided */
    public initiateEntityRegistration = async (
        memberGroupId: number,
        advertiserId: number,
        internal_ids: string[]
    ) => {
        const response = await this.axios.post(
            `v1/member_groups/${memberGroupId}/advertisers/${advertiserId}/entity_registrations/initiate`,
            {
                internal_ids,
            }
        );
        return response.data.result;
    };

    /* Initiates the registration process with csv file */
    public initiateEntityRegistrationCSV = async (
        memberGroupId: number,
        advertiserId: number,
        csv: File
    ) => {
        const response = await this.axios.post(
            `v1/member_groups/${memberGroupId}/advertisers/${advertiserId}/entity_registrations/initiate/csv`,
            csv,
            {
                headers: {
                    'Content-Type': 'text/csv',
                },
            }
        );
        return response.data.result;
    };

    /* Get the registration events currently in progress */
    public getEntityRegistrations = async (
        memberGroupId: number,
        advertiserId: number
    ): Promise<EntityRegistrationResponse> => {
        const response = await this.axios.get(
            `v1/member_groups/${memberGroupId}/advertisers/${advertiserId}/entity_registrations`
        );
        return response.data.result;
    };

    public entityRegistrationReport = async (
        memberGroupId: number,
        advertiserId: number
    ) => {
        const response = await this.axios.post(
            `v1/member_groups/${memberGroupId}/advertisers/${advertiserId}/entity_registrations/failed/report`,
            {
                pagination: {
                    count: 20,
                    start: 0,
                },
            }
        );
        return response.data.result;
    };

    public entityRegistrationRetry = async (
        memberGroupId: number,
        advertiserId: number,
        eventDetailId: number
    ) => {
        const response = await this.axios.post(
            `v1/member_groups/${memberGroupId}/advertisers/${advertiserId}/entity_registrations/${eventDetailId}/failed/retry`
        );
        return response;
    };

    public entityRegistrationRetryAll = async (
        memberGroupId: number,
        advertiserId: number
    ) => {
        const response = await this.axios.post(
            `v1/member_groups/${memberGroupId}/advertisers/${advertiserId}/entity_registrations/failed/retry`
        );
        return response;
    };

    public entityRegistrationHide = async (
        memberGroupId: number,
        advertiserId: number,
        eventDetailId: number
    ) => {
        const response = await this.axios.patch(
            `v1/member_groups/${memberGroupId}/advertisers/${advertiserId}/entity_registrations/${eventDetailId}/failed/hide`
        );
        return response;
    };

    public entityRegistrationHideAll = async (
        memberGroupId: number,
        advertiserId: number
    ) => {
        const response = await this.axios.post(
            `v1/member_groups/${memberGroupId}/advertisers/${advertiserId}/entity_registrations/failed/hide`
        );
        return response;
    };

    public entityRegistrationReportDetails = async (
        memberGroupId: number,
        advertiserId: number,
        params?: KoddiListFilterParams
    ) => {
        const response = await this.axios.post(
            `v1/member_groups/${memberGroupId}/advertisers/${advertiserId}/entity_registrations/failed/report`,
            {
                pagination: {
                    start: params?.start || 0,
                    count: params?.count || 5,
                },
            }
        );
        return response.data.result.details || [];
    };
}

export default EntityRegistrationAPI;
