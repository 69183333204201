import produce, { Draft } from 'immer';
import { combineReducers, AnyAction } from 'redux';
import {
    EntityRegistrationState,
    EntityRegistrationInitialState,
    EntityRegistrationReportState,
    EntityRegistrationActions,
    EntityRegistrationAction,
} from './types';

export const initialState: EntityRegistrationState = {
    entityReg: {
        events: null,
        saving: false,
        errorMessage: null,
    },
    entityRegReport: {
        status: 'idle',
        data: {
            details: [
                {
                    event_detail_id: null,
                    event_id: null,
                    completed_on: null,
                    internal_id: null,
                    error_message: null,
                    error_code: null,
                },
            ],
            total: 0,
        },
        hideOrRetryError: '',
    },
};

const entityRegistrationInitialReducer = (
    state: EntityRegistrationInitialState = initialState.entityReg,
    action: EntityRegistrationActions
): EntityRegistrationInitialState =>
    produce(state, (draft: Draft<EntityRegistrationInitialState>) => {
        switch (action.type) {
            case EntityRegistrationAction.FETCH_ENTITY_REGISTRATIONS:
                draft.events = null;
                draft.errorMessage = null;
                break;
            case EntityRegistrationAction.FETCH_ENTITY_REGISTRATIONS_SUCCESS:
                draft.events = action.payload.events;
                draft.errorMessage = null;
                break;
            case EntityRegistrationAction.INITIATE_ENTITY_REGISTRATION:
                draft.saving = true;
                break;
            case EntityRegistrationAction.INITIATE_ENTITY_REGISTRATION_CSV:
                draft.saving = true;
                break;
            case EntityRegistrationAction.ERROR_MESSAGE:
                draft.errorMessage = action.payload.message;
                break;
            case EntityRegistrationAction.CLEAR_ERROR_MESSSAGE:
                draft.errorMessage = null;
                break;
            case EntityRegistrationAction.CLEAR_SAVING_STATE:
                draft.saving = false;
                break;
            default:
        }
    });

const entityRegistrationReportReducer = (
    state = initialState.entityRegReport,
    action: AnyAction
): EntityRegistrationReportState =>
    produce(state, (draft: Draft<EntityRegistrationReportState>) => {
        switch (action.type) {
            case EntityRegistrationAction.ENTITY_REGISTRATION_REPORT:
                draft.status = 'pending';
                draft.data.details = [];
                draft.data.total = 0;
                draft.hideOrRetryError = '';
                break;
            case EntityRegistrationAction.ENTITY_REGISTRATION_REPORT_PENDING:
                draft.status = 'pending';
                draft.data.details = [];
                draft.data.total = 0;
                break;
            case EntityRegistrationAction.ENTITY_REGISTRATION_REPORT_SUCCESS:
                draft.status = 'success';
                draft.data = action.payload.data;
                break;
            case EntityRegistrationAction.ENTITY_REGISTRATION_REPORT_FAILURE:
                draft.status = 'failure';
                draft.data.details = [];
                draft.data.total = 0;
                break;
            case EntityRegistrationAction.CLEAR_REGISTRATION_REPORT:
                draft.status = initialState.entityRegReport.status;
                draft.data = initialState.entityRegReport.data;
                break;
            case EntityRegistrationAction.ENTITY_REGISTRATION_HIDE_OR_RETRY_ERROR:
                draft.hideOrRetryError = action.payload.error;
                break;
            default:
        }
    });

const entityRegistrationReducer = combineReducers<EntityRegistrationState>({
    entityReg: entityRegistrationInitialReducer,
    entityRegReport: entityRegistrationReportReducer,
});

export default entityRegistrationReducer;
