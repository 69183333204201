import { ReportConfig, AppSlices } from 'api/Reports';
import { SelectOption } from 'koddi-components/Select';
import {
    ApplicationRootState,
    ApplicationOutputSelector,
} from 'redux-core/types';
import { createSelector } from 'reselect';
import { initialState } from './reducer';
import { AppFiltersState, DateRangeFilter } from './types';

const selectState = (state: ApplicationRootState) =>
    state.app.filters || initialState;

export const selectAppFilters: ApplicationOutputSelector<
    AppFiltersState,
    (res: AppFiltersState) => AppFiltersState
> = createSelector(selectState, (state) => state);

/** Returns the graphMetric filter. */
export const selectAppGraphMetric: ApplicationOutputSelector<
    SelectOption,
    (res: AppFiltersState) => SelectOption
> = createSelector(selectAppFilters, ({ graphMetric }) => graphMetric);

/** Returns the dateRange filter. */
export const selectAppDateRangeFilter: ApplicationOutputSelector<
    DateRangeFilter,
    (res: AppFiltersState) => DateRangeFilter
> = createSelector(selectAppFilters, ({ dateRange }) => dateRange);

export const selectAppSlices: ApplicationOutputSelector<
    { [key: string]: AppSlices },
    (res: AppFiltersState) => { [key: string]: AppSlices }
> = createSelector(selectState, ({ slices }) => slices);

export const selectReportConfig: ApplicationOutputSelector<
    ReportConfig | null,
    (res: AppFiltersState) => ReportConfig | null
> = createSelector(selectState, ({ reportConfig }) => reportConfig);

export const selectReportConfigError: ApplicationOutputSelector<
    boolean,
    (res: AppFiltersState) => boolean
> = createSelector(selectAppFilters, (state) => state.reportConfigError);

export const selectReportMetricOptions: ApplicationOutputSelector<
    SelectOption[],
    (res: AppFiltersState) => SelectOption[]
> = createSelector(selectState, ({ reportConfig }) => {
    if (!reportConfig || !reportConfig.metrics || !reportConfig.settings)
        return [];

    return Object.keys(reportConfig.settings).map((key) => {
        const fieldConfig = reportConfig.settings[key];
        return { label: fieldConfig.name, value: fieldConfig.id };
    });
});

export const selectNonContextualFields: ApplicationOutputSelector<
    SelectOption[],
    (res: AppFiltersState) => SelectOption[]
> = createSelector(selectState, ({ reportConfig }) => {
    const metrics: Record<string, any> = { ...reportConfig?.metrics };

    return Object.keys(metrics).map((key) => {
        const fieldConfig = metrics[key];
        return { label: fieldConfig.name, value: fieldConfig.id };
    });
});

export const selectAllFieldOptions: ApplicationOutputSelector<
    SelectOption[],
    (res: AppFiltersState) => SelectOption[]
> = createSelector(selectState, ({ reportConfig }) => {
    const combinedFields: Record<string, any> = {
        ...reportConfig?.settings,
        ...reportConfig?.metrics,
    };
    return Object.keys(combinedFields).map((key) => {
        const fieldConfig = combinedFields[key];
        return { label: fieldConfig.name, value: fieldConfig.id };
    });
});

export const selectFilterDisplayOrder = createSelector(
    selectState,
    ({ filterDisplayOrder }) => filterDisplayOrder
);

export const selectOperationDefinitions = createSelector(
    selectState,
    ({ reportConfig }) => reportConfig?.operations
);

export const selectFieldOrder = createSelector(
    selectState,
    ({ reportConfig }) => reportConfig?.field_order
);

export const selectContextualFields = createSelector(
    selectState,
    ({ reportConfig }) => reportConfig?.settings
);

export const selectFields = createSelector(
    selectState,
    ({ reportConfig }) => reportConfig?.metrics
);

export const selectFilterError = createSelector(
    selectState,
    (state) => state.filterError
);
