import styled, { css } from 'styled-components';

export const TabListBase = styled.nav`
    ${({ theme: { grayLight } }) => css`
        display: block;
        position: relative;

        &:before {
            content: '';
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 1px;
            background: ${grayLight};
        }
    `}
`;
