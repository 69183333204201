import {
    ReduxActionWithPayload,
    ReduxAction,
    ReduxActionWithError,
} from 'types';

export enum EditorAction {
    OPEN_EDITOR = 'admin/editor/OPEN_EDITOR',
    CLOSE_EDITOR = 'admin/editor/CLOSE_EDITOR',
    SET_EDITOR_MESSAGE = 'admin/editor/SET_EDITOR_MESSAGE',
    EDITOR_SUBMIT = 'admin/editor/EDITOR_SUBMIT',
    EDITOR_SUBMIT_SUCCESS = 'admin/editor/EDITOR_SUBMIT_SUCCESS',
    EDITOR_SUBMIT_ERROR = 'admin/editor/EDITOR_SUBMIT_ERROR',
}

export type EditorFormKeys = 'create_user' | 'edit_role';

export type OpenEditorPayload<
    Data extends Record<string, unknown> = Record<string, unknown>
> = {
    /** The header text of the editor. */
    header: string;
    /** The key of the form that will be rendered. */
    formKey: EditorFormKeys;
    /** Additional data that is specific to the editor being opened. */
    data?: Data;
};

export type OpenEditorAction<
    Data extends Record<string, unknown> = Record<string, unknown>
> = ReduxActionWithPayload<EditorAction.OPEN_EDITOR, OpenEditorPayload<Data>>;

export type CloseEditorAction = ReduxAction<EditorAction.CLOSE_EDITOR>;

export type SetEditorMessageAction = ReduxActionWithPayload<
    EditorAction.SET_EDITOR_MESSAGE,
    { message: string }
>;

export type EditorSubmitAction = ReduxAction<EditorAction.EDITOR_SUBMIT>;

export type EditorSubmitSuccessAction = ReduxAction<
    EditorAction.EDITOR_SUBMIT_SUCCESS
>;

export type EditorSubmitErrorAction = ReduxActionWithError<
    EditorAction.EDITOR_SUBMIT_ERROR
>;

export type EditorActions =
    | OpenEditorAction
    | CloseEditorAction
    | SetEditorMessageAction
    | EditorSubmitAction
    | EditorSubmitErrorAction
    | EditorSubmitSuccessAction
    | EditorSubmitSuccessAction;

export type EditorState<
    Data extends Record<string, unknown> = Record<string, unknown>
> = {
    header: string | null;
    open: boolean;
    formKey: EditorFormKeys | null;
    error: Error | null;
    message: string | null;
    saving: boolean;
    data: Data | null;
};
