import { combineReducers } from 'redux';
import createAsyncReducer from 'utils/createAsyncReducer';
import { IndustryList } from 'api/Admin/Industry';
import { IndustryState, IndustryAction } from './types';

export const initialState: IndustryState = {
    list: {
        last_updated: null,
        loading: false,
        error: null,
        data: null,
    },
};

export const industryListReducer = createAsyncReducer<IndustryList>(
    initialState.list,
    {
        start: IndustryAction.GET_INDUSTRY_LIST,
        success: IndustryAction.GET_INDUSTRY_LIST_SUCCESS,
        error: IndustryAction.GET_INDUSTRY_LIST_ERROR,
    }
);

const industryReducer = combineReducers<IndustryState>({
    list: industryListReducer,
});

export default industryReducer;
