/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import KoddiAPI from 'api';
import { createSimpleSagaWithCache } from 'utils/createSimpleSaga';
import { takeEvery, all } from 'redux-saga/effects';
import SagaRegistry from 'redux-core/sagaRegistry';
import { MemberGroupList } from 'api/Admin/MemberGroup';
import { getMemberGroupListError, getMemberGroupListSuccess } from './actions';
import { MemberGroupAction } from './types';
import { selectMemberGroupList } from './selectors';

export const getMemberGroupListApi = (): Promise<MemberGroupList> =>
    KoddiAPI.Admin.MemberGroup.getList();

export const getMemberGroupListSaga = createSimpleSagaWithCache(
    getMemberGroupListApi,
    getMemberGroupListSuccess,
    getMemberGroupListError,
    selectMemberGroupList
);

export function* watchGetMemberGroupList() {
    yield takeEvery(
        MemberGroupAction.GET_MEMBER_GROUP_LIST,
        getMemberGroupListSaga
    );
}

function* memberGroupSagas() {
    yield all([watchGetMemberGroupList()]);
}

SagaRegistry.registerSaga(memberGroupSagas);
