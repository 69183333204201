import React, { FunctionComponent } from 'react';
import { BaseSVGProps } from '../icon.types';

const InfoIcon: FunctionComponent<BaseSVGProps> = ({ width, height }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 24 24"
    >
        <path d="M12.07 0H12a12 12 0 10.07 0zm1.57 19.64h-3.28v-8.73h3.28zM12 8.73a2.19 2.19 0 112.18-2.18A2.18 2.18 0 0112 8.73z" />
    </svg>
);

export default InfoIcon;
