import { KoddiAPIResponse } from 'types';
import CachedKoddiAPI from 'api/api.cachedObject';
import { BillingType } from './Billing.types';

class KoddiBillingAPI extends CachedKoddiAPI {
    public getBillingMethods = async (): Promise<BillingType[]> => {
        const response = await this.axios.get<KoddiAPIResponse<any>>(
            `v1/billing/methods`
        );
        return response.data.result.billing_methods;
    };

    public updateBillingMethods = async (
        memberGroupId: number,
        data: number[]
    ): Promise<any> => {
        const response = await this.axios.patch<KoddiAPIResponse<any>>(
            `v1/member_groups/${memberGroupId}/billing/methods`,
            { billing_method_ids: data }
        );
        return response;
    };
}

export default KoddiBillingAPI;
