import styled, { css } from 'styled-components';
import { IconWrapper } from 'koddi-components/Icon';
import { fadeDownIn } from 'koddi-components/animations';
import { cssValue, cssProp } from 'koddi-components/utils';
import OnOutsideClick from 'koddi-components/OnOutsideClick';
import Button, { ButtonText } from 'koddi-components/Button';
import { CheckboxContainer } from 'koddi-components/Icon/icons/checkbox';
import { IconTooltipWrapper } from 'koddi-components/Tooltip';
import {
    DropdownContainerProps,
    StatusOptionWrapperProps,
    ControlProps,
} from './select.types';

export const SelectOutsideClick = styled(OnOutsideClick)<{
    width?: number | string;
}>`
    ${({ width }) => css`
        ${cssProp('width', width || '100%')}
    `}
`;

export const ReadOnlyTarget = styled.div`
    ${({ theme }) => css`
        background-color: ${theme.grayLighter};
        width: 100%;
        height: 30px;
        font-size: 12px;
        display: flex;
        align-items: center;
        padding-left: ${theme.spaceUnit * 4}px;
        padding-right: ${theme.spaceUnit * 4}px;
        cursor: default;
    `}
`;

export const SelectButton = styled(Button)`
    ${({ disabled, theme: { grayLightest, gray }, display }) => css`
        &:disabled {
            background-color: ${grayLightest};
        }
        ${ButtonText} {
            color: ${disabled ? gray : 'inherit'};
            display: flex;
            align-items: center;
            display: ${display ? 'block' : 'flex'};
        }
    `}
`;

export const Control = styled.div<ControlProps>`
    ${({ theme, isHidden }) => css`
        padding: ${theme.spaceUnit * 5}px;
        border-bottom: 1px solid ${theme.grayLight};
        display: ${isHidden ? 'none' : 'block'};
    `}
`;

export const ControlActions = styled.div`
    ${({ theme }) => css`
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        color: ${theme.black};
        margin-top: ${theme.spaceUnit * 4}px;
    `}
`;

export const ControlAction = styled.a`
    ${({ theme }) => css`
        padding: 0px ${theme.spaceUnit * 2}px;
        border-right: 1px solid ${theme.black};
        opacity: 1;
        transition: opacity 0.2s ease;
        &:first-of-type {
            padding-left: 0px;
        }
        &:last-of-type {
            padding-right: 0px;
            border-right: none;
        }
        &:hover {
            cursor: pointer;
            text-decoration: underline;
            opacity: 0.7;
        }
    `}
`;

export const ControlContainer = styled.div<{ isSearchable?: boolean }>`
    ${({ theme }) => css`
        display: flex;
        flex-direction: row;
        background-color: ${theme.grayLightest};
        justify-content: flex-start;
        align-items: center;
        height: 30px;
        ${IconWrapper} {
            display: inline-flex;
            position: relative;
            margin: 0px ${theme.spaceUnit * 3}px;
            color: ${theme.gray};
        }
    `}
`;

export const ControlInnerContainer = styled.div`
    position: relative;
    flex: 1;
    & > div:first-of-type {
        outline: none;
        border: none;
        box-shadow: none;
        background-color: transparent;
        &:hover {
            cursor: text;
        }
    }
`;

export const ValueContainer = styled.div`
    ${({ theme }) => css`
        width: 100%;
        & > div {
            padding: 0;
        }
        & > input:focus {
            background-color: ${theme.grayLight};
        }
    `}
`;

export const TargetContainer = styled.div<{ hasError?: boolean; v2: boolean }>`
    ${({ hasError, theme, v2 }) => css`
        button {
            width: 100%;
            font-size: ${theme.typography.baseFontSize};
            outline: none;
            border: ${v2 ? `1px solid ${theme.grayLight}` : 'none'};
            color: ${v2 ? theme.black : ''};
            border-radius: ${v2 ? '5px' : ''};
            background-color: ${v2
                ? theme.white
                : hasError && `background-color: ${theme.errorBackground}`};
        }
    `}
`;

export const Menu = styled.div`
    ${({ theme }) => css`
        color: ${theme.black};
        & > div {
            margin-top: 0px;
            margin-bottom: 0px;
            border: none;
            box-shadow: none;
            position: relative;
            & > div {
                /* MenuList styles */
                padding: ${theme.spaceUnit * 4}px 0px;
                max-width: 100%;
                font-size: ${theme.typography.baseFontSize};
            }
        }
    `}
`;

export const SelectContainer = styled.div<{
    selectWidth?: string;
}>`
    ${({ selectWidth }) => css`
        position: relative;
        width: ${selectWidth || '100%'};
        min-width: ${selectWidth ? 'auto' : '150px'};
    `}
`;

export const DropdownContainer = styled.div<DropdownContainerProps>`
    ${({ theme, dropdownWidth, dropdownMinWidth }) => css`
        width: ${cssValue(dropdownWidth, '300px')};
        min-width: ${cssValue(dropdownMinWidth)};
        z-index: ${theme.zIndex.popper};
        background-color: ${theme.white};
        & > div {
            box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.12);
            animation: ${fadeDownIn} 0.2s ease-out both;
        }
    `}
`;

export const Input = styled.div`
    height: 30px;
    font-size: 12px;
`;

export const Option = styled.div`
    ${({ theme }) => css`
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        color: ${theme.black};
        padding: 0;
        outline: none;
        border: none;
    `}
`;

export const MultiOptionWrapper = styled.div<{ isSelected: boolean }>`
    ${({ theme, isSelected }) => css`
        outline: none;
        border: none;
        box-shadow: none;
        & > div {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            padding-left: ${theme.spaceUnit * 4}px;
            height: ${theme.spaceUnit * 8}px;
            background-color: ${isSelected ? theme.grayLightest : theme.white};
            transition: background-color 0.2s ease-in-out;
        }
        &:hover > div {
            cursor: pointer;
            background-color: ${theme.grayLightest};
        }
        & > div:hover {
            cursor: pointer;
        }
        ${CheckboxContainer} {
            display: inline-flex;
        }
    `}
`;

export const OptionWrapper = styled.div<{ isSelected: boolean }>`
    ${({ theme, isSelected }) => css`
        & > div {
            background-color: ${isSelected ? theme.grayLightest : null};
            padding-left: ${theme.spaceUnit * 4}px;
            transition: background-color 0.2s ease-in-out;
        }
        &:hover > div {
            cursor: pointer;
        }
    `}
`;

export const StatusOptionWrapper = styled(OptionWrapper)<
    StatusOptionWrapperProps
>`
    ${({ theme }) => css`
        display: flex;
        flex-direction: row;
        align-items: center;
        &:before {
            content: '';
            position: absolute;
            left: ${theme.spaceUnit * 2}px;
        }
        & > div {
            background-color: ${theme.white};
            padding: ${theme.spaceUnit * 2}px ${theme.spaceUnit * 6}px;
            transition: background-color 0.2s ease-in-out;
        }
    `}
`;

export const OptionInner = styled.div`
    ${({ theme }) => css`
        margin-left: ${theme.spaceUnit * 4}px;
    `}
`;

export const Group = styled.div`
    ${({ theme: { spaceUnit, white } }) => css`
        padding-left: ${spaceUnit * 4}px;
        ${MultiOptionWrapper} {
            background-color: ${white};
            & > div,
            &:hover > div {
                cursor: pointer;
                background-color: ${white};
            }
        }
    `}
`;

export const GroupHeadingOption = styled.div`
    ${({ theme: { spaceUnit } }) => css`
        height: ${spaceUnit * 8}px;
        display: flex;
        align-items: center;
        cursor: pointer;
    `}
`;

export const LabelWrapper = styled.div<{
    labelToolTip?: boolean;
    visibility?: string;
}>`
    ${({ labelToolTip, visibility }) => {
        return css`
            display: ${labelToolTip ? 'flex' : 'block'};
            & > label:after {
                visibility: ${visibility};
                color: red;
                content: ' *'};
            }
        `;
    }}
`;
export const LabelToolTip = styled(IconTooltipWrapper)`
    ${() => css`
        margin-bottom: 12px;
        margin-left: 10px;
    `}
`;
