import { AxiosInstance } from 'axios';
import { KoddiAPIResponse } from 'types';
import { MemberList } from './Member.types';

const KODDI_MEMBER_URL = '/member';

class MemberAPI {
    protected axios: AxiosInstance;

    constructor(axios: AxiosInstance) {
        this.axios = axios;
    }

    public getList = async (): Promise<MemberList> => {
        const response = await this.axios.get<KoddiAPIResponse<MemberList>>(
            `v1${KODDI_MEMBER_URL}`
        );
        return response.data.result;
    };
}

export default MemberAPI;
