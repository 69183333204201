import React, { FunctionComponent } from 'react';
import { BaseSVGProps } from '../icon.types';

const PercentIcon: FunctionComponent<BaseSVGProps> = ({ width, height }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        height={height}
        width={width}
        viewBox="0 0 24 24"
    >
        <path d="M8.9 10.6c-1 1-2.2 1.5-3.7 1.5-1.4 0-2.7-.5-3.7-1.5S0 8.3 0 6.9s.5-2.7 1.5-3.7 2.2-1.5 3.7-1.5 2.7.5 3.7 1.5 1.5 2.2 1.5 3.7-.5 2.7-1.5 3.7zM6.8 8.5c.4-.4.7-1 .7-1.6s-.3-1.1-.7-1.6c-.4-.4-1-.7-1.6-.7s-1.1.3-1.6.7c-.4.5-.6 1-.6 1.6s.2 1.1.6 1.6c.4.4 1 .7 1.6.7s1.1-.3 1.6-.7zm9.8-6.9h2.1L7.4 22.4H5.2L16.6 1.6zm5.9 19.2c-1 1-2.2 1.5-3.7 1.5s-2.7-.5-3.7-1.5-1.5-2.2-1.5-3.7c0-1.4.5-2.7 1.5-3.7s2.2-1.5 3.7-1.5 2.7.5 3.7 1.5 1.5 2.2 1.5 3.7-.5 2.6-1.5 3.7zm-2.1-5.3c-.4-.4-1-.7-1.6-.7s-1.1.2-1.6.7c-.4.4-.7 1-.7 1.6s.2 1.1.7 1.6c.4.4 1 .7 1.6.7s1.1-.2 1.6-.7c.4-.4.7-1 .7-1.6s-.3-1.2-.7-1.6z" />
    </svg>
);

export default PercentIcon;
