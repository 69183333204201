import React, { FunctionComponent } from 'react';
import { BaseSVGProps } from '../icon.types';

const DollarSignIcon: FunctionComponent<BaseSVGProps> = ({ width, height }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 24 24"
    >
        <path
            id="Path_3144"
            data-name="Path 3144"
            d="M16.25 11.49a5.1 5.1 0 00-.88-.57 25.91 25.91 0 00-2.94-1.33 6.55 6.55 0 01-1.61-.91 1.41 1.41 0 01-.37-2 1.51 1.51 0 01.71-.54 3.06 3.06 0 011-.2 7.6 7.6 0 013.72.74c.58.28.78.19 1-.42s.38-1.3.56-1.95a.69.69 0 00-.44-.85h-.06a9.64 9.64 0 00-2.35-.7c-1.06-.16-1.06-.17-1.07-1.24C13.55 0 13.55 0 12 0h-.66c-.7 0-.82.14-.84.85v1c0 1 0 .93-.91 1.26A5 5 0 006 7.73a4.78 4.78 0 002.7 4.57 33 33 0 003.3 1.52 4.73 4.73 0 011.23.72 1.7 1.7 0 01.39 2.36 1.74 1.74 0 01-.79.6 4 4 0 01-2.17.28 9.67 9.67 0 01-3.31-1c-.61-.32-.79-.23-1 .43s-.34 1.15-.5 1.72c-.21.78-.13 1 .61 1.32a10.31 10.31 0 003 .85c.81.13.83.16.85 1v1.12a.68.68 0 00.58.76h1.82a.64.64 0 00.69-.58.65.65 0 000-.14V21.7a.81.81 0 01.69-.93A5.59 5.59 0 0017 18.91a5.27 5.27 0 00-.75-7.42z"
            transform="translate(0 0)"
            fill="#fff"
        />
    </svg>
);

export default DollarSignIcon;
