import React, { FunctionComponent } from 'react';
import { BaseSVGProps } from '../icon.types';

const ProfileIcon: FunctionComponent<BaseSVGProps> = ({ width, height }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        height={height}
        width={width}
        viewBox="0 0 24 24"
    >
        <path
            id="Path_3038"
            d="M11.47,11.52L5.13,8.85c-0.07-0.03-0.15,0-0.18,0.07C4.94,8.94,4.94,8.96,4.94,8.98v7.14
	c0,0.18,0.11,0.34,0.28,0.41l6.15,2.46c0.07,0.03,0.15-0.01,0.18-0.08c0.01-0.02,0.01-0.03,0.01-0.05v-7.21
	C11.56,11.59,11.53,11.54,11.47,11.52z"
        />
        <path
            id="Path_3039"
            d="M12.05,10.76l6.13-2.58c0.07-0.03,0.1-0.11,0.07-0.18c-0.01-0.03-0.04-0.06-0.07-0.07l-6-2.8
	c-0.12-0.06-0.25-0.06-0.37,0l-6,2.8c-0.07,0.03-0.1,0.11-0.07,0.18c0.01,0.03,0.04,0.06,0.07,0.07l6.14,2.58
	C11.98,10.78,12.02,10.78,12.05,10.76z"
        />
        <path
            id="Path_3040"
            d="M12.44,11.65v7.21c0,0.08,0.06,0.14,0.14,0.14c0.02,0,0.03,0,0.05-0.01l6.15-2.46
	c0.17-0.07,0.28-0.23,0.28-0.41V8.98c0-0.08-0.06-0.14-0.14-0.14c-0.02,0-0.04,0-0.05,0.01l-6.34,2.67
	C12.47,11.54,12.44,11.59,12.44,11.65L12.44,11.65z"
        />
    </svg>
);

export default ProfileIcon;
