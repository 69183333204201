import React from 'react';
import LoadingSpinner from 'koddi-components/LoadingSpinner';
import { TableBodyProps } from './TableBody.types';
import { TableBodyWrapper, TableBodyElement } from './TableBody.styled';

/**
 * The component rendered for the table body.
 */
const TableBody = ({
    tableHeight,
    rowElements,
    style,
    getTableBodyProps,
    id,
    filterState,
    ...rest
}: TableBodyProps): JSX.Element => {
    return (
        <TableBodyWrapper height={tableHeight} data-test={`${id}-body`}>
            {filterState ? (
                <LoadingSpinner id={id} absolutePosition />
            ) : (
                <TableBodyElement
                    {...getTableBodyProps()}
                    {...rest}
                    style={style}
                >
                    {rowElements}
                </TableBodyElement>
            )}
        </TableBodyWrapper>
    );
};

export default TableBody;
