import React, { FunctionComponent } from 'react';
import styled, { css } from 'styled-components';

import { IconProps } from './icon.types';

const Icons = React.lazy(() => import('./icons'));

export const IconWrapper = styled.span<{
    marginTop?: number;
    marginRight?: number;
    overRidePathColor?: boolean;
}>`
    ${({
        theme,
        /* @ts-ignore */
        disabled,
        marginTop,
        marginRight,
        overRidePathColor,
    }) => css`
        color: inherit;
        display: inline-flex;
        align-items: center;
        cursor: ${disabled ? 'not-allowed' : 'pointer'};
        svg path {
            fill: ${overRidePathColor ? 'currentColor' : 'null'};
        }
        svg circle {
            fill: currentColor;
        }
        .icon-primary {
            fill: ${theme.primary};
        }
        margin-top: ${marginTop}px;
        margin-right: ${marginRight}px;
        .icon-gray {
            fill: ${theme.gray};
        }
        .icon-black {
            fill: black;
        }
        .icon-white {
            fill: white;
        }
    `}
`;

export const Icon: FunctionComponent<IconProps> = ({
    icon = 'chevronDown',
    height = 16,
    width = 16,
    testSuffix,
    forwardedRef,
    marginTop = 0,
    marginRight = 0,
    overRidePathColor = true,
    ...restProps
}) => {
    const suffix = testSuffix ? `-${testSuffix}` : '';

    return (
        <IconWrapper
            {...restProps}
            ref={forwardedRef}
            marginTop={marginTop}
            marginRight={marginRight}
            overRidePathColor={overRidePathColor}
            data-test={`icon-${icon}${suffix}`}
        >
            <Icons icon={icon} height={height} width={width} />
        </IconWrapper>
    );
};

const IconWithForwardedRef = React.forwardRef<HTMLSpanElement, IconProps>(
    (props, ref) => <Icon {...props} forwardedRef={ref} />
);

IconWithForwardedRef.displayName = 'IconWithForwardedRef';

export default IconWithForwardedRef;
