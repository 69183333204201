import CachedKoddiAPI from 'api/api.cachedObject';
import { CreateReportInput } from 'api/Reports';
import { BulkEditResponse } from 'koddi/types';
import { KoddiAPIResponse } from 'types';
import {
    AdGroup,
    AdGroupBudgetInfo,
    BulkEditAdGroupStatus,
    EntitiesPayload,
    AllEntitiesPayload,
    UpdateAdvertiserAdGroupInput,
    CreateAdvertiserAdGroupInput,
    BidStrength,
    PCLSum,
} from './AdGroups.types';

class KoddiAdGroupsAPI extends CachedKoddiAPI {
    public getByID = async (
        memberGroupId: number,
        advertiserId: number,
        adGroupId: number,
        forceRequest = false
    ): Promise<AdGroup> => {
        const response = await this.cache.makeCachedRequest(
            'getByID',
            `${memberGroupId}-${advertiserId}-${adGroupId}`,
            () => {
                return this.axios.get<KoddiAPIResponse<AdGroup>>(
                    `v1/member_groups/${memberGroupId}/advertisers/${advertiserId}/ad_groups/${adGroupId}`
                );
            },
            forceRequest
        );

        return response.data.result;
    };

    public create = async (
        advertiser_id: number,
        member_group_id: number,
        data: CreateAdvertiserAdGroupInput
    ): Promise<AdGroup> => {
        const response = await this.axios.post<KoddiAPIResponse<AdGroup>>(
            `v1/member_groups/${member_group_id}/advertisers/${advertiser_id}/ad_groups`,
            data
        );
        return response.data.result;
    };

    public createV2 = async ({
        advertiser_id,
        member_group_id,
        data,
    }: {
        advertiser_id: number;
        member_group_id: number;
        data: CreateAdvertiserAdGroupInput;
    }): Promise<AdGroup> => {
        const response = await this.axios.post<KoddiAPIResponse<AdGroup>>(
            `v2/member_groups/${member_group_id}/advertisers/${advertiser_id}/ad_groups`,
            data
        );
        return response.data.result;
    };

    public update = async (
        advertiser_id: number,
        member_group_id: number,
        ad_group_id: number,
        data: UpdateAdvertiserAdGroupInput
    ): Promise<AdGroup> => {
        const response = await this.axios.put<KoddiAPIResponse<AdGroup>>(
            `v1/member_groups/${member_group_id}/advertisers/${advertiser_id}/ad_groups/${ad_group_id}`,
            data
        );
        this.cache.break(
            'getByID',
            `${member_group_id}-${advertiser_id}-${ad_group_id}`
        );
        return response.data.result;
    };

    public updateV2 = async ({
        advertiser_id,
        member_group_id,
        ad_group_id,
        data,
    }: {
        advertiser_id: number;
        member_group_id: number;
        ad_group_id: number;
        data: UpdateAdvertiserAdGroupInput;
    }): Promise<AdGroup> => {
        const response = await this.axios.patch<KoddiAPIResponse<AdGroup>>(
            `v2/member_groups/${member_group_id}/advertisers/${advertiser_id}/ad_groups/${ad_group_id}`,
            data
        );
        this.cache.break(
            'getByID',
            `${member_group_id}-${advertiser_id}-${ad_group_id}`
        );
        return response.data.result;
    };

    public getBudgetInfo = async (
        memberGroupId: number,
        advertiserId: number,
        adGroupId: number
    ): Promise<AdGroupBudgetInfo> => {
        const response = await this.axios.get<
            KoddiAPIResponse<AdGroupBudgetInfo>
        >(
            `v1/member_groups/${memberGroupId}/advertisers/${advertiserId}/ad_groups/${adGroupId}/budget`
        );
        return response.data.result || {};
    };

    /**
     * Bulk edit ad groups by their ids.
     */
    public bulkEdit = async (
        memberGroupId: number,
        advertiserId: number,
        ad_group_ids: AdGroup['id'][],
        status: BulkEditAdGroupStatus,
        budget_percentage?: number,
        base_bid_percentage?: number,
        budget_increase?: number,
        base_bid_increase?: number,
        reportPayload?: CreateReportInput,
        selectAll?: boolean
    ): Promise<BulkEditResponse> => {
        const response = await this.axios.patch<
            KoddiAPIResponse<BulkEditResponse>
        >(
            `v1/member_groups/${memberGroupId}/advertisers/${advertiserId}/ad_groups/bulk`,
            {
                ad_group_ids,
                budget_percentage,
                base_bid_percentage,
                budget_increase,
                base_bid_increase,
                status: status || undefined,
                report: reportPayload,
                all_selected: selectAll,
            }
        );
        this.cache.breakMethod('getByID');
        return response.data.result;
    };

    public bulkEditV2 = async ({
        memberGroupId,
        advertiserId,
        ad_group_ids,
        status,
        budget_percentage,
        base_bid_percentage,
        budget_increase,
        base_bid_increase,
        reportPayload,
        selectAll,
    }: {
        memberGroupId: number;
        advertiserId: number;
        ad_group_ids: AdGroup['id'][];
        status: BulkEditAdGroupStatus;
        budget_percentage?: number;
        base_bid_percentage?: number;
        budget_increase?: number;
        base_bid_increase?: number;
        reportPayload?: CreateReportInput;
        selectAll?: boolean;
    }): Promise<BulkEditResponse> => {
        const response = await this.axios.patch<
            KoddiAPIResponse<BulkEditResponse>
        >(
            `v2/member_groups/${memberGroupId}/advertisers/${advertiserId}/ad_groups/bulk`,
            {
                ad_group_ids,
                budget_percentage,
                base_bid_percentage,
                budget_increase,
                base_bid_increase,
                status: status || undefined,
                report: reportPayload,
                all_selected: selectAll,
            }
        );
        this.cache.breakMethod('getByID');
        return response.data.result;
    };

    public disableEntities = async (
        entities: EntitiesPayload
    ): Promise<any> => {
        const response = await this.axios.delete<
            KoddiAPIResponse<BulkEditResponse>
        >('v1/ad_groups/entities/bulk', {
            data: { entities },
        });
        this.cache.breakMethod('getByID');
        return response.data.result;
    };

    public enableEntities = async (
        entities: EntitiesPayload,
        use_base_bid?: boolean
    ): Promise<any> => {
        const response = await this.axios.post<
            KoddiAPIResponse<BulkEditResponse>
        >('v1/ad_groups/entities/bulk', {
            entities,
            use_base_bid,
        });
        this.cache.breakMethod('getByID');
        return response.data.result;
    };

    public enableAllEntities = async (
        payload: AllEntitiesPayload
    ): Promise<any> => {
        const response = await this.axios.post<
            KoddiAPIResponse<BulkEditResponse>
        >('v1/ad_groups/entities/bulk/all', payload);
        this.cache.breakMethod('getByID');
        return response.data.result;
    };

    public getNewBidStrength = async (
        memberGroupId: number,
        advertiserId: number,
        adGroupId: number,
        newBid: number
    ): Promise<BidStrength> => {
        const response = await this.axios.post<KoddiAPIResponse<BidStrength>>(
            `v1/member_groups/${memberGroupId}/advertisers/${advertiserId}/ad_groups/${adGroupId}/bid_strength`,
            { base_bid: newBid }
        );
        return response.data.result;
    };

    public getNewEntityBidStrength = async (
        memberGroupId: number,
        entityId: number,
        newBid: number
    ): Promise<BidStrength> => {
        const response = await this.axios.post<KoddiAPIResponse<BidStrength>>(
            `v1/member_groups/${memberGroupId}/entities/${entityId}/bid_strength`,
            { base_bid: newBid }
        );
        return response.data.result;
    };

    public getAdGroupPCLSum = async (
        memberGroupId: number,
        advertiserId: number,
        adGroupId: number
    ): Promise<PCLSum> => {
        const response = await this.axios.get<KoddiAPIResponse<PCLSum>>(
            `v1/member_groups/${memberGroupId}/advertisers/${advertiserId}/ad_groups/${adGroupId}/pcl`
        );
        return response.data.result;
    };
}

export default KoddiAdGroupsAPI;
