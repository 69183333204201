import React, { FunctionComponent } from 'react';
import TableHead from '../TableHead';
import TableBody from '../TableBody';
import { TableContainerInnerElementProps } from './TableContainer.types';
import { useKoddiTable } from '../TableProvider';

/**
 * The `TableContainerInnerElement` renders the table head and table body
 * and enables css styles for sticky columns and rows.
 */
const TableContainerInnerElement: FunctionComponent<TableContainerInnerElementProps> = ({
    children: rowElements,
    style,
    ...rest
}) => {
    const {
        headerGroups,
        headerRowHeight,
        disableManualSort,
        sticky,
        borderless,
        id,
        headless,
        tableHeight,
        getTableBodyProps,
        filterState,
    } = useKoddiTable();

    return (
        <>
            {!headless && (
                <TableHead
                    headerGroups={headerGroups}
                    headerRowHeight={headerRowHeight}
                    disableManualSort={disableManualSort}
                    sticky={sticky}
                    borderless={borderless}
                    id={id}
                />
            )}
            <TableBody
                {...rest}
                tableHeight={tableHeight}
                getTableBodyProps={getTableBodyProps}
                style={style}
                rowElements={rowElements}
                id={id}
                filterState={filterState}
            />
        </>
    );
};

export default TableContainerInnerElement;
