import {
    AdGroupStatus,
    AdGroupBudgetType,
    AdGroupBidType,
    AdGroupTargetType,
    AdGroupRotation,
} from './AdGroups.types';

export const AD_GROUP_STATUS: Record<AdGroupStatus, AdGroupStatus> = {
    active: 'active',
    paused: 'paused',
    pending: 'pending',
    ended: 'ended',
    inactive: 'inactive',
    invalid: 'invalid',
};

export const AD_GROUP_BUDGET_TYPE: Record<
    AdGroupBudgetType,
    AdGroupBudgetType
> = {
    Daily: 'Daily',
    Weekly: 'Weekly',
    Monthly: 'Monthly',
    Custom: 'Custom',
};

export const AD_GROUP_BID_TYPE: Record<AdGroupBidType, AdGroupBidType> = {
    CPC: 'CPC',
};

export const AD_GROUP_TARGET_TYPE: Record<
    AdGroupTargetType,
    AdGroupTargetType
> = {
    Exact: 'Exact',
    'Bid Boost': 'Bid Boost',
    'Bid Multiplier': 'Bid Multiplier',
};

export const AD_GROUP_ROTATION: Record<AdGroupRotation, AdGroupRotation> = {
    Even: 'Even',
};
