import React, { FunctionComponent } from 'react';
import { usePermissions } from '../PermissionsProvider';
import { CanViewProps, PermissionName } from './CanView.types';

export const checkPermissions = (
    permissions: PermissionName[],
    userPermissions: Record<string, string[]>,
    any?: boolean
): boolean => {
    const hasPermissions: string[] = permissions.filter((permission) => {
        if (!permission) return true;
        const [name, activity] = permission.split('.');
        return userPermissions[name]?.includes(activity) ?? false;
    });
    const hasAllPermissions = hasPermissions.length === permissions.length;
    const hasSomePermissions = hasPermissions.length > 0;
    return any ? hasSomePermissions : hasAllPermissions;
};

export const checkRoles = (
    userRoles: number[],
    permissionRole: number[],
    anyRole?: boolean
): boolean => {
    if (!permissionRole.length) return true;
    const hasRoles = permissionRole.filter((role) => userRoles.includes(role));
    const hasAllRoles = hasRoles.length === permissionRole.length;
    const hasSomeRoles = hasRoles.length > 0;
    return anyRole ? hasSomeRoles : hasAllRoles;
};

export const checkPermissionsAndRole = (
    role: number | number[],
    permission: string | string[],
    userRoles: number[],
    userPermissions: Record<string, string[]>,
    /* eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types */
    condition: any,
    anyRole = false,
    anyPermission = false
): boolean => {
    const permissionRoleList = Array.isArray(role) ? role : [role];
    const permissionList = Array.isArray(permission)
        ? permission
        : [permission];

    const hasRole =
        userRoles && role
            ? checkRoles(userRoles, permissionRoleList, anyRole)
            : true;
    const havePermission =
        userPermissions && permission
            ? checkPermissions(permissionList, userPermissions, anyPermission)
            : true;

    const meetsCondition = condition || condition === false ? condition : true;
    return !!(hasRole && havePermission && meetsCondition);
};

/*
 * The `CanView` Component is used to conditionally render a component
 * based on the current users permissions.
 */
const CanView: FunctionComponent<CanViewProps> = ({
    permission = [],
    children,
    condition,
    noAccessRender,
    role = [],
    any = false,
    anyRole = false,
}) => {
    const { map: userPermissions, roles: userRoles } = usePermissions();

    const canView = checkPermissionsAndRole(
        role,
        permission,
        userRoles,
        userPermissions,
        condition,
        anyRole,
        any
    );

    const noAccess = noAccessRender ? noAccessRender() : null;
    return <>{canView ? children : noAccess}</>;
};

export default CanView;
