import {
    ReduxActionWithError,
    ReduxActionWithPayload,
    ReduxActionWithRefresh,
    AsyncState,
} from 'types';
import { ActivityList } from 'api/Admin/Activity/Activity.types';

export enum ActivityAction {
    GET_ACTIVITY_LIST = 'admin/activity/GET_ACTIVITY_LIST',
    GET_ACTIVITY_LIST_SUCCESS = 'admin/activity/GET_ACTIVITY_LIST_SUCCESS',
    GET_ACTIVITY_LIST_ERROR = 'admin/activity/GET_ACTIVITY_LIST_ERROR',
}

export type GetActivityListAction = ReduxActionWithRefresh<
    ActivityAction.GET_ACTIVITY_LIST
>;

export type GetActivityListSuccessAction = ReduxActionWithPayload<
    ActivityAction.GET_ACTIVITY_LIST_SUCCESS,
    { data: ActivityList }
>;

export type GetActivityListErrorAction = ReduxActionWithError<
    ActivityAction.GET_ACTIVITY_LIST_ERROR
>;

export type ActivityActions =
    | GetActivityListAction
    | GetActivityListSuccessAction
    | GetActivityListErrorAction;

export type ActivityState = {
    list: AsyncState<ActivityList>;
};
