import React, { FunctionComponent } from 'react';
import { BaseSVGProps } from '../icon.types';

const CampaignIcon: FunctionComponent<BaseSVGProps> = ({ width, height }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        height={height}
        width={width}
        viewBox="0 0 24 24"
    >
        <g id="megaphone" transform="translate(-24.1 0.25)">
            <path
                id="Path_3025"
                d="M40.96,6.77l1.09-1.09c0.2-0.2,0.2-0.52,0-0.72c-0.2-0.2-0.52-0.2-0.72,0l-1.09,1.09
		c-0.2,0.2-0.2,0.52,0,0.72C40.44,6.97,40.76,6.97,40.96,6.77z"
            />
            <path
                id="Path_3026"
                d="M43.32,9.16h-1.09c-0.28-0.01-0.52,0.2-0.53,0.48c-0.01,0.28,0.2,0.52,0.48,0.53
		c0.01,0,0.03,0,0.05,0h1.09c0.28,0.01,0.52-0.2,0.53-0.48c0.01-0.28-0.2-0.52-0.48-0.53C43.35,9.16,43.33,9.16,43.32,9.16z"
            />
            <path
                id="Path_3027"
                d="M37.35,5.29c0.28,0,0.51-0.23,0.51-0.51V3.7c-0.01-0.28-0.25-0.5-0.53-0.48
		c-0.26,0.01-0.47,0.22-0.48,0.48v1.08C36.84,5.06,37.07,5.29,37.35,5.29L37.35,5.29z"
            />
            <path
                id="Path_3028"
                d="M28.3,15.82c-0.2,0.2-0.2,0.52,0,0.72c0,0,0,0,0,0l2.17,2.17c0.2,0.2,0.52,0.2,0.72,0l0.85-0.85l-2.89-2.89
		L28.3,15.82z"
            />
            <path
                id="Path_3029"
                d="M37.08,16.23l-3.56,1.67l2.43,2.43c0.2,0.2,0.52,0.2,0.72,0l1.9-1.9c0.2-0.2,0.2-0.52,0-0.72l0,0
		L37.08,16.23z"
            />
            <path
                id="Path_3030"
                d="M34.99,4.97c-0.2-0.2-0.52-0.2-0.72,0c-0.04,0.04-0.08,0.09-0.1,0.14l-4.3,9.15l2.89,2.89l9.15-4.3
		c0.25-0.12,0.36-0.42,0.24-0.67c-0.02-0.05-0.06-0.1-0.1-0.14L34.99,4.97z"
            />
        </g>
    </svg>
);

export default CampaignIcon;
