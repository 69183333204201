/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { AxiosInstance } from 'axios';
import { KoddiListFilterParams } from 'koddi/types';
import { MediaPlan, MediaPlanGetListResponse } from './MediaPlan.types';

class MediaPlanAPI {
    protected axios: AxiosInstance;

    constructor(axios: AxiosInstance) {
        this.axios = axios;
    }

    /**
     * Gets a list of media plans across a given set of advertisers or an advertiser.
     * @param advertiser_id The advertiser id
     * @param query Query parameters for searching, sorting and filtering.
     */
    public getList = async (
        advertiser_ids: number[],
        params?: KoddiListFilterParams
    ): Promise<MediaPlan[]> => {
        const config = { params: { advertiser_ids: advertiser_ids.join(',') } };
        const response = await this.axios.get<MediaPlanGetListResponse>(
            `v1/mediaPlans`,
            config
        );
        return response.data.result.media_plans;
    };
}

export default MediaPlanAPI;
