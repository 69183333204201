import { ReduxAction, ReduxActionWithPayload } from 'types';

export enum EntityRegistrationAction {
    INITIATE_ENTITY_REGISTRATION = 'admin/entityRegistration/INITIATE_ENTITY_REGISTRATION',
    INITIATE_ENTITY_REGISTRATION_CSV = 'amin/entityRegistration/INITIATE_ENTITY_REGISTRATION_CSV',
    FETCH_ENTITY_REGISTRATIONS = 'admin/entityRegistration/FETCH_ENTITY_REGISTRATIONS',
    FETCH_ENTITY_REGISTRATIONS_SUCCESS = 'admin/entityRegistration/FETCH_ENTITY_REGISTRATIONS_SUCCESS',
    ERROR_MESSAGE = 'admin/entityRegistration/ERROR_MESSAGE',
    CLEAR_ERROR_MESSSAGE = 'admin/entityRegistration/CLEAR_ERROR_MESSSAGE',
    CLEAR_SAVING_STATE = 'admin/entityRegistration/CLEAR_SAVING_STATE',
    ENTITY_REGISTRATION_REPORT = 'admin/entityRegistration/ENTITY_REGISTRATION_REPORT',
    ENTITY_REGISTRATION_REPORT_PENDING = 'admin/entityRegistration/ENTITY_REGISTRATION_REPORT_PENDING',
    ENTITY_REGISTRATION_REPORT_SUCCESS = 'admin/entityRegistration/ENTITY_REGISTRATION_REPORT_SUCCESS',
    ENTITY_REGISTRATION_REPORT_FAILURE = 'admin/entityRegistration/ENTITY_REGISTRATION_REPORT_FAILURE',
    CLEAR_REGISTRATION_REPORT = 'admin/entityRegistration/CLEAR_REGISTRATION_REPORT',
    ENTITY_REGISTRATION_RETRY = 'admin/entityRegistration/ENTITY_REGISTRATION_RETRY',
    ENTITY_REGISTRATION_RETRY_ALL = 'admin/entityRegistration/ENTITY_REGISTRATION_RETRY_ALL',
    ENTITY_REGISTRATION_HIDE = 'admin/entityRegistration/ENTITY_REGISTRATION_HIDE',
    ENTITY_REGISTRATION_HIDE_ALL = 'admin/entityRegistration/ENTITY_REGISTRATION_HIDE_ALL',
    ENTITY_REGISTRATION_HIDE_OR_RETRY_ERROR = 'admin/entityRegistration/ENTITY_REGISTRATION_HIDE_OR_RETRY_ERROR',
}

export type EntityRegistrationInitialState = {
    events: number[] | null;
    saving: boolean;
    errorMessage: string | null;
};

export type EntityRegistrationReportState = {
    status: string;
    data: EntityRegistrationReportData;
    hideOrRetryError: string;
};

export type EntityRegistrationReportDetails = {
    event_detail_id: number | null;
    event_id: number | null;
    completed_on: string | null;
    internal_id: number | null;
    error_message: string | null;
    error_code: number | null;
};

export type EntityRegistrationReportData = {
    details: EntityRegistrationReportDetails[];
    total: number | null;
};

export type EntityRegistrationState = {
    entityReg: EntityRegistrationInitialState;
    entityRegReport: EntityRegistrationReportState;
};

export type InitiateEntityRegistrationAction = ReduxActionWithPayload<
    EntityRegistrationAction.INITIATE_ENTITY_REGISTRATION,
    {
        memberGroupId: number;
        advertiserId: number;
        uuids: string[];
    }
>;

export type InitiateEntityRegistrationCSVAction = ReduxActionWithPayload<
    EntityRegistrationAction.INITIATE_ENTITY_REGISTRATION_CSV,
    {
        memberGroupId: number;
        advertiserId: number;
        csvFile: File;
    }
>;

export type EntityRegistrationsFetchAction = ReduxActionWithPayload<
    EntityRegistrationAction.FETCH_ENTITY_REGISTRATIONS,
    {
        memberGroupId: number;
        advertiserId: number;
    }
>;

export type EntityRegistrationFetchSucess = ReduxActionWithPayload<
    EntityRegistrationAction.FETCH_ENTITY_REGISTRATIONS_SUCCESS,
    {
        events: number[];
    }
>;

export type EntityRegistrationErrorMessage = ReduxActionWithPayload<
    EntityRegistrationAction.ERROR_MESSAGE,
    {
        message: string;
    }
>;

export type ClearEntityRegistrationSave = ReduxAction<
    EntityRegistrationAction.CLEAR_SAVING_STATE
>;

export type ClearEntityRegistrationErrorMessage = ReduxAction<
    EntityRegistrationAction.CLEAR_ERROR_MESSSAGE
>;

export type EntityRegistrationReport = ReduxActionWithPayload<
    EntityRegistrationAction.ENTITY_REGISTRATION_REPORT,
    { memberGroupId: number; advertiserId: number }
>;

export type EntityRegistrationReportPending = ReduxAction<
    EntityRegistrationAction.ENTITY_REGISTRATION_REPORT_PENDING
>;

export type EntityRegistrationReportSuccess = ReduxActionWithPayload<
    EntityRegistrationAction.ENTITY_REGISTRATION_REPORT_SUCCESS,
    { data: any[] }
>;

export type EntityRegistrationReportFailure = ReduxAction<
    EntityRegistrationAction.ENTITY_REGISTRATION_REPORT_FAILURE
>;

export type ClearEntityRegistrationReport = ReduxAction<
    EntityRegistrationAction.CLEAR_REGISTRATION_REPORT
>;

export type EntityRegistrationRetry = ReduxActionWithPayload<
    EntityRegistrationAction.ENTITY_REGISTRATION_RETRY,
    { memberGroupId: number; advertiserId: number; eventDetailId: number }
>;

export type EntityRegistrationRetryAll = ReduxActionWithPayload<
    EntityRegistrationAction.ENTITY_REGISTRATION_RETRY_ALL,
    { memberGroupId: number; advertiserId: number }
>;

export type EntityRegistrationHide = ReduxActionWithPayload<
    EntityRegistrationAction.ENTITY_REGISTRATION_HIDE,
    { memberGroupId: number; advertiserId: number; eventDetailId: number }
>;

export type EntityRegistrationHideAll = ReduxActionWithPayload<
    EntityRegistrationAction.ENTITY_REGISTRATION_HIDE_ALL,
    { memberGroupId: number; advertiserId: number }
>;

export type EntityRegistrationHideOrRetryError = ReduxActionWithPayload<
    EntityRegistrationAction.ENTITY_REGISTRATION_HIDE_OR_RETRY_ERROR,
    { error: string }
>;

export type EntityRegistrationActions =
    | EntityRegistrationsFetchAction
    | EntityRegistrationFetchSucess
    | InitiateEntityRegistrationAction
    | ClearEntityRegistrationErrorMessage
    | InitiateEntityRegistrationCSVAction
    | ClearEntityRegistrationSave
    | ClearEntityRegistrationReport
    | EntityRegistrationErrorMessage
    | EntityRegistrationReport
    | EntityRegistrationReportPending
    | EntityRegistrationReportSuccess
    | EntityRegistrationReportFailure
    | EntityRegistrationRetry
    | EntityRegistrationRetryAll
    | EntityRegistrationHide
    | EntityRegistrationHideAll
    | EntityRegistrationHideOrRetryError;
