import styled, { css } from 'styled-components';
import { cssValue } from 'koddi-components/utils';

export const TabContentBase = styled.div<{ minHeight?: number | string }>`
    ${({ minHeight }) => css`
        display: flex;
        flex: 1 1 auto;
        align-self: stretch;
        align-items: stretch;
        justify-content: stretch;
        position: relative;
        overflow: hidden;
        min-height: ${cssValue(minHeight)};
    `}
`;

export const TabContentPane = styled.div<{
    noPadding: boolean;
    noHorizontalPadding: boolean;
}>`
    ${({ theme: { spaceUnit }, noPadding, noHorizontalPadding }) => css`
        width: 100%;
        padding: ${noPadding ? 0 : spaceUnit * 5}px;
        ${noHorizontalPadding
            ? css`
                  padding-left: 0px;
                  padding-right: 0px;
              `
            : undefined}
        &:focus {
            outline: none;
        }

        &[aria-hidden='true'] {
            position: absolute;
            width: 100%;
            height: 100%;
            z-index: 0;
            opacity: 0;
        }

        &[aria-hidden='false'] {
            position: relative;
            z-index: 1;
        }
    `}
`;
