import React from 'react';
import { Field } from 'react-final-form';
import Label from 'koddi-components/Label';
import { ErrorText } from 'koddi-components/Form';
import {
    OptionTypeBase,
    ValueType,
    Props as ReactSelectProps,
} from 'react-select';
import Select from './select';
import { SelectFieldProps, SelectOption } from './select.types';
import { LabelWrapper, LabelToolTip } from './select.styled';

function getFinalFormValue<
    OptionType extends OptionTypeBase = { label: string; value: string }
>(option: ValueType<OptionType>, getOptionValue: (option: OptionType) => any) {
    if (option) {
        if (Array.isArray(option)) {
            return (option as OptionType[]).map((o) => getOptionValue(o));
        }
        return getOptionValue(option as OptionType);
    }
    return option;
}

function getReactSelectValue<
    OptionType extends OptionTypeBase = { label: string; value: string }
>(
    finalFormValue: ValueType<OptionType>,
    options: ReactSelectProps<OptionType>['options']
) {
    if (!options) return options;
    if (Array.isArray(options) && Array.isArray(finalFormValue)) {
        return finalFormValue.map((value) =>
            options.find((option: OptionType) => option.value === value)
        );
    }
    if (Array.isArray(options)) {
        return options.find((option: OptionType) => {
            return option.value === finalFormValue;
        });
    }
    return finalFormValue;
}

/**
 * The `SelectField` wraps a react-final-form Field and
 * follows the API of the Koddi Select.
 */
export function SelectField<OptionType extends OptionTypeBase = SelectOption>(
    props: SelectFieldProps<OptionType>
): JSX.Element {
    const {
        name,
        label,
        options,
        onChange,
        getOptionValue = (o) => o.value,
        validate,
        validateFields,
        showError = true,
        value,
        required,
        isDisabled,
        getValueFinalFormValue = true,
        initialValue,
        labelToolTip = false,
        toolTipText,
        ignoreTouchState = false,
        v2 = false,
        ...rest
    } = props;
    const visibility = !isDisabled && required ? 'visible' : 'hidden';
    return (
        <>
            {label && (
                <LabelWrapper
                    visibility={visibility}
                    labelToolTip={labelToolTip}
                >
                    <Label htmlFor={name}>{label}</Label>
                    {labelToolTip && toolTipText && (
                        <LabelToolTip
                            translationKey=""
                            defaultText={toolTipText}
                            icon="info"
                            iconHeight={13}
                            iconWidth={13}
                        />
                    )}
                </LabelWrapper>
            )}
            <Field
                name={name}
                validate={validate}
                validateFields={validateFields}
                initialValue={getFinalFormValue(initialValue, getOptionValue)}
                render={({ input, meta }) => {
                    const handleChange: ReactSelectProps<
                        OptionType
                    >['onChange'] = (option, action) => {
                        if (onChange) onChange(option, action);
                        const finalFormValue = getFinalFormValue(
                            option,
                            getOptionValue
                        );
                        input.onChange(finalFormValue);
                    };

                    let hasError = meta && meta.error && meta.touched;
                    if (ignoreTouchState) {
                        hasError = meta && meta.error;
                    }
                    return (
                        <>
                            <Select<OptionType>
                                {...rest}
                                {...input}
                                btnStyle="rect"
                                hasError={hasError}
                                options={options}
                                onChange={handleChange}
                                initialValue={initialValue}
                                value={
                                    getValueFinalFormValue
                                        ? getReactSelectValue<OptionType>(
                                              input.value,
                                              options
                                          )
                                        : value
                                }
                                v2={v2}
                            />
                            {showError && (
                                <ErrorText hasError={hasError}>
                                    {meta?.error}
                                </ErrorText>
                            )}
                        </>
                    );
                }}
            />
        </>
    );
}

export function MultiSelectField<
    OptionType extends OptionTypeBase = SelectOption
>(props: SelectFieldProps<OptionType>): JSX.Element {
    return <SelectField<OptionType> {...props} isMulti />;
}

export default SelectField;
