/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-empty-function */
import React, {
    FunctionComponent,
    useCallback,
    useState,
    useRef,
    useEffect,
} from 'react';
import { Modals } from 'koddi-components/Modals';
import {
    ModalProviderContextValue,
    ModalProviderState,
    OpenModalOptions,
} from './ModalProvider.types';
import { ModalAnimation } from './ModalProvider.styled';

export const ModalProviderContext = React.createContext<
    ModalProviderContextValue | undefined
>(undefined);

const defaultOnSubmit = () => {
    console.log('You called onSubmit without passing onSubmit to openModal');
};

/**
 * The `ModalProvider` provides child components with the hooks
 * for opening and closing modals.
 */
const ModalProvider: FunctionComponent<{ closeModals?: boolean }> = ({
    children,
    closeModals = false,
}) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [state, setState] = useState<ModalProviderState>({
        onCancel: undefined,
        onClose: undefined,
        onSubmit: undefined,
        modal: undefined,
        props: undefined,
    });
    const { modal, props, onSubmit, onCancel } = state;
    const timeoutRef = useRef<number | undefined>(undefined);

    const Modal = modal ? Modals[modal] : null;

    const handleOpenModal = useCallback(
        (options: OpenModalOptions) => {
            if (timeoutRef.current) clearTimeout(timeoutRef.current);
            setIsOpen(true);
            setState(options);
        },
        [timeoutRef]
    );

    const handleCloseModal = useCallback(() => {
        setIsOpen(false);
        timeoutRef.current = setTimeout(() => {
            setState({
                onCancel: undefined,
                onClose: undefined,
                onSubmit: undefined,
                modal: undefined,
                props: undefined,
            });
        }, 300);
    }, [timeoutRef]);

    const value: ModalProviderContextValue = {
        isOpen,
        openModal: handleOpenModal,
        closeModal: handleCloseModal,
    };

    useEffect(() => {
        return function cleanup() {
            if (timeoutRef.current) clearTimeout(timeoutRef.current);
        };
    }, [timeoutRef]);

    useEffect(() => {
        // close all modals if user is logged out
        if (closeModals) {
            handleCloseModal();
        }
    }, [closeModals, handleCloseModal]);

    return (
        <ModalProviderContext.Provider value={value}>
            <ModalAnimation />
            {Modal && props ? (
                <Modal
                    {...(props as any)}
                    closeModal={handleCloseModal}
                    onSubmit={onSubmit || defaultOnSubmit}
                    onCancel={onCancel}
                    isOpen={isOpen}
                    v2={state?.props?.v2}
                />
            ) : null}
            {children}
        </ModalProviderContext.Provider>
    );
};

export default ModalProvider;
