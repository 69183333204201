import React, { FunctionComponent } from 'react';
import { BaseSVGProps } from '../icon.types';

const ChevronUpIcon: FunctionComponent<BaseSVGProps> = ({ width, height }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        height={height}
        width={width}
    >
        <path d="M0 20.4v-7.6l12-9.2 12 9.2v7.6l-12-9.2z" />
    </svg>
);

export default ChevronUpIcon;
