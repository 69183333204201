import { useFavicon } from 'koddi-components/hooks';

type Favicon = {
    faviconURL: string;
};

const Favicon = ({ faviconURL }: Favicon): null => {
    useFavicon(faviconURL);
    return null;
};

export default Favicon;
