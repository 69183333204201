/* eslint-disable no-nested-ternary */
import styled, { css } from 'styled-components';
import { IconWrapper } from 'koddi-components/Icon';
import { cssProp, cssValue } from 'koddi-components/utils';
import {
    StyledButtonProps,
    ButtonGroupProps,
    ButtonTextProps,
} from './button.types';

export const ButtonText = styled.span<ButtonTextProps>`
    flex: 1;
    ${({ textAlign = 'left' }) => css`
        ${cssProp('text-align', textAlign)};
    `}
`;

export const ButtonBase = styled.button<StyledButtonProps>`
    ${({
        theme,
        disabled,
        minWidth,
        ellipsisOnOverflow,
        iconOnly,
        noPadding,
        width,
        notInteractive,
        errorBackground,
        v2,
        size = 1,
    }) => css`
        outline: none;
        appearance: none;
        text-decoration: none;
        border: none;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        min-height: 30px;
        position: relative;
        cursor: ${disabled ? 'not-allowed' : 'pointer'};
        transition: background-color 0.14s ease-in-out;
        padding: ${noPadding
            ? '0px'
            : `${theme.spaceUnit}px ${theme.spaceUnit * 4}px`};
        user-select: none;
        min-width: ${minWidth || 'auto'};
        font-size: ${theme.typography.baseFontSize};
        font-family: ${theme.typography.fontFamily};
        background-color: ${errorBackground ? theme.error : 'transparent'};

        ${v2
            ? `
            border-radius: 4px;
            padding: ${5 * size}px ${20 * size}px ;
            height: 40px;
            font-size:${
                size !== 1
                    ? `${13 * size * 0.75}px`
                    : theme.typography.baseFontSize
            }
            `
            : ''}

        ${cssProp('width', width)};
        ${cssProp('pointer-events', notInteractive ? 'none' : undefined)};

        ${ellipsisOnOverflow &&
        css`
            overflow: hidden;
            ${ButtonText} {
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
        `}

        ${iconOnly
            ? css`
                  ${IconWrapper} {
                      &:first-child {
                          margin: 0px;
                      }
                  }
              `
            : css`
                  ${IconWrapper} {
                      &:first-child {
                          margin-right: ${theme.spaceUnit * 3}px;
                      }
                      &:last-child {
                          margin-left: ${theme.spaceUnit * 3}px;
                      }
                      & span {
                          display: flex;
                      }
                  }
              `}

        &:focus {
            outline: none;
            appearance: none;
            text-decoration: none;
            border: none;
        }
        &::-moz-focus-inner {
            border: 0;
        }
    `}
`;

export const SecondaryButton = styled(ButtonBase)`
    ${({
        theme: { btnSecondary, btnColorSecondary, createStateColor, gray },
        disabled,
        v2,
    }) => css`
        background-color: ${btnSecondary};
        color: ${disabled ? gray : btnColorSecondary};

        ${v2 ? 'padding: 0px 10px 0px 10px;' : ''}
        ${!disabled &&
        css`
            &:active {
                background-color: ${createStateColor(btnSecondary)};
                color: ${createStateColor(btnColorSecondary)};
            }
            &:hover,
            &:focus:not(:active) {
                background-color: ${createStateColor(btnSecondary, 0.1)};
                color: ${createStateColor(btnColorSecondary, 0.1)};
            }
        `}
    `}
`;

export const PrimaryButton = styled(ButtonBase)`
    ${({
        theme: {
            btnPrimary,
            btnColorPrimary,
            createStateColor,
            grayLight,
            error,
            gray,
        },
        disabled,
        errorBackground,
    }) => css`
        background-color: ${disabled
            ? grayLight
            : errorBackground
            ? error
            : btnPrimary};
        color: ${disabled ? gray : btnColorPrimary};
        ${!disabled &&
        css`
            &:active {
                background-color: ${errorBackground
                    ? error
                    : createStateColor(btnPrimary)};
                color: ${createStateColor(btnColorPrimary)};
            }
            &:hover,
            &:focus:not(:active) {
                background-color: ${errorBackground
                    ? error
                    : createStateColor(btnPrimary, 0.1)};
                color: ${createStateColor(btnColorPrimary, 0.1)};
            }
        `}
    `}
`;

export const TertiaryButton = styled(ButtonBase)`
    ${({ theme }) => css`
        background-color: inherit;
        color: ${theme.gray};
        text-decoration: underline;
        font-size: ${theme.typography.baseFontSize};
        &:focus {
            text-decoration: underline;
        }
        &:hover {
            color: ${theme.grayLight};
        }
    `}
`;

export const PillButton = styled(ButtonBase)`
    ${({
        theme: {
            grayLighter,
            gray,
            black,
            spaceUnit,
            darken,
            lighten,
            typography,
        },
        disabled,
    }) => css`
        font-size: ${typography.baseFontSize};
        border-radius: 20px;
        background-color: ${grayLighter};
        color: ${disabled ? gray : black};
        padding: 0px ${spaceUnit * 6}px;
        ${!disabled &&
        css`
            &:active {
                background-color: ${darken(grayLighter)};
                color: ${lighten(black)};
            }
            &:hover,
            &:focus:not(:active) {
                background-color: ${darken(grayLighter, 0.1)};
                color: ${lighten(black, 0.1)};
            }
        `}
    `}
`;

export const RectButton = styled(ButtonBase)`
    ${({
        theme: { grayLighter, black, gray, darken, lighten },
        disabled,
    }) => css`
        border-radius: 0px;
        background-color: ${grayLighter};
        color: ${disabled ? gray : black};
        ${!disabled &&
        css`
            &:active {
                background-color: ${darken(grayLighter)};
                color: ${lighten(black)};
            }
            &:hover,
            &:focus:not(:active) {
                background-color: ${darken(grayLighter, 0.1)};
                color: ${lighten(black, 0.1)};
            }
        `}
    `}
`;
export const TransparentButton = styled(ButtonBase)`
    ${({ theme: { primary, createStateColor }, disabled }) => css`
        background-color: 'transparent';
        padding: 0px;
        color: ${primary};
        ${!disabled &&
        css`
            &:active {
                color: ${primary};
            }
            &:hover,
            &:focus:not(:active) {
                color: ${createStateColor(primary, 0.1)};
            }
        `}
    `}
`;

export const SelectorButton = styled(ButtonBase)`
    ${({
        theme: {
            spaceUnit,
            typography,
            advertiserSelectorFontColor,
            advertiserSelectorBackground,
            advertiserSelectorActiveBackground,
            advertiserSelectorHoverBackground,
        },
        disabled,
    }) => css`
        font-size: ${typography.baseFontSize};
        border-radius: 5px;
        background-color: ${advertiserSelectorBackground};
        color: ${advertiserSelectorFontColor};
        padding: 0px ${spaceUnit * 3}px;
        ${css`
            ${IconWrapper} {
                &:last-child {
                    margin-left: ${spaceUnit * 5}px;
                    color: ${advertiserSelectorFontColor};
                }
            }
        `}
        ${!disabled &&
        css`
            &:active {
                background-color: ${advertiserSelectorActiveBackground};
            }
            &:hover,
            &:focus:not(:active) {
                background-color: ${advertiserSelectorHoverBackground};
            }
        `}
    `}
`;

export const ButtonGroup = styled.div<ButtonGroupProps>`
    ${({ theme: { spaceUnit }, alignEnd, spacing }) => css`
        display: flex;
        flex-direction: row;
        justify-content: ${alignEnd ? 'flex-end' : 'flex-start'};
        button {
            margin-right: ${cssValue(spacing, `${spaceUnit * 2}px`)};
            &:last-of-type {
                margin-right: 0px;
            }
        }
    `}
`;

export const ButtonLink = styled.div<{ inline?: boolean }>`
    ${({ theme, inline }) => css`
        color: ${theme.linkColor};
        text-decoration: underline;
        margin-top: ${theme.spaceUnit}px;
        font-size: 12px;
        display: ${inline ? 'inline-block' : 'block'};
        &:hover {
            cursor: pointer;
        }
    `}
`;

export const BorderButton = styled(ButtonBase)`
    ${({ theme }) => css`
        border: 1px solid ${theme.btnPrimary};
        color: ${theme.btnPrimary};

        :focus {
            border: 1px solid ${theme.btnPrimary};
        }
    `}
`;

export const IconWithNumberWrapper = styled.div`
    ${({ theme }) => css`
        background-color: white;
        width: 15px;
        height: 15px;
        margin-left: 20px;
        line-height: 12px;
        border-radius: 50%;
        font-size: x-small;
        color: ${theme.primary};
        border: 2px solid #fff;
        text-align: center;
        font-weight: 600;
    `}
`;

export const LoginButton = styled(PrimaryButton)`
    width: 100%;
    font-size: 16px;
    letter-spacing: 0;
    > * {
        text-align: center;
    }
`;

export const LoginSecondaryButton = styled(BorderButton)`
    ${({ theme }) => css`
        border-color: ${theme.grayLight};
        letter-spacing: 0;
        width: 100%;
        font-size: 16px;
        letter-spacing: 0;
        > * {
            text-align: center;
        }
    `}
`;
