import React, { FunctionComponent, useEffect, useState } from 'react';
import { i18n } from 'i18next';
import { I18nextProvider as ReactI18nextProvider } from 'react-i18next';
// import { useLocale } from 'koddi-components/LocaleProvider';
import i18nDefault from './I18nextProvider.i18n';
import { I18nextProviderProps } from './I18nextProvider.types';

/**
 * The Koddi `I18nextProvider` is a wrapper around `react-i18next`'s
 * `I18nextProvider` that automatically changes the language whenever
 * the `locale` from the `LocaleProvider` changes. This ensures that
 * the `I18nextProvider` is always in sync with the locales Koddi supports.
 */
const I18nextProvider: FunctionComponent<I18nextProviderProps> = ({
    children,
    i18n: i18nCustom,
    onInit,
}) => {
    const [i18nInstance] = useState<i18n>(i18nCustom || i18nDefault);
    // const { locale } = useLocale();

    // useEffect(() => {
    //     i18nInstance.changeLanguage(locale);
    // }, [locale, i18nInstance]);

    useEffect(() => {
        if (onInit) onInit(i18nInstance);
    }, [i18nInstance, onInit]);

    return (
        <ReactI18nextProvider i18n={i18nInstance}>
            {children}
        </ReactI18nextProvider>
    );
};

export default I18nextProvider;
