import React, { FunctionComponent } from 'react';
import { BaseSVGProps } from '../icon.types';

const MinusBoxIcon: FunctionComponent<BaseSVGProps> = ({ width, height }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 24 24"
    >
        <path
            d="M20.733,3.933v16.8H3.933V3.933Zm3.6-3.6h-24v24h24Z"
            transform="translate(-0.333 -0.333)"
        />
        <path
            d="M.333.333v20h20v-20Zm16,8.5h-12v3h12Z"
            transform="translate(1.666 1.667)"
            fillRule="evenodd"
        />
    </svg>
);

export default MinusBoxIcon;
