import { ReduxActionWithPayload } from 'types';

export enum KPICardDataAction {
    FETCH_KPI_CARD_DATA = 'app/dashboard/FETCH_KPI_CARD_DATA',
    FETCH_KPI_CARD_DATA_SUCCESS = 'app/dashboard/FETCH_KPI_CARD_DATA_SUCCESS',
    FETCH_KPI_CARD_DATA_ERROR = 'app/dashboard/FETCH_KPI_CARD_DATA_ERROR',
}

export type KPICardInitialState = {
    data: any;
    status: string;
};

export type DashboardState = {
    KPICard: KPICardInitialState;
};

export type ReportTotal = {
    average_basket_size: {
        total: number;
        percentage: number;
    };
    average_conversion_rate: {
        total: number;
        percentage: number;
    };
    cost_per_transaction: {
        total: number;
        percentage: number;
    };
    exposed_roas: {
        total: number;
        percentage: number;
    };
    exposed_revenue: {
        total: number;
        percentage: number;
    };
    exposed_transactions: {
        total: number;
        percentage: number;
    };
    exposed_units: {
        total: number;
        percentage: number;
    };
    impressions: {
        total: number;
        percentage: number;
    };
    clicks: {
        total: number;
        percentage: number;
    };
    click_through_rate: {
        total: number;
        percentage: number;
    };
    cost: {
        total: number;
        percentage: number;
    };
    cost_per_click: {
        total: number;
        percentage: number;
    };
    revenue: {
        total: number;
        percentage: number;
    };
    return_on_ad_spend: {
        total: number;
        percentage: number;
    };
    transactions: {
        total: number;
        percentage: number;
    };
    units: {
        total: number;
        percentage: number;
    };
    halo_units: {
        total: number;
        percentage: number;
    };
    halo_transactions: {
        total: number;
        percentage: number;
    };
    halo_revenue: {
        total: number;
        percentage: number;
    };
    halo_roas: {
        total: number;
        percentage: number;
    };
    halo_exposed_revenue: {
        total: number;
        percentage: number;
    };
    halo_exposed_roas: {
        total: number;
        percentage: number;
    };
    halo_exposed_transactions: {
        total: number;
        percentage: number;
    };
    halo_exposed_units: {
        total: number;
        percentage: number;
    };
};

export type FetchKPICardDataAction = ReduxActionWithPayload<
    KPICardDataAction.FETCH_KPI_CARD_DATA,
    { reportPayload: any }
>;

export type FetchKPICardDataSuccessAction = ReduxActionWithPayload<
    KPICardDataAction.FETCH_KPI_CARD_DATA_SUCCESS,
    { data: any }
>;

export type FetchKPICardDataErrorAction = ReduxActionWithPayload<
    KPICardDataAction.FETCH_KPI_CARD_DATA_ERROR,
    { error: string }
>;

export type KPICardDataActions =
    | FetchKPICardDataAction
    | FetchKPICardDataSuccessAction
    | FetchKPICardDataErrorAction;
