import React, { FunctionComponent } from 'react';
import { BaseSVGProps } from '../icon.types';

const EllipsesIcon: FunctionComponent<BaseSVGProps> = ({ width, height }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        height={height}
        width={width}
        viewBox="0 0 24 24"
    >
        <path d="M12 5.54a2.77 2.77 0 10-2.77-2.77A2.77 2.77 0 0012 5.54zm0 12.92a2.77 2.77 0 102.77 2.77A2.77 2.77 0 0012 18.46zm0-9.23A2.77 2.77 0 1014.77 12 2.77 2.77 0 0012 9.23z" />
    </svg>
);

export default EllipsesIcon;
