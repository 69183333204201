/* eslint-disable default-case */
/* eslint-disable no-param-reassign */
import { Entity } from 'api/Entities';
import createAsyncReducer from 'utils/createAsyncReducer';
import {
    AppEntitiesAction,
    AppEntitiesActions,
    AppEntitiesState,
} from './types';

export const initialState: AppEntitiesState = {
    last_updated: null,
    loading: false,
    error: null,
    data: [],
};

const appEntitiesReducer = createAsyncReducer<
    Entity[],
    AppEntitiesState,
    AppEntitiesActions,
    Entity[]
>(initialState, {
    start: AppEntitiesAction.GET_ENTITIES_LIST,
    success: AppEntitiesAction.GET_ENTITIES_LIST_SUCCESS,
    error: AppEntitiesAction.GET_ENTITIES_LIST_ERROR,
});

export default appEntitiesReducer;
