import { PermissionList } from 'api/Admin/Permission';
import {
    GetPermissionListAction,
    PermissionAction,
    GetPermissionListSuccessAction,
    GetPermissionListErrorAction,
} from './types';

export function getPermissionList(refresh?: boolean): GetPermissionListAction {
    return {
        type: PermissionAction.GET_PERMISSION_LIST,
        payload: { refresh: refresh || false },
    };
}

export function getPermissionListSuccess(
    data: PermissionList
): GetPermissionListSuccessAction {
    return {
        type: PermissionAction.GET_PERMISSION_LIST_SUCCESS,
        payload: { data },
    };
}

export function getPermissionListError(
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    error: any
): GetPermissionListErrorAction {
    return {
        type: PermissionAction.GET_PERMISSION_LIST_ERROR,
        error,
    };
}
