import { useContext } from 'react';
import { TableProviderContext } from './TableProvider';
import { TableProviderContextValue } from './TableProvider.types';

export function useKoddiTable<
    Data extends Record<string, unknown>
>(): TableProviderContextValue<Data> {
    const context = useContext(TableProviderContext);

    if (context === undefined) {
        throw new Error(
            '`useKoddiTable` must be used within a `TableProvider`'
        );
    }
    return context as TableProviderContextValue<Data>;
}
