import { useState, useEffect } from 'react';
import { useForm, useFormState } from 'react-final-form';
import { useBillingStateOptions } from 'koddi/hooks/useBillingCountryState';
import { UseDynamicStateField } from './DynamicStateField.types';

export const useDynamicStateField = ({
    fieldName,
    defaultCountry,
    connectedCountryFieldName,
}: UseDynamicStateField): any => {
    const form = useForm();
    const formState = useFormState();

    const [hasStateOptions, setHasStateOptions] = useState<boolean>();
    const [selectedCountry, setSelectedCountry] = useState<string>(
        defaultCountry
    );

    const stateOptions = useBillingStateOptions(selectedCountry);

    useEffect(() => {
        const { values } = formState;
        const { change } = form;
        if (values[connectedCountryFieldName]) {
            setSelectedCountry(values[connectedCountryFieldName]);
        }
        if (stateOptions.length > 0) {
            if (values[connectedCountryFieldName] !== selectedCountry) {
                change(fieldName, '');
            }
            return setHasStateOptions(true);
        }
        return setHasStateOptions(false);

        // this will prevent needed to add selectedCountry to dependency array, which would create an infinite render
        // eslint-disable-next-line
    }, [stateOptions, form, name, formState]);

    return {
        isHidden: !hasStateOptions,
        selectedCountry,
        stateOptions,
    };
};
