import { createSelector } from 'reselect';
import { ApplicationRootState } from 'redux-core/types';
import { AppSetupState } from './types';

const selectAppSetup = (state: ApplicationRootState): AppSetupState =>
    state.app.setup;

export const selectAppSetupStatus = createSelector(
    selectAppSetup,
    (state: AppSetupState) => state.status
);
