import styled, { css } from 'styled-components';
import { fadeIn } from 'koddi-components/animations';
import { cssValue } from 'koddi-components/utils';
import { TableBaseProps, StyledTableCellProps } from './Table.types';

export const TableFrame = styled.div<{
    sticky: boolean;
    borderless: boolean;
    disabled?: boolean;
    tableLaidOut: boolean;
    overFlowXDisabled?: boolean;
}>`
    ${({
        theme: { grayLight },
        sticky,
        borderless,
        disabled,
        tableLaidOut,
        overFlowXDisabled,
    }) => css`
        overflow: auto;
        overflow-x: ${overFlowXDisabled ? 'hidden' : 'visible'};
        width: 100%;
        border: 1px solid ${borderless ? 'transparent' : grayLight};
        opacity: 0;
        ${
            disabled &&
            css`
                opacity: 0.5;
                pointer-events: none;
            `
        }
        ${
            sticky &&
            css`
                width: 100%;
                display: inline-block;
                max-width: 100%;
            `
        }
        ${
            tableLaidOut &&
            css`
                animation: ${fadeIn} 0.1s linear both;
            `
        }
    `}
`;

export const TableBase = styled.div<TableBaseProps>`
    ${({ sticky, tableHeight }) => css`
        display: flex;
        flex-direction: column;
        border-spacing: 0;
        height: ${tableHeight}px;
        ${sticky &&
        css`
            overflow: auto;
        `}
        overflow-x: visible;
        overflow-y: hidden;
    `}
`;

export const TableRowBase = styled.div<{ odd?: boolean }>`
    opacity: 0;
    animation: ${fadeIn} 0.14s ease-in;
    animation-fill-mode: forwards;
`;

export const CellBase = styled.div<StyledTableCellProps>`
    ${({
        theme: { grayLight, spaceUnit },
        align,
        textEllipsis,
        sticky,
        borderless,
        cellWidth,
        textAlign,
        disableSelection,
    }) => css`
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: ${align || 'flex-start'};
        position: relative;
        padding: 0px ${spaceUnit * 2}px;
        border-right: 1px solid ${borderless ? 'transparent' : grayLight};
        user-select: ${disableSelection ? 'none' : 'text'};
        text-align: ${textAlign || 'inherit'};

        &:last-child {
            border-right: none;
        }

        ${sticky &&
        css`
            max-width: ${cssValue(cellWidth)};
            &[data-sticky-td] {
                position: sticky;
            }
            &[data-sticky-last-left-td] {
                box-shadow: 1px 0px 3px ${grayLight};
            }
            &[data-sticky-first-right-td] {
                box-shadow: -1px 0px 3px ${grayLight};
            }
        `}

        ${textEllipsis &&
        css`
            display: block !important;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        `}
    `}
`;
