import {
    ReduxActionWithError,
    ReduxActionWithPayload,
    ReduxActionWithRefresh,
    AsyncState,
    ReduxActionWithData,
} from 'types';
import { RoleList, Role } from 'api/Admin/Role';

export enum RoleAction {
    GET_ROLE_LIST = 'admin/role/GET_ROLE_LIST',
    GET_ROLE_LIST_SUCCESS = 'admin/role/GET_ROLE_LIST_SUCCESS',
    GET_ROLE_LIST_ERROR = 'admin/role/GET_ROLE_LIST_ERROR',

    EDIT_ROLE = 'admin/role/EDIT_ROLE',
    EDIT_ROLE_SUBMIT = 'admin/role/EDIT_ROLE_SUBMIT',
    EDIT_ROLE_SUBMIT_SUCCESS = 'admin/role/EDIT_ROLE_SUBMIT_SUCCESS',
    EDIT_ROLE_SUBMIT_ERROR = 'admin/role/EDIT_ROLE_SUBMIT_ERROR',
}

export type GetRoleListAction = ReduxActionWithRefresh<
    RoleAction.GET_ROLE_LIST
>;

export type GetRoleListSuccessAction = ReduxActionWithPayload<
    RoleAction.GET_ROLE_LIST_SUCCESS,
    { data: RoleList }
>;

export type GetRoleListErrorAction = ReduxActionWithError<
    RoleAction.GET_ROLE_LIST_ERROR
>;

export type EditRoleAction = ReduxActionWithPayload<
    RoleAction.EDIT_ROLE,
    { role: Role }
>;

type PermissionName = string;

type ActivityName = string;

export type EditRolePermissionInput = {
    activity_id: number;
    permission_id: number;
    role_permission_id: number | undefined;
    selected: boolean;
};

export type EditRoleActivitiesInput = Record<
    ActivityName,
    Record<PermissionName, EditRolePermissionInput>
>;

export type EditRolePayload = {
    name: string;
    id: number;
    activities: EditRoleActivitiesInput;
};

export type EditRoleSubmitAction = ReduxActionWithData<
    RoleAction.EDIT_ROLE_SUBMIT,
    EditRolePayload
>;

export type EditRoleSuccessAction = ReduxActionWithPayload<
    RoleAction.EDIT_ROLE_SUBMIT_SUCCESS,
    { role: Role }
>;

export type EditRoleErrorAction = ReduxActionWithError<
    RoleAction.EDIT_ROLE_SUBMIT_ERROR
>;

export type RoleActions =
    | GetRoleListAction
    | GetRoleListSuccessAction
    | GetRoleListErrorAction
    | EditRoleAction
    | EditRoleSubmitAction
    | EditRoleSuccessAction
    | EditRoleErrorAction;

export type RoleState = {
    list: AsyncState<RoleList>;
};
