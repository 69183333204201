import { combineReducers } from 'redux';
import createAsyncReducer from 'utils/createAsyncReducer';
import { MemberGroupTypesList } from 'api/Admin/MemberGroupTypes';
import { MemberGroupTypesState, MemberGroupTypesAction } from './types';

export const initialState: MemberGroupTypesState = {
    list: {
        last_updated: null,
        loading: false,
        error: null,
        data: null,
    },
};

export const memberGroupTypesListReducer = createAsyncReducer<
    MemberGroupTypesList
>(initialState.list, {
    start: MemberGroupTypesAction.GET_MEMBER_GROUP_TYPES,
    success: MemberGroupTypesAction.GET_MEMBER_GROUP_TYPES_SUCCESS,
    error: MemberGroupTypesAction.GET_MEMBER_GROUP_TYPES_ERROR,
});

const memberGroupTypesReducer = combineReducers<MemberGroupTypesState>({
    list: memberGroupTypesListReducer,
});

export default memberGroupTypesReducer;
