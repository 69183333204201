import {
    ApplicationRootState,
    ApplicationOutputSelector,
} from 'redux-core/types';
import { createSelector } from 'reselect';
import { AsyncState } from 'types';
import { Currency, CurrencyList } from 'api/Admin/Currency';
import { initialState } from './reducer';
import { CurrencyState } from './types';

export const selectAdminCurrency = (
    state: ApplicationRootState
): CurrencyState => state.admin.currency || initialState;

export const selectCurrencyList: ApplicationOutputSelector<
    AsyncState<CurrencyList>,
    (res: CurrencyState) => AsyncState<CurrencyList>
> = createSelector(selectAdminCurrency, (state) => state.list);

export const selectCurrency = (
    currencyId: number
): ApplicationOutputSelector<
    Currency | null,
    (res: AsyncState<CurrencyList>) => Currency | null
> =>
    createSelector(selectCurrencyList, (currencies) => {
        return currencies.data?.find((c) => c.id === currencyId) ?? null;
    });

export const selectCurrencyOptions = createSelector(
    selectCurrencyList,
    (currencies) => {
        return (
            currencies.data?.map((currency) => ({
                label: `${currency.symbol} - ${currency.name} (${currency.code})`,
                value: currency,
            })) ?? []
        );
    }
);

export const selectCurrenciesLoading = createSelector(
    selectAdminCurrency,
    (currencies: CurrencyState) => currencies.list.loading
);
