import React, { FunctionComponent } from 'react';
import { BaseSVGProps } from '../icon.types';

const DownloadIcon: FunctionComponent<BaseSVGProps> = ({ width, height }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        height={height}
        width={width}
        viewBox="0 0 24 24"
    >
        <path d="M19.71 11.14l-2.57-2.57L13.71 12V0h-3.42v12L6.86 8.57l-2.57 2.57L12 18.86zM0 20.57V24h24v-3.43z" />
    </svg>
);

export default DownloadIcon;
