import { combineReducers } from 'redux';
import { RoleList } from 'api/Admin/Role';
import createAsyncReducer from 'utils/createAsyncReducer';
import { RoleState, RoleAction } from './types';

export const initialState: RoleState = {
    list: {
        last_updated: null,
        loading: false,
        error: null,
        data: null,
    },
};

export const roleListReducer = createAsyncReducer<RoleList>(initialState.list, {
    start: RoleAction.GET_ROLE_LIST,
    success: RoleAction.GET_ROLE_LIST_SUCCESS,
    error: RoleAction.GET_ROLE_LIST_ERROR,
});

const roleReducer = combineReducers<RoleState>({
    list: roleListReducer,
});

export default roleReducer;
