/* eslint-disable no-unused-vars */
import CachedKoddiAPI from 'api/api.cachedObject';
import { KoddiAPIResponse } from 'types';
import { KoddiListFilterParams, Order } from 'koddi/types';
import {
    CreateReportInput,
    ReportConfig,
    SavedReport,
    SavedReports,
} from './Reports.types';

export const KODDI_REPORT_API = '/report';
export const KODDI_TREND_REPORT_API = '/report/trend';
export const KODDI_TOTAL_REPORT_API = '/report/total';
export const KODDI_REPORT_CONFIG_API = '/report/config';
export const KODDI_TARGETING_REPORT_API = '/report/targeting';

class ReportAPI extends CachedKoddiAPI {
    public createReportPayload = (
        report: CreateReportInput,
        params?: KoddiListFilterParams,
        excludePagination?: boolean
    ): CreateReportInput => {
        const [id, direction] = params?.sort?.split(' ') ?? [];
        const reportPayload = excludePagination
            ? { ...report }
            : {
                  ...report,
                  pagination: {
                      start: params?.start || 0,
                      count: params?.count || 20,
                  },
              };
        if (id && direction) {
            reportPayload.sort = [
                { field: id, order: (direction as Order) || 'DESC' },
            ];
        }
        return reportPayload;
    };

    /**
     * Creates a report
     * @param report formatted request for which fields and filters to use
     */
    public create = async (
        report: CreateReportInput,
        params?: KoddiListFilterParams
    ): Promise<any> => {
        const endpoint =
            report.context === 'general'
                ? `v2${KODDI_TARGETING_REPORT_API}`
                : `v2${KODDI_REPORT_API}`;
        const response = await this.axios.post<any>(
            endpoint,
            this.createReportPayload(report, params)
        );
        return response.data.result.data || [];
    };

    public downloadTargetingCSV = async (
        report: CreateReportInput,
        params?: KoddiListFilterParams
    ): Promise<any> => {
        const response = await this.axios.post<any>(
            `v2${KODDI_TARGETING_REPORT_API}/csv_download`,
            this.createReportPayload(report, params)
        );
        return response;
    };

    public downloadCSV = async (
        report: CreateReportInput,
        params?: KoddiListFilterParams
    ): Promise<any> => {
        const response = await this.axios.post<any>(
            `v1${KODDI_REPORT_API}/csv_download`,
            this.createReportPayload(report, params, true)
        );
        return response;
    };

    public fetchTrendReport = async (
        report: CreateReportInput,
        params?: KoddiListFilterParams
    ): Promise<any> => {
        const excludePagination = true;
        const response = await this.axios.post<any>(
            `v2${KODDI_TREND_REPORT_API}`,
            this.createReportPayload(report, params, excludePagination)
        );
        return response.data.result || {};
    };

    public fetchTotalReport = async (
        report: CreateReportInput,
        params?: KoddiListFilterParams
    ): Promise<any> => {
        const response = await this.axios.post<any>(
            `v2${KODDI_TOTAL_REPORT_API}`,
            this.createReportPayload(report, params)
        );
        return response.data.result;
    };

    public getConfig = async (memberGroupId: number): Promise<ReportConfig> => {
        const response = await this.cache.makeCachedRequest(
            'getConfig',
            memberGroupId,
            () => {
                return this.axios.get<KoddiAPIResponse<ReportConfig>>(
                    `v1/member_groups/${memberGroupId}/report/config`
                );
            }
        );
        return response.data.result;
    };

    public fetchSavedReports = async (): Promise<SavedReports> => {
        const response = await this.axios.get<any>(`/v1/saved_reports`);

        return response.data.result.saved_reports;
    };

    public getSavedReport = async (reportID: number): Promise<SavedReport> => {
        const response = await this.axios.get<any>(
            `/v1/saved_report/${reportID}`
        );
        return response.data.result;
    };

    public saveReport = async (
        name: string,
        link: string,
        memberGroupId: number,
        advertiserId: number,
        description?: string
    ): Promise<{ saved_report_id: string }> => {
        const response = await this.axios.post<any>(
            `v1/member_groups/${memberGroupId}/advertisers/${advertiserId}/saved_report`,
            {
                name,
                description,
                link,
            }
        );
        return response.data.result;
    };

    public deleteSavedReport = async (reportID: number): Promise<any> => {
        const response = await this.axios.delete<any>(
            `/v1/saved_report/${reportID}`
        );
        return response.data;
    };
}

export default ReportAPI;
