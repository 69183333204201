/* eslint-disable @typescript-eslint/no-unused-vars */
import styled, { css } from 'styled-components';
import Icon from 'koddi-components/Icon';
import { MenuItemState } from './MenuItem.types';

export const MenuItemIcon = styled(Icon)<MenuItemState>`
    ${({ theme }) => css`
        position: relative;
        color: inherit;
        & > span {
            display: flex;
        }
        &:hover {
            color: ${theme.primary};
        }
    `}
`;

export const MenuItemText = styled.span<MenuItemState>`
    ${({ theme }) => css`
        margin-left: ${theme.spaceUnit * 4}px;
        color: inherit;
        &:hover {
            color: ${theme.primary};
        }
    `}
`;

export const MenuItemLink = styled.span<{
    isActive: boolean;
    disabled?: boolean;
}>`
    ${({ theme, isActive, disabled }) => css`
        display: flex;
        align-items: center;
        cursor: ${disabled ? 'not-allowed' : 'inherit'};
        color: ${isActive ? theme.primary : theme.grayDarker};
        font-weight: ${isActive ? 700 : 400};
        a {
            color: inherit;
            display: flex;
            align-items: center;
        }
        &:hover ${MenuItemText}, &:hover ${MenuItemIcon} {
            color: ${isActive ? theme.primary : 'inherit'};
        }
        ${MenuItemIcon} {
            cursor: ${disabled ? 'not-allowed' : 'inherit'};
        }
    `}
`;

export const MenuItemLI = styled.li<MenuItemState>`
    ${({ theme, isActive, nested }) => css`
        margin-bottom: ${theme.spaceUnit * 4}px;
        font-size: ${nested ? '12px' : '13px'};
        position: relative;
        transition: color 0.24s ease-in-out;
        &:before {
            content: '';
            display: ${isActive ? 'block' : 'none'};
            position: absolute;
            height: ${theme.spaceUnit * 7}px;
            width: ${theme.spaceUnit + 3}px;
            left: -${nested ? theme.spaceUnit * 11 : theme.spaceUnit * 8}px;
            top: -${theme.spaceUnit}px;
            background-color: ${nested ? 'transparent' : theme.primary};
        }
        &:last-of-type {
            margin-bottom: 0px;
        }
    `}
`;
