import { AnyAction } from 'redux';
import produce, { Draft } from 'immer';
import { AdvertiserAction, AdvertisersState } from './types';

export const initialState = { status: 'idle' };

const advertisersReducer = (
    state = initialState,
    action: AnyAction
): AdvertisersState =>
    produce(state, (draft: Draft<AdvertisersState>) => {
        switch (action.type) {
            case AdvertiserAction.REMOVE_ADVERTISER_FROM_GROUP:
                draft.status = 'pending';
                break;
            case AdvertiserAction.ADVERTISER_IN_ADVERTISER_GROUP_API_PENDING:
                draft.status = 'pending';
                break;
            case AdvertiserAction.ADVERTISER_IN_ADVERTISER_GROUP_API_SUCCESS:
                draft.status = 'success';
                break;
            case AdvertiserAction.ADVERTISER_IN_ADVERTISER_GROUP_API_FAILURE:
                draft.status = 'failure';
                break;
            default:
        }
    });
export default advertisersReducer;
