import styled, { css } from 'styled-components';
import Icon from 'koddi-components/Icon';
import FlexBox from 'koddi-components/FlexBox';
import { KoddiToast, KoddiToastType } from '../ToastProvider.types';

const iconBackgroundColors: Record<
    KoddiToastType,
    'success' | 'error' | 'warning'
> = {
    success: 'success',
    error: 'error',
    warning: 'warning',
};

export const ToastBase = styled.button`
    ${({ theme: { white, spaceUnit } }) => css`
        background: ${white};
        padding: 0px ${spaceUnit * 5}px;
        box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.15);
        margin-top: ${spaceUnit * 4}px;
        width: 280px;
        height: 80px;
        border: 0px;
        text-align: left;
        &:hover {
            cursor: pointer;
        }
        &:focus {
            outline: none;
        }
    `}
`;

export const ToastStatusIconWrapper = styled(FlexBox)`
    ${({ theme: { spaceUnit } }) => css`
        width: 42px;
        height: 42px;
        flex-shrink: 0;
        margin-right: ${spaceUnit * 4}px;
    `}
`;

export const ToastStatusIcon = styled(Icon)<Pick<KoddiToast, 'type'>>`
    ${({ theme, type }) => css`
        width: 33px;
        height: 33px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: ${theme.white};
        flex-shrink: 0;
        border-radius: 50%;
        background: ${theme[iconBackgroundColors[type]]};
        svg {
            margin-top: 2px;
        }
    `}
`;

export const ToastTitle = styled.div`
    ${({ theme: { spaceUnit } }) => css`
        font-size: 15px;
        margin-bottom: ${spaceUnit}px;
    `}
`;

export const ToastDetails = styled.div`
    font-size: 12px;
`;
