import CachedKoddiAPI from 'api/api.cachedObject';
import { KoddiListFilterParams, ReportFilter } from 'koddi/types';
import { KoddiAPIResponse } from 'types';
import { AdvertiserEntity } from 'api/Advertiser/Advertiser.types';
import { MemberGroup } from '../MemberGroup';
import {
    AdvertiserGroup,
    AdvertiserGroupsList,
    AdvertiserGroupFormPayload,
} from './AdvertiserGroups.types';

const ADVERTISER_GROUP_URL = '/advertiser_groups';

class AdvertiserGroupsAPI extends CachedKoddiAPI {
    public create = async (
        data: AdvertiserGroupFormPayload,
        memberGroupId: number
    ): Promise<AdvertiserGroup> => {
        const response = await this.axios.post<
            KoddiAPIResponse<AdvertiserGroup>
        >(`v1/member_groups/${memberGroupId}/advertiser_groups`, data);
        return response.data.result;
    };

    public update = async (
        member_group_id: number,
        advertiserId: number,
        name: string
    ): Promise<any> => {
        const response = await this.axios.patch<any>(
            `v1/member_groups/${member_group_id}/advertiser_groups/${advertiserId}`,
            {
                member_group_id,
                name,
            }
        );
        this.cache.break('getByID', `${member_group_id}-${advertiserId}`);
        this.cache.breakMethod('getAdvertiserEntitiesReport');
        return response.data.result;
    };

    public get = async (
        advertiser_group_id: AdvertiserGroup['id']
    ): Promise<AdvertiserGroup> => {
        const response = await this.axios.get<
            KoddiAPIResponse<AdvertiserGroup>
        >(`v1${ADVERTISER_GROUP_URL}/${advertiser_group_id}`);
        return response.data.result;
    };

    public getByID = async (
        memberGroupId: number,
        advertiserGroupId: number
    ): Promise<AdvertiserGroup> => {
        const response = await this.cache.makeCachedRequest(
            'getByID',
            `${memberGroupId}-${advertiserGroupId}`,
            () => {
                return this.axios.get<KoddiAPIResponse<AdvertiserGroup>>(
                    `v1/member_groups/${memberGroupId}/advertiser_groups/${advertiserGroupId}`
                );
            }
        );
        return response.data.result;
    };

    /** Gets a list advertiser reporting data */
    public getAdvertiserGroupsReport = async (
        userId: number,
        params?: KoddiListFilterParams,
        advertiserGroupId?: string
    ): Promise<any> => {
        const [id, direction] = params?.sort?.split(' ') ?? [];

        const filters = [
            {
                field: 'id',
                operation: advertiserGroupId ? '=' : '>',
                value: advertiserGroupId || '0',
            },
        ];

        if (params?.search) {
            filters.push({
                field: 'id_name',
                operation: 'ILIKE',
                value: params.search,
            });
        }
        const response = await this.axios.post<any>(
            `v1/users/${userId}/advertiser_groups_report`,
            {
                pagination: {
                    start: params?.start || 0,
                    count: params?.count || 20,
                },
                filters,
                ...(id
                    ? {
                          sort: [
                              {
                                  field: id,
                                  ...(direction ? { order: direction } : {}),
                              },
                          ],
                      }
                    : {}),
            }
        );
        if (advertiserGroupId) {
            return response.data.result.advertiser_groups[0] || [];
        }
        return response.data.result.advertiser_groups || [];
    };

    public deleteAdvertiserGroup = async (
        memberGroupId: number,
        advertiserGroupId: number
    ): Promise<any> => {
        const response = await this.axios.delete<any>(
            `v1/member_groups/${memberGroupId}/advertiser_groups/${advertiserGroupId}`
        );
        return response || [];
    };

    public getList = async (
        params?: KoddiListFilterParams,
        client_ids?: MemberGroup['member_group_id'][]
    ): Promise<AdvertiserGroupsList> => {
        const response = await this.axios.get<
            KoddiAPIResponse<AdvertiserGroupsList>
        >(`v1${ADVERTISER_GROUP_URL}`, {
            params: {
                client_ids: JSON.stringify(client_ids || [1, 2]),
                ...params,
            },
        });
        return response.data.result || [];
    };

    /** Gets the paginated list of users report in the DB. */
    public getAdvertiserGroupReportByClient = async (
        memberGroupId: number,
        params: KoddiListFilterParams
    ): Promise<AdvertiserGroup[]> => {
        const [id, direction] = params?.sort?.split(' ') ?? [];
        const response = await this.axios.post<any>(
            `v1/member_groups/${memberGroupId}/advertiser_groups_report`,
            {
                pagination: {
                    start: params?.start || 0,
                    count: params?.count || 20,
                },
                filters: [
                    {
                        field: 'id_name',
                        operation: 'ILIKE',
                        value: params?.search || '%',
                    },
                ],
                ...(id
                    ? {
                          sort: [
                              {
                                  field: id,
                                  ...(direction ? { order: direction } : {}),
                              },
                          ],
                      }
                    : {}),
            }
        );
        return response.data.result.advertiser_groups || [];
    };

    public getAdvertiserEntitiesReport = async (
        clientId: number,
        advertiserId: number,
        adGroupId: number,
        params: KoddiListFilterParams & {
            filters: ReportFilter[];
        },
        v2?: boolean,
        force?: boolean
    ): Promise<any> => {
        const [id, direction] = params?.sort?.split(' ') ?? [];

        const response = await this.cache.makeCachedRequest(
            'getAdvertiserEntitiesReport',
            `${clientId}-${advertiserId}-${adGroupId}-${JSON.stringify(
                params
            )}`,
            () => {
                return this.axios.post<
                    KoddiAPIResponse<{ entities: AdvertiserEntity[] }>
                >(
                    `v1/member_groups/${clientId}/advertisers/${advertiserId}/ad_groups/${adGroupId}/entities_report`,
                    {
                        pagination: {
                            count: params?.count || 20,
                            start: params?.start || 0,
                        },
                        filters: params.filters || [
                            {
                                field: 'name',
                                operation: 'ILIKE',
                                value: params?.search || '%',
                            },
                        ],
                        ...(id
                            ? {
                                  sort: [
                                      {
                                          field: id,
                                          ...(direction
                                              ? { order: direction }
                                              : {}),
                                      },
                                  ],
                              }
                            : {}),
                    }
                );
            },
            force === undefined ? true : force
        );

        // const response = await
        if (v2) {
            return response.data.result;
        }
        return response.data.result.entities;
    };
}

export default AdvertiserGroupsAPI;
