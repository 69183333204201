import { useSelector } from 'react-redux';
import { KoddiTheme } from 'koddi-components/ThemeProvider';
import { useMemberGroupId } from 'redux-core/app/context/hooks';
import { KoddiThemeResource, ThemeError } from './types';
import {
    selectAppTheme,
    selectAppThemeResource,
    selectAppThemeList,
    selectThemeStatus,
    selectThemeError,
    selectThemeInitialized,
    selectThemeRegistration,
    selectThemeSSOIds,
} from './selectors';

export function useAppTheme(): KoddiTheme {
    return useSelector(selectAppTheme);
}

export function useKoddiThemeResource(): KoddiThemeResource {
    return useSelector(selectAppThemeResource);
}

export function useKoddiThemeList(): KoddiThemeResource[] {
    return useSelector(selectAppThemeList);
}

export function useMemberGroupTheme(
    memberGroupId: string
): KoddiThemeResource | undefined {
    const themes = useSelector(selectAppThemeList);
    return themes.find(
        (theme) => theme.member_group_id === Number(memberGroupId)
    );
}

export function useThemeContextChanged(): boolean {
    const memberGroupId = useMemberGroupId();
    const theme = useKoddiThemeResource();
    return memberGroupId !== theme.member_group_id;
}

export function useThemeStatus(): string {
    return useSelector(selectThemeStatus);
}

export function useThemeError(): ThemeError | null {
    return useSelector(selectThemeError);
}

export function useThemeInitialized(): boolean {
    return useSelector(selectThemeInitialized);
}

export function useThemeHasError(): boolean {
    const status = useThemeStatus();
    const error = useThemeError();
    const memberGroupId = useMemberGroupId();
    return status === 'error' && memberGroupId === error?.memberGroupId;
}

export function useRegistrationEnabled(): boolean {
    return useSelector(selectThemeRegistration);
}

export function useSSOIds(): Record<string, string | undefined> {
    return useSelector(selectThemeSSOIds);
}
