import { useSelector } from 'react-redux';
import {
    selectAppAdvertisers,
    selectSelectedAdvertisers,
    selectSelectedAdvertiserIds,
    selectLoadingStatus,
} from './selectors';
import { AppAdvertisersState, AdvertiserWithSelection } from './types';

export function useAppAdvertisers(): AppAdvertisersState {
    return useSelector(selectAppAdvertisers);
}

// @TODO this should be replaced by app context advertiser
export function useSelectedAdvertisers(): AdvertiserWithSelection[] {
    return useSelector(selectSelectedAdvertisers);
}

export function useSelectedAdvertisersIds(): number[] {
    return useSelector(selectSelectedAdvertiserIds);
}

export function useAdvertisersLoading(): boolean {
    const advertisers = useSelector(selectAppAdvertisers);
    const loading = useSelector(selectLoadingStatus);
    // prevent loading flash if loading new advertisers on top of existing ones
    if (advertisers && advertisers.data && advertisers.data.length)
        return false;
    return loading;
}
