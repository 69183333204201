import React, { useMemo, useEffect } from 'react';
import { Route, useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { useLDClient } from 'launchdarkly-react-client-sdk';

import { useAuthUser } from 'redux-core/auth/hooks';
import {
    useMemberGroupId,
    useAdvertiserId,
    useAdvertiserGroupId,
    useAppContextStatus,
} from 'redux-core/app/context/hooks';
import { useAppTheme } from 'redux-core/app/theme/hooks';
import { setupEmbedded } from 'redux-core/app/setup';

import LoadingSpinner from 'koddi-components/LoadingSpinner';
import ThemeProvider from 'koddi-components/ThemeProvider';
import PermissionsProvider from 'koddi-components/PermissionsProvider';

import Chart from './components/Chart';

const EmbeddedApp = (): JSX.Element => {
    const launchDarkly = useLDClient();
    const appContextStatus = useAppContextStatus();
    const contextMemberGroupId = useMemberGroupId();
    const contextAdvertiserId = useAdvertiserId();
    const contextAdvertiserGroupId = useAdvertiserGroupId();
    const user = useAuthUser();
    const theme = useAppTheme();
    const history = useHistory();
    const dispatch = useDispatch();
    const showLoadingSpinner =
        appContextStatus === 'pending' || appContextStatus === 'idle';

    const userPermissions = useMemo(() => {
        if (contextMemberGroupId === null) return [];
        return (
            user?.base_activities ||
            user?.member_group_activities?.[contextMemberGroupId]?.activities ||
            (contextAdvertiserGroupId &&
                user?.advertiser_group_activities?.[contextAdvertiserGroupId]
                    ?.activities) ||
            (contextAdvertiserId &&
                user?.advertiser_activities?.[contextAdvertiserId]
                    ?.activities) ||
            []
        );
    }, [
        user,
        contextMemberGroupId,
        contextAdvertiserGroupId,
        contextAdvertiserId,
    ]);

    useEffect(() => {
        if (user) {
            dispatch(setupEmbedded(contextMemberGroupId || 0));
        }
    }, [dispatch, contextMemberGroupId, user]);

    useEffect(() => {
        if (user && contextMemberGroupId) {
            launchDarkly?.identify({
                anonymous: false,
                key: String(user?.user_id),
                name: `${user.first_name} ${user.last_name}`,
                email: user.email,
                custom: {
                    clientId: contextMemberGroupId,
                    userId: user?.user_id,
                    advertiserId: Number(contextAdvertiserId),
                },
            });
        }
    }, [user, contextMemberGroupId, contextAdvertiserId, launchDarkly]);

    useEffect(() => {
        const handleMessage = (message: any) => {
            if (message.data.call === 'sendAuth') {
                window.localStorage.setItem(
                    'koddi-auth-session',
                    JSON.stringify(message.data.token)
                );
                if (message.data.options?.component) {
                    history.push(
                        `/embedded/clients/${message.data.clientId}/chart`
                    );
                }
            }
        };
        if (window.addEventListener) {
            window.addEventListener('message', handleMessage, false);
        } else {
            /* @ts-ignore */
            window.attachEvent('onmessage', handleMessage);
        }
    }, [history]);

    return (
        <PermissionsProvider
            roles={user?.roles || []}
            permissions={userPermissions}
        >
            <ThemeProvider theme={theme}>
                {showLoadingSpinner ? (
                    <LoadingSpinner id="embedded-app-spinner" />
                ) : (
                    <Route exact path="/embedded/clients/:clientId/chart">
                        <Chart />
                    </Route>
                )}
            </ThemeProvider>
        </PermissionsProvider>
    );
};

export default EmbeddedApp;
