/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import KoddiAPI from 'api';
import { createSimpleSagaWithCache } from 'utils/createSimpleSaga';
import { takeEvery, all } from 'redux-saga/effects';
import SagaRegistry from 'redux-core/sagaRegistry';
import { MemberGroupTypesList } from 'api/Admin/MemberGroupTypes';
import {
    getMemberGroupTypesError,
    getMemberGroupTypesSuccess,
} from './actions';
import { MemberGroupTypesAction } from './types';
import { selectMemberGroupTypes } from './selectors';

export const getMemberGroupTypesListApi = (): Promise<MemberGroupTypesList> =>
    KoddiAPI.Admin.MemberGroupTypes.getList();

export const getMemberGroupTypesSaga = createSimpleSagaWithCache(
    getMemberGroupTypesListApi,
    getMemberGroupTypesSuccess,
    getMemberGroupTypesError,
    selectMemberGroupTypes
);

export function* watchGetMemberGroupTypes() {
    yield takeEvery(
        MemberGroupTypesAction.GET_MEMBER_GROUP_TYPES,
        getMemberGroupTypesSaga
    );
}

function* memberGroupTypesSagas() {
    yield all([watchGetMemberGroupTypes()]);
}

SagaRegistry.registerSaga(memberGroupTypesSagas);
