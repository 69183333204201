import CachedKoddiAPI from 'api/api.cachedObject';
import { KoddiAPIResponse } from 'types';
import { TimezonesList } from './Timezones.types';

class KoddiTimezonesAPI extends CachedKoddiAPI {
    getList = async (): Promise<TimezonesList> => {
        const response = await this.cache.makeCachedRequest(
            'getList',
            '',
            () => {
                return this.axios.get<
                    KoddiAPIResponse<{ timezones: TimezonesList }>
                >(`v1/timezones`);
            }
        );
        return response.data.result.timezones;
    };
}

export default KoddiTimezonesAPI;
