import format from 'date-fns/format';
/**
 * Formats dates into the required date format for sending to the Koddi
 * API. `yyyy-MM-dd`
 */
function formatDateForKoddiAPI(dateToFormat: string | Date): string {
    return format(new Date(dateToFormat), 'yyyy-MM-dd');
}

export default formatDateForKoddiAPI;
