import {
    ApplicationRootState,
    ApplicationOutputSelector,
} from 'redux-core/types';
import { createSelector } from 'reselect';
import { initialState } from './reducer';
import { AppAdvertisersState, AdvertiserWithSelection } from './types';

const selectState = (state: ApplicationRootState) =>
    state.app.advertisers || initialState;

export const selectAppAdvertisers: ApplicationOutputSelector<
    AppAdvertisersState,
    (res: AppAdvertisersState) => AppAdvertisersState
> = createSelector(selectState, (state) => state);

/** Returns an array of advertisers that are currently selected for the global advertiser filter. */
export const selectSelectedAdvertisers: ApplicationOutputSelector<
    AdvertiserWithSelection[],
    (res: AppAdvertisersState) => AdvertiserWithSelection[]
> = createSelector(selectAppAdvertisers, ({ data, selectedAdvertiserIds }) =>
    data.filter((a) => !!selectedAdvertiserIds[a.advertiser_id])
);

/** Returns an array of advertisers that are currently selected for the global advertiser filter. */
export const selectSelectedAdvertiserIds: ApplicationOutputSelector<
    number[],
    (res: AppAdvertisersState) => number[]
> = createSelector(selectAppAdvertisers, ({ data, selectedAdvertiserIds }) => {
    const filteredIds = data.filter(
        (a) => !!selectedAdvertiserIds[a.advertiser_id]
    );
    const formattedIds = filteredIds.map((a) => Number(a.advertiser_id));
    return formattedIds;
});

export const selectLoadingStatus = createSelector(
    selectState,
    (state: AppAdvertisersState): boolean => state.loading
);

export const selectFetchedMemberGroupId = createSelector(
    selectState,
    (state: AppAdvertisersState) => state.fetchedMemberGroupId
);
