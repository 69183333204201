import { getReturnRoute } from 'api/api';
import { LOGIN_ROUTE } from 'modules/constants/routes';
import { GuardFunction } from './types';

export const requireAuthentication: GuardFunction = (
    to,
    from,
    next,
    params
): void => {
    if (to.meta.auth) {
        const { authStatus } = params;
        if (authStatus === 'signedIn') {
            next();
        } else {
            const returnRoute = getReturnRoute();
            next.redirect(`${LOGIN_ROUTE}?returnRoute=${returnRoute}`);
        }
    } else {
        next();
    }
};

export default requireAuthentication;
