import { applyMiddleware, createStore, compose, StoreEnhancer } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import { createInjectorsEnhancer, forceReducerReload } from 'redux-injectors';
import createSagaMiddleware from 'redux-saga';
// eslint-disable-next-line import/no-unresolved
import { History } from 'history';
import { composeWithDevTools } from 'redux-devtools-extension';
import createReducer from './reducer';
import errorTransformMiddleware from './errorTransformMiddleware';
import { ApplicationRootState, InjectedStore } from './types';
import SagaRegistry from './sagaRegistry';

export default function configureStore(
    initialState: Omit<ApplicationRootState, 'router'>,
    history: History
): InjectedStore {
    const reduxSagaMonitorOptions = {};
    const sagaMiddleware = createSagaMiddleware(reduxSagaMonitorOptions);
    const { run: runSaga } = sagaMiddleware;

    // Create the store with two middlewares
    // 1. sagaMiddleware: Makes redux-sagas work
    // 2. routerMiddleware: Syncs the location/URL path to the state
    // 3. errorTransformMiddleware: Makes Errors work correctly with `Immer`
    const middlewares = [
        sagaMiddleware,
        routerMiddleware(history),
        errorTransformMiddleware(),
    ];

    const enhancers = [
        applyMiddleware(...middlewares),
        createInjectorsEnhancer({
            createReducer: createReducer(history),
            runSaga,
        }),
    ];

    let enhancer: StoreEnhancer;

    // If Redux Dev Tools and Saga Dev Tools Extensions are installed, enable them
    /* istanbul ignore next */
    if (process.env.NODE_ENV !== 'production' && typeof window === 'object') {
        enhancer = composeWithDevTools(...enhancers);
    } else {
        enhancer = compose(...enhancers);
    }

    const store = createStore(
        createReducer(history)(),
        initialState,
        enhancer
    ) as InjectedStore;

    // Extensions
    store.runSaga = sagaMiddleware.run;
    store.injectedReducers = {}; // Reducer registry
    store.injectedSagas = {}; // Saga registry

    // Run the sagas
    SagaRegistry.getAllSagas().forEach(store.runSaga);

    // Make reducers hot reloadable, see http://mxs.is/googmo
    /* istanbul ignore next */
    if (module.hot) {
        module.hot.accept('./reducer', () => {
            forceReducerReload(store);
        });
    }

    return store;
}
