import React, { FunctionComponent } from 'react';
import { BaseSVGProps } from '../icon.types';

const BarChartIcon: FunctionComponent<BaseSVGProps> = ({ width, height }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 24 24"
    >
        <path
            d="M8.364,3.333H4V17.515H8.364Z"
            transform="translate(1.454 1.03)"
        />
        <path d="M12.364,7.333H8V14.97h4.364Z" transform="translate(4 3.576)" />
        <path
            d="M16.364,2.667H12V17.94h4.364Z"
            transform="translate(6.545 0.606)"
        />
        <path
            d="M3.939,21.394V.667H.667v24h24V21.394Z"
            transform="translate(-0.667 -0.667)"
        />
    </svg>
);

export default BarChartIcon;
