/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import KoddiAPI from 'api';
import { takeEvery, all, call, put } from 'redux-saga/effects';
import SagaRegistry from 'redux-core/sagaRegistry';
import { ReportConfig } from 'api/Reports';
import { FiltersAction, GetReportConfigAction } from './types';
import { getReportConfigError, getReportConfigSuccess } from './actions';

export function* getReportConfigApi(action: GetReportConfigAction) {
    try {
        const result: ReportConfig = yield call(
            KoddiAPI.Report.getConfig,
            action.payload.memberGroupId
        );
        yield put(getReportConfigSuccess(result));
    } catch {
        yield put(getReportConfigError());
    }
}

export function* watchReportConfig() {
    yield takeEvery(FiltersAction.GET_REPORT_CONFIG, getReportConfigApi);
}

function* reportConfigSagas() {
    yield all([watchReportConfig()]);
}

SagaRegistry.registerSaga(reportConfigSagas);
