import React, { FunctionComponent } from 'react';
import { BaseSVGProps } from '../icon.types';

const StatusDeactivatedIcon: FunctionComponent<BaseSVGProps> = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        height={10}
        width={10}
        viewBox="0 0 10 10"
    >
        <title>Deactivated</title>

        <g
            id="Group_1221"
            data-name="Group 1221"
            transform="translate(-12 -39)"
        >
            <g id="Group_1167" data-name="Group 1167">
                <circle
                    id="Ellipse_359"
                    data-name="Ellipse 359"
                    cx="5"
                    cy="5"
                    r="5"
                    transform="translate(12 39)"
                    fill="#818181"
                />
            </g>
            <g
                id="Group_988"
                data-name="Group 988"
                transform="translate(-52.5 -42)"
            >
                <line
                    id="Line_319"
                    data-name="Line 319"
                    x2="4"
                    y2="4"
                    transform="translate(67.5 84)"
                    fill="none"
                    stroke="#fff"
                />
                <line
                    id="Line_320"
                    data-name="Line 320"
                    x1="4"
                    y2="4"
                    transform="translate(67.5 84)"
                    fill="none"
                    stroke="#fff"
                />
            </g>
        </g>
    </svg>
);

export default StatusDeactivatedIcon;
