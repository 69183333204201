import React, { FunctionComponent } from 'react';
import { BaseSVGProps } from '../icon.types';

const AddFileIcon: FunctionComponent<BaseSVGProps> = ({ width, height }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        x={width}
        y={height}
        viewBox="0 0 264 264"
    >
        <path
            d="M209.1,33h-32.267V2.383L209.1,33z M190.167,180c23.159,0,42,18.841,42,42s-18.841,42-42,42c-23.159,0-42-18.841-42-42
	S167.007,180,190.167,180z M189.333,198c-4.143,0-7.5,3.357-7.5,7.5v9.5h-8.167c-4.143,0-7.5,3.357-7.5,7.5s3.357,7.5,7.5,7.5h8.167
	v8.5c0,4.143,3.357,7.5,7.5,7.5c4.143,0,7.5-3.357,7.5-7.5V230h9.833c4.143,0,7.5-3.357,7.5-7.5s-3.357-7.5-7.5-7.5h-9.833v-9.5
	C196.833,201.357,193.476,198,189.333,198z M138.801,246.701l-99.324-0.451c-4.134-0.013-7.644-3.617-7.644-7.75V7.5
	c0-4.143,3.69-7.5,7.833-7.5h122.167v40.5c0,4.143,3.69,7.5,7.833,7.5h42.167v121.421c-6-2.845-14.039-4.421-21.834-4.421
	c-31.43,0-56.917,25.57-56.917,57C133.083,230.844,135.191,239.224,138.801,246.701z M63.666,76.5c0,4.143,3.357,7.5,7.5,7.5h99
	c4.143,0,7.5-3.357,7.5-7.5s-3.357-7.5-7.5-7.5h-99C67.024,69,63.666,72.357,63.666,76.5z M63.666,107.5c0,4.143,3.357,7.5,7.5,7.5
	h99c4.143,0,7.5-3.357,7.5-7.5s-3.357-7.5-7.5-7.5h-99C67.024,100,63.666,103.357,63.666,107.5z M71.166,148h99
	c4.143,0,7.5-3.357,7.5-7.5s-3.357-7.5-7.5-7.5h-99c-4.143,0-7.5,3.357-7.5,7.5S67.024,148,71.166,148z"
        />
        <g />
        <g />
        <g />
        <g />
        <g />
        <g />
        <g />
        <g />
        <g />
        <g />
        <g />
        <g />
        <g />
        <g />
        <g />
    </svg>
);

export default AddFileIcon;
