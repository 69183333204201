import { AdvertiserGroup } from 'api/Admin/AdvertiserGroups';
import {
    DeleteAdvertiserGroupAction,
    DeleteAdvertiserGroupSuccessAction,
    DeleteAdvertiserGroupFailureAction,
    AdvertiserGroupAction,
    GetAdvertiserGroupListAction,
    GetAdvertiserGroupListSuccessAction,
    GetAdvertiserGroupListFailureAction,
} from './types';

export function deleteAdvertiserGroup(
    memberGroupId: number,
    advertiserGroupId: number
): DeleteAdvertiserGroupAction {
    return {
        type: AdvertiserGroupAction.DELETE_ADVERTISER_GROUP,
        payload: {
            memberGroupId,
            advertiserGroupId,
        },
    };
}

export function deleteAdvertiserGroupSuccess(): DeleteAdvertiserGroupSuccessAction {
    return {
        type: AdvertiserGroupAction.DELETE_ADVERTISER_GROUP_SUCCESS,
    };
}

export function deleteAdvertiserGroupFailure(): DeleteAdvertiserGroupFailureAction {
    return {
        type: AdvertiserGroupAction.DELETE_ADVERTISER_GROUP_FAILURE,
    };
}

export function getAdvertiserGroupList(
    userId: number
): GetAdvertiserGroupListAction {
    return {
        type: AdvertiserGroupAction.GET_ADVERTISER_GROUP_LIST,
        payload: {
            userId,
        },
    };
}

export function getAdvertiserGroupListSuccess(
    adGroups: AdvertiserGroup[]
): GetAdvertiserGroupListSuccessAction {
    return {
        type: AdvertiserGroupAction.GET_ADVERTISER_GROUP_LIST_SUCCESS,
        payload: {
            adGroups,
        },
    };
}

export function getAdvertiserGroupListFailure(): GetAdvertiserGroupListFailureAction {
    return {
        type: AdvertiserGroupAction.GET_ADVERTISER_GROUP_LIST_ERROR,
    };
}
