import { useSelector } from 'react-redux';
import { useAuthUser, useAuthStatus } from 'redux-core/auth/hooks';
import { useMemberGroupId } from 'redux-core/app/context/hooks';
import { useIsAppReady } from 'redux-core/app/setup/hooks';

import { TermsAndConditionsErrorState } from './types';

import {
    selectHasAccceptedTerms,
    selectTermsUrl,
    selectTermsLoading,
    selectTermsMemberGroupId,
    selectTermsLocaleId,
    selectTermsError,
} from './selectors';

export function useHasAcceptedTerms(): boolean {
    return useSelector(selectHasAccceptedTerms);
}

export function useTermsAndConditionsUrl(): string {
    return useSelector(selectTermsUrl);
}

export function useTermsAndConditionsLoading(): boolean {
    return useSelector(selectTermsLoading);
}

export function useTermsMemberGroupId(): number | null {
    return useSelector(selectTermsMemberGroupId);
}

export function useTermsLocaleId(): number | null {
    return useSelector(selectTermsLocaleId);
}

export function useTermsError(): TermsAndConditionsErrorState {
    return useSelector(selectTermsError);
}

export function useTermsContextChanged(): boolean {
    const user = useAuthUser();
    const termsMemberGroupId = useTermsMemberGroupId();
    const memberGroupId = useMemberGroupId();
    const termsLocaleId = useTermsLocaleId();
    const memberGroupChanged = memberGroupId !== termsMemberGroupId;
    const localeId = user?.locale?.id;
    const localeChanged = localeId !== termsLocaleId;
    return memberGroupChanged || localeChanged;
}

export function useShowTermsAndConditionsModal(): boolean {
    const appReady = useIsAppReady();
    const termsLoading = useTermsAndConditionsLoading();
    const userHasAccepted = useHasAcceptedTerms();
    const termsError = useTermsError();
    const authStatus = useAuthStatus();
    const loggedIn = authStatus === 'signedIn';
    const url = useTermsAndConditionsUrl();

    if (termsError.errorType === 'POST') {
        return true;
    }

    if (termsError.errorType === 'GET') {
        return false;
    }

    return !!(appReady && loggedIn && !userHasAccepted && !termsLoading && url);
}
