import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { formatDate, KoddiLocaleKey } from 'koddi-components/LocaleProvider';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n.use(initReactI18next)
    .use(LanguageDetector)
    .init({
        lng: 'en-US',
        fallbackLng: [],
        interpolation: {
            escapeValue: false,
            format: (value, format, lng) => {
                if (value instanceof Date)
                    return formatDate(value, lng as KoddiLocaleKey, format);
                return value;
            },
        },
        react: {
            useSuspense: false,
        },
    });

export default i18n;
