/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {
    OpenEditorAction,
    EditorAction,
    OpenEditorPayload,
    CloseEditorAction,
    EditorSubmitAction,
    EditorSubmitSuccessAction,
    EditorSubmitErrorAction,
} from './types';

export function openEditor<Data extends Record<string, unknown>>(
    payload: OpenEditorPayload<Data>
): OpenEditorAction<Data> {
    return {
        type: EditorAction.OPEN_EDITOR,
        payload,
    };
}

export function closeEditor(): CloseEditorAction {
    return {
        type: EditorAction.CLOSE_EDITOR,
    };
}

export function editorSubmit(): EditorSubmitAction {
    return {
        type: EditorAction.EDITOR_SUBMIT,
    };
}

export function editorSubmitSuccess(): EditorSubmitSuccessAction {
    return {
        type: EditorAction.EDITOR_SUBMIT_SUCCESS,
    };
}

export function editorSubmitError(error: any): EditorSubmitErrorAction {
    return {
        type: EditorAction.EDITOR_SUBMIT_ERROR,
        error,
    };
}
