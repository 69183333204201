import React, { FunctionComponent } from 'react';
import { KoddiTheme, useKoddiTheme } from 'koddi-components/ThemeProvider';
import LoadingSpinnerIcon from 'koddi-components/Icon/icons/loading_spinner';
import { FadeTransitionProps } from 'koddi-components/Transitions/FadeTransition';
import { LoadingSpinnerProps } from './loadingSpinner.types';
import {
    LoadingSpinnerContainer,
    LoadingSpinnerOverlay,
    LoadingSpinnerFadeTransition,
} from './loadingSpinner.styled';

const SPINNER_SIZES = {
    large: 48,
    small: 24,
};

/**
 * The Koddi `Loading Spinner` Component
 *
 * `reportSuccess` - boolean for whether to show checkmark animation
 * 
 * `onSuccessReported` - success animation finished, use this callback to hide the spinner
 * 
 * `absolutePosition` - positioning boolean
 *

 * Example Usage:

        <LoadingSpinner id="my-spinner" reportSuccess onSuccessReported={() => {}} />

 */
const LoadingSpinnerComponent: FunctionComponent<LoadingSpinnerProps> = ({
    size = 'large',
    withOverlay,
    reportSuccess = false,
    successLabel = 'Updated!',
    onSuccessReported,
    absolutePosition = false,
    hideContentBelow = false,
    id,
    height,
}) => {
    const theme: KoddiTheme = useKoddiTheme();
    const spinnerSize = SPINNER_SIZES[size];
    return (
        <LoadingSpinnerContainer
            absolutePosition={absolutePosition}
            data-test={`${id}-loading-spinner`}
            hideContentBelow={hideContentBelow}
            height={height}
        >
            {withOverlay && <LoadingSpinnerOverlay />}
            <LoadingSpinnerIcon
                reportSuccess={reportSuccess}
                onSuccessReported={onSuccessReported}
                successLabel={successLabel}
                height={spinnerSize}
                width={spinnerSize}
                color={theme.black}
            />
        </LoadingSpinnerContainer>
    );
};

/**
 * A LoadingSpinner that fades in and out to provide
 * smooth transitions between dom elements.
 *
 * @TODO Eventually all loading spinners will use this by default
 * but that will bloat this PR too much so a follow up PR will migrate
 * our exisiting loading spinners.
 */
export const LoadingSpinnerWithTransition: FunctionComponent<
    LoadingSpinnerProps & FadeTransitionProps
> = ({
    in: inProp,
    appear,
    duration,
    absolutePosition,
    hideContentBelow = false,
    ...rest
}) => {
    return (
        <LoadingSpinnerFadeTransition
            in={inProp}
            duration={duration}
            appear={appear}
            absolutePosition={absolutePosition}
            hideContentBelow={hideContentBelow}
            mountOnEnter
            unmountOnExit
        >
            <LoadingSpinnerComponent
                {...rest}
                absolutePosition={absolutePosition}
                hideContentBelow={hideContentBelow}
            />
        </LoadingSpinnerFadeTransition>
    );
};

export default LoadingSpinnerComponent;
