import React, { FunctionComponent } from 'react';
import { BaseSVGProps } from '../icon.types';

const AccountProfileIcon: FunctionComponent<BaseSVGProps> = ({
    width,
    height,
}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 24 24"
    >
        <path d="M12 0a12 12 0 1012 12A12 12 0 0012 0z" />
        <path
            d="M12 11.78A3.68 3.68 0 108.32 8.1 3.68 3.68 0 0012 11.78zm.05.25H12a6.21 6.21 0 00-6.21 6.21v4a11.93 11.93 0 0012.48 0v-4A6.21 6.21 0 0012.05 12z"
            className="icon-gray"
        />
    </svg>
);

export default AccountProfileIcon;
