/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { RouteMatchAction, RouteMatchState } from './types';

export const initialState: RouteMatchState | null = null;

const routeMatchReducer = (
    state: RouteMatchState | null = initialState,
    action: any
): RouteMatchState | null => {
    switch (action.type) {
        case RouteMatchAction.ROUTE_MATCH_CHANGED:
            return action.payload.routeMatch;
        case RouteMatchAction.CLEAR_ROUTE_MATCH:
            return null;
        default:
            return state;
    }
};

export default routeMatchReducer;
