import styled, { css } from 'styled-components';
import { ButtonBase, ButtonText } from 'koddi-components/Button';
import { IconWrapper } from 'koddi-components/Icon';

export const AdvertiserSelectContainer = styled.div`
    ${({ theme }) => css`
        margin-right: ${theme.spaceUnit * 4}px;
        button {
            background-color: ${theme.advertiserSelectorBackground ||
            theme.grayDarkest};
            &:hover {
                background-color: ${theme.advertiserSelectorHoverBackground ||
                theme.grayDarker};
            }
            &:active {
                background-color: ${theme.advertiserSelectorActiveBackground ||
                '#bbb'};
            }
            &:focus:not(:active) {
                background-color: ${theme.advertiserSelectorActiveBackground ||
                '#bbb'};
            }
        }
        ${ButtonBase} {
            padding: 10px;
        }
        ${ButtonText} {
            color: ${theme.advertiserSelectorFontColor || '#ffffff'};
        }
        ${IconWrapper} {
            &:last-child {
                margin-left: ${theme.spaceUnit * 18}px;
            }
        }
        button:disabled {
            opacity: ${theme.opacity.disabled};
        }
    `}
`;

export const MultiAdvertiserWrapper = styled.div<{ contextChanging: boolean }>`
    ${({ theme, contextChanging }) => css`
        ${contextChanging &&
        css`
            button {
                background-color: ${theme.grayLight};
                color: transparent;
                opactity: 0.1;
                &:hover {
                    fill: #444;
                    color: transparent;
                    z-index: -1000;
                }
            }
        `}
    `}
`;
