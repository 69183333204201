import {
    ReduxActionWithError,
    ReduxActionWithPayload,
    ReduxActionWithRefresh,
    AsyncState,
} from 'types';
import { RolePermissionList } from 'api/Admin/RolePermission';

export enum RolePermissionAction {
    GET_ROLE_PERMISSION_LIST = 'admin/role_permission/GET_ROLE_PERMISSION_LIST',
    GET_ROLE_PERMISSION_LIST_SUCCESS = 'admin/role_permission/GET_ROLE_PERMISSION_LIST_SUCCESS',
    GET_ROLE_PERMISSION_LIST_ERROR = 'admin/role_permission/GET_ROLE_PERMISSION_LIST_ERROR',
}

export type GetRolePermissionListAction = ReduxActionWithRefresh<
    RolePermissionAction.GET_ROLE_PERMISSION_LIST
>;

export type GetRolePermissionListSuccessAction = ReduxActionWithPayload<
    RolePermissionAction.GET_ROLE_PERMISSION_LIST_SUCCESS,
    { data: RolePermissionList }
>;

export type GetRolePermissionListErrorAction = ReduxActionWithError<
    RolePermissionAction.GET_ROLE_PERMISSION_LIST_ERROR
>;

export type RolePermissionActions =
    | GetRolePermissionListAction
    | GetRolePermissionListSuccessAction
    | GetRolePermissionListErrorAction;

export type RolePermissionState = {
    list: AsyncState<RolePermissionList>;
};
