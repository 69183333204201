import { combineReducers, Reducer, CombinedState } from 'redux';
import { connectRouter } from 'connected-react-router';
import { History } from 'history';
import { authReducer } from './auth';
import { adminReducer } from './admin';
import { appReducer } from './app';
import { ApplicationRootState } from './types';

/**
 * Merges the main reducer with the router state and dynamically injected reducers
 */
const createReducer = (history: History) => (
    injectedReducers = {}
): Reducer<CombinedState<ApplicationRootState>> => {
    const rootReducer = combineReducers<ApplicationRootState>({
        app: appReducer,
        auth: authReducer,
        admin: adminReducer,
        router: connectRouter(history),
        ...injectedReducers,
    });
    return rootReducer;
};

export default createReducer;
