import styled, { css } from 'styled-components';
import { HorizontalFlexBox } from 'koddi-components/FlexBox';
import { Heading3, Heading4 } from 'koddi-components/Typography';

export const ModalWrapper = styled.div`
    ${({ theme: { spaceUnit } }) => css`
        padding: ${spaceUnit * 5}px;
        max-height: 80vh;
        overflow: scroll;
    `}
`;

export const AddressModalFooter = styled(HorizontalFlexBox)`
    justify-content: flex-end;
`;

export const ModalHeader = styled(Heading3)`
    ${({ theme: { spaceUnit } }) => css`
        margin: 0px 0px ${spaceUnit * 4}px 0px;
        display: inline-block;
    `}
`;

export const OptionalTaxIDHeader = styled(Heading4)`
    ${({ theme: { spaceUnit } }) => css`
        margin: 0px 0px ${spaceUnit * 4}px 0px;
    `}
`;

export const BillingDisclaimer = styled.p`
    ${({ theme: { gray } }) => css`
        color: ${gray};
        font-size: 10px;
        line-height: 1.3em;
    `}
`;
