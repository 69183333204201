import { useSelector } from 'react-redux';

import { useAuthStatus } from 'redux-core/auth/hooks';
import {
    selectAppContextInitialized,
    selectAppContextStatus,
} from 'redux-core/app/context/selectors';
import { useAdvertisersLoading } from 'redux-core/app/advertisers/hooks';
import {
    useThemeStatus,
    useThemeInitialized,
} from 'redux-core/app/theme/hooks';
import { selectAppSetupStatus } from './selectors';

export function useAppStatus(): string {
    return useSelector(selectAppSetupStatus);
}

export function useIsAppReady(): boolean {
    const setupStatus = useAppStatus();
    const authStatus = useAuthStatus();
    const appContextStatus = useSelector(selectAppContextStatus);
    const contextInitialized = useSelector(selectAppContextInitialized);
    const advertisersLoading = useAdvertisersLoading();
    const themeStatus = useThemeStatus();
    const themeInitialized = useThemeInitialized();
    const themeLoading =
        !themeInitialized &&
        (themeStatus === 'pending' || themeStatus === 'idle');
    const settingUpApp = setupStatus === 'pending' || setupStatus === 'idle';
    const appContextLoading =
        !contextInitialized &&
        (appContextStatus === 'idle' || appContextStatus === 'pending');
    if (
        [
            'signIn',
            'accountSetup',
            'forgotPassword',
            'confirmPassword',
            'resetPassword',
        ].includes(authStatus)
    ) {
        return true;
    }
    if (authStatus === 'attemptingLoginOnLoad') {
        return false;
    }
    return (
        !settingUpApp &&
        !appContextLoading &&
        !advertisersLoading &&
        !themeLoading
    );
}
